
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="银行名称">
                        <el-input v-model="queryForm.bankName" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="状态">
                        <el-select class="select_width" v-model="queryForm.isEnable">
                            <el-option value="1" label="启用"></el-option>
                            <el-option value="0" label="禁用"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="使用场景">
                        <el-select class="select_width" v-model="queryForm.useType" clearable filterable>
                            <el-option value="1" label="收款"></el-option>
                            <el-option value="2" label="付款"></el-option>
                            <el-option value="3" label="备用金"></el-option>
                            <el-option value="0" label="其他"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="币种">
                        <f-select
                                v-model="queryForm.cur"
                                :fatherVal="queryForm.cur"
                                dict="bd_cur"
                        ></f-select>
                    </el-form-item>
                </el-col>
            </el-row>

              <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="开户名">
                    <el-input v-model="queryForm.accountName" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="账号归属">
                    <f-select
                        v-model="queryForm.accountOwnership"
                        :isNeed="queryForm.accountOwnership"
                        dict="account_ownership">
                    </f-select>
                  </el-form-item>
                </el-col>
              </el-row>

            <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card">
             <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
                           v-if="buttonShowList.add"
                >新增</el-button>
               <el-button type="primary" size="medium" @click="handleExportXls('银行账号导出')" v-if="buttonShowList.export">导出</el-button>
            </div>

            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="66vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row,false)"
                               v-if="buttonShowList.bj"
                    >编辑</el-button>
                    <el-button type="text" @click="handleDele(scope.row)"
                               v-if="buttonShowList.sc"
                    >删除</el-button>
                    <el-button type="text" @click="handleEnable(scope.row, 1)" v-if="scope.row.isEnableText =='禁用'">启用</el-button>
                    <el-button type="text" @click="handleEnable(scope.row, 0)" v-else="scope.row.isEnableText">禁用</el-button>
                       <el-button type="text" @click="handleEdit(scope.row,true)"
                               v-if="buttonShowList.sc"
                    >复制</el-button>
                </template>
              <template v-else-if="scope.column.property === 'accountOwnership'">
                <span>{{parseDict(scope.row.accountOwnership,'account_ownership')}}</span>
              </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog :title="form.id && isCopy?'复制银行账号': form.id && !isCopy? '编辑银行账号':'新增银行账号'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="银行全称" :label-width="formLabelWidth" prop="bankName">
                    <el-input v-model="form.bankName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="币种" :label-width="formLabelWidth" prop="cur">
                  <el-input v-model="form.cur" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户名" :label-width="formLabelWidth" prop="accountName">
                  <el-input v-model="form.accountName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行地址" :label-width="formLabelWidth" prop="bankAddress">
                  <el-input v-model="form.bankAddress" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用场景" :label-width="formLabelWidth" prop="useTypeList">
                    <el-select class="select_width" v-model="form.useTypeList" placeholder="可选使用场景" :multiple="true" filterable clearable>
                      <el-option value="1" label="收款"></el-option>
                      <el-option value="2" label="付款"></el-option>
                      <el-option value="3" label="备用金"></el-option>
                      <el-option value="0" label="其他"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="account">
                  <el-input v-model="form.account" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Swift Code" :label-width="formLabelWidth" prop="swiftCode">
                  <el-input v-model="form.swiftCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="公司地址" :label-width="formLabelWidth" prop="branchAddress">
                  <el-input v-model="form.branchAddress" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Bank code" :label-width="formLabelWidth" prop="bankCode">
                  <el-input v-model="form.bankCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Branch code" :label-width="formLabelWidth" prop="branchCode">
                  <el-input v-model="form.branchCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="金蝶code" prop="kingdeeCode" :label-width="formLabelWidth">
                  <el-input v-model="form.kingdeeCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注:" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="form.remark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="排序:" :label-width="formLabelWidth" prop="sortNum">
                  <el-input v-model="form.sortNum" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="账号归属:" :label-width="formLabelWidth" prop="accountOwnership">
                  <f-select
                      v-model="form.accountOwnership"
                      :isNeed="form.accountOwnership"
                      dict="account_ownership">
                  </f-select>
                </el-form-item>
                <el-form-item label="是否启用:" :label-width="formLabelWidth" prop="isEnable">
                  <el-switch v-model="form.isEnable" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction,getcityList,getstateList,getallcountries} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {zhengshuPattern} from "@/utils/pattern";
    //import {mobilePattern} from '@/utils/pattern'

    export default {
        name: 'Importer',
        components: {},

        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                  bankName: [
                        {required: true, message: '请输入银行名称', trigger: 'change'},
                        {max: 100, message: '长度不能超过100', trigger: 'change'},
                    ],
                  bankAddress: [
                        {required: false, message: '请输入银行地址', trigger: 'change'},
                        {max: 200, message: '长度不能超过50', trigger: 'change'},
                    ],
                  cur: [
                        {required: true, message: '请输入币种', trigger: 'change'},
                        {max: 20, message: '长度不能超过20', trigger: 'change'},
                    ],
                  accountName: [
                        {required: true, message: '请输入开户名', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                  account: [
                        {required: true, message: '请输入账号', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                  swiftCode: [
                        {required: false, message: '请输入银行识别码', trigger: 'change'},
                    ],
                  remark: [
                        {required: false, message: '请输入备注', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                  sortNum: [
                    {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'},
                  ],
                  accountOwnership: [
                    {required: true, message: '请输入账号归属', trigger: 'change'},
                    {max: 20, message: '长度不能超过20', trigger: 'change'},
                  ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                dictCodes: ['account_ownership'],
                columns: [
                  {
                    type: "selection",

                  },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'isEnableText',
                        label: '状态',
                        width: 80,
                    },
                    {
                        prop: 'bankName',
                        label: '银行名称',
                        /*sortable: 'custom',*/

                    },
                    {
                        prop: 'bankAddress',
                        label: '银行地址',
                        /*sortable: 'custom',*/

                    },

                    {
                        prop: 'cur',
                        label: '币种',
                        /*sortable: 'custom',*/

                    },
                    {
                        prop: 'accountName',
                        label: '开户名',
                        /*sortable: 'custom',*/

                    },
                    {
                        prop: 'account',
                        label: '账号',
                        // sortable: 'custom',

                    },
                    {
                        prop: 'useTypeText',
                        label: '使用场景',

                    },
                    {
                        prop: 'swiftCode',
                        label: 'Swift Code',
                        /*sortable: 'custom',*/

                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        /*sortable: 'custom',*/

                    },
                  {
                    prop: 'branchAddress',
                    label: '公司地址',

                  },
                  {
                    prop: 'bankCode',
                    label: 'Bank Code',

                  },
                  {
                    prop: 'branchCode',
                    label: 'Branch Code',

                  },
                  {
                    prop: 'kingdeeCode',
                    label: '金蝶银行科目',

                  },
                  {
                    prop: 'accountOwnership',
                    label: '账号归属',

                  },
                  {
                    prop: 'sortNum',
                    label: '排序',
                    width: 80,
                  },
                ],
                url: {
                    list: '/sys/basebank/list',
                    add: '/sys/basebank/add',
                    edit: '/sys/basebank/edit',
                    getById: '/sys/basebank/queryById',
                    delete: '/sys/basebank/delete',
                    changeEnableStatus: '/sys/basebank/changeEnableStatus',
                    exportXlsUrl:'/file/export/excel/exportSysBank',
                },
                isCopy:false
            }
        },
        methods: {
            /*handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },*/
            handleAdd() {
                this.isCopy = false
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row,flag) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.isCopy = flag
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id && !this.isCopy) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            if(this.isCopy) {
                                delete this.form.id
                            }
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除该银行账号`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleEnable(row, type) {
                var tips = type == '1' ? '启用' : '禁用';
                this.$confirm(`确定${tips}账户[${row.bankName}]吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    this.form = {};
                    this.form.id = row.id;
                    this.form.isEnable = type;
                    putAction(this.url.changeEnableStatus, this.form).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                    this.form = {};
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            copyInfo(row) {

            }
        }, created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/BankManage','0','新增'),
            'bj':this.$btnIsShow('isystem/BaseData/BankManage','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/BankManage','1','删除'),
            'export':this.$btnIsShow('isystem/BaseData/BankManage','0','导出'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
