<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="queryForm"
      :model="queryForm"
      label-width="86px"
    >
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="4">
            <el-form-item label="订单号">
              <el-input
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="HS是否有效">
              <f-select
                v-model="queryForm.hsValid"
                :isNeed="queryForm.hsValid"
                :dict="'is_default'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="审核HS状态">
              <el-select clearable filterable v-model="queryForm.goodsCheckHs">
                <el-option value="0" label="未审核"></el-option>
                <el-option value="1" label="已审核"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否已确认发票">
              <f-select
                :isNeed="queryForm.invoiceStatus"
                v-model="queryForm.invoiceStatus"
                :dict="'is_default'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否包税">
              <f-select
                v-model="queryForm.hasTax"
                :isNeed="queryForm.hasTax"
                :dict="'is_default'"
              ></f-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label-width="20px">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button size="medium" plain @click="handleReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card>
      <div class="f-p-b-15">
        <el-button
          type="primary"
          @click="reviewInformation({})"
          v-if="buttonShowList.shHS"
          :disabled="!selectionRows.length"
          >批量审核HS
        </el-button>
        <el-button
          type="primary"
          @click="saveInformation({})"
          v-if="buttonShowList.bc"
          :disabled="!selectionRows.length"
          >保存
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="61vh"
        @selection-change="handleSelectionChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="editorColumn(scope.$index)"
              v-if="buttonShowList.bj"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="reviewInformation(scope.row)"
              v-if="buttonShowList.shHS"
              >审核
            </el-button>
            <el-button
              type="text"
              @click="saveInformation(scope.row)"
              v-if="buttonShowList.bc"
              >保存
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'goodsCheckHs'">
            <span :class="scope.row.goodsCheckHs == '0' ? 'red_color' : ''">{{
              scope.row.goodsCheckHs == "0" ? "未审核" : "已审核"
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'name'">
            <f-select
              v-model="scope.row.nameid"
              :is-need="scope.row.nameid"
              :getDictUrl="url.getProductList"
              :getDictParams="{
                cusId: scope.row.cusId,
              }"
              @changetAllData="(obj) => updatePro(scope.$index, obj)"
              v-if="scope.row.isEditor"
            ></f-select>
            <span v-else>{{ scope.row.name }}</span>
          </template>
          <template v-else-if="scope.column.property === 'ename'">
            <f-select
              v-model="scope.row.enameid"
              :is-need="scope.row.enameid"
              :getDictUrl="url.getProductList"
              :getDictParams="{
                cusId: scope.row.cusId,
              }"
              v-if="scope.row.isEditor"
              @changetAllData="(obj) => updatePro(scope.$index, obj)"
            ></f-select>
            <span v-else>{{ scope.row.ename }}</span>
          </template>
          <template v-else-if="scope.column.property === 'material'">
            <el-input
              v-model.trim="scope.row.material"
              maxlength="50"
              v-if="scope.row.isEditor"
            ></el-input>
            <span v-else>{{ scope.row.material }}</span>
          </template>
          <template v-else-if="scope.column.property === 'purpose'">
            <el-input
              v-model.trim="scope.row.purpose"
              maxlength="50"
              v-if="scope.row.isEditor"
            ></el-input>
            <span v-else>{{ scope.row.purpose }}</span>
          </template>
          <template v-else-if="scope.column.property === 'hscode'">
            <el-input
              v-model.trim="scope.row.hscode"
              maxlength="50"
              v-if="scope.row.isEditor"
              @input="
                updateGoods33(scope.row.hscode, scope.$index, scope.row.country)
              "
            ></el-input>
            <span v-else>{{ scope.row.hscode }}</span>
          </template>
          <template v-else-if="scope.column.property === 'taxRate'">
            <el-input
              v-model.trim="scope.row.taxRate"
              maxlength="50"
              v-if="scope.row.isEditor"
            ></el-input>
            <span v-else>{{ scope.row.taxRate }}</span>
          </template>
          <template v-else-if="scope.column.property === 'remark'">
            <el-input
              v-model.trim="scope.row.remark"
              maxlength="50"
              v-if="scope.row.isEditor"
            ></el-input>
            <span v-else>{{ scope.row.remark }}</span>
          </template>
          <template v-else-if="scope.column.property === 'country'">
            <f-select
              v-model="scope.row.country"
              :is-need="scope.row.country"
              v-if="scope.row.isEditor"
              :dict="'bd_country'"
            ></f-select>
            <span v-else>{{ scope.row.country }}</span>
          </template>
          <template v-else-if="scope.column.property === 'addtionalCode'">
            <f-select
              v-model="scope.row.addtionalCode"
              :is-need="scope.row.addtionalCode"
              dict="hs_code_301"
              v-if="scope.row.isEditor"
            ></f-select>
            <span v-else>{{ scope.row.addtionalCode }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <span v-for="(item, index) in scope.row.taxList" :key="index">
              <i
                class="el-icon-question"
                @click="lockRecord(scope.row)"
                v-if="scope.row.hasGuanwu == '1' && index == 0"
              ></i>
              <span
                :class="item.hasTax == '1' ? 'yellow_color' : ''"
                :style="changeColorFromRow(scope.row,item.orderNo)"
                @click.prevent="$intoDocPage(item.orderNo)"
                >{{ item.orderNo }}</span
              >

              <span v-if="index < scope.row.taxList.length - 1">,</span>
            </span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <customs-record-dialog
      :visible="recordVisible"
      :cusId="cusId"
      @update:visible="
        (val) => {
          recordVisible = val;
        }
      "
      :changeUrl="true"
    />
  </div>
</template>
  <script>
import QueryMixins from "@/mixins/QueryMixins";
import {
  deleteAction,
  getAction,
  getallcountries,
  postAction,
} from "@/api/manage";

export default {
  mixins: [QueryMixins],
  components: {},
  props: {},
  data() {
    return {
      isUsePost: true,
      queryForm: {
        invoiceStatus: "0",
      },
      url: {
        list: "/interface/orderCheck/getCheckHsPage",
        udpateGoodsCheckHs: "/interface/orderCheck/udpateGoodsCheckHs",
        updateGoodsInfo: "/interface/orderCheck/updateGoodsInfo",
        get301binamByHscode: "/interface/ttOrder/get301binamByHscode",
        getProductList: "/interface/orderCheck/getProductList",
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 130,
        },
        {
          prop: "goodsCheckHs",
          label: "审核HS状态",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "name",
          label: "中文品名",
          headerType: "require",
        },
        {
          prop: "ename",
          label: "英文品名",
          headerType: "require",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "material",
          label: "材质",
          headerType: "require",
        },
        {
          prop: "purpose",
          label: "用途",
          headerType: "require",
        },
        {
          prop: "clearedHsCode",
          label: "推荐HS",
        },
        {
          prop: "hscode",
          label: "税号",
          headerType: "require",
        },
        {
          prop: "addtionalCode",
          label: "301编码",
        },
        {
          prop: "taxRate",
          label: "税率",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "doc",
          label: "客服",
        },
      ],
      dictCodes: ["is_default"],
      ipagination: {
        currentPage: 1,
        pageSize: 50,
        pageSizes: [100, 200, 300, 400, 500],
        total: 0,
      },
      recordVisible: false,
      cusId: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 变绿吧
    changeColorFromRow(row,orderNo) {
      let statusList = row.statusList;
      if (statusList && statusList.length > 0) {
        //
        let findList = statusList.filter(s=>s.orderNo === orderNo && s.invoiceStatus === '1');
        if (findList && findList.length > 0) {
          return "color:green"
        }
      }
      return "color: #00a0e9";
    },
    editorColumn(index) {
      this.tableData[index].isEditor = true;
      this.$forceUpdate();
    },
    handleQuery() {
      this.loading = true;
      let form = { ...this.queryForm, ...this.filterForm, ...this.initData };
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else if (this.enabledCreatetime) {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
        //排序
        //提货中查询
        if (this.queryForm.column === "sort") {
          this.$set(params, "column", "sort");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      postAction(this.url.list, params)
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.$refs.GTable &&
              this.$refs.GTable.handleScrollTop &&
              this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const { records, total } = res.result;
              this.tableData = records;
              this.tableData.forEach((item) => {
                item.nameid = item.name;
                item.enameid = item.ename;
              });

              this.totalValue = res.result.totalValue;
              this.ipagination &&
                (this.ipagination.total = total || res.result.length);
            } else {
              this.tableData = [];
              this.totalValue = 0;
              this.ipagination = 0;
            }
          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1
            ? this.initMoreFuc()
            : ""; //用于初始的时候调用其他的方法
          this.getSpanArr();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    reviewInformation(row) {
      let ids = [];
      if (row && row.id) {
        ids = row.ids;
      } else {
        ids = this.selectionRows.flatMap((item) => item.ids);
      }
      postAction(this.url.udpateGoodsCheckHs, {
        ids,
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    lockRecord(item) {
      this.cusId = item.cusId;
      this.recordVisible = true;
    },
    saveInformation(row) {
      if (row && row.id) {
        if (
          !row.name ||
          !row.ename ||
          !row.material ||
          !row.purpose ||
          !row.hscode
        ) {
          this.$message.warning("请填写完整信息");
          return;
        }
      }
      postAction(
        this.url.updateGoodsInfo,
        row && row.id ? [row] : this.selectionRows
      ).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    updateGoods33(val, index, country) {
      if (val && country === "美国") {
        getAction(this.url.get301binamByHscode, { hscode: val }).then((res) => {
          if (res.result) {
            this.tableData[index].addtionalCode =
              res.result["addtionalDutyText"];
            this.tableData[index].taxRate = res.result["dutyRate"];
          }
        });
      }
    },
    updatePro(index, row) {
      this.tableData[index].ename = row.ename;
      this.tableData[index].name = row.cname;
      this.tableData[index].nameid = row.cname;
      this.tableData[index].enameid = row.ename;
      this.tableData[index].country = row.country;
      this.tableData[index].hscode = row.hscode;
      this.tableData[index].taxRate = row.taxRate;
      this.tableData[index].material = row.material;
      this.tableData[index].purpose = row.purpose;
    },
  },
  computed: {
    buttonShowList() {
      return {
        shHS: this.$btnIsShow("conformance/ReviewHS", "1", "审核"),
        bc: this.$btnIsShow("conformance/ReviewHS", "1", "保存"),
        bj: this.$btnIsShow("conformance/ReviewHS", "1", "编辑"),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.yellow_color {
  background-color: yellow;
}
.red_color {
  color: red;
}
</style>
