<template>
  <el-dialog
      title="拜访"
      :visible="visible"
      width="40%"
      center
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
  >
    <el-form :model="form" :rules="rules" ref="form" :label-width="formLabelWidth">
      <el-row>
        <el-col>
          <el-form-item label="是否是异地">
            <el-radio-group v-model="form.isYidi" :disabled="true">
              <el-radio :label="'0'">否</el-radio>
              <el-radio :label="'1'">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="类型" prop="type" >
            <f-select v-model="form.type" :data-list="fatherQueryDictListMap['customer_order_type']" :isNeed="form.type">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="客户名"

              :rules="rules.contact"
          >
            <el-input
                v-model="form.username"
                autocomplete="off"
                disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="拜访/沟通方式"
              prop="visitType"

          >
            <f-select
                v-model="form.visitType"
                :is-need="form.visitType"
                :data-list="fatherQueryDictListMap['visit_type']"></f-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="陪同拜访人" prop="visitor" >
            <f-select
                :multiple="true"
                v-model="form.visitorList"
                :isNeed="form.visitorList"
                :data-list="fatherQueryDictListMap['sys_user_id']"></f-select>
          </el-form-item>
        </el-col>
        <el-col v-if="form.type !== '3'">
          <el-form-item
              label="货量情况"
              prop="volume"

          >
            <el-input
                type="textarea"
                v-model="form.volume"
                auto-complete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="form.type !== '3'">
          <el-form-item
              label="发货周期"
              prop="shippingCycle"
              :rules="form.grade === '新客户' ? rules.shippingCycle : []"

          >
            <el-input v-model="form.shippingCycle" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="竞争对手"
              prop="competitor"

          >
            <el-input v-model="form.competitor" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="痛点和需求"
              prop="attentionPoint"

          >
            <el-input
                v-model="form.attentionPoint"
                autocomplete="off"
                type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="form.type =='3'">
          <el-form-item label="目的国家地区" label-width="110px" prop="destAddr">
            <el-input v-model="form.destAddr" type="textarea" maxlength="255" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <!--          业务类型-->
        <el-col v-if="form.type =='3'">
          <el-form-item label="业务类型" label-width="110px">
            <el-select multiple filterabel clearable v-model="form.bussinessTypeList"
                       @click.native="initBussinessTypeList">
              <el-option v-for="(item,index) in bussinessTypeList" :key="index" :value="item.value"
                         :label="item.text"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--          <el-col >-->
        <!--            <el-form-item-->
        <!--              prop="isNormalShipping"-->
        <!--              label="是否正常发货"-->
        <!--              -->
        <!--            >-->
        <!--              <el-radio-group v-model="form.isNormalShipping">-->
        <!--                <el-radio :label="'1'">是</el-radio>-->
        <!--                <el-radio :label="'0'">否</el-radio>-->
        <!--              </el-radio-group>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <el-col>
          <el-form-item
              label="沟通事项"
              prop="communicate"

          >
            <el-input
                v-model="form.communicate"
                autocomplete="off"
                type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="沟通结果"
              prop="communicationResult"

          >
            <el-input
                v-model="form.communicationResult"
                autocomplete="off"
                maxlength="255"
                show-word-limit
                type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="工作/收款计划"
              prop="workPlan"

          >
            <el-input v-model="form.workPlan" autocomplete="off" maxlength="255" show-word-limit
                      type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="货物属性"
              v-if="form.type ==='3'"

          >
            <el-checkbox-group v-model="form.goodsPropList">
              <el-checkbox label="0">普货</el-checkbox>
              <el-checkbox label="1">带电</el-checkbox>
              <el-checkbox label="2">纯电</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="物流规格"
              v-if="form.type ==='3'"

          >
            <el-checkbox-group v-model="form.circuStageList">
              <el-checkbox label="0">整柜</el-checkbox>
              <el-checkbox label="1">拼箱</el-checkbox>
              <el-checkbox label="2">电商物流双清到门</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
              label="货量及发货频次"


          >
            <el-input v-model="form.goodsHz" show-word-limit maxlength="500" type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="url" label="附件" >
            <f-upload v-model="form.url" :baifang="form" :dir="'customer'"></f-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit"
      >确 定
      </el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {httpAction, postAction} from '@/api/manage'
import {validateVisitRemark} from "@/utils/validator";

export default {
  name: 'AddVisitLogDialog',
  props: {
    visible: {
      type: Boolean
    },
    fatherForm : {
      type : Object,
      default : () => {}
    },
    fatherQueryDictListMap : {
      type : Object,
      default : () => {}
    }
  },
  data() {
    return {
      form: {},
      formLabelWidth: "120px",
      rules: {
        type:[
          {required:true,message:'请选择类型',trigger:'change'}
        ],
        visitType: [
          { required: true, message: "请选择拜访沟通方式", trigger: "change" },
        ],

        shippingCycle: [
          { required: true, message: "请输入发货周期", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        communicationResult: [
          { required: true, message: "请输入沟通结果", trigger: "blur" },
          { validator: validateVisitRemark, trigger: 'change'}
        ],
        attentionPoint: [
          { required: false, message: "请输入关心的点", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        workPlan: [
          { required: true, message: "请输入工作/收款计划", trigger: "blur" },
          { validator: validateVisitRemark, trigger: 'change'}
        ],
      },
      url: {
        addVisit: "/customer/visitLog/add",
      },
    }
  },
  methods: {
    handleSubmit() {
      //保存拜访记录
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.goodsPropList) {
            this.form.goodsProp = this.form.goodsPropList.join(',')
          }
          if (this.form.circuStageList) {
            this.form.circuStage = this.form.circuStageList.join(',')
          }
          postAction(this.url.addVisit, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.handleClose();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
        } else {
          this.btnDisable = false;
        }
      });
    },
    handleClose() {
      this.form = {}
      this.$emit('update:visible', false)
      this.$refs.form.resetFields();
    },
  },
  watch: {
    'fatherForm': {
      handler(newValue, oldValue) {
        // 这里处理变化逻辑
        if (newValue) {
          this.form={circuStageList:[],goodsPropList: []};
          this.form.username = newValue.username;
          this.form.cusId = newValue.id;
          this.form.grade = newValue.grade;
        }
      },
      deep: true,
      immediate: true // 初始化时也触发一次
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
