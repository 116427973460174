import {postAction} from "@/api/manage";
import OssClientMixins from "./OssClientMixins";
import QueryMixins from "./QueryMixins";

export default {
    data() {
        return {
            url: {
                uploadPod: '/interface/ttOrder/uploadPODBatch',
                sendCommissionEmail: '/file/read/excel/sendCommissionEmail',
                sendCommissionDetailEmail: '/file/read/excel/sendCommissionDetailEmail',
            },

        }
    },
    mixins: [OssClientMixins],
    methods: {
        //上传之前
        beforeFile(file) {
            let name = "";
            if (file && file.name) {
                name = file.name;
            }
            const isFile = name.indexOf('.') !== -1;
            if (!isFile) {
                this.$message.warning('该文件名不合法')
            }
            return isFile;
        },
        //使用阿里云上传文件
       async uploadPODFile(data) {
            const that = this;
            if (!that.selectionRows || that.selectionRows.length === 0) {
                that.$message.warning("请勾选订单在上传");
                return false;
            }
            let file = data['file'];
            console.log(file, "阿里云OSS文件上传");
            let uuid = that.generateUUID(6);
           const fileItem = await that.handleUploadOss2(data,'waitLock','operation',uuid,"0",'55');
           console.log("上传返回的结果：" , fileItem);
           if (!fileItem) {
               that.$message.warning('上传文件失败');
               return false;
           }
           let name = "";
           if (file && file.name) {
               name = file.name;
           }
           let list = [];
           that.selectionRows.forEach(a=> {
               let obj = {
                   orderNo: a.orderNo,
                   ossId: uuid,
                   name: file.name,
                   url: fileItem.url,
                   size: file.size / 1000,
                   ext: name.substring(name.lastIndexOf('.') + 1),
                   type: '55'
               };
               list.push(obj);
           })
            postAction(that.url.uploadPod, list).then((res) => {
                if (res.success) {
                    this.$message.success(res.message);
                    // this.handleSearch();
                }
            })

        },
        //发送提成邮件
        sendCommissionEmail(data) {
            console.log("上传文件", data);
            const formData = new FormData();
            formData.append("file", data.file);
            postAction(this.url.sendCommissionEmail, formData)
                .then((res) => {
                    console.log("发送提成邮件返回数据", res);
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    }
                })
        },
        sendCommissionDetailEmail(data) {
            console.log("上传文件", data);
            const formData = new FormData();
            formData.append("file", data.file);
            postAction(this.url.sendCommissionDetailEmail, formData)
                .then((res) => {
                    console.log("发送提成明细邮件返回数据", res);
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    }
                })
        },
    }
}
