<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="queryForm"
      :model="queryForm"
      label-width="86px"
    >
      <el-card class="box-card">
        <el-row class="inputs_row" :gutter="20">
          <el-col :span="4">
            <el-form-item label="订单号">
              <el-input
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="HS是否有效">
              <f-select
                v-model="queryForm.hsValid"
                :isNeed="queryForm.hsValid"
                :dict="'is_default'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="审核HS状态">
              <el-select clearable filterable v-model="queryForm.orderCheckHs">
                <el-option value="0" label="未审核"></el-option>
                <el-option value="1" label="已审核"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否已确认发票" label-width="100px">
              <f-select
                :isNeed="queryForm.invoiceStatus"
                v-model="queryForm.invoiceStatus"
                :dict="'is_default'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否发票审核">
              <el-select clearable filterable v-model="queryForm.invoiceCheck">
                <el-option value="0" label="未审核"></el-option>
                <el-option value="1" label="已审核"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label-width="20px">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button size="medium" plain @click="handleReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card>
      <div class="f-p-b-15">
        <el-button
          type="primary"
          @click="handleBatch(null, {}, '2', '1')"
          :disabled="!selectionRows.length"
          v-if="buttonShowList.发票审核"
          >发票审核
        </el-button>
        <el-button
          type="primary"
          @click="handleBatch(null, {}, '2', '0')"
          :disabled="!selectionRows.length"
          v-if="buttonShowList.发票取消审核"
          >发票取消审核
        </el-button>
      </div>
      <el-form class="widthFull" :model="form" ref="goodref" :rules="rules">
        <!-- <div ref="topScroll" class="top-scroll2" @scroll="handleScrollTop">
          <div
            class="top-scroll-content2"
            :style="{ width: topScrollWidth }"
          ></div>
        </div> -->
        <el-table
          ref="TaxInvoiceReview_Tbale"
          key="TaxInvoiceReview_Tbale"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="form.tableData"
          class="customTable c-th-has-bgcolor"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ padding: 2 + 'px' }"
          :span-method="objectSpanMethod"
          height="600px"
        >
          <el-table-column type="selection" width="30"></el-table-column>
          <el-table-column prop="action" label="操作" minWidth="95">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleBatch(scope.$index, scope.row, '0')"
                v-if="buttonShowList.保存"
                >保存
              </el-button>
              <el-button
                type="text"
                @click="handleBatch(scope.$index, scope.row, '2', '1')"
                v-if="scope.row.invoiceCheck == '0' && buttonShowList.发票审核"
                >发票审核
              </el-button>
              <el-button
                type="text"
                @click="handleBatch(scope.$index, scope.row, '2', '0')"
                v-if="
                  scope.row.invoiceCheck == '1' && buttonShowList.发票取消审核
                "
                >发票取消审核
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="fjf" label="附加费" minWidth="75">
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">附加费</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.fjf'"
                :key="'tableData.' + scope.$index + '.fjf'"
                :rules="rules.fjf"
              >
                <el-input v-model.trim="scope.row.fjf" type="number"></el-input>
                <el-button
                  type="text"
                  @click="handleBatch(scope.$index, scope.row, '1')"
                  >保存附加费
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="orderNo" label="订单号" minWidth="120">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.orderNo'"
                :key="'tableData.' + scope.$index + '.orderNo'"
                :rules="rules.orderNo"
              >
                <a
                  style="color: #00a0e9"
                  @click.prevent="$intoDocPage(scope.row.orderNo)"
                  :class="scope.row.invoiceStatus == '1' ? 'green_coloe' : ''"
                  >{{ scope.row.orderNo }}</a
                >
                <i
                  class="el-icon-question"
                  @click="lockRecord(scope.row.orderNo)"
                  v-if="scope.row.hasGuanwu == '1'"
                ></i>
                <div>
                  <span
                    style="margin-right: 7px"
                    :class="scope.row.invoiceCheck == '0' ? 'red_color' : ''"
                    >{{
                      scope.row.invoiceCheck == "0" ? "未审核" : "已审核"
                    }}</span
                  >{{ scope.row.countFeeNum }}
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="中文品名" minWidth="150">
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">中文品名</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.name'"
                :key="'tableData.' + scope.$index + '.name'"
                :rules="rules.name"
              >
                <f-select
                  v-model="scope.row.nameid"
                  :is-need="scope.row.nameid"
                  :getDictUrl="url.getProductList"
                  :getDictParams="{
                    cusId: scope.row.cusId,
                  }"
                  @changetAllData="(obj) => updatePro(scope.$index, obj)"
                ></f-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ename" label="英文品名" minWidth="150">
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">英文品名</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.ename'"
                :key="'tableData.' + scope.$index + '.ename'"
                :rules="rules.ename"
              >
                <f-select
                  v-model="scope.row.enameid"
                  :is-need="scope.row.enameid"
                  :getDictUrl="url.getProductList"
                  :getDictParams="{
                    cusId: scope.row.cusId,
                  }"
                  @changetAllData="(obj) => updatePro(scope.$index, obj)"
                ></f-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="country"
            label="国家"
            minWidth="75"
          ></el-table-column>
          <el-table-column prop="material" label="材质" minWidth="75">
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">材质</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.material'"
                :key="'tableData.' + scope.$index + '.material'"
                :rules="rules.material"
              >
                <el-input
                  v-model.trim="scope.row.material"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="purpose" label="用途" minWidth="75">
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">用途</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.purpose'"
                :key="'tableData.' + scope.$index + '.purpose'"
                :rules="rules.purpose"
              >
                <el-input
                  v-model.trim="scope.row.purpose"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="hscode" label="税号" minWidth="120">
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">税号</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.hscode'"
                :key="'tableData.' + scope.$index + '.hscode'"
                :rules="rules.hscode"
              >
                <el-input
                  v-model.trim="scope.row.hscode"
                  type="textarea"
                  @input="
                    updateGoods33(
                      scope.row.hscode,
                      scope.$index,
                      scope.row.country
                    )
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="addtionalCode" label="301编码" minWidth="75">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.addtionalCode'"
                :key="'tableData.' + scope.$index + '.addtionalCode'"
                :rules="rules.addtionalCode"
              >
                <f-select
                  v-model="scope.row.addtionalCode"
                  :isNeed="scope.row.addtionalCode"
                  :dict="'hs_code_301'"
                ></f-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="taxRate"
            label="税率"
            minWidth="120"
          ></el-table-column>
          <el-table-column
            prop="unitCode"
            label="成本单价"
            minWidth="75"
          ></el-table-column>
          <el-table-column prop="unitValue" label="申报单价" minWidth="75">
            <template slot="header">
              <template>
                <span class="is_require_form">申报单价</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.unitValue'"
                :key="'tableData.' + scope.$index + '.unitValue'"
                :rules="rules.unitValue"
              >
                <el-input
                  v-model.trim="scope.row.unitValue"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="qty"
            label="数量"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="totalPrice"
            label="总价"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="ctn"
            label="箱数"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="kg"
            label="毛重"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="nkg"
            label="净重"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="cmb"
            label="体积"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="preTariff"
            label="预算关税"
            minWidth="75"
          ></el-table-column>
          <el-table-column
            prop="invoiceTag"
            label="包税价格"
            minWidth="75"
          ></el-table-column>
          <el-table-column prop="remark" label="备注" minWidth="150px">
            <template slot="header">
              <template>
                <span class="is_require_form">备注</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.remark'"
                :key="'tableData.' + scope.$index + '.remark'"
                :rules="rules.remark"
              >
                <el-input
                  v-model.trim="scope.row.remark"
                  maxlength="50"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <customs-record-dialog
      :visible="recordVisible"
      :orderNo="lockOrderNo"
      @update:visible="
        (val) => {
          recordVisible = val;
        }
      "
    />
  </div>
</template>
    <script>
import QueryMixins from "@/mixins/QueryMixins";
import { getAction, putAction, postAction } from "@/api/manage";

export default {
  mixins: [QueryMixins],
  components: {},
  props: {},
  data() {
    return {
      isUsePost: true,
      queryForm: {
        invoiceStatus: "0",
      },
      url: {
        list: "/interface/orderCheck/getGoodsInvoiceCheck",
        udpateGoodsCheckHs: "/interface/orderCheck/udpateGoodsCheckHs",
        updateGoodsInfo: "/interface/orderCheck/updateGoodsInfo",
        get301binamByHscode: "/interface/ttOrder/get301binamByHscode",
        saveTax: "/interface/ttOrder/saveTax",
        updateGoodsBatch: "/interface/orderCheck/checkUpdateGoodsBatch",
        batchUpdateInvoiceCheck:
          "/interface/orderCheck/batchUpdateInvoiceCheck",
        getProductList: "/interface/orderCheck/getProductList",
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "goodsCheckHs",
          label: "附加费",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "name",
          label: "中文品名",
        },
        {
          prop: "ename",
          label: "英文品名",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "material",
          label: "材质",
        },
        {
          prop: "purpose",
          label: "用途",
        },
        {
          prop: "hscode",
          label: "税号",
        },
        {
          prop: "addtionalCode",
          label: "301编码",
        },
        {
          prop: "taxRate",
          label: "税率",
        },
        {
          prop: "unitCode",
          label: "成本单价",
        },
        {
          prop: "unitValue",
          label: "申报单价",
        },
        {
          prop: "qty",
          label: "数量",
        },
        {
          prop: "totalPrice",
          label: "总价",
        },
        {
          prop: "ctn",
          label: "箱数",
        },
        {
          prop: "kg",
          label: "毛重",
        },
        {
          prop: "nkg",
          label: "净重",
        },
        {
          prop: "cmb",
          label: "体积",
        },
        {
          prop: "preTariff",
          label: "预算关税",
        },
        {
          prop: "invoiceTag",
          label: "包税价格",
        },
        {
          prop: "remark",
          label: "备注",
        },
      ],
      dictCodes: ["is_default"],
      ipagination: {
        currentPage: 1,
        pageSize: 100,
        pageSizes: [100],
        total: 0,
      },
      form: {
        tableData: this.tableData,
      },
      rules: {},
      isShow: true,
      spanArr: [],
      disableMixinMounted: true,
      lockOrderNo: "",
      recordVisible: false,
      productList: [],
    };
  },
  created() {
    this.handleQuery();
  },
  mounted() {},
  methods: {
    handleQuery() {
      this.loading = true;
      let form = { ...this.queryForm, ...this.filterForm, ...this.initData };
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else if (this.enabledCreatetime) {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
        //排序
        //提货中查询
        if (this.queryForm.column === "sort") {
          this.$set(params, "column", "sort");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      postAction(this.url.list, params)
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.$refs.GTable &&
              this.$refs.GTable.handleScrollTop &&
              this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const { records, total } = res.result;
              this.form.tableData = records;
              this.form.tableData.forEach((item) => {
                item.nameid = item.name;
                item.enameid = item.ename;
              });

              this.totalValue = res.result.totalValue;
              this.ipagination &&
                (this.ipagination.total = total || res.result.length);
            } else {
              this.form.tableData = [];
              this.totalValue = 0;
              this.ipagination = 0;
            }
          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1
            ? this.initMoreFuc()
            : ""; //用于初始的时候调用其他的方法
          this.getSpanArr();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    editorColumn(index) {
      this.tableData[index].isEditor = true;
      this.$forceUpdate();
    },
    getSpanArr() {
      const result = [];
      let pos = 0;
      for (var i = 0; i < this.form.tableData.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          result.push(1);
          pos = 0;
        } else {
          if (
            this.form.tableData[i].orderNo &&
            this.form.tableData[i].orderNo ===
              this.form.tableData[i - 1].orderNo
          ) {
            result[pos] += 1;
            result.push(0);
          } else {
            // 不相等push 1
            result.push(1);
            pos = i;
          }
        }
      }
      this.spanArr = result;
      console.log(this.spanArr, "this.spanArr");

      this.isShow = false;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3].indexOf(columnIndex) !== -1) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    updateGoods33(val, index, country) {
      if (val && country === "美国") {
        getAction(this.url.get301binamByHscode, { hscode: val }).then((res) => {
          if (res.result) {
            this.tableData[index].addtionalCode =
              res.result["addtionalDutyText"];
            this.tableData[index].taxRate = res.result["dutyRate"];
            // this.$forceUpdate()
          }
        });
      }
    },
    lockRecord(orderNo) {
      this.lockOrderNo = orderNo;
      this.recordVisible = true;
    },
    handleBatch(index, row, type, params) {
      if (type == "0") {
        const groupedArr = [];
        this.form.tableData.forEach((item) => {
          if (item.orderNo == row.orderNo) {
            groupedArr.push(item);
          }
        });
        console.log(groupedArr, "groupedArr");

        let result = groupedArr.every(
          (item) =>
            item.name &&
            item.name.trim() !== "" &&
            item.ename &&
            item.ename.trim() !== "" &&
            item.material &&
            item.material.trim() !== "" &&
            item.purpose &&
            item.purpose.trim() !== "" &&
            item.hscode &&
            item.hscode.trim() !== "" &&
            item.unitValue
        );
        if (!result) {
          this.$message.warning("请完整填写信息再保存");
          return;
        }
        putAction(
          this.url.updateGoodsBatch + "?orderNo=" + row.orderNo,
          groupedArr
        ).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          }
        });
      } else if (type == "1") {
        if (
          !this.form.tableData[index].fjf &&
          this.form.tableData[index].fjf !== 0
        ) {
          this.$message.warning("请填写附加费");
          return;
        }
        postAction(this.url.saveTax, {
          fjf: row.fjf,
          orderNo: row.orderNo,
        }).then((res) => {
          this.$message.success(res.message);
          this.handleQuery();
        });
      } else if (type == "2") {
        postAction(this.url.batchUpdateInvoiceCheck, {
          orderNoList:
            row && row.id
              ? [row.orderNo]
              : this.selectionRows.map((item) => item.orderNo),
          invoiceCheck: params,
        }).then((res) => {
          this.$message.success(res.message);
          this.handleQuery();
        });
      }
    },
    updatePro(index, row) {
      this.form.tableData[index].ename = row.ename;
      this.form.tableData[index].name = row.cname;
      this.form.tableData[index].country = row.country;
      this.form.tableData[index].hscode = row.hscode;
      this.form.tableData[index].taxRate = row.taxRate;
      this.form.tableData[index].material = row.material;
      this.form.tableData[index].purpose = row.purpose;
      this.form.tableData[index].nameid = row.cname;
      this.form.tableData[index].enameid = row.ename;

    },
  },
  computed: {
    buttonShowList() {
      return {
        发票审核: this.$btnIsShow(
          "conformance/TaxInvoiceReview",
          "0",
          "发票审核"
        ),
        发票取消审核: this.$btnIsShow(
          "conformance/TaxInvoiceReview",
          "0",
          "发票取消审核"
        ),
        保存附加费: this.$btnIsShow(
          "conformance/TaxInvoiceReview",
          "0",
          "保存附加费"
        ),
        保存: this.$btnIsShow("conformance/TaxInvoiceReview", "0", "保存"),
      };
    },
  },
};
</script>
  <style lang="scss">
.yellow_color {
  background-color: yellow;
}
.green_coloe {
  color: green !important;
}
.red_color {
  color: red !important;
}
.TaxInvoiceReview_Tbale {
  .el-table--scrollable-y .el-table__body-wrapper {
    overflow: auto !important;
  }
}
</style>