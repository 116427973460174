<template>
    <el-dialog
            title="派车"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


                    <el-form-item prop="supplierId" label="供应商" :label-width="formLabelWidth">
                        <f-select v-model="form.supplierId" :is-need="form.supplierId" dict="warehouse_supplier_id"></f-select>
                    </el-form-item>

                    <el-form-item prop="takeFee" label="提货费" :label-width="formLabelWidth">
                        <el-input v-model="form.takeFee" type="number"></el-input>
                    </el-form-item>


        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'

    export default {
        name: 'dispatchModule',
        props: {
            visible: {
                type: Boolean
            },
            takeIds:{
                type:String,
                default:''
            },
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    supplierId: [
                        {required: true, message: '请选择供应商', trigger: 'change'},
                    ],

                },
                url: {
                    batchUpdateUrl:"/warehouse/takeDelivery/batchUpdate",
                },
                supplierName:'',
                takeFee: '',

            }
        },
        watch: {
            visible(val) {
                if (val && this.takeIds) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'takeIds',this.takeIds);
                }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    //批量派车 1
                    this.$set(this.form,'updateType','1');
                    if (valid) {
                        postAction(this.url.batchUpdateUrl, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            });
                            this.form = {};
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.form = {};
                this.$emit('update:visible', false);
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
