<template>
  <el-dialog
          :title="row.id ? '船期' : '新增船期'"
          :visible="dialogVisible"
          width="840px"
          custom-class="c-custom-dialog"
          append-to-body
          :before-close="handleClose"
          :close-on-click-modal="false">
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
          <el-row >
              <el-form-item prop="shippingDate" label="船期年份">
                  <el-input v-model="form.shippingDate" show-word-limit maxlength="22"></el-input>
              </el-form-item>
              <el-form-item prop="shippingWeek" label="船期周数">
                  <el-input v-model="form.shippingWeek" show-word-limit maxlength="50"></el-input>
              </el-form-item>

              <el-form-item label="渠道名称" prop="channelId">
                  <f-select
                          v-model="form.channelId"
                          :isNeed="form.channelId"
                          :dict="'transportStatus'">
                  </f-select>
              </el-form-item>
              <el-form-item label="交货仓库"  prop="deliveryPoint">
                  <f-select v-model="form.deliveryPoint" :dict="'delivery_point'" :is-need="form.deliveryPoint"></f-select>
              </el-form-item>
              <el-form-item label="起运港" prop="pol">
                  <f-select v-model="form.pol"
                            :is-need="form.pol"
                            placeholder="请选择" filterable clearable :dict="'bd_port'">
                  </f-select>
              </el-form-item>
              <el-form-item label="目的港" prop="podEnd">
                  <f-select v-model="form.podEnd"
                            :isNeed="form.podEnd"
                            placeholder="请选择" filterable clearable :dict="'bd_port'">
                  </f-select>
              </el-form-item>
              <el-form-item label="船公司" prop="shipCompanyId">
                  <f-select
                          v-model="form.shipCompanyId"
                          :isNeed="form.shipCompanyId"
                          :dict="'ship_company'"></f-select>
              </el-form-item>
              <el-form-item prop="shippingSchedule" label="船期截开">
                  <el-input v-model="form.shippingSchedule" show-word-limit maxlength="50"></el-input>
              </el-form-item>
              <el-form-item prop="solicitationCode" label="航线代码">
                  <el-input v-model="form.solicitationCode" show-word-limit maxlength="50"></el-input>
              </el-form-item>
              <el-form-item prop="shipNo" label="船名航次">
                  <el-input v-model="form.shipNo" show-word-limit maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="截货日期" prop="cutOffDate">
                  <el-date-picker
                          class="c-full-width"
                          v-model="form.cutOffDate"
                          type="datetime"
                          default-time="12:00:00"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :picker-options="editCutoffDateStartOptions"
                          placeholder="请选择时间">
                  </el-date-picker>
              </el-form-item>
              <el-form-item label="预计开船日期" prop="etd" >
                  <el-date-picker
                          class="c-full-width"
                          v-model="form.etd"
                          type="datetime"
                          default-time="12:00:00"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :picker-options="editStartOptions"
                          placeholder="请选择时间">
                  </el-date-picker>
              </el-form-item>
              <el-form-item label="预计到港日期" prop="eta" >
                  <el-date-picker
                          class="c-full-width"
                          v-model="form.eta"
                          type="datetime"
                          default-time="12:00:00"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :picker-options="editETAStartOptions"
                          placeholder="请选择时间">
                  </el-date-picker>
              </el-form-item>
              <el-form-item prop="shippingVoyage" label="航程">
                  <el-input v-model="form.shippingVoyage" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
              </el-form-item>
              <el-form-item prop="isEmptyClass" label="是否空班" label-width="80px">
                  <el-radio-group v-model="form.isEmptyClass">
                      <el-radio :label='"0"'>正常</el-radio>
                      <el-radio :label='"1"'>空班</el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item prop="remark" label="备注">
                  <el-input v-model="form.remark" show-word-limit maxlength="255"></el-input>
              </el-form-item>
          </el-row>
      </el-form>
      <span slot="footer">
          <el-button size="medium" @click="handleClose">取消</el-button>
          <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
      </span>
  </el-dialog>
</template>

<script>
  import {httpAction} from '@/api/manage'
  import {getAction, getallcountries,postAction} from "@/api/manage";
  import {sanweixiaoshu} from "@/utils/pattern";
  import {noZhongwenValidator} from "@/utils/validator";
  import {mgHsCodePattern} from "@/utils/pattern";

  export default {
      name: 'ProductDialog',
      props: {
          dialogVisible: {
              type: Boolean
          },
          row: {
              type: Object,
              default: () => {
              }
          }
      },
      data() {
          return {
              dictCodes:['tax_clear_requirements','bd_clear_cases'],
              picList: [],
              form: {},
              rules: {
                  shippingDate: [
                      {required: true, message: "请输入船期年份", trigger: "change"},
                  ],
                  shippingWeek: [
                      {required: true, message: "请输入船期年份", trigger: "change"},
                  ],
                  isEmptyClass: [
                      {required: true, message: "请选择是否空班", trigger: "change"},
                  ],
                  shippingVoyage: [
                      {required: true, message: "请输入航程", trigger: "change"},
                  ],
                  shippingSchedule: [
                      {required: true, message: "请输入船期截开", trigger: "change"},
                  ],
                  solicitationCode: [
                      {required: true, message: "请输入航线代码", trigger: "change"},
                  ],
                  shipNo: [
                      {required: true, message: "请输入船名航次", trigger: "change"},
                  ],
                  channelId: [
                      {required: true, message: "请选择渠道", trigger: "change"},
                  ],
                  deliveryPoint: [
                      {required: true, message: "请选择交货仓库", trigger: "change"},
                  ],
                  pol: [
                      {required: true, message: "请选择起运港", trigger: "change"},
                  ],
                  podEnd: [
                      {required: true, message: "请选择目的港", trigger: "change"},
                  ],
                  shipCompanyId: [
                      {required: true, message: "请选择船公司", trigger: "change"},
                  ],
              },
              url: {
                  add: "/order/channel/shippingDate/addShippingDate",
                  edit: '/order/channel/shippingDate/editShippingDate',
                  getDemandList: '/sys/dict/getDictItems/demand',
              },
              demandList: [],
              clearCasesIdList:[],
              clearRequirementsIdList:[],
              countrylist: [],
          }
      },
      watch: {
          dialogVisible(val) {
              if (val && this.row.id) {
                  this.form = {...this.row}
              } else {
                this.form ={}
                this.$refs.form.resetFields()
              }

              if (this.form.clearCases) {
                  this.$set(this.form, 'clearCasesIdList', this.form.clearCases.split('/'))
              }
              if (this.form.clearRequirements) {
                  this.$set(this.form, 'clearRequirementsIdList', this.form.clearRequirements.split('/'))
                  console.log(this.form.clearRequirementsIdList)
              }
              
          },
          picList(val) {
              if (val && val.length > 0) {
                  if (this.$refs['picUrl']) {
                      this.$refs['picUrl'].clearValidate();
                  }
              }
          },

      },
      methods: {
          editCutoffDateStartOptions: {
              disabledDate: time => {
                  if (!this.form.cutOffDate) {
                      // time = new Date(time.replace(/-/g, '/'));
                      return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                  } else {
                      return time.getTime() > new Date(this.form.cutOffDate);
                  }
              }
          },
          editETAStartOptions: {
              disabledDate: time => {
                  if (!this.form.eta) {
                      // time = new Date(time.replace(/-/g, '/'));
                      return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                  } else {
                      return time.getTime() > new Date(this.form.eta);
                  }
              }
          },
          editStartOptions: {
              disabledDate: time => {
                  if (!this.form.etd) {
                      // time = new Date(time.replace(/-/g, '/'));
                      return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                  } else {
                      return time.getTime() > new Date(this.form.etd);
                  }
              }
          },
          initCountryList() {
              if (this.countrylist.length <= 0) {
                  this.countrylist = [];
                  getallcountries().then(res => {
                      if (res.success) {
                          for (var i = 0; i < res.result.length; i++) {
                              let obj = {};
                              obj.value = res.result[i].cnName;
                              obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                              this.countrylist.push(obj);
                          }
                      }
                  }).catch(err => {
                      this.$message.error(err)
                  })
              }

          },
          handleSubmit() {
              this.$refs["form"].validate((valid) => {
                  if (valid) {
                      if(!this.row.id){
                          postAction(this.url.add, this.form)
                              .then((res) => {
                                  if (res.success) {
                                      this.$message.success(res.message);
                                      this.$emit('update:dialogVisible', false)
                                  }
                              });
                      }else{
                          postAction(this.url.edit, this.form)
                              .then((res) => {
                                  if (res.success) {
                                      this.$message.success(res.message);
                                      this.$emit('update:dialogVisible', false)
                                  }
                              });
                      }

                  }
              });
          },
          handleClose() {
              this.$emit('update:dialogVisible', false)
              this.$refs.form.resetFields()
          }
      },
      created() {

      }
  }
</script>

<style lang="scss" scoped>
  ::v-deep
  .el-dialog__body {
      padding: 20px 40px 20px 6px;
  }
  .addEditBox {
    max-height: 615px;
    overflow: scroll;
  }
</style>
