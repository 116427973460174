<template>
    <el-dialog
            title="发送美国税单"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
      <span>{{dialogTips}}</span>
       <span slot="footer" class="dialog-footer">

        <el-button @click="handleSubmit('2')" v-if="selectedType !== '3'">加税20%</el-button>
        <el-button @click="handleSubmit('1')" v-if="selectedType !== '3'">加税10%</el-button>
        <el-button @click="handleSubmit('0')" v-if="selectedType !== '3'">不加税</el-button>
        <el-button @click="handleSubmit('3')" v-if="selectedType === '3'">确定</el-button>
        <el-button @click="handleClose" v-if="selectedType === '3'">取消</el-button>

      </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'

    export default {
        name: 'usShuidanDialog',
        props: {
            visible: {
                type: Boolean
            },
            selectedIds:{
                type:Array,
                default:()=>[]
            },
          selectedNos:{
            type:Array,
            default:()=>[]
          },
            selectedType : {
              type : String,
              default : '2'
            }

        },
        data() {
            return {
              // 弹框提示
              dialogTips : '',

                url: {
                  sendShuidan : 'operation/container/sendUsShuidanEmailBatch',
                  sendShuidanNoFee : 'operation/container/sendShuidanEmailNoGeneralFeeBatch',
                  reSendShuidanByOrderNo: "/operation/container/sendShuidanEmailDuichong",
                },

            }
        },
        watch: {

            visible(val) {
                if (val) {
                  if (this.selectedType === '3') {
                    this.dialogTips = "确定重新发送订单的税单邮件吗？";
                  }else {
                    this.dialogTips = "确定发送税单邮件吗，请选择是否加税";
                  }
                }
            },
        },
        methods: {

            handleSubmit(isAdd10) {
              let url = this.url.sendShuidan;
              let queryParams = JSON.parse(JSON.stringify(this.selectedIds));
              if (this.selectedType === '2') {
                url = this.url.sendShuidanNoFee;
              }else if (this.selectedType === '3') {
                url = this.url.reSendShuidanByOrderNo;
                queryParams = {
                  containerIds : this.selectedIds,
                  isNeedSendEmail : '1',
                  isNeedAddFee : '1',
                  isDuichong : '1',
                  isNeedSendErrorEmail : '0',
                  'nos' : this.selectedNos
                }
              }
              postAction(url + "?isAdd10=" + isAdd10, queryParams)
                  .then(res => {
                    this.$emit("okUs", res);
                  })
            },
            handleClose() {
                this.$emit('closeUsDialog');
            },

        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
