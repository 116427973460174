<!--带入仓-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div>
                <el-card class="box-card">
                    <el-row class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="订单号">
                                <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"
                                          type="textarea"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="客户">
                                <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="客服">
                                <f-select :dict="'docId'" v-model="queryForm.docId" :is-need="queryForm.docId"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item prop="billmanId" label="收款人">
                                <f-select :dict="'sys_user_id'" v-model="queryForm.billmanId" :isNeed="queryForm.billmanId"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="检查状态">
                                <el-select v-model="queryForm.checkLock">
                                    <el-option value="1" label="已检查"></el-option>
                                    <el-option value="0" label="未检查"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="是否包税">
                                <f-select
                                        class="textarea_table" v-model="queryForm.hasTax" :is-need="queryForm.hasTax" :dict="'is_default'"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="业务员">
                                <f-select
                                        :dict="'sys_user_id'"
                                        v-model="queryForm.salesmanId"
                                        :is-need="queryForm.salesmanId"
                                        :father-val="queryForm.salesmanId"
                                ></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="业务提成发放状态" label-width="120px">
                                <f-select :dict="'salesman_commission'" v-model="queryForm.salesmanCommission"
                                          :is-need="queryForm.salesmanCommission"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="锁定状态" label-width="100px">
                                <el-select v-model="queryForm.costLock">
                                    <el-option value="1" label="已锁定"></el-option>
                                    <el-option value="0" label="未锁定"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="国家">
                                <f-select v-model="queryForm.shipmentCountry" :dict="'bd_country'"
                                          :is-need="queryForm.shipmentCountry"></f-select>
                            </el-form-item>

                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="销售锁价服务" label-width="110px" >
                                <el-select v-model="queryForm.saleLockPrice" clearable filterable>
                                    <el-option value="1" label="是"></el-option>
                                    <el-option value="0" label="否"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="渠道名称"label-width="100px">
                                <f-select :dict="'transportStatus'" :multiple="true" v-model="queryForm.transportStatusList"
                                          :is-need="queryForm.transportStatusList"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="地址类型" label-width="100px">
                                <f-select :dict="'address_type'" :multiple="true" v-model="queryForm.addressType"
                                          :is-need="queryForm.addressType"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label="费用名称" label-width="100px">
                                <f-select v-model="queryForm.feeNameList" :is-need="queryForm.feeNameList" :multiple="true" :dict="'bd_fee_type_name'" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="成本锁定时间" label-width="100px">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.costLockTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.costLockTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="费用时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.writeTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.writeTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="检查时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.checkTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.checkTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="下单时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="开船时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.shipmentBoardS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.shipmentBoardE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="到港时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.shipmentArrivedTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.shipmentArrivedTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="完成时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.deliveryTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>

                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.deliveryTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询
                    </el-button>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearchByOrderNos"
                    >订单号反查
                    </el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置
                    </el-button>
                </el-card>
            </div>
        </el-form>
        <el-card class="box-card last_card">
            <div>
                <el-button
                  @click="batchCheckLock(1)"
                  type="primary"
                  size="medium"
                  v-if="buttonShowList.check"
                  :disabled="selectionRows.length === 0"
                  >批量检查
                </el-button>
                <el-button
                  @click="batchCheckLock(0)"
                  type="primary"
                  size="medium"
                  v-if="buttonShowList.check"
                  :disabled="selectionRows.length === 0"
                  >批量解锁检查
                </el-button>
                <el-button type="primary" size="medium"  @click="exportSettleCommissionDetail(1)">导出成本账单费用明细1 </el-button>
                <el-button type="primary" size="medium"  @click="exportSettleCommissionDetail(2)">导出成本账单费用明细2 </el-button>
                <el-button type="primary" size="medium" v-if="buttonShowList.lrjcbb" @click="exportSettleCommissionDetail(3)">利润检查报表</el-button>
                <el-button
            size="medium"
            type="primary"
            :disabled="selectionRows.length === 0"
            @click="$intoDocPageBatch(selectedRowOrderNos,'/sale/noCost/checkList')"
            v-if="buttonShowList.pldkdd"
        >批量打开订单
        </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="65vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'orderStatus'">
                        <span>{{parseDict(scope.row.orderStatus,'orderStatus')}}<br></span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipmentType'">
                        <span>{{parseDict(scope.row.shipmentType,'address_type')}}<br></span>
                    </template>
                    <template v-else-if="scope.column.property === 'saleLockPrice'">
                        <span>{{ parseDict(scope.row.saleLockPrice, "is_default") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'salesmanCommission'">
                        <span v-if="scope.row.salesmanCommission == '0'">未发放提成</span>
                        <span v-if="scope.row.salesmanCommission == '1'">已发放提成</span>
                    </template>
                    <template v-else-if="scope.column.property === 'costLock'">
                        <span v-if="scope.row.costLock == '0'">未锁定</span>
                        <span v-if="scope.row.costLock == '1'">已锁定</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipmentType'">
                           <span>{{parseDict(scope.row.shipmentType, "shipment_type")  }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ["is_default","address_type"],
                disableMixinMounted: true,
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 100,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                    },
                    {
                        prop: "feeName",
                        label: "费用名称",
                        minWidth: 100,
                    },
                    {
                        prop: "shipmentCountry",
                        label: "国家",
                        minWidth: 100,
                    },
                    {
                        prop: "quotation",
                        label: "单价",
                        minWidth: 80,
                    },
                    {
                        prop: "num",
                        label: "数量",
                        minWidth: 80,
                    },
                    {
                        prop: "total",
                        label: "金额",
                        minWidth: 80,
                    },
                    {
                        prop: "realCur",
                        label: "币种",
                        minWidth: 80,
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                        sortable: "createBy",
                    },
                    {
                        prop: "saleLockPrice",
                        label: "销售锁价服务",
                        minWidth: 100,
                    },
                    {
                        prop: "salesmanCommission",
                        label: "业务提成发放状态",
                        minWidth: 110,
                    },
                    {
                        prop: "costLockTime",
                        label: "成本锁定时间",
                        minWidth: 100,
                    },
                    {
                        prop: "costLock",
                        label: "锁定状态",
                        minWidth: 100,
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                        minWidth: 100,
                    },
                    {
                        prop: "shipmentType",
                        label: "地址类型",
                        minWidth: 100,
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                        sortable: "createTime",
                    },
                    {
                        prop: "checkLock",
                        label: "检查状态",
                        sortable: "checkLock",
                    },
                    {
                        prop: "checkTime",
                        label: "检查时间",
                        sortable: "checkTime",
                    },
                    {
                        prop: "updateBy",
                        label: "修改人",
                    },
                    {
                        prop: "updateTime",
                        label: "修改时间",
                        minWidth: 100,
                    },

                ],
                url: {
                    list: "finance/fee/settleCommission/queryPageByConditon",
                    exportSettleCommissionDetail: "/file/export/excel/exportSettleCommissionDetail",
                    exportSettleCommissionDetail2: "/file/export/excel/exportSettleCommissionDetail2",
                    exportCostCheckProfitReport: '/file/export/excel/exportCostCheckProfitReport',
                    batchCheckFee: "/finance/fee/settleCommission/batchCheckFee",
                },
                isUsePost: true,
            };
        },
        methods: {
            batchCheckLock(checkLock) {
                if(checkLock==1&&this.selectionRows.some(item=>item.checkLock=='已检查')){
                    // 批量检查
                      this.$message.warning('不能重复提交已检查的费用');
                      return
                }else if(checkLock==0&&this.selectionRows.some(item=>item.checkLock=='未检查')){
                    // 批量解锁检查
                      this.$message.warning('存在未检查的费用');
                      return
                }
                postAction(
                    this.url.batchCheckFee + "?checkLock=" + checkLock,
                    this.selectedRowKeys
                ).then((res) => {
                    this.$message.success(res.message);
                    this.handleSearch();
                });
            },
            getStrIsOrNot(str){
                if (str === '1') {
                    return '是';
                }else if (str === '0') {
                    return '否';
                }
                return '';
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$intoDocPage(orderNo);
                // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },

            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.queryForm.isWhether='0'
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            handleSearch() {
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },

            handleSearchByOrderNos() {
                this.queryForm.isInvertSelection = true;
                this.queryForm.currentPage = 1;
                this.queryForm.pageSize = -1;
                postAction(this.url.list, this.queryForm).then((res) => {
                    if (!res.success) {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                })
                this.queryForm.isInvertSelection = false;

            },


            exportSettleCommissionDetail(type) {
                let url = this.url.exportSettleCommissionDetail;
                let filename = this.getDateStr() + '成本账单费用明细1'
                if(type === 2) {
                    url = this.url.exportSettleCommissionDetail2;
                    filename = this.getDateStr() + '成本账单费用明细2'
                } else if(type == 3) {
                    url = this.url.exportCostCheckProfitReport;
                    filename = this.getDateStr() + '利润检查报表'
                }
                                postAction(url, this.queryForm, {responseType: 'blob'})
                    .then(res => {
                        // console.log(res)
                        if (res.size === 0) {
                            this.$message.error('导出失败，请稍后再试或联系管理员')
                            this.visible = false
                            return
                        }
                        var binaryData = [];
                        binaryData.push(res);

                        let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.setAttribute('download', filename + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);//下载完成移除元素
                        window.URL.revokeObjectURL(url) //释放掉blob对象

                        this.visible = false
                        setTimeout(() => {   //设置延迟执行
                            this.$message.success("导出成功！")
                            // this.$emit('ok')
                            this.handleCheck();
                        }, 3000)
                    })
            },
        },


        created() {
        },
      computed:{
        buttonShowList() {
          return {
            'check':this.$btnIsShow('sale/noCost/checkList', '0', '检查'),
            'lrjcbb':this.$btnIsShow('sale/noCost/checkList', '0', '利润检查报表'),
            'pldkdd':this.$btnIsShow('sale/noCost/checkList','0','批量打开订单'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
