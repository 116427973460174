<template>
    <el-dialog
            :title="row.id?'编辑':'新增' + '司机信息'"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


                    <el-form-item prop="name" label="姓名" :label-width="formLabelWidth">
                        <el-input v-model="form.name" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="phone" label="手机号" :label-width="formLabelWidth">
                        <el-input v-model="form.phone" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="licencePlate" label="车牌号" :label-width="formLabelWidth">
                        <el-input v-model="form.licencePlate" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="carType" label="车型" :label-width="formLabelWidth">
                        <el-input v-model="form.carType" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="loadWeight" label="载重" :label-width="formLabelWidth">
                        <el-input v-model="form.loadWeight" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="inTheEmpty" label="内空" :label-width="formLabelWidth">
                        <el-input v-model="form.inTheEmpty" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";
    export default {
        name: 'warehouseDriverInfoDialog',
        mixins: [QueryMixins],
        props: {
            visible: {
                type: Boolean
            },

            row:{
                type:Object,
                default:()=>{}
            },
        },
        data() {
            return {
                disableMixinMounted:true,
                form: {},
                formLabelWidth:'100px',
                rules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'change'},
                    ],
                    phone: [
                        {required: true, message: '请输入手机号', trigger: 'change'},
                    ],
                    licencePlate: [
                        {required: true, message: '请输入车牌号', trigger: 'change'},
                    ],
                    carType: [
                        {required: true, message: '请输入车型', trigger: 'change'},
                    ],
                    loadWeight: [
                        {required: true, message: '请输入载重', trigger: 'change'},
                    ],
                    inTheEmpty: [
                        {required: true, message: '请输入内空', trigger: 'change'},
                    ],
                },
                url: {
                    add: '/warehouse/driverInfo/addOrUpdateOne',

                },

            }
        },
        watch: {
            visible(val) {
               if (val && this.row && this.row.id) {
                   this.form = JSON.parse(JSON.stringify(this.row));
               }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.form={};
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$refs['form'].resetFields();
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
