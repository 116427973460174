<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item>
              <f-select
                  placeholder="时间"
                  slot="label"
                  :dict="'profit_statement_time_type'"
                  v-model="queryForm.timeType"
                  :isNeed="queryForm.timeType"
                  @changet="() => {this.$set(this.queryForm, 'timeStr', '')}">

              </f-select>
              <el-date-picker
                  class="width_date_per"
                  v-model="queryForm.timeStr"
                  :type="queryForm.timeType"
                  placeholder="选择时间"
                  :value-format="queryForm.timeType === 'year' ? 'yyyy' : 'yyyy-MM'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="拼柜类型">
              <f-select
                  v-model="queryForm.containerType"
                  :isNeed="queryForm.containerType"
                  :dict="'profit_statement_container_type'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportLossOrder, '亏本订单分类')"
          v-if="buttonShowList.dc">导出
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import QueryMixins from "@/mixins/QueryMixins.js";
  import {profitStatementUrl} from "@/utils/profitStatementUrl";

  export default {
    name: "LoseOrder",
    mixins: [QueryMixins],
    components: {
    },
    props: {

    },
    data() {
      return {
        queryForm : {
          timeType : '',
          timeStr : '',
          containerType : '0',
          queryType : 'lose'
        },
        isUsePost: true,
        columns: [
          {
            type: "selection",
            width: 50,
          },
          {
            prop: "timeStr",
            label: "年月",
          },
          {
            prop: "containerType",
            label: "拼柜类型",
            sortable: "custom",
          },
          {
            prop: "handleType",
            label: "处理类型",
          },
          {
            prop: "reason",
            label: "原因",
          },
          {
            prop: "orderCount",
            label: "票数",
            sortable: "custom",
          },
          {
            prop: "orderCountRateStr",
            label: "票数占比",
            sortable: "custom",
          },
          {
            prop: "loseMoney",
            label: "亏本金额",
            sortable: "custom",
          },
          {
            prop: "loseMoneyRateStr",
            label: "亏本金额比例",
            sortable: "custom",
          },
        ],
        url: {
          list: profitStatementUrl.list,
          exportLossOrder: profitStatementUrl.export
        },
      };
    },
    created() {

    },
    methods: {
      handleReset() {
        this.queryForm = { queryType : 'lose', containerType: '0' };
        this.filterForm = {};
        this.ipagination.currentPage = 1
        this.handleQuery()
      },
    },
    computed:{
      buttonShowList() {
        return {
          'dc':this.$btnIsShow('sale/SalesReport/LoseOrder', '0', '导出'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
