<template>
    <el-dialog
            :title="row.id?'编辑':'新增' + '卸货人'"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


                    <el-form-item prop="name" label="姓名" :label-width="formLabelWidth">
                        <el-input v-model="form.name" maxlength="20" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="code" label="编码" :label-width="formLabelWidth">
                        <el-input v-model="form.code" maxlength="30" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item prop="warehouseId" label="仓库" :label-width="formLabelWidth">
                        <f-select
                            v-model="form.warehouseId"
                            :is-need="form.warehouseId"
                            :data-list="fatherQueryDictListMap['delivery_point']"
                        ></f-select>
                    </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";
    import {dischargerCode} from "@/utils/pattern";
    export default {
        name: 'warehouseDischargerDialog',
        mixins: [QueryMixins],
        props: {
            visible: {
                type: Boolean
            },

            row:{
                type:Object,
                default:()=>{}
            },
          fatherQueryDictListMap : {
              type : Object,
              default : () => {}
          }
        },
        data() {
            return {
                disableMixinMounted:true,
                form: {
                  name : '',
                  code : '',
                  warehouseId : ''
                },
                formLabelWidth:'100px',
                rules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'change'},
                    ],
                    code: [
                        {required: true, message: '请输入编码', trigger: 'change'},
                        {pattern : dischargerCode, trigger: 'change', message: '编码格式不对 大写字母-中文组成'},
                    ],
                    warehouseId: [
                        {required: true, message: '请选择仓库', trigger: 'change'},
                    ],

                },
                url: {
                    add: '/warehouse/discharger/add',
                    edit: '/warehouse/discharger/edit',

                },

            }
        },
        watch: {
            visible(val) {
               if (val && this.row && this.row.id) {
                   this.form = JSON.parse(JSON.stringify(this.row));
               }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                      var url = this.url.add;
                      if (this.form.id) {
                        url = this.url.edit;
                      }
                        postAction(url, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.form={};
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$refs['form'].resetFields();
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
