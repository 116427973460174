<template>
  <!-- 新增编辑活动配置弹窗 -->
  <div>
    <el-dialog title="匹配结果" :visible.sync="visible" :close-on-click-modal="false"
               :destroy-on-close="destroyOnClose" :before-close="handleClose" append-to-body>
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="height: 100%;">
        <el-card class="box-card">
          <div slot="header">
            <span>规则匹配结果</span>
          </div>
          <el-row :gutter="20" class="inputs_row">
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="commissionRate" label="提成比例" :rules="rules.commissionRate">
                <el-input v-model="form.commissionRate" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="personRespRatio"  :rules="rules.personRespRatio">
                <template slot="label">
                  <span>个人承担比例（%）</span>
                  <el-tooltip
                      class="item"
                      effect="dark"
                      content="该比例值*利润得到利润/亏本提成"
                      placement="top"
                  >

                    <i class="el-icon-question" style="color:#2c68ff"></i>
                  </el-tooltip>
                </template>
                <el-input v-model="form.personRespRatio" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="subsidy" label="补助" :rules="rules.subsidy">
                <el-input v-model="form.subsidy" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="lastRatio" label="最终提成比例（%）" :rules="rules.lastRatio">
                <template slot="label">
                  <span>最终提成比例（%）</span>
                  <el-tooltip
                      class="item"
                      effect="dark"
                      content="该比例值将会在利润率大于30%使用"
                      placement="top"
                  >

                    <i class="el-icon-question" style="color:#2c68ff"></i>
                  </el-tooltip>
                </template>
                <el-input v-model="form.lastRatio" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="salesCommissionRate" label="销售提成比例比值(%)" :rules="rules.salesCommissionRate">
                <el-input v-model="form.salesCommissionRate" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="followCommissionRate" label="跟进人提成比例比值(%)" :rules="rules.followCommissionRate">
                <el-input v-model="form.followCommissionRate" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>

      <template #footer>
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="handleSubmit">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {postAction} from '@/api/manage';
import {getAction} from '@/api/manage';
import {validateUnitValue4 , validateOuhao} from "@/utils/validator";

export default {
  name : 'salesPerMatchResultDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
    selectForm: {
      type : Object,
      default : () => {}
    },
    selectIndex : {
      type : Number,
      default : 0
    }
  },
  data() {
    return {

      rules: {
        commissionRate: [
          {required: true, message: '请输入提成比例', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        personRespRatio: [
          {required: true, message: '请输入个人承担比例', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        subsidy: [
          {required: true, message: '请输入补助', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        lastRatio: [
          {required: true, message: '请输入最终比例', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        salesCommissionRate: [
          {required: true, message: '请输入销售提成比例比值', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        followCommissionRate: [
          {required: true, message: '请输入跟进人提成比例比值', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ]
      },
      form: {

      },

      url: {
      },
    }
  },
  watch: {
    visible(val) {
      if (this.selectForm) {
        this.form = JSON.parse(JSON.stringify(this.selectForm));
      }else {
        this.form = {};
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {

    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleClose();
        }
      })
    },
    validateForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('validate', true, this.form, this.selectIndex);
        }else {
          this.$emit('validate', false, null);
        }
      });
    },
    handleClose() {
      this.$emit('ok',this.selectIndex, this.form);
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
