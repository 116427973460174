<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="订单号" :label-width="formLabelWidth">
                                <el-input v-model="queryForm.orderNo" placeholder="多个单号需要完整单号" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                         <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="用车编号">
                <el-input v-model="queryForm.takeNo" type="textarea" @keyup.enter.native="handleSearch" placeholder="多个用车编号需要完整编号"></el-input>
              </el-form-item>
            </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="渠道名" :label-width="formLabelWidth">
                                <f-select v-model="queryForm.channelId"
                                          :is-need="queryForm.channelId"
                                          :dict="'transportStatus'"
                                          @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="是否预约" :label-width="formLabelWidth">
                                <f-select :dict="'unit_whether'" v-model="queryForm.isBook" :isNeed="queryForm.isBook"
                                          @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="客服" :label-width="formLabelWidth">
                                <f-select :dict="'sys_user_id'" v-model="queryForm.docIdList" :isNeed="queryForm.docIdList"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="预约车型" :label-width="formLabelWidth">
                                <f-select :dict="'reserved_model'" v-model="queryForm.reservedModelList"
                                          :isNeed="queryForm.reservedModelList" :multiple="true"
                                          @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="预计到仓时间" :label-width="formLabelWidth">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.arrivalTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.arrivalTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px" >
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        class="margin_right_10"
                        :disabled="selectedRowKeys.length === 0"
                        v-if="buttonShowList.add">开单
                </el-button>

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadExcelUrl,'仓储部开单数据')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <!--                    <template v-if="scope.column.property === 'action'">-->
                    <!--                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
                    <!--                    </template>-->

                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                        <span v-if="scope.row.hasQianzhiDadan == '1'" style="margin-left: 10px;">快递已打单</span>
                    </template>

                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <add-warehouse-receipts :visible.sync="dialogFormVisible" :orderNos="selectedOrderNos" 
                                :warehouseId = "warehouseId"
                                :selectedBook="selectedBook" :selectedCarnumber="selectedCarNumber"
                                :selectedPhone="selectedPhone"
                                @ok="handleSearch" :selectionRowsDialog="selectionRows"></add-warehouse-receipts>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {httpAction,getAction} from "@/api/manage";
    /*import { putAction, getAction } from "@/api/manage";*/
    import addWarehouseReceipts from "@/views/warehouse2.0/modules/addWarehouseReceipts";

    export default {
        name: "WarehouseNoReceiptOrder",
        mixins: [QueryMixins],
        components: {addWarehouseReceipts},
        data() {
            return {
              warehouseId:null,
              isUsePost: true,
                dialogFormVisible: false,
                formLabelWidth: "120px",
                //表单校验

                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        width:150
                    },
                    {
                        prop: "ctnStr",
                        label: "预报件重体",
                    },
                       {
                        prop: "takeNo",
                        label: "⽤⻋编号",
                    },
                       {
                        prop: "dispatchSchedule",
                        label: "⽤⻋进度",
                    },
                    {
                        prop: "remark",
                        label: "内部备注",
                        overFlow: true,
                    },
                    {
                        prop: "isRucangStr",
                        label: "入仓费记账",

                    },
                    {
                        prop: "isBook",
                        label: "是否预约",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名",
                    },
                    {
                        prop: "driverName",
                        label: "司机姓名",
                    },
                    {
                        prop: "driverPhone",
                        label: "手机号",
                    },
                    {
                        prop: "reservedCarNumber",
                        label: "车牌号",
                    },
                    {
                        prop: "reservedModelDictText",
                        label: "车型",
                    },
                    {
                        prop: "doc",
                        label: "客服",
                    },
                    {
                        prop: "salesman",
                        label: "销售",
                    },
                    {
                        prop: "arrivalTime",
                        label: "预计到仓时间",
                    },

                ],
                url: {
                    list: "/warehouse/receipt/queryNoReceiptOrderList",
                    downloadExcelUrl: '/file/export/excel/downloadWarehouseNoReceiptOrderExcel',
                    getAcquiesceStash:"/warehouse/receipt/queryAcquiesceStash",

                },
                selectedOrderNos: '',
                selectedBook: '',
                //车牌号
                selectedCarNumber: '',
                //手机号
                selectedPhone: '',
                disableMixinMounted: true,
            };
        },
        methods: {


            handleAdd() {
                this.form = {};
                let hasNo = false;
                let hasYes = false;
                this.selectionRows.forEach(s => {
                    if (s.isBook === '已预约') {
                        hasYes = true;
                    } else if (s.isBook === '未预约') {
                        hasNo = true;
                    }
                })
                if (hasYes && hasNo) {
                    this.$message.warning("未预约的订单不能和已预约的订单一起进行开单");
                    return false;
                }

                // if (hasYes && this.selectionRows && this.selectionRows.length > 0) {
                //     let selectedCarNumber = this.selectionRows[0].reservedCarNumber || '';
                //     let selectedPhone = this.selectionRows[0].driverPhone || '';
                //     this.selectionRows.forEach(s=>{
                //         let numbe = s.reservedCarNumber;
                //         //只要有不相同的就清空
                //         if (!numbe) {
                //
                //         }else {
                //             if (!selectedCarNumber) {
                //                 selectedCarNumber = numbe;
                //             }else if (selectedCarNumber !== numbe) {
                //                 selectedCarNumber = ''
                //             }
                //         }
                //         let phone = s.driverPhone;
                //         if (!phone) {
                //
                //         }else {
                //             if (!selectedPhone) {
                //                 selectedPhone = phone;
                //             }else if (selectedPhone !== phone) {
                //                 selectedPhone = ''
                //             }
                //         }
                //     })
                //     this.selectedCarNumber = selectedCarNumber;
                //     this.selectedPhone = selectedPhone;
                // }
                //已经预约的 取 不为空的 第一个单号和
                this.selectedOrderNos = this.selectedRowKeys.join(',');
                this.selectedBook = hasNo ? '0' : '1';
                // getAction(this.url.getAcquiesceStash).then((res)=>{
                //   if (res.success){
                //     this.warehouseId = res.result;
                    this.dialogFormVisible = true;
                  // }
                // })
            },

            saveImporter() {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        let url = this.url.addOneSettle;
                        let method = 'POST';
                        if (this.form.id) {
                            url = this.url.updateOneSettle;
                            method = "PUT";
                        }
                        httpAction(url, method, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                    }
                });
            },
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/WarehouseNoReceiptOrder','0','导出'),
            'add':this.$btnIsShow('warehouse2.0/WarehouseNoReceiptOrder','0','开单'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
