import { render, staticRenderFns } from "./noCreate.vue?vue&type=template&id=268df58e&scoped=true&"
import script from "./noCreate.vue?vue&type=script&lang=js&"
export * from "./noCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268df58e",
  null
  
)

export default component.exports