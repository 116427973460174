<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="组合名称">
                <el-input v-model="queryForm.ruleName"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="组合状态">
                <f-select v-model="queryForm.isShow" :is-need="queryForm.isShow" :dict="'unit_enable'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="录入人">
                <f-select v-model="queryForm.createBy" :is-need="queryForm.createBy"
                          :data-list="queryDictCodeListMap['sys_user']"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="修改人">
                <f-select v-model="queryForm.updateBy" :is-need="queryForm.updateBy"
                          :data-list="queryDictCodeListMap['sys_user']"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
              <el-form-item label="录入时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
              <el-form-item label="修改时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.updateTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.updateTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>


            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>


        </el-card>
      </el-form>
      <div class="f-p-b-15">
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="handleEdit(null)"
            v-if="buttonShowList['xz']"
        >新增
        </el-button>
        <el-button
            type="primary"
            size="medium"
            class="c-theme-error-button"
            @click="handleBatDel"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList.sc"
        >批量删除
        </el-button>
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="handleEditUpdateRuleShow('1','启用')"
            :disabled="selectedRowKeys.length === 0"
            v-if="buttonShowList['en']"
        >批量启用
        </el-button>
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="handleEditUpdateRuleShow('0','禁用')"
            :disabled="selectedRowKeys.length === 0"
            v-if="buttonShowList['en']"
        >批量禁用
        </el-button>
      </div>

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList['bj']">编辑
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'isShow'">
            <span>{{ parseDict2(scope.row.isShow,'unit_enable') }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <sales-per-config-first-dialog
        :visible="salesPerConfigFirstDialogVisible"
        :select-form="salesPerConfigFirstDialogForm"
        :edit-type="salesPerConfigFirstDialogEditType"
        :select-base-vo="salesPerConfigFirstDialogBaseVo"
        @update:visible="(value) => { salesPerConfigFirstDialogVisible = value; }"
        @ok="handleQuery()"
    ></sales-per-config-first-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {getAction, postAction} from "@/api/manage";
import salesPerConfigFirstDialog from "@/views/salePerConfig/modules/salesPerConfigFirstDialog.vue";

export default {
  name: "SalesPerConfigFirst",
  mixins: [QueryMixins],
  components: {salesPerConfigFirstDialog},
  data() {
    return {
      isUsePost: true,
      needQueryDictCodes: ['sys_user'],
      columns: [
        {
          type: "selection",
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "ruleName",
          label: "组合名称",
        },
        {
          prop: "isShow",
          label: "状态",
        },
        {
          prop: "detail",
          label: "组合详情",
          isHtml : true
        },
        {
          prop: "createBy",
          label: "录入人",
        },
        {
          prop: "createTime",
          label: "录入时间",
        },
        {
          prop: "updateBy",
          label: "修改人",
        },
        {
          prop: "updateTime",
          label: "修改时间",
        },

      ],
      url: {
        //查询列表的接口
        list: "/shipping/sales/salesPerConfigFirst/getFirstRulePage",
        delFirstRule: "/shipping/sales/salesPerConfigFirst/delFirstRule",
        getRelevantConfig: "/shipping/sales/salesPerConfigFirst/getRelevantConfig",
        getById: "/shipping/sales/salesPerConfigFirst/getById",
        getBaseInfo: '/shipping/sales/per/config/getBaseModel',
        updateRuleShow: '/shipping/sales/salesPerConfigFirst/updateRuleShow',
      },

      salesPerConfigFirstDialogEditType: '',
      salesPerConfigFirstDialogVisible: false,
      salesPerConfigFirstDialogForm: {},
      salesPerConfigFirstDialogBaseVo: {},
    };
  },
  methods: {
    handleEditUpdateRuleShow(isShow, name) {
      this.$confirm(`确定${name}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(res => {
        postAction(this.url.updateRuleShow,{
          idList: this.selectedRowKeys,
          isShow : isShow
        }).then(res => {
          this.$message.success(res.message);
          this.handleSearch();
        })
      })
    },
    realEdit(row){
      postAction(this.url.getById, {id: row.id})
          .then(res => {
            this.salesPerConfigFirstDialogForm = res.result;
            this.salesPerConfigFirstDialogVisible = true;
          })
    },
    async handleEdit(row) {
      await getAction(this.url.getBaseInfo)
          .then(res => {
            this.salesPerConfigFirstDialogBaseVo = res.result;
          })
      if (row) {
        // 编辑

        this.salesPerConfigFirstDialogEditType = '编辑';
        // 请求校验 是否允许修改
        postAction(this.url.getRelevantConfig, {idList: [row.id], operator: '0'})
            .then(res => {
              if (res.message && res.message.length > 0) {
                this.$confirm(`${res.message}？`, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'error',
                  closeOnClickModal: false,
                }).then(() => {
                  this.realEdit(row);
                })

              } else {
                this.realEdit(row);
              }
            })
      } else {
        // 新增
        this.salesPerConfigFirstDialogEditType = '新增';
        this.salesPerConfigFirstDialogVisible = true;
      }

    },
    realDel(){
      postAction(this.url.delFirstRule, {idList: this.selectedRowKeys})
          .then(res => {
            this.$message.success(res.message);
            this.handleSearch();
          })
    },
    handleBatDel() {
      this.$confirm(`确定删除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.getRelevantConfig, {idList: this.selectedRowKeys, operator: '1'})
            .then(res => {
              if (res.message && res.message.length > 0) {
                this.$confirm(`${res.message}？`, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'error',
                  closeOnClickModal: false,
                }).then(res => {
                  this.realDel();
                })
              } else {
                  this.realDel();
              }
            })
      })
    }
  },
  computed: {
    buttonShowList() {
      return {
        'bj': this.$btnIsShow('salePerConfig/SalesPerConfigFirst', '0', '编辑'),
        'sc': this.$btnIsShow('salePerConfig/SalesPerConfigFirst', '0', '删除'),
        'xz': this.$btnIsShow('salePerConfig/SalesPerConfigFirst', '0', '新增'),
        'en': this.$btnIsShow('salePerConfig/SalesPerConfigFirst', '0', '启用'),
        'dis': this.$btnIsShow('salePerConfig/SalesPerConfigFirst', '0', '禁用'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
