<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-row>
        <el-col :span="4">
          <el-form-item label="柜号">
            <el-input
              v-model="queryForm.containerNo"
              @keyup.enter.native="handleSearch"
              type="textarea"
              placeholder="多个柜号精确查询"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="订舱号">
            <el-input
              v-model="queryForm.bookNo"
              @keyup.enter.native="handleSearch"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="船名航次">
            <el-input v-model="queryForm.shipNo" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="发票确认状态" label-width="120px">
            <el-select
              clearable
              filterable
              v-model="queryForm.containerInvoiceStatus"
            >
              <el-option value="0" label="未确认"></el-option>
              <el-option value="1" label="已确认"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="周数">
            <el-input
              v-model.trim="queryForm.week"
              @keyup.enter.native="
                () => {
                  handleSearch();
                }
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="清关代理">
            <f-select
              v-model="queryForm.clearAgentId"
              :isNeed="queryForm.clearAgentId"
              :dict="'clearAgent_id'"
            >
            </f-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item label="审核HS状态">
            <el-select
              clearable
              filterable
              v-model="queryForm.containerCheckHs"
            >
              <el-option value="0" label="未审核"></el-option>
              <el-option value="1" label="已审核"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="起运港">
            <f-select
              v-model="queryForm.pol"
              :is-need="queryForm.pol"
              placeholder="请选择"
              filterable
              clearable
              :dict="'bd_port'"
            >
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="卸货港">
            <f-select
              :isNeed="queryForm.pod"
              v-model="queryForm.pod"
              :dict="'sys_port'"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="目的港">
            <f-select
              :dict="'bd_port'"
              :is-need="queryForm.podEnd"
              v-model="queryForm.podEnd"
              autocomplete="off"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="包税发票审核状态" label-width="120px">
            <el-select
              clearable
              filterable
              v-model="queryForm.containerInvoiceCheck"
            >
              <el-option value="0" label="未审核"></el-option>
              <el-option value="1" label="已审核"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="operateId" label="排柜操作" key="operateId">
            <f-select
              v-model="queryForm.operateId"
              :isNeed="queryForm.operateId"
              :dict="'sys_user_id'"
            ></f-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item prop="docId" label="文件操作">
            <f-select
              v-model="queryForm.docId"
              :isNeed="queryForm.docId"
              :dict="'sys_user_id'"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="装柜时间" label-width="100px">
            <div class="f-flex">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.loadTimeStart"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
              <span class="f-p-h-2">~</span>
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.loadTimeEnd"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开船日期" label-width="80px">
            <div class="f-flex">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.eta"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
              <span class="f-p-h-2">~</span>
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.etaEnd"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4" style="display: none">
          <el-form-item label="no">
            <el-input
              v-model.trim="queryForm.no"
              @keyup.enter.native="
                () => {
                  handleSearch();
                }
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="30px">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                () => {
                  handleSearch();
                }
              "
              >查询</el-button
            >
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="f-p-b-15">
      <el-button
        type="primary"
        @click="reviewInformation({}, { containerCheckHs: '1' })"
        :disabled="!selectionRows.length"
        v-if="buttonShowList.sh"
        >审核HS
      </el-button>
      <el-button
        type="primary"
        @click="reviewInformation({}, { containerInvoiceCheck: '1' })"
        :disabled="!selectionRows.length"
        v-if="buttonShowList.fpsh"
        >包税发票审核
      </el-button>
      <el-button
        type="primary"
        @click="handleEdit({}, '3')"
        :disabled="!selectionRows.length"
        v-if="buttonShowList.canLoad"
        >可装柜
      </el-button>
      <el-button
        type="primary"
        @click="handleEdit({}, '2')"
        :disabled="!selectionRows.length"
        v-if="buttonShowList.canFile"
        >可发文件
      </el-button>
      <el-button
        type="primary"
        @click="handleEdit({}, '4')"
        :disabled="!selectionRows.length"
        v-if="buttonShowList.noLoad"
        >不可装柜
      </el-button>
    </div>
    <f-table
      v-loading="loading"
      ref="GTable"
      border
      size="medium"
      row-key="id"
      class="c-th-has-bgcolor"
      :columns="columns"
      :data="tableData"
      :cell-style="{ 'text-align': 'center' }"
      tableHeight="62vh"
      :isRecordTableScroll="true"
      @selection-change="handleSelectionChange"
      @sort-change="handleTableChange"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button
            type="text"
            @click="handleEdit(scope.row, '3')"
            v-if="buttonShowList.canLoad"
            >可装柜
          </el-button>
          <el-button
            type="text"
            @click="handleEdit(scope.row, '2')"
            v-if="buttonShowList.canFile"
            >可发文件
          </el-button>
          <el-button
            type="text"
            @click="handleEdit(scope.row, '4')"
            v-if="buttonShowList.noLoad"
            >不可装柜
          </el-button>
          <el-button
            type="text"
            @click="reviewInformation(scope.row, { containerCheckHs: '1' })"
            v-if="buttonShowList.sh"
            >审核HS
          </el-button>
          <el-button
            type="text"
            @click="
              reviewInformation(scope.row, { containerInvoiceCheck: '1' })
            "
            v-if="buttonShowList.fpsh"
            >包税发票审核
          </el-button>
        </template>
        <template v-else-if="scope.column.property === 'circulationState'">
          <span>{{
            parseDict(scope.row.circulationState, "circulation_state")
          }}</span>
        </template>
        <template v-else-if="scope.column.property === 'cabinetCategory'">
          <span>{{
            parseDict(scope.row.cabinetCategory, "cabinet_category")
          }}</span>
        </template>
        <template v-else-if="scope.column.property === 'bookAgentId'">
          <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id") }}</span>
        </template>
        <template v-else-if="scope.column.property === 'week'">
          <a style="color: #00a0e9" @click="infomation(scope.row.id)">{{
            scope.row.week
          }}</a>
        </template>
        <template
          v-else-if="scope.column.property === 'containerInvoiceStatus'"
        >
          <span>{{
            scope.row.containerInvoiceStatus == "0" ? "未确认" : "已确认"
          }}</span>
        </template>
        <template v-else-if="scope.column.property === 'containerCheckHs'">
          <span>{{
            scope.row.containerCheckHs == "0" ? "未审核" : "已审核"
          }}</span>
        </template>
        <template v-else-if="scope.column.property === 'containerInvoiceCheck'">
          <span>{{
            scope.row.containerInvoiceCheck == "0" ? "未审核" : "已审核"
          }}</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { putAction, postAction } from "@/api/manage";
import QueryMixins from "../../mixins/QueryMixins";
import { setUpRouteUtil } from "@/utils/util";

export default {
  name: "SensitiveProducts",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      dictCodes: ["circulation_state", "cabinet_category", "bookAgent_id"],
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 200,
        },
        {
          prop: "week",
          label: "周数",
          sortable: "custom",
          // width: 140
        },
        {
          prop: "bookNo",
          label: "订舱号",
          sortable: "custom",
          // width: 140
        },
        {
          prop: "containerNo",
          label: "柜号",
          sortable: "custom",
          // width: 160
        },
        {
          prop: "containerInvoiceStatus",
          label: "发票确认状态",
        },
        {
          prop: "containerCheckHs",
          label: "审核HS状态",
        },
        {
          prop: "containerInvoiceCheck",
          label: "包税发票审核状态",
        },
        {
          prop: "loadTime",
          label: "装柜时间",
          sortable: "custom",
          // width: 160
        },
      ],
      url: {
        list: "/operation/containerApi/getCabinetAuditList",
        updateCabinetAudit: "/operation/containerApi/updateCabinetAudit",
        updateBatchCabinetCheck:
          "operation/containerApi/updateBatchCabinetCheck", //审核HS
        updateBatchCabinetAudit:
          "/operation/containerApi/updateBatchCabinetAudit",
        // exportXlsUrl: '/customer/product/exportXls',
        // tuihui:'/customer/product/qxSense/',
        // tuihuiBatch:'/customer/product/qxSenseBatch',
      },
    };
  },
  methods: {
    infomation(id) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, "/operation/cabinet/Edit");
      this.$router.push("/operation/cabinet/Edit?id=" + id);
    },
    showRate(val) {
      if (val.indexOf("%") !== -1) {
        return val;
      } else {
        return val + "%";
      }
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.queryForm.sense = "1";
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    reviewInformation(row, params) {
      const paramsObj = {
        containerIds: [],
        ...params,
      };
      if (row && row.id) {
        paramsObj.containerIds.push(row.id);
      } else {
        paramsObj.containerIds = this.selectionRows.map((item) => item.id);
      }
      postAction(this.url.updateBatchCabinetCheck, paramsObj).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    handleEdit(row, type) {
      let that = this;
      let form = JSON.parse(JSON.stringify(row));
      form.complianceState = type;
      form.actionType = "complianceState";
      if (row && row.id) {
        that
          .$confirm(`确定修改【${row.week}】状态?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            closeOnClickModal: false,
          })
          .then(() => {
            putAction(that.url.updateCabinetAudit, form).then((res) => {
              that.$message({
                type: "success",
                message: "修改成功",
              });
              that.handleQuery();
            });
          })
          .catch(() => {});
      } else {
        postAction(that.url.updateBatchCabinetAudit, {
          complianceState: type,
          actionType: "complianceState",
          containerIds: this.selectionRows.map((item) => item.id),
        }).then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "修改成功",
            });
            that.handleQuery();
          }
        });
      }
    },
  },
  created() {
    this.initData.complianceState = "1";
    this.queryForm.sense = "1";
  },
  computed: {
    buttonShowList() {
      return {
        canLoad: this.$btnIsShow("conformance/CabinetAudit", "1", "可装柜"),
        canFile: this.$btnIsShow("conformance/CabinetAudit", "1", "可发文件"),
        noLoad: this.$btnIsShow("conformance/CabinetAudit", "1", "不可装柜"),
        fpsh: this.$btnIsShow("conformance/CabinetAudit", "0", "包税发票审核"),
        sh: this.$btnIsShow("conformance/CabinetAudit", "0", "审核HS"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
