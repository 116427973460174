<template>
  <!-- 新增编辑活动配置弹窗 -->
  <div>
    <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="height: 100%;" :disabled="!canEdit">
      <f-table :columns="columns" :data="form.list">
        <template #default="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleDelete(scope.row, scope.$index)" icon="el-icon-delete" ></el-button>
          </template>
          <template v-else-if="scope.column.property === 'dictId'">
            <el-form-item :prop="'list.' + scope.$index + '.dictId'" label-width="0px"
                          :rules="rules.dictId">
              <el-select v-model="scope.row.dictId" placeholder="请选择" :loading="loadingRule" clearable filterable
                         @change="(val) => { getOptionList1(val, scope.row, scope.$index) }"
              >
                <el-option v-for="item in salesPerConfigDictBaseVOList" :key="item.dictId" :label="item.dictName"
                           :value="item.dictId">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'operatorSymbol'">
            <el-form-item :prop="'list.' + scope.$index + '.operatorSymbol'" label-width="0px"
                          :rules="rules.operatorSymbol">
              <f-select :dataList="scope.row.operatorSymbolList" v-model="scope.row.operatorSymbol"
                        :isNeed="scope.row.operatorSymbol"
                        @input="(val) => { getOptionMultiple(val, scope.row, scope.$index) }">
              </f-select>
            </el-form-item>
          </template>

          <template v-else-if="scope.column.property === 'dictValue'">
            <!-- 包含不包含多选 -->
            <template
                v-if="((scope.row.operatorSymbol === 'in' || scope.row.operatorSymbol === 'not in') && scope.row.ruleType !== '6' && scope.row.show)">
              <el-form-item :prop="'list.' + scope.$index + '.dictValueList'" label-width="0px"
                            :rules="rules.dictValue">
                <f-select
                    v-model="scope.row.dictValueList" :data-list="scope.row.dictModelList"
                    :is-need="scope.row.dictValueList" :multiple="true" :is-all="true" placeholder="请选择"
                    @click.native="handleOption(scope.row, scope.$index)">

                </f-select>
              </el-form-item>
            </template>
            <!-- 其他单选 -->
            <template v-else-if="scope.row.show && ['1','2'].indexOf(scope.row.ruleType) !== -1">
              <el-form-item :prop="'list.' + scope.$index + '.dictValue'" label-width="0px"
                            :rules="rules.dictValue">
                <f-select
                    :data-list="scope.row.dictModelList"
                    v-model="scope.row.dictValue" :is-need="scope.row.dictValue" placeholder="请选择"
                    @click.native="handleOption(scope.row, scope.$index)">

                </f-select>
              </el-form-item>
            </template>
            <!--                手动输入 字符串类型-->
            <template v-else-if="scope.row.show && scope.row.ruleType === '3'">
              <el-form-item :prop="'list.' + scope.$index + '.dictValue'" label-width="0px"
                            :rules="rules.dictValue">
                <el-input v-model="scope.row.dictValue" maxlength="50" show-word-limit>

                </el-input>

              </el-form-item>
            </template>
            <!--                手动输入 整数类型-->
            <template v-else-if="scope.row.show && scope.row.ruleType === '4'">
              <el-form-item :prop="'list.' + scope.$index + '.dictValueInt'" label-width="0px"
                            :rules="rules.dictValue">
                <el-input
                    maxlength="5" show-word-limit type="number" v-model="scope.row.dictValueInt"
                    onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                ></el-input>

              </el-form-item>
            </template>
            <!--                手动输入 数字类型-->
            <template v-else-if="scope.row.show && scope.row.ruleType === '5'">
              <el-form-item :prop="'list.' + scope.$index + '.dictValueDouble'" label-width="0px"
                            :rules="rules.shuzi">
                <el-input
                    v-model="scope.row.dictValueDouble" maxlength="5" show-word-limit type="number" @change="changeInputValue(scope.$index,scope.row.dictValueDouble)"
                ></el-input>
              </el-form-item>
            </template>
            <!--                手动输入 订单集合类型-->
            <template v-else-if="scope.row.show && scope.row.ruleType === '6'">
              <el-form-item :prop="'list.' + scope.$index + '.dictValue'" label-width="0px"
                            :rules="rules.ouhao">
                <el-input
                    v-model="scope.row.dictValue" maxlength="150" show-word-limit type="textarea"
                ></el-input>
              </el-form-item>
            </template>
            <!--                手动输入 时间类型-->
            <template v-else-if="scope.row.show && scope.row.ruleType === '7'">
              <el-form-item :prop="'list.' + scope.$index + '.dictValue'" label-width="0px"
                            :rules="rules.dictValue">
                <el-date-picker
                    class="f-flex-1"
                    v-model="scope.row.dictValue"
                    type="datetime"
                    size="small"
                    placeholder="请选择时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </template>
          </template>

        </template>
      </f-table>
    </el-form>

  </div>
</template>
<script>
import {postAction} from '@/api/manage';
import {getAction} from '@/api/manage';
import {validateUnitValue4, validateOuhao} from "@/utils/validator";

export default {
  name: 'salesPerFieldConfigTable',
  components: {},
  props: {

    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },

    selectList: {
      type: Array,
      default: () => []
    },
    selectBaseVo: {
      type: Object,
      default: () => {
      }
    },
    // 是否可以编辑
    canEdit: {
      type : Boolean,
      default : true
    },
    selectIndex: {
      type : Number,
      default : 0
    }
  },
  data() {
    return {
      orderNo: [],
      generalOrderFlag: false,
      generalOrderList: [],
      queryForm: {},
      rules: {
        dictId: [
          {required: true, message: '请选择规则名', trigger: "change"},
        ],
        operatorSymbol: [
          {required: true, message: '请选择运算符', trigger: "change"},
        ],
        dictValue: [
          {required: true, message: '请选择选项', trigger: "change"},
        ],
        shuzi: [
          {required: true, message: '请选择选项', trigger: "change"},
          {validator: validateUnitValue4, trigger: 'change'}
        ],
        ouhao: [
          {required: true, message: '请选择选项', trigger: "change"},
          {validator: validateOuhao, trigger: 'change'}
        ],

      },
      form: {
        baseInfo: {},
        list: []
      },
      salesPerConfigDictBaseVOMap: {},
      salesPerConfigDictBaseVOList: [],
      operatorDictModelList: [],
      combineDictModelList: [],
      defaultOperatorSymbolList: [
        {value: 'in', text: '在集合中'},
        {value: 'not in', text: '不在集合中'},
      ],
      url: {
        getBaseInfo: '/shipping/sales/per/config/getBaseModel',
      },

      columns: [
        {
          prop: "dictId",
          label: '配置条件',
          headerType: 'require',
        },
        {
          prop: "operatorSymbol",
          label: '赋值',
          headerType: 'require',
        },
        {
          prop: "dictValue",
          label: '选项',
          headerType: 'require',
        },

        {
          prop: "action",
          label: '操作',
          width: 30
        },
      ],
      loadingRule: false,
      tips: '',
    }
  },
  watch: {
    'selectBaseVo': {
      handler(newValue, oldValue) {
        console.log("监控到vo变化",newValue,oldValue)
        // 这里处理变化逻辑
        if (newValue) {
          this.buildBaseData(newValue);
        }
      },
      deep: true,
      immediate: true // 初始化时也触发一次
    },
    'selectList' : {
      handler(newValue, oldValue) {
        console.log("监控到list变化",newValue,oldValue)
        if (newValue) {
          this.form.list = JSON.parse(JSON.stringify(newValue));
          this.buildFieldRuleList();
        }else {
          this.form = {list: []};
        }
      },
      deep: true,
      immediate: true // 初始化时也触发一次
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    changeInputValue(index,value){
      this.$set(this.form.list[index], 'dictValue', parseFloat(value));
      this.$set(this.form.list[index], 'fieldValue', parseFloat(value));
    },
    // 防止 下拉框 没有选项可以选择

    buildFieldRuleList() {
      if (this.form.list && this.form.list.length > 0) {
        this.form.list.forEach((item, index) => {
          this.$set(this.form.list[index], 'show', true);
          this.getOptionList(item.dictId, item, index);
          this.initDictValue(item, index);
        })
      }

      console.log(this.form.list, 'this.form.list')
    },
    buildBaseData(baseVo) {
      this.salesPerConfigDictBaseVOList = baseVo.salesPerConfigDictBaseVOList || [];
      this.salesPerConfigDictBaseVOMap = this.listToMap(this.salesPerConfigDictBaseVOList, 'dictId');
      this.operatorDictModelList = baseVo.operatorDictModelList;
      this.combineDictModelList = baseVo.combineDictModelList;
    },
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleRuleName() {
      if (!this.form.type) {
        this.$message.warning('请先选择活动类型');
      }
    },
    handleOption(row, index) {
      if (!row.dictId) {
        this.$message.warning('请先选择规则名');
      }
    },
    // 获取规则列表
    getOptionList(val, row, index) {
      let obj = this.salesPerConfigDictBaseVOMap[val];
      console.log(val, 'val');
      console.log(obj, '选中的规则对象');
      if (!(val && obj)) {
        return;
      }
      this.$set(this.form.list[index], 'dictName', obj.dictName);
      this.$set(this.form.list[index], 'ruleType', obj.ruleType);
      this.$set(this.form.list[index], 'ruleCode', obj.ruleCode);
      this.$set(this.form.list[index], 'dictModelList', obj.sysDictModelList);
      //这个可选的选项集合为
      let operatorList = this.operatorDictModelList.filter(item => item['ruleTypes'].indexOf(obj.ruleType) !== -1);
      if (operatorList.length > 0) {
        this.$set(this.form.list[index], 'operatorSymbolList', operatorList);
      } else {
        this.$set(this.form.list[index], 'operatorSymbolList', this.defaultOperatorSymbolList);
      }
    },
    getOptionList1(val, row, index){
      //重置 赋值 和选项下拉框的内容
      this.$set(this.form.list[index], 'operatorSymbol', '');
      this.$set(this.form.list[index], 'dictValue', '');
      this.getOptionList(val, row, index);
    },
    // 初始化 字典值用于回显
    initDictValue(row, index) {
      if (!row) {
        return
      }
      let ruleType = row.ruleType;
      let dictValue = row.dictValue;
      if (!dictValue) {
        return;
      }
      let operatorSymbol = row.operatorSymbol;
      if ((operatorSymbol === 'in' || operatorSymbol === 'not in') && ruleType !== '6' ) {
        this.$set(this.form.list[index], 'dictValueList', JSON.parse(dictValue));
      }else if (ruleType === '4') {
        this.$set(this.form.list[index], 'dictValueInt', parseInt(dictValue));
      }else if (ruleType === '5') {
        this.$set(this.form.list[index], 'dictValueDouble', parseFloat(dictValue));
      }
    },
    // 获取选项是否多选
    getOptionMultiple(val, row, index) {
      this.$set(this.form.list[index], 'operatorSymbol', val);
      if (val === 'in' || val === 'not in') {
        this.$set(this.form.list[index], 'dictValue', '');
        this.$set(this.form.list[index], 'dictValueInt', '');
        this.$set(this.form.list[index], 'dictValueDouble', '');
      } else {
        this.$set(this.form.list[index], 'dictValueList', []);
      }
      this.$set(this.form.list[index], 'show', false);
      console.log("不显示选项")
      setTimeout(() => {
        this.$set(this.form.list[index], 'show', true);
        console.log("显示选项")
      });
    },
    handleAdd() {
      this.form.list.push({dictValueList: [], show: true});
    },
    handleDelete(row, index) {
      if (this.form.list.length <= 1) {
        this.$message.warning('列表数据至少保留一项');
        return
      }
      this.form.list.splice(index, 1);
    },

    // 处理字典编码里的list 对象 给 fieldValue 获胜 fieldValueList赋值
    handleChargeListObj(obj) {
      if (!obj) {
        return;
      }
      let dictValueList = obj.dictValueList;
      let dictModelList = obj.dictModelList;
      let dictModelMap = {};
      if (dictModelList && dictModelList.length > 0 && Array.isArray(dictModelList)) {
        dictModelMap = this.listToMap(dictModelList, 'itemValue')
      }
      if (dictValueList && dictValueList.length > 0 && Array.isArray(dictValueList)) {
        let fieldValueList = dictValueList.map(s => dictModelMap[s]['itemText']);
        this.$set(obj, 'fieldValueList', fieldValueList);
      } else if (['1', '2'].indexOf(obj.ruleType) !== -1) {
        let fieldValue = dictModelMap && dictModelMap[obj['dictValue']] && dictModelMap[obj['dictValue']]['itemText'];
        if (fieldValue) {
          this.$set(obj, 'fieldValue', fieldValue);
        }

      } else if (obj['dictValue']) {
        this.$set(obj, 'fieldValue', obj['dictValue'].toString());
      } else if (obj['dictValueDouble']) {
        this.$set(obj, 'fieldValue', obj['dictValueDouble'].toString());
      }else if (obj['dictValueInt']) {
        this.$set(obj, 'fieldValue', obj['dictValueInt'].toString());
      }
    },
    /**
     * 子组件校验表单
     */
    validateForm(){
      let that = this;
      return new Promise((resolve, reject) => {
        that.$refs.form.validate((valid) => {
          if (valid) {
            that.form.list.forEach(obj => {
              that.handleChargeListObj(obj);
            });
            that.$emit('validate', true, that.form.list, that.selectIndex);
            resolve("校验成功")
          }else {
            that.$emit('validate', false, null);
            reject("校验失败")
          }
        });
      })

    },

    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!this.form.list || this.form.list.length === 0) {
          this.$message.warning('规则列表不能为空');
          return false;
        }
        this.form.list.forEach(obj => {
          this.handleChargeListObj(obj);
        })
        if (valid) {
          console.log(this.form)
          let params = JSON.parse(JSON.stringify(this.form));

          // params.list.forEach((item, index) => {
          //   delete item.optionList;
          //   delete item.show;
          // })
          this.$store.commit("setFullscreenLoading", true);
          // 复制的时候清空ID
          let url = this.url.add;
          if (this.editType === '复制') {
            delete params.id;
          } else if (this.editType === '编辑') {
            url = this.url.edit;
          }
          postAction(url, params).then((res) => {
            this.$store.commit("setFullscreenLoading", false);
            this.$message.success(res.message);
            this.handleClose();
            this.$emit("ok");
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          });

        }
      });
    },
    handleClose() {
      this.form = {
        list: []
      };
      this.$emit("update:visible", false);
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
