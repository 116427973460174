<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium" :inline="true">
            <el-card class="box-card">
              <el-row class="inputs_row">
                <el-col :md="4" :sm="24">
                  <el-form-item label="订单号">
                    <el-input v-model="queryForm.orderNo" type="textarea"></el-input>
                </el-form-item>
                </el-col>
                <el-col :md="5" :sm="24">
                  <el-form-item label="业务员">
                    <el-select filterable v-model="queryForm.salesmanIds" multiple @change="changeSelect" collapse-tags
                               style="margin-left: 0px; width: 200px" placeholder="请选择">
                      <el-checkbox v-model="checked" @change="selectAll">全选</el-checkbox>
                      <el-option v-for="item in this.dictMap.salesmanId" :key="item.value" :label="item.text"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :md="4" :sm="24">
                  <el-form-item label="客服">
                      <f-select :dict="'docId'" v-model="queryForm.docId" :is-need="queryForm.docId"></f-select>
                    </el-form-item>
                  </el-col>
                <el-col :md="4" :sm="24">
                  <el-form-item label="操作员">
                    <f-select :dict="'operationsId'" v-model="queryForm.operatorId"
                              :is-need="queryForm.operatorId"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :md="4" :sm="24">
                  <el-form-item label="客户">
                    <el-input v-model="queryForm.customer"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="4" :sm="24">
                  <el-form-item label="业务类型">
                    <f-select v-model="queryForm.containerType" :isNeed="queryForm.containerType" :clearable="false"
                              :dataList="containerTypeList">
                    </f-select>
                  </el-form-item>
                </el-col>
                <el-col :md="5" :sm="24">
                  <el-form-item label="费用选择">
                    <el-select filterable v-model="queryForm.remarkSelectList" multiple
                               style="margin-left: 0px; width: 200px" placeholder="请选择">
                      <el-option v-for="item in this.remarkSelectList" :key="item.value" :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
<!--                  <el-form-item label="费用选择">-->
<!--                    <el-select v-model="queryForm.remarkSelectList"-->
<!--                              :isNeed="queryForm.remarkSelectList"-->
<!--                              :multiple="true"-->
<!--                              :dataList="remarkSelectList">-->
<!--                    </el-select>-->
<!--                  </el-form-item>-->
                </el-col>


              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
              </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" icon="el-icon-plus" @click="noProblem" :disabled="!selectionRows.length"
                    v-if="buttonShowList['无问题']">
                    批量修改为无问题
                </el-button>
                <el-button type="primary" size="medium" @click="handleExportXls('海运异常订单导出')"
                    v-if="buttonShowList['导出']">导出</el-button>
              <el-button type="primary" icon="el-icon-plus" @click="updateRatio" :disabled="!selectionRows.length"
                         v-if="buttonShowList['同步更新海运费比值']">
                同步更新海运费比值
              </el-button>
            </div>
            <f-table v-loading="loading" border ref="GTable" size="medium" row-key="id" class="c-th-has-bgcolor"
                :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="66vh"
                @selection-change="handleSelectionChange" @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'orderNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoDocPage(scope.row.orderNo)"
                    >{{ scope.row.orderNo }}</a
                    >
                  </template>
                </template>
            </f-table>
        </el-card>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
import {putAction, deleteAction, postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
    name: "WarehouseRent",
    mixins: [QueryMixins],
    components: {
    },
    data() {
        return {
            isUsePost: true,
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "salesman",
                    label: "销售",
                },
                {
                    prop: 'doc',
                    label: '客服',
                },
                {
                    prop: 'operator',
                    label: '操作',
                },
                {
                    prop: "customer",
                    label: "客户名称",
                },
                {
                    prop: "orderNo",
                    label: "订单号",
                },
                {
                    prop: "receivables",
                    label: "应收",
                  sortable:"receivables",
                },
                {
                    prop: "settlement",
                    label: "销售结算",
                  sortable:"settlement",
                },
                {
                    prop: "profit",
                    label: "利润",
                  sortable:"profit",
                },
                {
                    prop: 'remark',
                    label: '备注',
                    minWidth: 200
                }
            ],
            url: {
                list: "/order/sale/SalesOrderOperate/getAbnormalOrder",
                batchUpdateOrderNormal: "/order/sale/SalesOrderOperate/batchUpdateOrderNormal",
                exportXlsUrl: "/order/sale/SalesOrderOperate/exportErrorOrder",
                updateHyRatio: "/order/sale/SalesOrderOperate/updateHyRatio",
            },
            containerTypeList: [
                {
                    label: "自拼",
                    value: "0",
                },
                {
                    label: "整柜",
                    value: "1",
                },
                {
                    label: "非拼",
                    value: "2",
                },
            ],
            remarkSelectList: [
              {
                label: "海运费",
                value: "has_hy_fee",
              },
              {
                label: "海运费比值低于70%",
                value: "hy_fee_seventy",
              },
              {
                label: "报关费",
                value: "has_baoguan_fee",
              },
              {
                label: "税费",
                value: "has_tax_fee",
              },
              {
                label: "提货费",
                value: "has_tihuo_fee",
              },
              {
                label: "锁价费",
                value: "has_lock_fee",
              },{
                label: "入仓费",
                value: "has_rucang_fee",
              },{
                label: "派送费",
                value: "has_paisong_fee",
              },{
                label: "保险费",
                value: "has_baoxian_fee",
              },{
                label: "超长费",
                value: "has_over_fee",
              },{
                label: "尾板车费",
                value: "has_weiban_fee",
              },{
                label: "单独清关费",
                value: "has_clearance_fee",
              },
            ],
            dictCodes: ["salesmanId"],
            checked:false
        };
    },
    computed: {
        buttonShowList() {
            return {
                '无问题': this.$btnIsShow('dashboard/HyErrorOrder', '0', '无问题'),
                '导出': this.$btnIsShow('dashboard/HyErrorOrder', '0', '导出'),
                '同步更新海运费比值': this.$btnIsShow('dashboard/HyErrorOrder', '0', '同步更新海运费比值'),
            }
        }
    },
    created() {
      this.queryForm.containerType = "0";
    },
    methods: {
        noProblem() {
            this.$confirm('确定批量操作为无问题订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning",
                closeOnClickModal: false,
                buttonSize: "small",
            })
                .then(() => {
                    this.$store.commit("setFullscreenLoading", true);
                    postAction(this.url.batchUpdateOrderNormal, { orderNoList: this.selectionRows.map((s) => s.orderNo) })
                        .then((res) => {
                            this.$store.commit("setFullscreenLoading", false);
                            this.$message(res.message, "success");
                            this.handleQuery();
                        })
                        .catch((err) => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
                })
                .catch(() => { });
        },
      updateRatio() {
            postAction(this.url.updateHyRatio, {
              orderNoList: this.selectionRows.map((s) => s.orderNo)
            }).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery();
              }
            });
      },
      handleReset() {
        this.queryForm = {
          containerType: "0",
          salesmanIds: [],
        };
        this.handleQuery();
      },

        changeSelect(val) {
            if (val.length === this.dictMap.salesmanId.length) {
                this.checked = true;
            } else {
                this.checked = false;
            }
        },
        selectAll() {
            this.queryForm.salesmanIds = [];
            if (this.checked) {
                for (const key in this.dictMap.salesmanId) {
                    this.queryForm.salesmanIds.push(this.dictMap.salesmanId[key].value);
                }
            } else {
                this.queryForm.salesmanIds = [];
            }
        },
    },

};
</script>
<style lang="scss" scoped>
.company_img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}
.el-checkbox {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
</style>