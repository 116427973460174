<template>
  <div>
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="订单号">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户名">
                    <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客服">
                    <f-select
                      v-model="queryForm.docId"
                      :is-need="queryForm.docId"
                      :data-list="queryDictCodeListMap['sys_user_id']"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="业务员">
                    <f-select
                        :data-list="queryDictCodeListMap['sys_user_id']"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="国家">
                    <f-select
                      :data-list="queryDictCodeListMap['bd_country']"
                      v-model="queryForm.shipmentCountry"
                      :is-need="queryForm.shipmentCountry"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="仓库编码">
                    <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="渠道名称">
                    <f-select
                      :data-list="queryDictCodeListMap['transportStatus']"
                      v-model="queryForm.transportStatus"
                      :is-need="queryForm.transportStatus"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="报关类型">
                    <f-select
                      :data-list="queryDictCodeListMap['os_declare_type']"
                      v-model="queryForm.declareType"
                      :is-need="queryForm.declareType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="派送地址类型" label-width="90px">
                    <f-select
                      :data-list="queryDictCodeListMap['shipment_type']"
                      v-model="queryForm.shipmentType"
                      :is-need="queryForm.shipmentType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="交货方式">
                    <f-select
                      :data-list="queryDictCodeListMap['wh_type']"
                      v-model="queryForm.whType"
                      :is-need="queryForm.whType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预计件/重/体" label-width="90px">
                    <el-input v-model="queryForm.yuji" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户提醒">
                    <el-input v-model="queryForm.froRemark" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户内部单号" label-width="90px">
                    <el-input v-model="queryForm.withinOrderRemark" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="是否购买保险" label-width="90px">
                    <f-select
                      :dict="'unit_whether'"
                      v-model="queryForm.insurance"
                      :is-need="queryForm.insurance"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="是否需要尾板车" label-width="100px">
                    <f-select
                      :dict="'unit_whether'"
                      v-model="queryForm.isWeiban"
                      :is-need="queryForm.isWeiban"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否问题件" label-width="100px">
                        <f-select
                                :dict="'unit_whether'"
                                v-model="queryForm.isWarning"
                                :is-need="queryForm.isWarning"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否入仓" label-width="100px">
                        <f-select
                                :dict="'unit_whether'"
                                v-model="queryForm.isRealCmb"
                                :is-need="queryForm.isRealCmb"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="是否需要搬运" label-width="90px">
                    <f-select
                      :dict="'unit_whether'"
                      v-model="queryForm.isBanyun"
                      :is-need="queryForm.isBanyun"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                  <el-form-item label="下单时间">
                    <div class="f-flex">
                      <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                      >
                      </el-date-picker>
                      <span class="f-p-h-2">~</span>
                      <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                        >查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-10">
        <el-button
            size="medium"
            type="primary"
            :disabled="selectionRows.length === 0"
            @click="$intoDocPageBatch(selectedRowOrderNos)"
            v-if="buttonShowList.pldkdd">批量打开订单
        </el-button>
      </div>
      <div class="c-alert f-m-b-10">
          已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
          <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
        @handleKeyup="handleSearch"
        :tableHeight="showLoadingSearch ? '54vh' : '61vh'"
        :isRecordTableScroll="true"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
                type="text"
                @click="remarkProblem(scope.row)"
                v-if="buttonShowList.bjwtj && scope.row.isWarning !=='1'"
              >标记问题件</el-button>
              <span v-if="scope.row.isWarning==='1'" style="color: red">问题件</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
              <a v-if="scope.row.isWarning==='1'"
                 style="color: red"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
              <a v-else
                 style="color: #00a0e9"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
          </template>

          <template v-else-if="scope.column.property === 'isHaiyun'">
            <span v-if="scope.row.isHaiyun === '2'">空运</span>
            <span v-else>海运</span>
          </template>
          <template v-else-if="scope.column.property === 'shipmentType'">
            <span>{{
              parseDict2(scope.row.shipmentType, "shipment_type")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
              parseDict2(scope.row.transportStatus, "transportStatus")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'declareType'">
            <span>{{ parseDict2(scope.row.declareType, "os_declare_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'insurance'">
            <span>{{ parseDict2(scope.row.insurance, "unit_whether") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'whType'">
            <span>{{ parseDict2(scope.row.whType, "wh_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isWeiban'">
            <span>{{ parseDict2(scope.row.isWeiban, "unit_whether") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isBanyun'">
            <span>{{ parseDict2(scope.row.isBanyun, "unit_whether") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'docId'">
            <span>{{ parseDict2(scope.row.docId, "all_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'salesmanId'">
            <span>{{ parseDict2(scope.row.salesmanId, "all_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <add-problem-dialog
      :visible.sync="proVisible"
      :row="row"
      @ok="handleQuery"
      v-if="proVisible"
    ></add-problem-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import AddProblemDialog from "./modules/AddProblemDialog";
import { getAction } from "@/api/manage";
import { setUpRouteUtil } from '@/utils/util';


export default {
  name: "NoReceiveOsOrder",
  mixins: [QueryMixins],
  components: {
    AddProblemDialog,
  },
  data() {
    return {
      showLoadingSearch: false,
      needQueryDictCodes: [
        "transportStatus",
        "os_declare_type",
        "shipment_type",
        "wh_type",
        "unit_whether",
        'all_user_id',
          "sys_user_id",
          "bd_country"
      ],
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,


      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          minWidth: 70,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 90,
        },
        {
          prop: "isHaiyun",
          label: "订单类型",
          minWidth: 50,
        },
        {
          prop: "customer",
          label: "客户名",
          minWidth: 100,
        },
        {
          prop: "docId",
          label: "客服",
          minWidth: 80,
        },
        {
          prop: "salesmanId",
          label: "业务员",
          minWidth: 80,
        },
        {
          prop: "createTime",
          label: "下单时间",
          minWidth: 75,
        },
        {
          prop: "shipmentCountry",
          label: "国家",
          minWidth: 80,
        },
        {
          prop: "shipmentCode",
          label: "仓库编码",
          minWidth: 50,
        },
        {
          prop: "channelName",
          label: "渠道名称",
          minWidth: 50,
        },
        {
          prop: "declareType",
          label: "报关类型",
          minWidth: 50,
        },
        {
          prop: "shipmentType",
          label: "派送地址类型",
          minWidth: 50,
        },
        {
          prop: "whType",
          label: "交货方式",
          minWidth: 50,
        },
        {
          prop: "yuji",
          label: "预计件/重/体",
          minWidth: 60,
        },
        {
          prop: "froRemark",
          label: "客户提醒",
        },
        {
          prop: "withinOrderRemark",
          label: "客户内部单号",
          minWidth: 60,
        },
        {
          prop: "insurance",
          label: "是否购买保险",
          minWidth: 50,
        },
        {
          prop: "isWeiban",
          label: "是否需要尾板车",
          minWidth: 50,
        },
        {
          prop: "isBanyun",
          label: "是否需要搬运",
          minWidth: 50,
        },
      ],
      proVisible: false,
      url: {
        list: "/interface/ttOrder/getNoBookingPlacedOrderPage",
      },
      issalesman: "0",
      isUsePost:true,
      initData:{
        isFilterCompleteOrder:'1'
      }
    };
  },
  created() {
    this.queryForm.pglx = "1";
    this.queryForm.orderStatus = "1";
  },
  methods: {
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },


    remarkProblem(row) {
      //打开标记问题弹窗
      this.row.orderNo = row.orderNo;
      this.proVisible = true;
    },
    //进入客服页
    intoDocpage(orderNo) {

        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },
  },
  computed : {
    buttonShowList() {
      return {
        'pldkdd':this.$btnIsShow('docpage/NoReceiveOsOrder','0','批量打开订单'),
        'bjwtj':this.$btnIsShow('docpage/NoReceiveOsOrder','1','标记问题件'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
