<!--待派送-->
<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row style="display: flex; flex-wrap: wrap">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="订单号">
              <el-input
                class="textarea_table"
                v-model="queryForm.orderNo"
                type="textarea"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="运单号">
              <el-input
                class="textarea_table"
                v-model="queryForm.runOrderNo"
                type="textarea"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="跟踪单号">
              <el-input
                class="textarea_table"
                v-model="queryForm.logNo"
                type="textarea"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="下单开始时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.createTime"
                type="datetime"
                size="small"
                placeholder="下单开始时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="下单结束时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.createTimeEnd"
                type="datetime"
                size="small"
                placeholder="下单结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户名">
              <el-input
                class="textarea_table"
                v-model="queryForm.cusName"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="业务员">
              <f-select
                v-model="queryForm.salesmanId"
                :dict="'salesmanId'"
                :isNeed="queryForm.salesmanId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客服">
              <f-select
                v-model="queryForm.docIdList"
                :dict="'sys_user_id'"
                :is-need="queryForm.docIdList"
                :multiple="true"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="是否购买保险">
              <f-select
                v-model="queryForm.insurance"
                :dict="'is_default'"
                :isNeed="queryForm.insurance"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="配送方式">
              <f-select
                v-model="queryForm.deliveryType"
                :dict="'wh_type'"
                :isNeed="queryForm.deliveryType"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="服务商">
              <el-select
                v-model="queryForm.supId"
                filterable
                clearable
                @change="getSupplierChannel"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in allSupplierList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="服务商渠道">
              <el-select
                v-model="queryForm.supChannelId"
                filterable
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in supplierChannelList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="报关类型">
              <f-select
                v-model="queryForm.declareType"
                :dict="'kd_declare_type'"
                :isNeed="queryForm.declareType"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="发货开始时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.deliverGoodsTime"
                type="datetime"
                size="small"
                placeholder="下单开始时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="发货结束时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.deliverGoodsTimeEnd"
                type="datetime"
                size="small"
                placeholder="下单结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="录材积开始时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.volumeSureTime"
                type="datetime"
                size="small"
                placeholder="下单开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="录材积结束时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.volumeSureTimeEnd"
                type="datetime"
                size="small"
                placeholder="下单结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="渠道名称">
              <f-select
                :multiple="true"
                v-model="selectChannelList"
                :dict="'kd_channel'"
                :isNeed="selectChannelList"
              ></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="跟踪号是否填写">
              <f-select
                v-model="queryForm.isLogNo"
                :dict="'is_default'"
                :isNeed="queryForm.isLogNo"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="货运类型">
              <f-select
                v-model="queryForm.goodType"
                :dict="'kd_good_type'"
                :isNeed="queryForm.goodType"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="物流状态">
              <f-select
                v-model="queryForm.logisState"
                :dict="'logis_state'"
                :isNeed="queryForm.logisState"
              ></f-select>
            </el-form-item>
          </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="销售组别" label-width="80px">
                    <el-select  :style="{ width: inputWid()}" v-model="queryForm.departId" multiple clearable filterable>
                        <el-option
                                v-for="item in departList"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
                <el-form-item label="国家">
                    <f-select v-model="queryForm.countryList" :multiple="true" :dict="'bd_country'" :isNeed="queryForm.countryList"></f-select>
                </el-form-item>
            </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearchM"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-dialog
        :title="'上传POD'"
        :visible.sync="dialogFormVisible"
        center
        width="30%"
        :close-on-click-modal="false"
      >
        <el-form :model="form" ref="form" size="medium">
          <el-form-item prop="fileList" label="附件上传">
            <f-upload v-model="form.fileList" :dir="'kdOrder'"></f-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveFile">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog
        :title="'跟踪单号'"
        :visible.sync="dialogFormNoVisible"
        center
        width="30%"
        :close-on-click-modal="false"
      >
        <el-form :model="form" ref="form" size="medium" :rules="rules">
          <el-form-item prop="logNo" label="跟踪单号">
            <el-input v-model="form.logNo"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormNoVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveLogNo">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        :title="'设置出货重'"
        :visible.sync="dialogFormChuHuoVisible"
        center
        width="30%"
        :close-on-click-modal="false"
      >
        <el-form :model="form" ref="form" size="medium" :rules="rules">
          <el-form-item prop="shipmentWeight" label="出货重">
            <el-input v-model="form.shipmentWeight" type="number"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormChuHuoVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveChuHuo">确 定</el-button>
        </div>
      </el-dialog>
    </el-form>
    <el-card class="box-card last_card">
      <div class="flex_wrap_between">
        <el-button
          type="primary"
          size="medium"
          @click="piliangpaisong"
          :disabled="selectionRows.length === 0"
          >批量派送
        </el-button>
        <div>
           <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('9', '已到达起运机场')"
            :disabled="disabledButton()"
            v-if="buttonShowList.a"
            >已到达起运机场
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('6', '国内报关放行')"
            :disabled="disabledButton('1')"
            v-if="buttonShowList.b"
            >国内报关放行
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('1', '航班预计起飞')"
            :disabled="disabledButton()"
            v-if="buttonShowList.c"
            >航班预计起飞
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('2', '航班已起飞')"
            :disabled="disabledButton()"
            v-if="buttonShowList.d"
            >航班已起飞
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('3', '航班已落地')"
            :disabled="disabledButton()"
            v-if="buttonShowList.e"
            >航班已落地
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('4', '已清关放行')"
            :disabled="disabledButton()"
            v-if="buttonShowList.f"
            >已清关放行
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('10', '目的机场已提货')"
            v-if="buttonShowList.g"
            :disabled="disabledButton()"
            >目的机场已提货
          </el-button>
          <el-button
            type="danger"
            size="medium"
            @click="handleBatchLogisState('5', '已交付快递')"
            :disabled="disabledButton('1')"
            v-if="buttonShowList.h"
            >已交付快递
          </el-button>
        </div>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleDelivery(scope.row)"
              v-if="buttonShowList.ps"
              >已派送
            </el-button>
            <el-button
              type="text"
              @click="handlePod(scope.row)"
              v-if="buttonShowList.pod"
              >上传POD
            </el-button>
            <el-button
              type="text"
              @click="handleLogNo(scope.row)"
              v-if="buttonShowList.dd"
              >跟踪单号
            </el-button>
            <el-button
              type="text"
              @click="handleBack(scope.row)"
              v-if="
                buttonShowList.th &&
                scope.row.allowRetrun === '1'
              "
              >退回
            </el-button>

            <el-button
              type="text"
              @click="chuHuo(scope.row)"
              v-if="buttonShowList.sz"
              >设置出货重
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'insurance'">
            <span
              v-if="scope.row.isInsured == '0' && scope.row.insurance == '1'"
              style="color: #dd0000"
            >
              {{ parseDict(scope.row.insurance, "is_default") }} / 未买</span
            >
            <span v-else-if="scope.row.isInsured == '0'">
              {{ parseDict(scope.row.insurance, "is_default") }} / 未买</span
            >
            <span v-else
              >{{ parseDict(scope.row.insurance, "is_default") }} / 已买</span
            >
          </template>
          <template v-else-if="scope.column.property === 'declareType'">
            <span
              style="color: red"
              >{{ parseDict(scope.row.declareType, "kd_declare_type") }}</span
            >
          </template>
          <!--                    <template v-else-if="scope.column.property === 'channel'">-->
          <!--                        <span>{{parseDict(scope.row.channel,'kd_channel')}}</span>-->
          <!--                    </template>-->
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="handleEdit(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'cusName'">
            <a
              type="text"
              @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
              style="color: #57a3f3"
              >{{ scope.row.cusName }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'supId'">
            <span>{{ parseDict(scope.row.supId, "supplier_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'supChannelId'">
            <span>{{
              parseDict(scope.row.supChannelId, "supplier_channel_id")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'receiveCountry'">
            <span v-if="scope.row.receiveFbaCode"
              >{{ scope.row.receiveCountry }} -
              {{ scope.row.receiveFbaCode }}</span
            >
            <span v-else>{{ scope.row.receiveCountry }}</span>
          </template>
          <template v-else-if="scope.column.property === 'goodType'">
            <span>{{ parseDict(scope.row.goodType, "kd_good_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'logisState'">
            <span>{{
              parseDict(String(scope.row.logisState), "logis_state")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderStatus'">
            <span>{{
                parseDict(String(scope.row.orderStatus), "kd_order_status")
              }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-p-v-8 bottom_flex">
        <el-col :span="12" class="total_price">
          <span>{{ "总票数：" + (countList.total || "0") }}</span>
          <span>{{ "总件数：" + (countList.totalCtn || "0") }}</span>
          <span>{{
            "总计费重：" + (countList.totalUpdateFeeWeight || "0")
          }}</span>
          <span>{{ "总实重：" + (countList.totalActualWeight || "0") }}</span>
        </el-col>
        <el-col class="f-text-right" :span="12">
          <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogExpected"
      center
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleBatchLogisClose"
    >
      <el-form
        :model="formExpected"
        ref="formExpected"
        size="medium"
        :rules="rulesExpected"
      >
        <el-form-item prop="logisEta" :label="`${dialogTitle}时间`">
          <el-date-picker
            class="f-flex-1"
            v-model="formExpected.logisEta"
            type="datetime"
            size="small"
            :placeholder="`${dialogTitle}时间`"
            default-time="23:59:59"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="handleBatchLogisClose()"
          >取 消</el-button>
        <el-button type="primary" @click="submitState">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "../../mixins/QueryMixins";
import { getAction, getAllSupplier, putAction } from "@/api/manage";
import {
  yingwenshuziPartten,
  zhengshuxiaoshuPartten,
} from "../../utils/pattern";
import { setUpRouteUtil } from "@/utils/util";
import { postAction } from "../../api/manage";

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      dialogExpected: false,
      isUsePost: true,
      dictCodes: [
        "kd_declare_type",
        "is_default",
        "supplier_id",
        "supplier_channel_id",
        "kd_good_type",
        "logis_state",
          "kd_order_status"
      ],
      departList:[],
      ids: [],
      row: {},
      form: { fileList: [] },
      formLabelWidth: "100px",
      //控制弹框显示
      dialogFormVisible: false,
      dialogFormNoVisible: false,
      dialogFormChuHuoVisible: false,
      rules: {
        logNo: [
          { max: 255, message: "长度不能超过255", trigger: "change" },
          {
            pattern: yingwenshuziPartten,
            message: "请输入正确的单号",
            trigger: "change",
          },
        ],
        shipmentWeight: [
          {
            pattern: zhengshuxiaoshuPartten,
            message: "请输入正整数及最多三位小数",
            trigger: "change",
          },
        ],
      },
      rulesExpected: {
        logisEta: [
          {
            required: true,
            message: "请选择时间",
            trigger: "blur",
          },
        ],
      },
      formExpected: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "runOrderNo",
          label: "运单号",
        },
        {
          prop: "logisState",
          label: "物流状态",
        },
        {
          prop: "orderStatus",
          label: "货物状态",
        },
        {
          prop: "cusName",
          label: "客户名",
        },
        {
          prop: "insurance",
          label: "是否购买保险",
          width: 50,
        },
        {
          prop: "logNo",
          label: "跟踪单号",
        },
          {
                        prop: 'orderGoodsName',
                        label: '货物品名',
                    },
        {
          prop: "receiveCountry",
          label: "目的地",
          width: 80,
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "declareType",
          label: "报关类型",
        },
        {
          prop: "actualCtn",
          label: "实际件数",
          sortable: "custom",
          width: 50,
        },
        {
          prop: "updateFeeWeight",
          label: "计费重(KG)",
          sortable: "custom",
          width: 50,
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "supId",
          label: "服务商",
        },
        {
          prop: "supChannelId",
          label: "服务商渠道",
        },
        {
          prop: "volumeSureTime",
          label: "材积录入时间",
          sortable: "custom",
        },
        {
          prop: "deliverGoodsTime",
          label: "发货时间",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "下单时间",
          sortable: "custom",
        },
        {
          prop: "goodType",
          label: "货运类型",
        },
      ],
      url: {
        list: "/order/kdOrder/list",
        deliveryed: "/order/kdOrder/deliveryed",
        batchdeliveryed: "/order/kdOrder/batchdeliveryed",
        deliveryedCallBack: "/order/kdOrder/deliveryedCallBack",
        add: "/order/KdOrderFile/add", //上传POD
        toGoodWeight: "/order/kdOrder/toGoodWeight",
        editLogNo: "/order/kdOrder/editLogNo", //上传跟踪单号
        getList: "/sys/oss/file/getList",
        queryBysupId: "/sys/supplierChannel/queryBysupId", // 根据供应商ID 获取供应商渠道
        listCount: "/order/kdOrder/listCount", //获取total-总票数,totalCtn-总件数,totalUpdateFeeWeight-总计费重
        batchLogisState: "/order/kdOrder/batchLogisState",
        batchUpdateOrderStatus:'/order/kdOrder/batchUpdateOrderStatus',
        getDepartList:'/sys/sysDepart/querySalesGroupDepartList',
      },
      allSupplierList: [], //快递供应商
      supplierChannelList: [], //快递供应商的渠道
      selectChannelList: [], //筛选渠道列表
      countList: {}, //统计总值
      logisState: "",
      dialogTitle: "",
    };
  },
  methods: {
    /**
     *
     * @param {*} type 按钮的类型  0 - 非已交付快递按钮（勾选的列表货运类型不一致按钮禁用） 1- 已交付快递按钮（勾选的列表货运类型是快递禁用非已交付快递按钮）
     */
    disabledButton(type='0') {
      let goodType = this.selectionRows && this.selectionRows.length && this.selectionRows[0].goodType;
      let disabled = this.selectionRows.length === 0;
      try {
        this.selectionRows && this.selectionRows.forEach((item, index) => {
            if(type == '0') {
              if (item.goodType == '1') {
                disabled = true;
                throw Error("有快递跳出循环");
              }
              if (item.goodType !== goodType) {
                disabled = true;
                throw Error("货运类型不一致跳出循环");
              }
            } else if(type == '1') {
              if (item.goodType !== goodType) {
                disabled = true;
                throw Error("货运类型不一致跳出循环");
              }
            }
          });
      } catch (e) {
        console.log(e);
      }
      return disabled;
    },
    initMoreFuc() {
      this.getListCount();
    },
    getListCount() {
      postAction(this.url.listCount, this.queryForm).then((res) => {
        if (res.result) {
          this.countList = res.result;
        } else {
          this.countList = {
            total: 0,
            totalCtn: 0,
            totalUpdateFeeWeight: 0,
            totalActualWeight: 0,
          };
        }
      });
    },
    handleSearchM() {
      this.queryForm.channelIds = this.selectChannelList;
      this.handleSearch();
    },
      //初始化组别列表
      getDepartList(){
          getAction(this.url.getDepartList).then(res=>{
              this.departList = res.result
          })
      },
    initCusPage({ cusId }) {
      if (cusId) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, "/customer/Edit");
        this.$router.push(`/customer/Edit?id=${cusId}`);
      }
    },
    //进入编辑页面
    handleEdit(val) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, "/kdorder/Edit");
      this.$router.push("/kdorder/Edit?orderNo=" + val);
    },
    handleReset() {
      this.queryForm = {};
      this.selectChannelList = [];
      this.queryForm.orderStatus = "3";
      this.ipagination.currentPage = 1;
      this.getSupplierChannel();
      this.handleQuery();
    },
    //已派送
    handleDelivery(row) {
      this.$confirm(`确定已派送${row.orderNo}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getAction(this.url.deliveryed + "?orderNo=" + row.orderNo).then(
            (res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery();
              }
            }
          );
        })
        .catch(() => {});
    },
    //批量派送
    piliangpaisong() {
      this.$confirm(`确定批量派送?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getAction(this.url.batchdeliveryed, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
            }
          });
        })
        .catch(() => {});
    },
    //退回
    handleBack(row) {
      this.$confirm(`确定退回${row.orderNo}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getAction(
            this.url.deliveryedCallBack + "?orderNo=" + row.orderNo
          ).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
            }
          });
        })
        .catch(() => {});
    },
    //上传POD
    handlePod(row) {
      this.form.id = row.id;
      if (row.podOssId) {
        //回显文件
        getAction(this.url.getList + "?ids=" + row.podOssId).then((res) => {
          if (res.success) {
            console.log("res.success", res.success);
            this.form.fileList = res.result;
          }
        });
      } else {
        this.form.fileList = [];
      }
      this.dialogFormVisible = true;
    },
    //保存文件
    saveFile() {
      /*              this.form.podOssId = this.form.fileList[0].id;   ////*/
      let attchlist = [];
      for (var i = 0; i < this.form.fileList.length; i++) {
        attchlist.push(this.form.fileList[i].id);
      }
      this.form.podOssId = attchlist.join(",");
      putAction(this.url.editLogNo, this.form).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
          this.dialogFormVisible = false;
        }
      });
    },
    //跟踪单号
    handleLogNo(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormNoVisible = true;
    },
    //保存跟踪单号
    saveLogNo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          putAction(this.url.editLogNo, this.form).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
              this.dialogFormNoVisible = false;
            }
          });
        }
      });
    },
    //获取所有的 快递供应商
    initAllSupplier() {
      this.allSupplierList = [];
      getAllSupplier("2")
        .then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.length; i++) {
              var obj = {};
              obj.value = res.result[i].id; //供应商ID
              obj.label = res.result[i].chinaseName; //供应商中文名
              this.allSupplierList.push(obj);
              console.log("allSupplierList", this.allSupplierList);
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取供应商的渠道
    getSupplierChannel(code) {
      this.supplierChannelList = [];
      let url = code
        ? this.url.queryBysupId + "?supId=" + code
        : this.url.queryBysupId + "?supId=";
      getAction(url).then((res) => {
        if (res.success) {
          for (var i = 0; i < res.result.length; i++) {
            var obj = {};
            obj.value = res.result[i].id; //供应商ID
            obj.label = res.result[i].channelName; //供应商中文名
            this.supplierChannelList.push(obj);
          }
        }
      });
    },
    //设置出货重
    chuHuo(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormChuHuoVisible = true;
    },
    saveChuHuo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          putAction(this.url.toGoodWeight, this.form).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
              this.dialogFormChuHuoVisible = false;
            }
          });
        }
      });
    },
    handleBatchLogisState(logisState, title) {
      this.logisState = logisState;
      this.dialogTitle = title;
      this.dialogExpected = true;
    },
    handleBatchLogisClose() {
      this.formExpected = {};
      this.$refs.formExpected && this.$refs.formExpected.resetFields()
      this.dialogExpected = false;
    },
    submitState() {
      this.$refs.formExpected.validate((valid) => {
        if (valid) {
          let params = {
            ids: this.selectedRowKeys.join(","),
            goodType: this.selectionRows && this.selectionRows.length && this.selectionRows[0].goodType,
            logisState: this.logisState,
            logisEtaDate: this.formExpected.logisEta,
          }
          let url = this.url.batchLogisState
          if (this.logisState == '9' || this.logisState == '10') {
          url = this.url.batchUpdateOrderStatus
          params = {
            logisState: this.logisState,
            orderNo : this.selectionRows.map(item=>item.orderNo).join(),
          }
          this.logisState == '9'? params.arrivedAirportTime = this.formExpected.logisEta : params.pickedUpTime = this.formExpected.logisEta
          }
          postAction(url, params).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
              this.handleBatchLogisClose();
            }
          });
        }
      });
    },
  },
  created() {
    this.queryForm.orderStatus = "3";
    this.initAllSupplier();
    this.getSupplierChannel();
      //初始化组别列表
      this.getDepartList();
    if(this.buttonShowList['ckfws']) {
     this.columns=[
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "runOrderNo",
          label: "运单号",
        },
        {
          prop: "logisState",
          label: "物流状态",
        },
        {
          prop: "orderStatus",
          label: "货物状态",
        },
        {
          prop: "cusName",
          label: "客户名",
        },
        {
          prop: "insurance",
          label: "是否购买保险",
          width: 50,
        },
        {
          prop: "logNo",
          label: "跟踪单号",
        },
          {
                        prop: 'orderGoodsName',
                        label: '货物品名',
                    },
        {
          prop: "receiveCountry",
          label: "目的地",
          width: 80,
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "declareType",
          label: "报关类型",
        },
        {
          prop: "actualCtn",
          label: "实际件数",
          sortable: "custom",
          width: 50,
        },
        {
          prop: "updateFeeWeight",
          label: "计费重(KG)",
          sortable: "custom",
          width: 50,
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "supId",
          label: "服务商",
        },
        {
          prop: "supChannelId",
          label: "服务商渠道",
        },
        {
          prop: "volumeSureTime",
          label: "材积录入时间",
          sortable: "custom",
        },
        {
          prop: "deliverGoodsTime",
          label: "发货时间",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "下单时间",
          sortable: "custom",
        },
        {
          prop: "goodType",
          label: "货运类型",
        },
      ]
    }else {
      this.columns=[
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "runOrderNo",
          label: "运单号",
        },
        {
          prop: "logisState",
          label: "物流状态",
        },
        {
          prop: "orderStatus",
          label: "货物状态",
        },
        {
          prop: "cusName",
          label: "客户名",
        },
        {
          prop: "insurance",
          label: "是否购买保险",
          width: 50,
        },
        {
          prop: "logNo",
          label: "跟踪单号",
        },
          {
                        prop: 'orderGoodsName',
                        label: '货物品名',
                    },
        {
          prop: "receiveCountry",
          label: "目的地",
          width: 80,
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "declareType",
          label: "报关类型",
        },
        {
          prop: "actualCtn",
          label: "实际件数",
          sortable: "custom",
          width: 50,
        },
        {
          prop: "updateFeeWeight",
          label: "计费重(KG)",
          sortable: "custom",
          width: 50,
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        // {
        //   prop: "supId",
        //   label: "服务商",
        // },
        {
          prop: "supChannelId",
          label: "服务商渠道",
        },
        {
          prop: "volumeSureTime",
          label: "材积录入时间",
          sortable: "custom",
        },
        {
          prop: "deliverGoodsTime",
          label: "发货时间",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "下单时间",
          sortable: "custom",
        },
        {
          prop: "goodType",
          label: "货运类型",
        },
      ]
    }
  },
  computed:{
      inputWid() {
          return function () {
              let strLength = '220px'
              if (this.queryForm.departId && this.queryForm.departId.length > 0){
                  this.departList.forEach(item=>{
                      if(item.value ==  this.queryForm.departId[0]){
                          strLength = (String(item.text).length * 21 + 90) + 'px'
                      }
                  })
              }
              return strLength
          }
      },
    buttonShowList(){
      return {
        'a':this.$btnIsShow('kdorder/WaitDelivery', '1', '已到达起运机场'),
        'b':this.$btnIsShow('kdorder/WaitDelivery', '1', '国内报关放行'),
        'c':this.$btnIsShow('kdorder/WaitDelivery', '1', '航班预计起飞'),
        'd':this.$btnIsShow('kdorder/WaitDelivery', '1', '航班已起飞'),
        'e':this.$btnIsShow('kdorder/WaitDelivery', '1', '航班已落地'),
        'f':this.$btnIsShow('kdorder/WaitDelivery', '1', '已清关放行'),
        'g':this.$btnIsShow('kdorder/WaitDelivery', '1', '目的机场已提货'),
        'h':this.$btnIsShow('kdorder/WaitDelivery', '1', '已交付快递'),
        'ps':this.$btnIsShow('kdorder/WaitDelivery', '1', '已派送'),
        'pod':this.$btnIsShow('kdorder/WaitDelivery', '1', '上传POD'),
        'dd':this.$btnIsShow('kdorder/WaitDelivery', '1', '跟踪单号'),
        'th':this.$btnIsShow('kdorder/WaitDelivery', '1', '退回'),
        'sz':this.$btnIsShow('kdorder/WaitDelivery', '1', '设置出货重'),
        'ckfws':this.$btnIsShow('kdorder/WaitDelivery','1','查看服务商'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
