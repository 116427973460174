
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="周数" :label-width="queryFormLabelWidth">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="专线Or拆柜" :label-width="queryFormLabelWidth">
                            <!--待提交 已拒绝-->
                            <f-select
                                    v-model="queryForm.cabinetCategory"
                                    :is-need="queryForm.cabinetCategory"
                                    :dict="'cabinet_category'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="柜型" :label-width="queryFormLabelWidth">
                            <f-select
                                    v-model="queryForm.size"
                                    :isNeed="queryForm.size"
                                    :dict="'cabinet_type'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="柜子状态" :label-width="queryFormLabelWidth">
                            <f-select
                                    v-model="queryForm.circulationState"
                                    :isNeed="queryForm.circulationState"
                                    :dict="'os_container_status'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="是否自拼柜" :label-width="queryFormLabelWidth">
                            <el-select filterable clearable v-model="queryForm.weekStatus">
                                <el-option value="0" label="自拼柜"></el-option>
                                <el-option value="1" label="整柜/非自拼"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="订舱号" :label-width="queryFormLabelWidth">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="柜号" :label-width="queryFormLabelWidth">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                                      type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="文件操作" prop="docOperateId" :label-width="queryFormLabelWidth">
                            <f-select
                                    :isNeed="queryForm.docOperateId"
                                    v-model="queryForm.docOperateId"
                                    :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="派送操作" prop="endOperateId" :label-width="queryFormLabelWidth">
                            <f-select
                                    :isNeed="queryForm.endOperateId"
                                    v-model="queryForm.endOperateId"
                                    :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="排柜操作" prop="operateId" :label-width="queryFormLabelWidth">
                            <f-select
                                    :isNeed="queryForm.operateId"
                                    v-model="queryForm.operateId"
                                    :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="起运港" :label-width="queryFormLabelWidth" >
                            <f-select :is-need="queryForm.pol"
                                      v-model="queryForm.pol"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="目的港" :label-width="queryFormLabelWidth">
                            <f-select v-model="queryForm.podEnd"
                                      :is-need="queryForm.podEnd"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="装柜时间" :label-width="queryFormLabelWidth">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.loadTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.loadTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item
                                label="进口商"
                                :label-width="formLabelWidth" >

                            <el-select
                                    v-model="queryForm.importerEinId"
                                    filterable
                                    clearable
                                    @click.native="initImporter"
                            >
                                <el-option
                                        v-for="(item, index) in importerList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="ETD" :label-width="queryFormLabelWidth">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etdTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etdTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="船公司" :label-width="queryFormLabelWidth">
                      <f-select v-model="queryForm.shipCompanyId"
                                :is-need="queryForm.shipCompanyId"
                                placeholder="请选择" filterable clearable :dict="'ship_company'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="海运订舱代理" :label-width="queryFormLabelWidth">
                      <f-select v-model="queryForm.oceanBookAgentId"
                                :is-need="queryForm.oceanBookAgentId"
                                placeholder="请选择" filterable clearable :dict="'supplier_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="清关代理" prop="clearAgentId" label-width="80px"><!--待提交 已拒绝-->
                            <f-select
                                    :isNeed="queryForm.clearAgentId"
                                    v-model="queryForm.clearAgentId"
                                    :dataList="addCabinetFormTwo.clearAgentIdList"
                                    @click.native="fillAgentIdList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="small" @click="handleExportXls('所有柜子列表')"
                           v-if="buttonShowList.dc"
                >导出</el-button>
                <el-button type="primary" size="small"
                           v-if="buttonShowList.dc"
                           @click="exportExcelPlus(url.exportDeclareAgent,'报关行')">导出报关行</el-button>
                <el-button type="primary" size="small"
                           v-if="buttonShowList.dc"
                           @click="exportExcelPlus(url.exportCleanAgent,'清关查验')">导出清关查验</el-button>
                <el-button type="primary" size="small"
                           v-if="buttonShowList.dc"
                           @click="exportExcelPlus(url.exportCleanAgentCost,'清关代理费用')">导出清关代理费用</el-button>

                <el-button type="primary" size="small" @click="insertPreFee" :disabled="selectedRowKeys.length===0"
                           v-if="buttonShowList.lrygfy"
                >录入预估费用</el-button>

                <el-button type="primary" size="medium" icon="el-icon-plus"
                           v-if="buttonShowList.dccy"
                           @click="exportExcelPlus(url.exportInspection,'查验报表')">导出查验</el-button>
                              <el-button type="primary" size="medium"  v-if="buttonShowList.派送文件2"  :disabled="selectedRowKeys.length===0"
                           @click="exportPrejudgment">派送文件2</el-button>
                           <el-button
                    size="medium"
                    type="primary"
                    @click="exportExcelPlus('/file/export/excel/container/exportEuropeContainer','欧洲ICS2申报舱单')"
                    v-if="buttonShowList.导出欧洲ICS2申报舱单"
                >导出欧洲ICS2申报舱单</el-button>
                <el-button
                    size="medium"
                    type="primary"
                    @click="exportExcelPlus('/operation/container/exportAllContainerList','商务导出')"
                    v-if="buttonShowList['商务导出']"
                >商务导出</el-button>
                <el-button
                    size="medium"
                    type="primary"
                    @click="exportExcelPlus('/operation/container/exportUSSensitiveGoodsTaxList','美线自拼柜敏感货税金')"
                    v-if="buttonShowList['美线自拼柜敏感货税金']"
                >美线自拼柜敏感货税金</el-button>

            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                       <el-button type="text" size="small" @click="caculateContainerFee(scope.row)"
                                  v-if="buttonShowList.klr"
                       >查看利润</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'pol'">
                        <span>{{parseDict(scope.row.pol, "bd_port")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd, "bd_port")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompanyId'">
                        <span>{{ parseDict(scope.row.shipCompanyId, "ship_company") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'clearAgentId'">
                        <span>{{ parseDict(scope.row.clearAgentId, "clearAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'dismantleAgentId'">
                        <span>{{ parseDict(scope.row.dismantleAgentId, "dismantleAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryAgentId'">
                        <span>{{ parseDict(scope.row.deliveryAgentId, "deliveryAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                        <span>排柜操作：{{ scope.row.operator}}</span> <br>
                        <span>文件操作：{{ scope.row.doc }}</span> <br>
                        <span>派送操作：{{ scope.row.endOperator }}</span> <br>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.id)">{{scope.row.week }}</a>
                        <span v-if="scope.row.preCount && Number(scope.row.preCount) >0" style="color: red">(有预估费)</span>
                    </template>
                    <template v-else-if="scope.column.property === 'realCmb'">
                        <span>{{scope.row.realCtn}}/{{scope.row.realKg}}/{{scope.row.realCmb}}</span>
                    </template>

                    <template v-else-if="scope.column.property === 'weekStatus'">
                        <span v-if="scope.row.weekStatus==='0'">自拼柜</span>
                        <span v-if="scope.row.weekStatus==='1'">非拼/整柜</span>
                        <span v-if="scope.row.weekStatus==='2'">传统物流</span>
                    </template>
                    <template v-else-if="scope.column.property === 'profit'">

                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
            <el-dialog title="统计数据显示" :visible.sync="dialogKdCountVisible" :close-on-click-modal="false"
                       v-if="dialogKdCountVisible">
                <div> 柜子： 周数：{{week}} , 柜号：{{containerNo}}
                    <p>
                        应收（CNY）：{{income}}
                    </p>
                    <p>
                        柜子应付（CNY）：{{conCost}}
                    </p>
                    <p>
                        订单实际应付（CNY）：{{orderCost}};
                    </p>
                    <p>
                        订单预计应付（CNY）：{{preOrderCost}};
                    </p>
                    <p>
                        利润（CNY）:{{profit}};
                    </p>
                    <p>
                        预估利润（CNY）：{{preProfit}}
                    </p>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                importerList:[],
                queryFormLabelWidth:'80px',
                dialogKdCountVisible:false,
                income:0,
                conCost:0,
                orderCost:0,
                preOrderCost:0,
                profit:0,
                preProfit:0,
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.queryForm.createTimeEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.queryForm.createTimeEnd);
                        }
                    }
                },
                rules: {
                },
                ids: [],
                row: {},
                form: {},
                EditCabinetForm: {},
                updateAlertCabinrt: {},
                dictCodes: ["bd_port", "ship_company","clearAgent_id", "dismantleAgent_id", "deliveryAgent_id",'all_user_id',],
                formLabelWidth: "120px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                      prop: "containerStatus",
                      label: "物流状态",
                      width: 80,
                    },
                    {
                        prop: "weekStatus",
                        label: "是否自拼",
                        width: 80,
                    },
                    // {
                    //     prop: "profit",
                    //     label: "柜子利润"
                    // },
                    {
                        prop: "week",
                        label: "周数",
                        width: 140,
                    },
                    {
                        prop: "pol",
                        label: "起运港",
                        width: 80,
                    },
                    {
                        prop: "podEnd",
                        label: "目的港",
                        width: 80,
                    },
                    {
                        prop: "shipCompanyId",
                        label: "船司",
                        minWidth: 120,
                    },
                  {
                    prop: "oceanBookAgent",
                    label: "海运订舱代理",

                  },
                    {
                        prop: "shipNo",
                        label: "船名航次",
                        minWidth: 80,
                    },
                    {
                        prop: "bookNo",
                        label: "so",
                        width: 80,
                    },
                    {
                        prop: "si",
                        label: "截SI",
                        width: 120,
                    },
                    {
                        prop: "etd",
                        label: "ETD",
                        width: 120,
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                        width: 120,
                    },
                    {
                        prop: "loadTime",
                        label: "装柜时间",
                    },
                    {
                        prop: "size",
                        label: "柜型",
                        width: 60,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        width: 80,
                    },
                    {
                        prop: "originalNo",
                        label: "封号",
                        width: 80,
                    },
                    {
                        prop: "realCmb",
                        label: "实际件重体",
                        minWidth: 100,
                    },
                    {
                        prop: "weight",
                        label: "过磅重",
                        minWidth: 80,
                    },
                    {
                        prop: "emptyContainerKg",
                        label: "空柜重",
                        minWidth: 80,
                    },
                    {
                        prop: "vgm",
                        label: "VGM",
                        minWidth: 80,
                    },
                    {
                        prop: "operateId",
                        label: "操作",
                        minWidth: 120,
                    },
                    {
                        prop: "clearAgentId",
                        label: "清关代理",
                        width: 140,
                    },
                    {
                        prop: "dismantleAgentId",
                        label: "拆柜代理",
                        width: 140,
                    },
                    {
                        prop: "deliveryAgentId",
                        label: "送货代理",
                        width: 140,
                    },

                ],
                url: {
                    list: "/operation/container/getAllContainerlist",
                    //exportXlsUrl: '/operation/container/exportAllContainerXls',  //导出
                    // exportXlsUrl: '/file/export/excel/downloadAllCupBoardExcel',  //导出
                    exportXlsUrl: '/operation/container/exportAllContainerXls',  //导出
                    //录入预估的费用
                    luruPreFee:'/operation/container/insertPreFeeWithIdList',
                    //计算柜子的费用
                    caculateCabinetFee:'/operation/container/caculateCabinetFee',

                    //导出查验
                    exportInspection:"/file/export/excel/exportInspection",    //导出已查验
                    getSysImporterList: "/sys/importer/getAll", //获取系统进口商
                    exportDeliveryDocTwo:"/file/export/excel/container/exportDeliveryDocTwo",
                    exportDeclareAgent:"/operation/container/exportDeclareAgent",    //导出报关行
                    exportCleanAgent:"/operation/container/exportCleanAgent",    //导出报关行
                    exportCleanAgentCost:"/operation/container/exportCleanAgentCost",    //导出报关行



                },
                addCabinetFormTwo: {},
                week:'',
                containerNo:'',
                //post查询
              isUsePost:true
            }

        },
        props: {
    exportBthShow:{
      type: Boolean,
      default: false
    }
  },
        methods: {
            initImporter() {
                this.importerList=[];
                getAction(this.url.getSysImporterList).then((res) => {
                    if (res.success) {
                        let list = res.result;
                        for (var i = 0; i < list.length; i++) {
                            let value = list[i].id;
                            let label = list[i].name
                            this.importerList.push({value, label});
                        }
                    } else {
                        this.$message.warning("获取大森林进口商列表失败");
                    }
                })
            },
            //计算预估的费用
            caculateContainerFee(row){
                getAction(this.url.caculateCabinetFee+"?id="+row.id)
                .then(res=>{
                    this.income = res.result.income;
                    this.conCost = res.result.conCost;
                    this.orderCost = res.result.orderCost;
                    this.preOrderCost = res.result.preOrderCost;
                    this.profit=Number(this.income)-Number(this.conCost)-Number(this.orderCost);
                    this.preProfit=Number(this.income)-Number(this.conCost)-Number(this.preOrderCost);
                    this.containerNo=row.containerNo;
                    this.week=row.week;
                    this.dialogKdCountVisible = true;
                })
            },
            insertPreFee(){
                postAction(this.url.luruPreFee,this.selectedRowKeys).then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                })
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            exportPrejudgment () {
                if(this.selectedRowKeys.length>100) {
                    this.$message.warning('最多支持 100 个柜子一起导出,请减少勾选项');
                    return
                }
                let fileName = '';
                if(this.selectionRows.length==1&&this.selectionRows[0].containerNo) {
                    fileName = '派送文件-'+this.selectionRows[0].containerNo
                }else{
                    fileName = '派送文件'
                }
this.downloadExcelPost(this.url.exportDeliveryDocTwo,this.selectedRowKeys.length>1?'派送文件-合并':fileName,{ids:this.selectedRowKeys})
            },
            fillAgentIdList() {
                getAction("/operation/container/getClearAgentList" )
                    .then(res => {
                        if (res.result) {
                            this.$set(this.addCabinetFormTwo, 'clearAgentIdList', res.result.clearAgentIdList);
                        }
                    })
            },
        },
        created() {
        },
      computed:{
        buttonShowList(){
          return {
            'dc':this.$btnIsShow('operation/cabinet/AllContainer','1','导出'),
            'lrygfy':this.$btnIsShow('operation/cabinet/AllContainer','0','录入预估费用'),
            'dccy':this.$btnIsShow('operation/cabinet/AllContainer','1','导出查验'),
            'klr':this.$btnIsShow('operation/cabinet/AllContainer','1','查看利润'),
             '派送文件2':this.$btnIsShow('operation/cabinet/AllContainer','0','派送文件2'),
             '导出欧洲ICS2申报舱单':this.$btnIsShow('operation/cabinet/AllContainer','0','导出欧洲ICS2申报舱单'),
             '商务导出':this.$btnIsShow('operation/cabinet/AllContainer','0','商务导出'),
            '美线自拼柜敏感货税金':this.$btnIsShow('operation/cabinet/AllContainer','0','美线自拼柜敏感货税金'),


          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
