<template>
    <div class="main-full-content">
        <el-form class="input_top" label-width="170px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="角色名称">
                            <el-input v-model="queryForm.roleName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="是否可以查看所有业务员订单	">
                      <f-select v-model="queryForm.isCanSeeAll"
                                :is-need="queryForm.isCanSeeAll"
                                dict="unit_whether"
                                @keyup.enter.native="handleSearch">

                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleEdit({})"
                        :disabled="$btnIsDisabled('isystem/RoleManage','0','新增')"
                        v-if="buttonShowList.add"
                >新增
                </el-button
                >
              <el-button
                  @click="updateCanSeeAll('1')"
                  type="primary"
                  size="medium"
                  v-if="buttonShowList.canSee"
                  :disabled="selectedRowKeys.length === 0"
              >关联所有
              </el-button>
              <el-button
                  @click="updateCanSeeAll('0')"
                  type="primary"
                  size="medium"
                  v-if="buttonShowList.canSee"
                  :disabled="selectedRowKeys.length === 0"
              >取消关联所有
              </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleAuth(scope.row.id)"
                                   v-if="buttonShowList.sq"
                        >授权
                        </el-button
                        >
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button
                        >
                        <el-button type="text" @click="handleDel(scope.row.id)"
                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button
                        >
<!--                        <el-button type="text" @click="handleBtnShow(scope.row)"-->
<!--                                   v-if="$btnIsShow('isystem/RoleManage','1','按钮权限')"-->
<!--                        >按钮权限-->
<!--                        </el-button-->
<!--                        >-->
<!--                        <el-button type="text" @click="addRelation(scope.row.id,'1')"-->
<!--                                   v-if="$btnIsShow('isystem/RoleManage','1','正向关联')"-->
<!--                        >正向关联-->
<!--                        </el-button>-->
<!--                        <el-button type="text" @click="addRelation(scope.row.id,'2')"-->
<!--                                   v-if="$btnIsShow('isystem/RoleManage','1','反向关联')"-->
<!--                        >反向关联-->
<!--                        </el-button>-->
                        <!--            <el-button type="text" @click="handleBtnDisabled(scope.row)"-->
                        <!--            >按钮权限</el-button-->
                        <!--            >-->
                    </template>
                  <template v-else-if="scope.column.property === 'isCanSeeAll'">
                    <span :style="scope.row.isCanSeeAll === '1' ? 'color : green' : '' " >{{ parseDict2(scope.row.isCanSeeAll, 'unit_whether') }}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog :title="'关联角色'" :visible.sync="relVis" v-if="relVis"
                   :close-on-click-modal="false">
            <el-form :model="re" ref="re" :rules="rules">
                <el-form-item prop="roleList" label="关联的角色">
                    <el-select clearable filterable multiple v-model="re.roleIdList">
                        <el-option v-for="(item,index) in roleList" :key="index" :label="item.text"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="relVis = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveRelation()" :loading="bbLoading">确 定</el-button>
            </div>
        </el-dialog>


        <add-role-dialog
                :visible.sync="visible"
                :row="row"
                @ok="handleSearch"
        ></add-role-dialog>
        <!-- <edit-tree-dialog
                :visible.sync="authVisible"
                title="授权"
                :id="id"
                saveIdField="roleId"
                idField="roleId"
                :url="authUrl"
        ></edit-tree-dialog> -->
        <edit-power
                :visible="authVisible"
                title="授权"
                :url="authUrl"
                :id="id"
                idField="roleId"
                saveIdField="roleId"
                @update:visible="authVisible = false"></edit-power>
        <role-btn-permission-dialog
                :visible.sync="showVisible"
                :row="row"
                @okVisbles="okVisbles"
        >
        </role-btn-permission-dialog>
    </div>
</template>

<script>
    import {deleteAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins.js";
    import AddRoleDialog from "./modules/AddRoleDialog";
    import EditTreeDialog from "./modules/EditTreeDialog";
    import editPower from "./modules/editPower";
    import RoleBtnPermissionDialog from "./modules/RoleBtnPermissionDialog";
    import {getAction, postAction} from "../../api/manage";

    export default {
        name: "RoleManage",
        mixins: [QueryMixins],
        components: {
            AddRoleDialog,
            EditTreeDialog,
            editPower,
            RoleBtnPermissionDialog
        },
        data() {
            return {
                re: {},
                roleList: [],
                relVis: false,
                visible: false,
                showVisible: false,
                authVisible: false,
                row: {},
                options: [],
                id: "",
                authUrl: {
                    list: "/sys/permission/list",
                    auth: "/sys/permission/queryRolePermission",
                    ok: "/sys/permission/saveRolePermission",
                },
                columns: [
                  {
                    type: "selection",
                    width: 50,
                  },
                    {
                        prop: "action",
                        label: "操作",
                        width: 180,
                    },
                    {
                        type: "index",
                        label: "#",
                    },
                    {
                        prop: "roleName",
                        label: "角色名称",
                        sortable: "custom",
                    },
                    {
                        prop: "roleCode",
                        label: "角色编码",
                        sortable: "custom",
                    },
                    {
                        prop: "description",
                        label: "角色描述",
                        sortable: "custom",
                    },
                    {
                        prop: "createBy",
                        label: "创建人",
                        sortable: "custom",
                        show: false,
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                        sortable: "custom",
                        width: 180,
                    },
                  {
                    prop: "isCanSeeAll",
                    label: "是否可以查看所有业务员订单",
                  },

                ],
                url: {
                    list: "/sys/role/list",
                    delete: "/sys/role/delete",
                    getRelationIdList: '/sys/role/relation/getRelationIdList',
                    updateRoleRelation: '/sys/role/relation/updateRoleRelation',
                    getroleList: 'sys/dict/getDictItems/sys_role_id',
                  updateCanSeeAll : 'sys/role/updateIsCanSeeAll'
                },
                bbLoading: false,
                rules: {
                    roleIdList: [],
                },
            };
        },
        created() {
            getAction(this.url.getroleList)
                .then(res => {
                    this.roleList = res.result;
                })
        },
        methods: {
            updateCanSeeAll(type) {
              let tips = "确定关联吗，将会将新增加的销售专员角色的用户自动关联到该角色上，只影响之后新增的用户";
              if (type === "0") {
                tips = "确定取消关联吗，将会将新增加的销售专员角色的用户不再自动关联到该角色上，只影响之后新增的用户";
              }
              this.$confirm(tips, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "error",
                closeOnClickModal: false,
              })
              .then(() => {
                postAction(this.url.updateCanSeeAll + "?canSee=" + type,this.selectedRowKeys)
                    .then(res => {
                      this.$message.success(res.message);
                      this.handleQuery();
                    })
              })

            },
            saveRelation() {
                this.bbLoading = true;
                this.$refs['re'].validate(valid => {
                    if (valid) {
                        let type = this.re.type;
                        if (type == '1') {
                            this.re.relationIdList = this.re.roleIdList;
                        } else {
                            this.re.roleIdList = this.re.roleIdList;
                        }
                        postAction(this.url.updateRoleRelation, this.re)
                            .then(res => {
                                this.$message.success(res.message);
                                this.relVis = false;
                                this.bbLoading = false;
                                this.handleQuery();
                            }).catch(err => {
                            this.bbLoading = false;
                        })
                    }
                })
            },
            addRelation(id, type) {

                this.re = {};
                if (type == '1') {
                    this.re.type = '1';
                    this.re.roleId = id;
                } else {
                    this.re.type = '2';
                    this.re.roleRelationId = id;
                }
                getAction(this.url.getRelationIdList, {type: type, roleId: id})
                    .then(res => {
                        if (res.result) {
                            this.$set(this.re, 'roleIdList', res.result);
                        } else {
                            this.$set(this.re, 'roleIdList', []);
                        }
                        this.relVis = true;
                    })

            },
            handleDel(id) {
                this.$confirm("确定删除该角色?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();

                        });
                    })
                    .catch(() => {
                    });
            },
            handleAuth(id) {
                this.id = id;
                this.authVisible = true;
            },
            handleEdit(row) {
                this.row = row;
                this.visible = true;
            },
            handleBtnShow(row) {
                this.row = row;
                this.row.gg = 'role';
                this.row.bp = 'show';
                this.showVisible = true;
            },
            handleBtnDisabled(row) {
                this.row = row;
                this.row.gg = 'role';
                this.row.bp = 'disabled';
                this.showVisible = true;
            },
            okVisbles() {
                this.showVisible = false;
            },
        },
      computed:{
          buttonShowList(){
            return {
              'add':this.$btnIsShow('isystem/RoleManage','0','新增'),
              'sq':this.$btnIsShow('isystem/RoleManage','1','授权'),
              'bj':this.$btnIsShow('isystem/RoleManage','1','编辑'),
              'sc':this.$btnIsShow('isystem/RoleManage','1','删除'),
              'canSee':this.$btnIsShow('isystem/RoleManage','1','是否可以关联所有'),
            }
          }
      }
    };
</script>

<style lang="scss" scoped>
</style>
