<template>
    <!-- 批量下载入仓单 -->
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium"
                     :rules="rules">
                <el-row :gutter="12" class="inputs_row">
                    <el-col :span="8">
                        <el-form-item label="扣货时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.holdTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.holdTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>


                    <el-col :span="4">
                        <el-button size="medium" type="primary" icon="el-icon-download"
                                   @click="exportExcelPlus(url.holdOrderRemarkExport,'财务备注导出')" :loading="exportLoading">
                            财务备注导出
                        </el-button>
                        <el-button size="medium" type="primary" icon="el-icon-download"
                                   @click="exportExcelPlus(url.holdOrderListExport,'扣货列表导出')" :loading="exportLoading">
                            扣货列表导出
                        </el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>
<script>
    import {getAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins.js";


    export default {
        components: {},
        mixins: [QueryMixins],
        props: {},
        data() {
            return {
                exportLoading: false,
                queryForm: {
                    promotionNo: '',
                },
                rules: {
                    orderNo: [
                        {required: true, message: "请输入要下载入仓单的单号", trigger: "blur"},
                    ],
                },
                url: {
                    //下载入仓单
                    holdOrderRemarkExport: "/file/export/excel/holdOrderRemarkExport",
                    holdOrderListExport: "/file/export/excel/holdOrderListExport",
                    getDownLoadOrderNos: "file/jasper/download/getDownLoadOrderNos"
                }
            }
        },
        watch: {},
        created() {
        },
        mounted() {
        },
        methods: {
            handleReset() {
                this.queryForm = {}
                // this.$set(this.queryForm, 'promotionNo', '');
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>