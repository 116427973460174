<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="姓名" :label-width="formLabelWidth">
                                <el-input v-model="queryForm.name"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="编码" :label-width="formLabelWidth">
                                <el-input v-model="queryForm.code" @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="仓库" :label-width="formLabelWidth">
                                <f-select v-model="queryForm.warehouseId"
                                          :is-need="queryForm.warehouseId"
                                          :data-list="queryDictCodeListMap['delivery_point']"
                                          @keyup.enter.native="handleSearch">

                                </f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px" >
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        class="margin_right_10"
                        style="float:left;margin-right: 5px;"
                        v-if="buttonShowList.add">新增
                </el-button>
                <el-button
                        type="primary"
                        size="small"
                        @click="handleDel"
                        class="margin_right_10"
                        style="float:left;margin-right: 5px;"
                        :disabled="selectedRowKeys.length === 0"
                        v-if="buttonShowList.del">删除
                </el-button>

            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleUpdate(scope.row)"
                                   v-if="buttonShowList.edit"
                        >编辑</el-button>
                    </template>
                  <template v-else-if="scope.column.property === 'warehouseId'">
                    <span>{{parseDict2(scope.row.warehouseId, 'delivery_point')}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <warehouse-discharger-dialog
            :visible.sync="dialogFormVisible" :row="selectedRow"
            :father-query-dict-list-map="queryDictCodeListMap"
            @ok="handleSearch">
        </warehouse-discharger-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {postAction} from "@/api/manage";
    import warehouseDischargerDialog from "@/views/warehouse2.0/modules/warehouseDischargerDialog.vue";

    export default {
        name: "WarehouseDischarger",
        mixins: [QueryMixins],
        components: {warehouseDischargerDialog},
        data() {
            return {
                isUsePost: true,
                dialogFormVisible: false,
                formLabelWidth: "120px",
                //表单校验

                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "name",
                        label: "姓名",
                    },
                    {
                        prop: "code",
                        label: "编码",
                    },
                    {
                        prop: "warehouseId",
                        label: "仓库",
                    },

                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },
                  {
                    prop: "updateBy",
                    label: "修改人",
                  },
                  {
                    prop: "updateTime",
                    label: "修改时间",
                  },

                ],
                url: {
                    list: "/warehouse/discharger/page",
                    del:'/warehouse/discharger/del',
                },
                selectedRow:{},
                needQueryDictCodes : ['delivery_point'],

            };
        },
        methods: {

            //新增
            handleAdd() {
                this.selectedRow = {};
                this.dialogFormVisible = true;
            },
            //修改
            handleUpdate(row) {
                this.selectedRow = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            //删除
            handleDel(){
                this.$confirm(`确定删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.del, this.selectedRowKeys)
                    .then(res=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        });
                        this.handleQuery();
                    })
                })
            },
        },

      computed:{
        buttonShowList() {
          return {
            'edit':this.$btnIsShow('warehouse2.0/WarehouseDischarger','1','编辑'),
            'del':this.$btnIsShow('warehouse2.0/WarehouseDischarger','0','删除'),
            'add':this.$btnIsShow('warehouse2.0/WarehouseDischarger','0','新增'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
