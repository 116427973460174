<template>
  <el-dialog :title="title" :visible="visible" :width="dialogWidth" append-to-body
             custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type == 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
             class="c-th-has-bgcolor" :columns="columns" :data="tableData"
             :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
             @sort-change="handleTableChange">
    </f-table>

    <el-form v-else :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="country" label="国家">
            <f-select v-model="addForm.country"
                      :is-need="addForm.country"
                      :data-list="fatherDictMap['bd_country']"
                      maxlength="20" show-word-limit
                      placeholder="请选择国家">
            </f-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="initialOrderTariffSurcharge" label="订单初始关税附加费">
            <el-input v-model.trim="addForm.initialOrderTariffSurcharge" type="number" placeholder="请输入订单初始关税附加费"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="includingTaxPriceCalculationTariffSurcharge" label="包税价格核算关税附加费">
            <el-input v-model.trim="addForm.includingTaxPriceCalculationTariffSurcharge" type="number" placeholder="请输入包税价格核算关税附加费"></el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {httpAction, getAction, postAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";
import {validateTrailerFee} from "@/utils/validator";


export default {
  name: 'EditBdSurchargeDialog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {
      }
    },
    // 弹窗类型
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    fatherDictMap:{
      type : Object,
      default : ()=>{}
    }
  },
  data() {
    return {
      isUsePost : true,
      disableMixinMounted: true,
      additionalTableData: [],
      totalTableData: [],
      dialogWidth: '',
      columns: [
        {
          prop: "fieldName",
          label: "字段名",
        },
        {
          prop: "beforeValue",
          label: "初始值",
        },
        {
          prop: "afterValue",
          label: "结束值",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],

      addForm: {},
      rules: {

        country: [
          {required: true, message: '请输入国家', trigger: 'change'},
        ],

        initialOrderTariffSurcharge: [
          {required: true, message: '请输入订单初始关税附加费', trigger: 'blur'},
          {validator: validateTrailerFee, trigger: 'change'}
        ],
        includingTaxPriceCalculationTariffSurcharge: [
          {required: true, message: '请输入包税价格核算关税附加费', trigger: 'blur'},
          {validator: validateTrailerFee, trigger: 'change'}
        ],

      },
      url: {
        add: '/operation/bdSurcharge/add',
        edit: '/operation/bdSurcharge/edit',
        list: '/operation/bdSurcharge/log/page',
      },
    }
  },
  watch: {
    visible(val) {
      this.dialogWidth = '560px';
      if (this.type === 'log') {
        this.initData = {
          bdSurchargeId: this.row.id,
        }
        this.dialogWidth = '50%';
        this.handleReset();
      }
      if (val && this.row.id) {
          this.addForm = JSON.parse(JSON.stringify(this.row))
      }else {
        this.addForm = {};
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
      if (['log'].indexOf(this.type) !== -1) {
        this.handleClose()
        this.$emit('ok')
      } else {
        let url = this.url.add
        if (this.row.id) {
          url = this.url.edit
        }
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            httpAction(url, 'POST', this.addForm).then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose()
              this.$emit('ok')
            })
          }
        })
      }

    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
