<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="邮编开始">
              <el-input v-model="queryForm.startPost" type="number" @keyup.enter.native="handleSearch"
                        onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="邮编结束">
              <el-input v-model="queryForm.endPost" type="number" @keyup.enter.native="handleSearch"
                        onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="单位">
              <el-input v-model="queryForm.unit" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="渠道名称">
              <f-select v-model="queryForm.channelId" :data-list="queryDictCodeListMap['amazon_send_channel_set']"
                        :is-need="queryForm.channelId"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="交货仓库">
              <f-select v-model="queryForm.deliveryPointId" :data-list="queryDictCodeListMap['delivery_point']"
                        :is-need="queryForm.deliveryPointId"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="报价版本">
              <el-input v-model="queryForm.priceVersion"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="是否超箱规">
              <f-select :dict="'unit_whether'" :is-need="queryForm.isOverLength" v-model="queryForm.isOverLength"
                        autocomplete="off"></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="20px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div class="f-p-b-15">
          <el-button
              type="primary"
              size="medium"
              icon="el-icon-plus"
              @click="handleAdd"
              v-if="buttonShowList.add"
          >新增
          </el-button
          >
          <el-button
              type="primary"
              size="medium"
              @click="downloadModel(url.downloadFba, 'AmazonSend邮编报价导入模板')"
          >模板下载
          </el-button>
          <el-upload
              action="/"
              :show-file-list="false"
              :http-request="uploadSectionFile"
              accept=".xls,.xlsx"
              style="margin:0 10px;display:inline"
              v-if="buttonShowList.dr"
          >
            <el-button size="medium" type="primary"

            >导入
            </el-button>
          </el-upload>
          <el-button type="primary" size="medium" @click="handleExportXls('AmazonSend邮编报价')"

          >导出
          </el-button>
          <el-button type="primary" size="small" icon="check" @click="deleteBatch" style="float: left;margin-left: 7px"
                     v-if="buttonShowList.del"
                     :disabled="selectedRowKeys.length === 0">批量删除
          </el-button>
        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            tableHeight="73vh"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'action'">
              <el-button type="text" @click="handleEdit(scope.row)"

                         v-if="buttonShowList.edit"
              >编辑
              </el-button>

            </template>

            <template v-else-if="scope.column.property === 'hasTax'">
              <span>{{ parseDict2(scope.row.hasTax, 'unit_whether') }}</span>
            </template>

            <template v-else-if="scope.column.property === 'isOverLength'">
              <span>{{ parseDict2(scope.row.isOverLength, 'unit_whether') }}</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </el-card>
    </el-form>
    <el-dialog :title="form.id ? '编辑邮编报价' : '新增邮编报价'" append-to-body :visible.sync="dialogFormVisible"
               :close-on-click-modal="false" width="60%">
      <el-form :model="form" :rules="rules" ref="form" class="addEditBox">
        <el-form-item label="渠道名称" :label-width="formLabelWidth" prop="channelId">
          <f-select :is-need="form.channelId" :data-list="queryDictCodeListMap['amazon_send_channel_set']"
                    v-model="form.channelId" autocomplete="off"></f-select>
        </el-form-item>
        <el-form-item label="报价版本" :label-width="formLabelWidth" prop="priceVersion">
          <el-input v-model="form.priceVersion" maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="交货仓库" :label-width="formLabelWidth" prop="deliveryPointId">
          <f-select :data-list="queryDictCodeListMap['delivery_point']" :is-need="form.deliveryPointId"
                    v-model="form.deliveryPointId" autocomplete="off"></f-select>
        </el-form-item>
        <el-form-item label="地址类型" :label-width="formLabelWidth" prop="shipmentType">
          <f-select
              :is-need="form.shipmentType"
              :data-list="queryDictCodeListMap['shipment_type']"
              v-model="form.shipmentType" autocomplete="off">

          </f-select>

        </el-form-item>

        <el-form-item label="邮编开始" :label-width="formLabelWidth" prop="startPost">
          <el-input
              v-model="form.startPost"
              type="number"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))">
          </el-input>

        </el-form-item>
        <el-form-item label="邮编结束" :label-width="formLabelWidth" prop="endPost">
          <el-input
              v-model="form.endPost"
              type="number"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))">
          </el-input>

        </el-form-item>

        <el-form-item label="单位" :label-width="formLabelWidth" prop="unit">
          <f-select :data-list="queryDictCodeListMap['per_price_unit']" :is-need="form.unit" v-model="form.unit"
                    autocomplete="off"></f-select>
        </el-form-item>
        <el-form-item label="最低消费" :label-width="formLabelWidth" prop="startNum">
          <el-input v-model="form.startNum" type="number"></el-input>
        </el-form-item>
        <el-form-item label="结束值" :label-width="formLabelWidth" prop="endNum">
          <el-input v-model="form.endNum" type="number"></el-input>
        </el-form-item>
        <el-form-item label="海运单价" :label-width="formLabelWidth" prop="price">
          <el-input v-model="form.price" type="number"></el-input>
        </el-form-item>
        <el-form-item label="销售单价" :label-width="formLabelWidth" prop="price">
          <el-input v-model="form.salePrice" type="number"></el-input>
        </el-form-item>
        <el-form-item label="官网价格" :label-width="formLabelWidth" prop="price">
          <el-input v-model="form.custPrice" type="number"></el-input>
        </el-form-item>
        <el-form-item label="成本红线" :label-width="formLabelWidth" prop="minPrice">
          <el-input v-model="form.minPrice" type="number"></el-input>
        </el-form-item>
        <el-form-item label="体重比" :label-width="formLabelWidth" prop="cbmKg">
          <el-input v-model="form.cbmKg" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="时效" :label-width="formLabelWidth" prop="preDay">
          <el-input v-model="form.preDay" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="派送方式" :label-width="formLabelWidth" prop="deliveryType">
          <el-input v-model="form.deliveryType" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEndName">
          <el-input v-model="form.podEndName" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="是否包税" :label-width="formLabelWidth" prop="hasTax">
          <f-select :dict="'unit_whether'" :is-need="form.hasTax" v-model="form.hasTax" autocomplete="off"></f-select>
        </el-form-item>
        <el-form-item label="有效开始时间" :label-width="formLabelWidth" prop="stime">
          <el-date-picker
              class="f-flex-1"
              v-model="form.stime"
              type="datetime"
              size="small"
              placeholder="有效开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>

    <import-file-dialog :visible="importFileVisible" :import-file-data="importFileData"
                        @ok="importFileVisible = false"></import-file-dialog>
  </div>
</template>

<script>
import {postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
import {zhengshuPattern, zhengshuxiaoshuPartten} from "@/utils/pattern";
import {validateCmb, validateCtn} from "@/utils/validator";
import importFileDialog from "./modules/importFileDialog.vue";

export default {
  name: "AmazonSendChannelPrice",
  mixins: [QueryMixins],
  components: {importFileDialog},
  data() {
    return {
      importFileData: [],
      importFileVisible: false,
      needQueryDictCodes: [
        'shipment_type',
        'per_price_unit',
        'delivery_point',
        'amazon_send_channel_set',
      ],
      rules: {
        startNum: [
          {required: true, message: "请填写最低消费", trigger: "change"}
        ],
        endNum: [
          {required: true, message: "请填写结束值", trigger: "change"}
        ],
        price: [
          {required: true, message: "请填写价格", trigger: "change"}
        ],
        minPrice: [
          {required: true, message: "请填写价格", trigger: "change"}
        ],
        startPost: [
          {required: true, message: "请输入起始邮编", trigger: "blur"}
        ],
        endPost: [
          {required: true, message: "请输入结束邮编", trigger: "blur"}
        ],
        channelId: [
          {required: true, message: "请选择渠道", trigger: "change"}
        ],
        deliveryPointId: [
          {required: true, message: "请选择交货仓库", trigger: "change"}
        ],
        unit: [
          {required: true, message: "请输入单位", trigger: "change"}
        ],
        priceVersion: [
          {required: true, message: "请输入报价版本", trigger: "change"}
        ],
        stime: [
          {required: true, message: "请选择有效开始时间", trigger: "change"}
        ],

      },
      feeTemplateList: [],
      emailList: [],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "120px",
      //控制弹框显示
      dialogFormVisible: false,
      dialogFormVisibleEmail: false,
      columns: [
        {
          type: "selection",

        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "isOverLength",
          label: "是否超箱规",
          width: 60
        },

        {
          prop: "priceVersion",
          label: "报价版本",

        },
        {
          prop: "preDay",
          label: "时效",

        },
        {
          prop: "hasTax",
          label: "是否包税",

        },

        {
          prop: "shipmentTypeStr",
          label: "地址类型",
        },

        {
          prop: "deliveryPointName",
          label: "交货仓库",

        },
        {
          prop: "channelName",
          label: "渠道名称",

        },
        {
          prop: "startPost",
          label: "邮编起始",

        },
        {
          prop: "endPost",
          label: "邮编结束",

        },
        {
          prop: "unit",
          label: "单位",

        },
        {
          prop: "startNum",
          label: "最低消费",

        },
        {
          prop: "endNum",
          label: "结束值",

        },
        {
          prop: "price",
          label: "成本价格",

        },
        {
          prop: "salePrice",
          label: "销售价格",

        },
        {
          prop: "custPrice",
          label: "官网价格",

        },
        {
          prop: "minPrice",
          label: "成本红线",

        },
        {
          prop: "cbmKg",
          label: "体重比",

        },
        {
          prop: "deliveryType",
          label: "派送方式",

        },
        {
          prop: "podEndName",
          label: "目的港",

        },
        {
          prop: "stime",
          label: "有效开始时间",

        },

        {
          prop: "createBy",
          label: "创建人",

        },
        {
          prop: "createTime",
          label: "创建时间",

        },
        {
          prop: "updateBy",
          label: "修改人",

        },
        {
          prop: "updateTime",
          label: "修改时间",

        },
      ],
      url: {
        list: "/express/amazonSend/channelPostPrice/page",
        add: "/express/amazonSend/channelPostPrice/add",
        edit: "/express/amazonSend/channelPostPrice/edit",


        deleteBatch: "/express/amazonSend/channelPostPrice/del",
        // importFba:'/order/channel/price/importFba',
        importFba: '/express/amazonSend/channelPostPrice/import',
        downloadFba: '/express/amazonSend/downloadModel2',
        exportXlsUrl: '/express/amazonSend/channelPostPrice/export',

      },
      isUsePost: true,
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importFba, form)
          .then((res) => {
            //这里做上传之后的操作
            if (res.success) {
              this.importFileVisible = true;
              this.importFileData = res.result;
              this.handleQuery();
            } else {
              this.handleQuery();
            }
          })
    },

    initBdFeeTypeList() {
      if (this.feeTypeList.length <= 0) {
        this.feeTypeList = [];
        getBdFeeTypeList("1")
            .then((res) => {
              if (res.success) {
                for (var i = 0; i < res.result.length; i++) {
                  var obj = {};
                  obj.value = res.result[i].id;
                  obj.label = res.result[i].chineseName;
                  this.feeTypeList.push(obj);
                }
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
      }
    },

    initAllSupplier() {
      this.allSupplierList = [];
      //获取所有海运供应商
      getAllSupplier("1")
          .then((res) => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                var obj = {};
                obj.value = res.result[i].id; //供应商ID
                obj.label = res.result[i].chinaseName; //供应商中文名
                this.allSupplierList.push(obj);
              }
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
    },
    //点击加号添加一行数据
    AddListRow() {
      /*  this.feeTemplateList=[];*/
      this.form.feeTemplateList.push({
        feeId: "",
        supId: "",
        count: "",
        cur: "",
        price: "",
      });
    },
    handleDelete(index) {
      this.form.feeTemplateList.splice(index, 1);
    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleAdd() {
      this.form = {};
      this.form.feeTemplateList = [];
      this.dialogFormVisible = true;
      this.$refs.form.resetFields();
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;

    },

    postOrPut() {
      var id = this.form.id;
      let url = this.url.add;
      // this.form.feeTemplateList = this.form.feeTemplateList;
      //编辑
      if (this.form.id) {
        url = this.url.edit;
      }
      postAction(url, this.form).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          id = res.id;
          //关闭弹窗
          this.dialogFormVisible = false;
          this.handleQuery();

        }
      });

    },
    handleSave() {

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postOrPut();
        }
      });
    },

    deleteBatch(row) {
      this.$confirm(`确定删除模板`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => {
          });
    },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.initFeeType = true;
    this.queryForm.initAllSupplier = true;
  },
  computed: {
    buttonShowList() {
      return {
        'add': this.$btnIsShow('channel/AmazonSendChannelPostPrice', '0', '新增'),
        'dr': this.$btnIsShow('channel/AmazonSendChannelPostPrice', '0', '导入'),

        'del': this.$btnIsShow('channel/AmazonSendChannelPostPrice', '1', '删除'),
        'edit': this.$btnIsShow('channel/AmazonSendChannelPostPrice', '1', '编辑'),
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
