<template>
  <div>
    <el-dialog
        :title="title"
        :visible="visible"
        width="75%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose"
        append-to-body
    >
      <el-card class="box-card">
        <el-form :model="emailForm" ref="emailForm" :rules="emailRules" label-width="80px">
          <el-form-item label="收件人" prop="receiver">
            <el-input
                v-model="emailForm.receiver"
                maxlength="255"
                show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="抄送" prop="ccReceiver">
            <el-input
                v-model="emailForm.ccReceiver"
                maxlength="255"
                show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="主题" prop="title">
            <el-input
                v-model="emailForm.title"
                maxlength="255"
                show-word-limit
            ></el-input>
          </el-form-item>
          <div style="" class="flex_box">
            <div style="flex:1;margin-left: 60px;display: flex;align-items: end;">
            <el-button size="medium" type="primary" @click="sendEmail" style="margin-left: 20px">发送</el-button>
            <el-button size="medium" type="primary" @click="save" style="margin-left: 20px">保存</el-button>
            <f-upload v-model="emailForm.attachmentList" style="margin-left: 20px;flex:1" :is-use-oss="true" class="flex_upload"></f-upload>
            </div>

            <el-button size="medium" type="primary"
                       @click="downloadExcelPost(url.exportDeliveryDocTwo, containerNo + '-派送文件',{ids:[containerId]})"
                       style="margin-left: 20px;float: right" v-if="buttonShowList.xzpswj">
              下载派送文件2
            </el-button>
          </div>
          <el-form-item label="内容" prop="contact">
            <quill-editor
                v-model="emailForm.content"
                :options="quillOption"
                ref="myQuillEditor"
            />
          </el-form-item>

        </el-form>
      </el-card>

    </el-dialog>


  </div>
</template>

<script>
import {getAction,postAction} from "@/api/manage";
import quillConfig from "@/utils/quill-config";
import {quillEditor} from "vue-quill-editor";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import queryMixins from "@/mixins/QueryMixins";
export default {
  name: "deliveryContainerEmailDialog",
  mixins: [queryMixins],
  components: {quillEditor},
  props: {
    title: {
      type: String
    },
    type: {
      type: String
    },
    containerId: {
      type: String
    },
    containerNo: {
      type: String
    },
    visible: {
      type: Boolean
    },
    fatherForm:{}

  },
  data() {
    return {
      disableMixinMounted:true,
      quillOption: quillConfig,
      emailForm: {},
      loading: false,
      url: {
        queryEmailModelByIdAndType: 'operation/container/delivery/queryEmailBOByIdAndType',
        addOrUpdateEmail: 'operation/container/delivery/addOrUpdateEmail',
        sendEmail: 'operation/container/delivery/sendEmail',
        getPaiSongUrl: "/file/jasper/getPaiSong",
        exportDeliveryDocTwo:"/file/export/excel/container/exportDeliveryDocTwo"


      },
      emailRules: {
        receiver: [
          {required: true, message: '请输入收件人', trigger: 'change'}
        ],
        ccReceiver: [
          {required: true, message: '请输入抄送人', trigger: 'change'}
        ],
        title: [
          {required: true, message: '请输入主题', trigger: 'change'}
        ],
        content: [
          {required: true, message: '请输入内容', trigger: 'change'}
        ]
      },
      editorHeight: 'auto', // 初始高度设置为 auto 或者具体的值
      editorContent: ''     // 编辑器的内容

    };
  },
  watch: {
    visible(val) {
      if (val && this.fatherForm) {
        debugger
        this.emailForm = JSON.parse(JSON.stringify(this.fatherForm));
      }
    }
  },
  methods: {


    getEmailFormByIdAndType(){
      getAction(this.url.queryEmailModelByIdAndType, {id: this.containerId, type: this.type})
          .then(res => {
            this.emailForm = res.result;
          })
    },
    sendEmail(){
      this.$refs.emailForm.validate(valid => {
        if (valid) {
          postAction(this.url.sendEmail,this.emailForm)
              .then(res => {
                this.$message.success(res.message);
                this.getEmailFormByIdAndType();
              })
        }
      })
    },
    save(){
      this.$refs.emailForm.validate(valid => {
        if (valid) {
          postAction(this.url.addOrUpdateEmail,this.emailForm)
              .then(res => {
                this.$message.success(res.message);
                this.getEmailFormByIdAndType();
              })
        }
      })
    },
    initEmailForm() {
      this.emailForm = {
        receiver: '',
        ccReceiver: '',
        title: '',
        content: '',
        containerId: '',
        type: '',
        attachmentList: []
      }
    },
    handleClose() {

      //重置对象
      this.initEmailForm();
      this.$emit("close");
    },
  },
  created() {
    this.initEmailForm();
  },
  computed: {
    buttonShowList(){
      return {
        'xzpswj': this.$btnIsShow('operation/cabinet/Edit','0','下载派送文件2'),
      }
    }
  }
};
</script>

<style lang="scss">
.flex_upload > *:first-child {
  display: flex;
  align-items: end;
}
.flex_box {
display: flex;
margin-bottom: 12px;
align-items: end;
.el-button {
  height: 22px;
}
}

</style>

