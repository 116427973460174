<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="国家" label-width="120px">
              <f-select
                  v-model="queryForm.country"
                  :is-need="queryForm.country"
                  :data-list="queryDictCodeListMap['bd_country']"
                  @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleEdit({})"
                   v-if="buttonShowList[ 'add']">新增
        </el-button>
        <el-button :disabled="selectionRows.length === 0" type="primary" size="medium" icon="el-icon-plus"
          @click="handleBatDel()" v-if="buttonShowList['del']">批量删除
        </el-button>


      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row,'edit','编辑关税附加费设置')"
              v-if="buttonShowList['edit']">编辑
            </el-button>

            <el-button type="text" @click="handleEdit(scope.row, 'log','关税附加费设置日志')"
              v-if="buttonShowList['log']">日志
            </el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <edit-bd-surcharge-dialog :visible.sync="addVisible" :type="type" :row="row" :title="title" @ok="handleSearch"
    :father-dict-map="queryDictCodeListMap"></edit-bd-surcharge-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditBdSurchargeDialog from "@/views/isystem/BaseData/modules/EditBdSurchargeDialog.vue";
export default {
  name: "BdSurcharge",
  mixins: [QueryMixins],
  components: {
    EditBdSurchargeDialog
  },
  data() {
    return {
      needQueryDictCodes : ['bd_country'],
      loading: false,
      addVisible: false,
      authVisible: false,
      type: '',
      title:'',
      isUsePost: true,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "initialOrderTariffSurcharge",
          label: "订单初始关税附加费",
        },
        {
          prop: "includingTaxPriceCalculationTariffSurcharge",
          label: "包税价格核算关税附加费",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
        {
          prop: "updateTime",
          label: "修改时间",
        },
        {
          prop: "updateBy",
          label: "修改人",
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
        },
      ],
      url: {
        list: "/operation/bdSurcharge/page",
        deleteBatch: '/operation/bdSurcharge/del',
        downloadModel: '/file/download/model/additionalFeeSet',
        edit: '/operation/bdSurcharge/edit',
      },
    };
  },
  methods: {

    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => { });
    },

    handleEdit(row, type,title) {
      this.type = type;
      this.row = row;
      this.title = title;
      this.addVisible = true;
    },

  },
  computed:{
    buttonShowList(){
      return {
        'add':this.$btnIsShow('isystem/BaseData/BdSurcharge', '0', '新增'),
        'del':this.$btnIsShow('isystem/BaseData/BdSurcharge', '0', '批量删除'),
        'edit':this.$btnIsShow('isystem/BaseData/BdSurcharge', '1', '编辑'),
        'log':this.$btnIsShow('isystem/BaseData/BdSurcharge', '1', '查看日志'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
