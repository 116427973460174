<template>
  <div class="main-full-content">

    <el-form class="multiline_select" ref="form" :model="queryForm" size="medium" label-width="auto">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col style="width: 20%;">
            <el-form-item label="派送操作">
              <f-select v-model="queryForm.endOperatorId" :is-need="queryForm.endOperatorId" :data-list="queryDictCodeListMap['delivery_operator']"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo" type="textarea"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="柜号">
              <el-input v-model="queryForm.containerNo" type="textarea"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="SO号">
              <el-input v-model="queryForm.bookNo"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="预约号">
              <el-input v-model="queryForm.reverseNo"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="周期">
              <el-input v-model="queryForm.week"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="船名航次">
              <el-input v-model="queryForm.shipNo"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="国家">
              <f-select v-model="queryForm.country" :is-need="queryForm.country" :data-list="queryDictCodeListMap['bd_country']"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="拆柜代理">
              <f-select
                  v-model="queryForm.dismantleAgentId"
                  :is-need="queryForm.dismantleAgentId"
                  :data-list="queryDictCodeListMap['dismantleAgent_id']"
                  @keyup.enter.native="handleSearch"
              ></f-select>
            </el-form-item>
          </el-col>

          <el-col style="width: 20%;">
            <el-form-item label="到港状态">
              <el-select v-model="queryForm.isArrived" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未到港"></el-option>
                <el-option value="1" label="已到港"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 40%;">
            <el-form-item label="ETD">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etdStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etdEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col style="width: 40%;">
            <el-form-item label="ETA">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etaStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etaEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="提柜状态">
              <el-select v-model="queryForm.isPicked" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未提柜"></el-option>
                <el-option value="1" label="已提柜"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 40%;">
            <el-form-item label="提柜日期">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.pickedTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.pickedTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col style="width: 40%;">
            <el-form-item label="预约时间">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.returnedTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.returnedTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="派送状态">
              <el-select v-model="queryForm.isDelivery" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未派送"></el-option>
                <el-option value="1" label="已派送"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 40%;">
            <el-form-item label="派送时间">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.deliveryTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.deliveryTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col style="width: 40%;">
            <el-form-item label="POD时间">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.podUploadTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.podUploadTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="POD状态">
              <el-select v-model="queryForm.isUploadPod" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未上传"></el-option>
                <el-option value="1" label="已上传"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="地址类型">
              <f-select
                  v-model="queryForm.shipmentType"
                  :is-need="queryForm.shipmentType"
                  :data-list="queryDictCodeListMap['shipment_type']"
                  @keyup.enter.native="handleSearch"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="FBA CODE/ZIP">
              <el-input v-model="queryForm.fbaCodeOrZip"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="SH ID/MARK">
              <el-input v-model="queryForm.shipmentIdOrMark"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="扣放状态">
              <f-select :dict="'delivery_order_status'"
                        v-model="queryForm.delivery" :is-need="queryForm.delivery"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="Remark">
              <el-input v-model="queryForm.remark"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col style="width: 20%;">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">

      <div class="f-p-b-15">
        <el-button
            type="primary"
            size="medium"
            @click="handleAppointment(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plyy"
        >批量预约
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleDeliveryChange(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plpsbg"
        >批量派送变更
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleRejection(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plgs"
        >批量拒收
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleSend(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.plpscg"

        >批量派送成功
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleSendTiQu(null, 'batch')"
            :disabled="selectionRows.length === 0 " v-if="buttonShowList.pltqcg"

        >批量提取成功
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="exceptionOrderFinish(null, 'batch')"
            :disabled="selectionRows.length === 0 ||btnDisabled" v-if="buttonShowList.ycwjdd"
        >异常完结订单
        </el-button>
        <el-button
            size="medium"
            type="primary"
            :disabled="selectionRows.length === 0"
            @click="holdOrderBatch"
            v-if="buttonShowList.plhh"
            style="margin-left: 10px"
        >批量hold货
        </el-button>
        <el-button
            type="primary"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList.tbtx"
            @click="tieReminder"
        >特别提醒
        </el-button>
        <el-upload
            class="upload-demo"
            action=""
            :http-request="uploadPODFile"
            :show-file-list="false"
            ref="add"
            :multiple="true"
            :before-upload="beforeFile"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList.pod"
            style="margin-left: 10px">
          <el-button :disabled="selectionRows.length === 0" type="primary" size="small" >批量上传POD</el-button>
        </el-upload>
        <el-button
            type="primary"
            size="medium"
            @click="exportNoUpdateTT('导出未更新订单TT')"
            v-if="buttonShowList.tt"
            style="margin-left: 10px"
        >未更新订单TT
        </el-button>
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="beatHandcustomsClearanceResult"
            :disabled="this.selectedRowOrderNos.length===0"
            v-if="buttonShowList.plgwqg"
        >批量国外清关结果
        </el-button>
        <el-button type="primary" style="margin-left: 10px"
                   v-if="buttonShowList.dc" @click="exportExcelPlus(url.exportUrl,'派送订单报表')">导出报表
        </el-button>
      </div>
      <div class="c-alert f-m-b-10">
        已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
        <span
            class="stress f-m-l-12 f-cursor-pointer"
            @click="handleClearSelection"
        >清空</span
        >
      </div>

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">

          <template v-if="scope.column.property === 'week'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
            >{{ scope.row.week }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'containerNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
            >{{ scope.row.containerNo }}</a
            >
          </template>

          <template v-else-if="scope.column.property === 'week'">
            <span style="color: #00a0e9"
                  @click="$intoOperationCabinetEditPage(scope.row.id)">{{ scope.row.week }}
          </span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a  v-if="['1','2','5'].indexOf(scope.row.delivery) !== -1"
                style="color: #ff0000"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
            <a  v-else
                style="color: #00a0e9"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'delivery'">
            <span>{{ parseDict2(scope.row.delivery, 'delivery_order_status') }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

<!--    预约-->
    <el-dialog
        title="预约"
        :visible.sync="appointmentFormVisible"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-form :model="form" :rules="rules" ref="yuyue">
        <el-form-item
            label="预约时间"
            :label-width="formLabelWidth"
            prop="appointmentTime"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.appointmentTime"
              type="datetime"
              size="small"
              placeholder="完成时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="派送方式"
            :label-width="formLabelWidth"
            prop="deliveryType"
        >
          <f-select
              v-model="form.deliveryType"
              :isNeed="form.deliveryType"
              :data-list="queryDictCodeListMap['the_label_type']"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="预计到达目地时间"
            :label-width="formLabelWidth"
            prop="estimatedOfArrivalTime"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.estimatedOfArrivalTime"
              type="datetime"
              size="small"
              placeholder="预计到达目的地时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="快递公司"
            :label-width="formLabelWidth"
            prop="expressId"
            v-if="form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressId"
              :data-list="queryDictCodeListMap['sys_express']"
              :isNeed="form.expressId"
              @change="setExpressIdUrl"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="跟踪单号"
            :label-width="formLabelWidth"
            prop="logNo"
            v-if="form.deliveryType == '2'"
        >
          <el-input v-model="form.logNo" type="test"></el-input>
        </el-form-item>
        <el-form-item
            label="快递链接"
            :label-width="formLabelWidth"
            v-if="form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressIdUrl"
              :data-list="queryDictCodeListMap['sys_express_url']"
              :isNeed="form.expressIdUrl"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="查询网址"
            :label-width="formLabelWidth"
            v-if="form.deliveryType == '5'"
            prop="website"
        >
          <el-input v-model="form.website"></el-input>
        </el-form-item>
        <el-form-item
            label="查询单号"
            :label-width="formLabelWidth"
            v-if="form.deliveryType == '5'"
            prop="logNo"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item
            label="预约号"
            :label-width="formLabelWidth"
            prop="reservationNumber"
        >
          <el-input v-model="form.reservationNumber" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
            v-if="this.form.changeTypeType !='batch'"
            label="派送数量"
            :label-width="formLabelWidth"
            prop="reservationNumber"
        >
          <el-input v-model="form.partCtn" maxlength="10" show-word-limit placeholder="非必填项目，不填写默认全部配送" ></el-input>
        </el-form-item>
        <el-form-item
            v-if="form.partCtn && this.form.changeTypeType !='batch'"
            label="派送地址"
            :label-width="formLabelWidth"
            prop="deliveryAddress"
        >
          <el-input v-model="form.deliveryAddress" maxlength="100" show-word-limit  ></el-input>
        </el-form-item>

        <el-form-item
            label="上传POD"
            :label-width="formLabelWidth"
            ref="yanwu"
        >
          <!--                    <el-upload-->
          <!--                            :action="'/sys/oss/file/upload'"-->
          <!--                            :file-list="fileList"-->
          <!--                            :http-request="handleUpload"-->
          <!--                            :on-preview="handlePreview"-->
          <!--                            :on-remove="handleRemove"-->
          <!--                            :before-upload="beforeUpload"-->
          <!--                            :on-exceed="onexceed"-->
          <!--                            v-bind="$attrs"-->

          <!--                            :disabled="uploadDisable"-->
          <!--                    >-->
          <!--                        <template slot="trigger">-->
          <!--                            <template-->
          <!--                                    v-if="this.$attrs.drag === '' || this.$attrs.drag === true"-->
          <!--                            >-->
          <!--                                <i class="el-icon-upload"></i>-->
          <!--                                <div class="el-upload__text">-->
          <!--                                    将文件拖到此处，或<em>点击上传</em>-->
          <!--                                </div>-->
          <!--                            </template>-->
          <!--                            <template v-else>-->
          <!--                                <template v-if="this.$attrs['list-type'] === 'picture-card'">-->
          <!--                                    <i class="el-icon-plus"></i>-->
          <!--                                </template>-->
          <!--                                <el-button :disabled="uploadDisable" size="small" type="primary" v-else-->
          <!--                                >{{uploadDisable ? '回显中，请稍后' : '点击上传'}}-->
          <!--                                </el-button-->
          <!--                                >-->
          <!--                            </template>-->
          <!--                        </template>-->
          <!--                        <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>-->
          <!--                    </el-upload>-->
          <f-upload v-model="fileList" :trigger="true" :upload-file-type="'55'" :is-use-oss="true"></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            size="medium"
            @click="
            appointmentFormVisible = false;
            form = {};
            fileList = [];
          "
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('appointemt')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
<!--    派送变更-->
    <el-dialog
        title="派送变更"
        :visible.sync="dialogSendTheChangeFormVisible"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-form :model="form" :rules="rules" ref="changepaisong">
        <el-form-item
            label="派送变更"
            :label-width="formLabelWidth"
            prop="changeType"
        >
          <el-select v-model="form.changeType">
            <el-option label="更改预约时间" value="0"/>
            <el-option label="更改派送地址" value="1" />
            <el-option label="更改派送方式" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item
            label="预约时间"
            :label-width="formLabelWidth"
            prop="appointmentTime"
            v-if="form.changeType == '0'"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.appointmentTime"
              type="datetime"
              size="small"
              placeholder="预约时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
            label="派送地址"
            :label-width="formLabelWidth"
            prop="addressId"
            v-if="form.changeType == '1'"
        >
          <f-select
              v-model="form.addressId"
              :data-list="queryDictCodeListMap['fba_address']"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '0'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :data-list="queryDictCodeListMap['person_address']"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '1'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :data-list="queryDictCodeListMap['orther_address']"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '2'"
          ></f-select>
          <f-select
              v-model="form.addressId"
              :data-list="queryDictCodeListMap['person_address3']"
              :isNeed="form.addressId"
              @change="$forceUpdate()"
              v-if="form.shipmentType == '3'"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="派送方式"
            :label-width="formLabelWidth"
            prop="deliveryType"
            v-if="form.changeType == '2'"
        >
          <f-select
              v-model="form.deliveryType"
              :isNeed="form.deliveryType"
              :data-list="queryDictCodeListMap['the_label_type']"
          ></f-select>

        </el-form-item>
        <el-form-item
            label="预计到达目地时间"
            :label-width="formLabelWidth"
            prop="estimatedOfArrivalTime"
            v-if="form.changeType == '2'"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.estimatedOfArrivalTime"
              type="datetime"
              size="small"
              placeholder="预计到达目的地时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="快递公司"
            :label-width="formLabelWidth"
            prop="expressId"
            v-if="form.changeType == '2' && form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressId"
              :data-list="queryDictCodeListMap['sys_express']"
              :isNeed="form.expressId"
              @change="setExpressIdUrl"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="跟踪单号"
            :label-width="formLabelWidth"
            prop="logNo"
            v-if="form.changeType == '2' && form.deliveryType == '2'"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item
            label="快递链接"
            :label-width="formLabelWidth"
            v-if="form.changeType == '2' && form.deliveryType == '2'"
        >
          <f-select
              v-model="form.expressIdUrl"
              :data-list="queryDictCodeListMap['sys_express_url']"
              :isNeed="form.expressIdUrl"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>

        <el-form-item
            label="查询网址"
            :label-width="formLabelWidth"
            v-if="form.changeType == '2' && form.deliveryType == '5'"
            prop="website"
        >
          <el-input v-model="form.website"></el-input>
        </el-form-item>
        <el-form-item
            label="查询单号"
            :label-width="formLabelWidth"
            v-if="form.changeType == '2' && form.deliveryType == '5'"
            prop="logNo"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item
            label="上传POD"
            :label-width="formLabelWidth"
            ref="yanwu"
        >
          <f-upload :disabled="uploadDisable" v-model="fileList" :trigger="true" :upload-file-type="'55'" :is-use-oss="true">
          </f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogSendTheChangeFormVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="saveSendTheChange"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
<!--    拒收-->
    <el-dialog
        title="拒收"
        :visible.sync="dialogRejectionFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="jushou">
        <el-form-item
            label="拒收原因"
            :label-width="formLabelWidth"
            prop="rejection"
        >
          <f-select
              v-model="form.rejection"
              :isNeed="form.rejection"
              :data-list="queryDictCodeListMap['rejection']"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogRejectionFormVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('rejection')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
<!--    派送成功-->
    <el-dialog
        :title="this.isTiQu?'提取成功':'派送成功'"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="paisong">
        <el-form-item
            label="派送方式"
            :label-width="formLabelWidth"
            prop="deliveryType"
        >
          <f-select
              v-model="form.deliveryType"
              :isNeed="form.deliveryType"
              :data-list="this.isTiQu?queryDictCodeListMap['the_label_type_tiqu']:queryDictCodeListMap['the_label_type']"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="快递公司"
            :label-width="formLabelWidth"
            prop="expressId"
            v-if="form.deliveryType === '2'"
        >
          <f-select
              v-model="form.expressId"
              :data-list="queryDictCodeListMap['sys_express']"
              :isNeed="form.expressId"
              @change="setExpressIdUrl"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="跟踪单号"
            :label-width="formLabelWidth"
            prop="expressId"
            v-if="form.deliveryType === '2'"
        >
          <el-input v-model="form.logNo"></el-input>
        </el-form-item>
        <el-form-item label="快递链接" :label-width="formLabelWidth" v-if="form.deliveryType === '2'">
          <f-select
              v-model="form.expressIdUrl"
              :data-list="queryDictCodeListMap['sys_express_url']"
              :isNeed="form.expressIdUrl"
              @change="$forceUpdate()"
          ></f-select>
        </el-form-item>
        <el-form-item
            :label="this.isTiQu?'提取时间':'派送完成时间'"
            :label-width="formLabelWidth"
            prop="deliveryTime"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.deliveryTime"
              type="datetime"
              size="small"
              :placeholder="this.isTiQu?'提取时间':'完成时间'"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="交货时间"
            :label-width="formLabelWidth"
            prop="deliveryGoodTime"
            v-if="this.isTiQu"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="form.deliveryGoodTime"
              type="datetime"
              size="small"
              placeholder="交货时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="上传POD"
            :label-width="formLabelWidth"
            ref="yanwu"
        >
          <f-upload v-model="fileList2" :trigger="true" :multiple="true" :is-use-oss="true" :upload-file-type="'55'"></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false ; fileList = []"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="save('delivery')"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
<!--    异常完结-->
    <el-dialog
        title="异常订单完结"
        :visible.sync="ExceptionOrderDialogFlag"
        :close-on-click-modal="false"
    >
      <el-form :model="exceptionOrderForm" :rules="rules" ref="exceptionOrderForm">
        <el-form-item
            v-if="ExceptionOrderDialogFlag"
            prop="exceptionType"
            label="异常订单类型"
            :label-width="formLabelWidth"
        >
          <f-select :data-list="queryDictCodeListMap['order_exception_type']"
                    v-model="exceptionOrderForm.exceptionType"
                    :isNeed="exceptionOrderForm.exceptionType"
          ></f-select>
        </el-form-item>
        <el-form-item
            prop="remark"
            label="备注"
            :label-width="formLabelWidth"
            v-if="exceptionOrderForm.exceptionType == 5"
        >
          <el-input v-model="exceptionOrderForm.remark" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="ExceptionOrderDialogFlag = false && this.handleExceptionOrderRest()"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="orderExceptionFinish"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
<!--    特别提醒-->
    <el-dialog title="特别提醒"
               :visible.sync="dialogTieReminderVisible"
               :close-on-click-modal="false"
               append-to-body
               v-if="dialogTieReminderVisible">
      <el-form :model="tieForm" ref="tieForm" :rules="rules">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="tieForm.title"></el-input>
        </el-form-item>
        <el-form-item label="提醒事项" :label-width="formLabelWidth" prop="remark">
          <el-input v-model="tieForm.remark" type="textarea" maxlength="1000" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="文件类型" :label-width="formLabelWidth" prop="fileType">
          <f-select :data-list="queryDictCodeListMap['os_order_filelist']" v-model="tieForm.fileType"
                    :isNeed="tieForm.fileType"></f-select>
        </el-form-item>
        <el-form-item label="提醒文件" :label-width="formLabelWidth" prop="fileList">
          <f-upload v-model="tieForm.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
        </el-form-item>
        <el-form-item label="是否推送邮件" :label-width="formLabelWidth" prop="isSendEmail">

          <template>
            <el-radio-group v-model="tieForm.isSendEmail">
              <el-radio label="0">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item label="是否异常动态" :label-width="formLabelWidth" prop="isRemind">
          <el-radio-group v-model="tieForm.isRemind">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTieReminderVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendTieReminderEmail">确 定</el-button>
      </div>
    </el-dialog>
<!--    国外清关结果-->
    <el-dialog
        title="国外清关结果"
        :visible.sync="clearanceDialogFormVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="saveForm" ref="saveForm" :rules="rules">
        <el-form-item label="订单号" :label-width="formLabelWidth">
          <el-input v-model="saveForm.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="清关结果" :label-width="formLabelWidth" prop="customsClearanceResults">
          <f-select
              v-model="saveForm.customsClearanceResults"
              :is-need="saveForm.customsClearanceResults"
              :data-list="queryDictCodeListMap['customsClearanceResu']"
              placeholder="请选择">
          </f-select>
        </el-form-item>
        <el-form-item label="清关放行时间" :label-width="formLabelWidth" prop="clearTime"
                      v-if="saveForm.customsClearanceResults === '4'">
          <el-date-picker
              class="f-flex-1"
              v-model="saveForm.clearTime"
              type="datetime"
              size="small"
              placeholder="清关放行时间"
              default-time="23:59:59"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="扣留原因" :label-width="formLabelWidth" prop="detainedReason"
                      v-if="saveForm.customsClearanceResults === '2'">
          <f-select
              v-model="saveForm.detainedReason"
              :is-need="saveForm.detainedReason"
              :dict="'detainedReason'"
              placeholder="请选择">
          </f-select>
        </el-form-item>
        <el-form-item label="监管部门查验" :label-width="formLabelWidth" prop="departmentOfInspection"
                      v-if="saveForm.customsClearanceResults === '1'">
          <f-select
              v-model="saveForm.departmentOfInspection"
              :is-need="saveForm.departmentOfInspection"
              :dict="'departmentOfInspecti'"
              placeholder="请选择">
          </f-select>
        </el-form-item>
        <el-form-item label="最终结果" :label-width="formLabelWidth" prop="finalResult"
                      v-if="saveForm.customsClearanceResults === '3'">
          <f-select
              v-model="saveForm.finalResult"
              :is-need="saveForm.finalResult"
              :dict="'finalResult'"
              placeholder="请选择">
          </f-select>
        </el-form-item>
        <el-form-item
            label="查验类型"
            :label-width="formLabelWidth"
            prop="checkTheType"
            v-if="saveForm.customsClearanceResults === '5'"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="saveForm.checkTheType"
              placeholder="请选择"
          >
            <el-option key="0" label="文件审查" value="0"></el-option>
            <el-option key="1" label="X光查验" value="1"></el-option>
            <el-option key="2" label="人工查验" value="2"></el-option>
            <el-option key="3" label="其它" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="清关查验周数"
            :label-width="formLabelWidth"
            prop="checkWeek"
            v-if="saveForm.customsClearanceResults === '5'"
        ><el-input v-model="saveForm.checkWeek"></el-input>
        </el-form-item>
        <el-form-item
            label="清关查验文件"
            :label-width="formLabelWidth"
            prop="checkedFileList"
            v-if="saveForm.customsClearanceResults === '5' || saveForm.customsClearanceResults === '6' || saveForm.customsClearanceResults === '2'"
        >
          <f-upload
              class="f-full-width"
              v-model="saveForm.checkedFileList"
              :isUseOss="true"
          ></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clearanceDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveClearance">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {postAction, getAction, putAction, httpAction, postRefreshAction} from "@/api/manage";
import sendShuidanEmailLog from "./cabinet/SendShuidanEmailLog.vue";
import reviewTaxDialog from "./cabinet/ReviewTaxDialog.vue";
import deliveryContainerEmailDialog from "./cabinet/deliveryContainerEmailDialog.vue";
import qaqFile from "@/mixins/qaqFile";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";
export default {
  name: "DispatchVerification",
  mixins: [QueryMixins, qaqFile],
  components: {sendShuidanEmailLog,reviewTaxDialog,deliveryContainerEmailDialog},
  data() {
    let checkWords = (rule, value, callback) => {
      // console.log(value)
      if (value === '') {
        this.$message.warning('关键词不能为空！')
        return callback(new Error('关键词不能为空！'))
      }
      let regBox = {regWords: /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g}
      // console.log('第一个条件')
      // console.log(regBox.regWords.test(value))
      let result = regBox.regWords.test(value)
      if (result === true) {
        this.$message.warning('关键词格不能中文混杂！！')
        return callback(new Error('关键词格不能中文混杂！！'))

      } else {
        return callback()
      }
    }
    return {
      needQueryDictCodes : [
          'sys_user_id','bd_country','dismantleAgent_id','shipment_type','the_label_type','sys_express','fba_address',
          'person_address','orther_address','person_address3','sys_express_url','rejection','the_label_type_tiqu','order_exception_type',
          'os_order_filelist','customsClearanceResu','delivery_operator'
      ],
      isUsePost: true,
      queryForm: {

      },
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "delivery",
          label: "扣放状态",
        },
        {
          prop: "eta",
          label: "ETA",
        },
        {
          prop: "pickedTimeStr",
          label: "提柜时间",
        },
        {
          prop: "reverseNo",
          label: "预约号",
        },
        {
          prop: "returnedTimeStr",
          label: "预约时间",
        },
        {
          prop: "deliveryTimeStr",
          label: "派送时间",
        },
        {
          prop: "podUploadTimeStr",
          label: "POD时间",
        },
        {
          prop: "dismantleAgent",
          label: "拆柜代理",
          minWidth: 100
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "week",
          label: "周期",
          minWidth: 140
        },
        {
          prop: "deliveryAddress1",
          label: "Delivery address1",
        },
        {
          prop: "deliveryAddress2",
          label: "Delivery address2",
          minWidth : 200
        },
        {
          prop: "remark",
          label: "remark",
          minWidth: 150
        },
        {
          prop: "statusTimeStr",
          label: "物流更新时间",
        },
        {
          prop: "statusDesc",
          label: "最新物流轨迹",
          minWidth : 200
        },
        {
          prop: "shipmentIdOrMark",
          label: "SHIP ID/MARK",
        },
        {
          prop: "referenceId",
          label: "Refer,ID",
        },
        {
          prop: "country",
          label: "国家",
        },

        {
          prop: "deliveryCtnStr",
          label: "派送件重体",
        },
        {
          prop: "bookNo",
          label: "SO号",
        },

        {
          prop: "endOperator",
          label: "派送操作",
        },

        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "salesman",
          label: "销售",
        },
      ],
      url: {
        //查询列表的接口
        list: "/operation/delivery/order/page",
        exportUrl: '/operation/delivery/order/export',
        saveForLabeling: "/operation/container/updateDeliveryStatus",
        appointmentDelivery: "/operation/container/appointmentDelivery",
        saveSendTheChange: "/operation/container/updateDeliveryChange",
        orderExceptionFinish:'/operation/container/orderExceptionFinish',
        holdOrderBatch: "/interface/ttOrder/updateTtOrderDelivery1Batch",
        tieReminder: "/file/sendEmail/tieReminder", //特别提醒
        exportXlsUrl:"/file/export/excel/OperationExcel/overNoUpdateExportXls",
        clearanceResult: '/operation/container/clearanceResult'

      },
      formLabelWidth: "150px",
      appointmentType:'one',
      selectRow: {},
      form: {},
      exceptionOrderForm:{
        orderNo:null,
        exceptionType:null,
        remark:null
      },
      appointmentFormVisible: false,
      rules: {
        customsClearanceResults: [
          {required: true, message: '请选择清关结果', trigger: 'change'},
        ],
        detainedReason: [
          {required: true, message: '请选择扣留原因', trigger: 'change'},
        ],
        departmentOfInspection: [
          {required: true, message: '请选择监管部门查验', trigger: 'change'},
        ],
        finalResult: [
          {required: true, message: '请选择最终结果', trigger: 'change'},
        ],
        checkTheType: [
          {required: true, message: "请选择查验类型", trigger: "change"},
        ],
        clearTime: [
          {required: true, message: "请选择清关放行时间", trigger: "change"},
        ],
        checkWeek: [
          {
            required: true,
            message: "请输入国外清关查验周数",
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              if (/^(?:[1-9]\d*|0)$/.test(value) == false) {
                callback(new Error("请输入正整数"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        title: [
          { required: true, message: "请填写标题", trigger: "change" },
          { max: 100, message: "长度不能超过100", trigger: "change" },
        ],
        stopReceiving: [
          {required: true, message: "请选择暂停原因", trigger: "change"},
        ],
        deliveryTime: [
          {required: true, message: "请选择派送完成时间", trigger: "change"},
        ],
        rejection: [
          {required: true, message: "请选择拒收原因", trigger: "change"},
        ],
        changeType: [
          {required: true, message: "请选择派送变更", trigger: "change"},
        ],
        appointmentTime: [
          {required: true, message: "请选择预约时间", trigger: "change"},
        ],
        deliveryType: [
          {required: true, message: "请选择派送方式", trigger: "change"},
        ],
        estimatedOfArrivalTime: [
          {
            required: true,
            message: "请选择预计到达目地时间",
            trigger: "change",
          },
        ],
        // expressId: [
        //     {required: true, message: "请选择快递公司", trigger: "change"},
        // ],
        addressId: [
          {required: true, message: "请选择派送地址", trigger: "change"},
        ],
        logNo: [
          {required: true, message: "请输入跟踪单号", trigger: "change"},
          {validator: checkWords, trigger: 'change'},
        ],
        website: [
          {required: true, message: "请输入查询网址", trigger: "change"},
        ],
        exceptionType:[
          {required: true, message: "请选择订单异常完结类型", trigger: "change"},
        ],
        remark:[
          {required: true, message: "请输入备注", trigger: "change"},

        ],
        deliveryAddress:[
          {required: true, message: "请输入派送地址", trigger: "change"}
        ],

        // reservationNumber:[
        //     {max: 80, message: '长度不能超过80', trigger: 'change'},
        // ],
        // fileId: [{validator:bolFileListValidator, trigger: "change"}],
      },
      fileList: [],
      fileList2: [],
      dialogSendTheChangeFormVisible: false,
      uploadDisable: false, //bol文件是否可上传
      dialogRejectionFormVisible: false,
      isTiQu: false,
      dialogFormVisible : false,
      ExceptionOrderDialogFlag: false,
      tieForm: { fileList: [] },
      dialogTieReminderVisible : false,
      btnDisabled: false,
      saveForm: {checkedFileList : []},
      clearanceDialogFormVisible : false,
    };
  },
  methods : {
     saveClearance() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          this.$store.commit("setFullscreenLoading", true);
          let list = this.saveForm.checkedFileList;
          if (list && list.length>0){
            let orderNoList=[];
             postAction("/order/os/file/saveClearFileList?checkedFileIds="+this.saveForm.checkedFileIds+"&orderNo="+this.saveForm.orderNo+"&type=38",list).then(res=>{

            }).catch()
            this.saveForm.checkedFileIds = list.map(l=>l.id).join(',')
          }
          console.log(this.saveForm)
          postAction(this.url.clearanceResult, this.saveForm)
              .then((res) => {
                this.$store.commit("setFullscreenLoading", false);
                if (res.success) {
                  this.$message.success(res.message);
                  this.clearanceDialogFormVisible = false;
                  this.handleSearch()

                }
              }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          })
        } else {
          return false;
        }
      })
    },
    //批量国外清关结果
    beatHandcustomsClearanceResult(){
      let orderNos = this.selectionRows.map(s => s.orderNo);
      this.saveForm.orderNo = orderNos.join(",");
      this.saveForm.checkedFileList = [];
      this.clearanceDialogFormVisible = true;
    },
    exportNoUpdateTT(fileName) {
      if (!fileName || typeof fileName != 'string') {
        fileName = '导出文件'
      }
      let params = JSON.parse(JSON.stringify(this.queryForm));
      params.isOverNoUpdate = '1'
      console.log('导出参数', params)
      downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("post",this.url.exportXlsUrl,
      params,"未更新订单tt.xls",this);
    },

    //发送特别提醒文件
    sendTieReminderEmail() {
      this.$refs.tieForm.validate((valid) => {
        if (valid) {
          if (
              this.tieForm.fileList &&
              this.tieForm.fileList.length &&
              !this.tieForm.fileType
          ) {
            this.$message.warning("有文件请选择文件类型！");
            return;
          }
          this.tieForm.orderNos = this.selectedRowOrderNos.join(",");
          postAction(this.url.tieReminder, this.tieForm).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogTieReminderVisible = false;
            }
          });
        }
      });
    },
    tieReminder() {
      this.tieForm = {};
      this.tieForm.fileList = [];
      this.$set(this.tieForm,"isSendEmail",'1')
      this.$set(this.tieForm,"isRemind",'0')
      this.dialogTieReminderVisible = true;
    },
    holdOrderBatch() {
      let list = this.selectionRows;
      if (!list || list.length === 0) {
        this.$message.warning("请勾选要hold货的订单");
      }
      let orderNoList = list.map(s=>s.orderNo);

      this.$confirm(
          `确定hold货？该操作将会将订单状态变更为手动扣货！`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          }
      )
          .then(() => {
            postAction(this.url.holdOrderBatch, {orderNoList: orderNoList, isOper: this.bb['暂存仓库'] ? '1' : '0'}).then((res) => {
              this.$message.success(res.message);
              this.handleQuery();
            });
          })
          .catch(() => {});
    },
    orderExceptionFinish(){
      this.$refs['exceptionOrderForm'].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          //新增
          putAction(this.url.orderExceptionFinish, this.exceptionOrderForm)
              .then(res => {
                if (res.success) {
                  this.$message.success("异常完结订单成功");
                  this.ExceptionOrderDialogFlag = false;
                  this.handleQuery();
                } else {
                  this.btnDisabled = true;
                  this.$message.error("异常完结订单失败")
                }
              }).catch(err => {
            this.btnDisabled = true;
          })
          this.fullscreenLoading =false
        }
      })
    },
    handleExceptionOrderRest(){
      this.exceptionOrderForm={
        orderNo: null,
        exceptionType:null,
        remark:null
      }
    },
    exceptionOrderFinish(row,type){
      console.log(this.exceptionOrderForm)
      this.handleExceptionOrderRest()
      console.log(this.exceptionOrderForm)
      this.setForm(row,type)
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.ExceptionOrderDialogFlag = true;
    },
    handleSendTiQu(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.isTiQu = true;
      this.dialogFormVisible = true;
    },
    handleSend(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.isTiQu = false;
      this.dialogFormVisible = true;


    },
    handleRejection(row, type) {
      this.setForm(row, type);
      if (row != null) {
        if (Number(row.orderStatus) >= 99) {
          this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
          return false;
        }
      }
      if (type !== 'one') {
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (Number(this.selectionRows[i].orderStatus) >= 99) {
            this.$message({type: 'warning', message: '不允许对已派送完成的订单进行操作'});
            return false;
          }
        }
      }
      this.fileList = [];
      this.fileList2 = [];
      this.dialogRejectionFormVisible = true;
    },
    saveSendTheChange() {
      this.$refs['changepaisong'].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          if (this.form.labelList && this.form.labelList.length > 0) {
            this.form.labelList.forEach(l => {
              if (this.fileList && this.fileList.length > 0) {
                l.bolId = this.fileList[0].id;
                l.bolUrl = this.fileList[0].url;

              } else if (this.fileList2 && this.fileList2.length > 0) {
                l.bolId = this.fileList2[0].id;
                l.bolUrl = this.fileList2[0].url;
              }
            })
          }
          //调用接口 上传到对应的文件集合里面去
          this.syncOsOrderFile(this.form);
          putAction(this.url.saveSendTheChange, this.form)
              .then((res) => {
                this.fullscreenLoading = false;
                // console.log(res);
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogSendTheChangeFormVisible = false;
                  this.handleQuery();
                }
              })
        }
      })
    },
    handleDeliveryChange(row, type) {
      this.setForm(row, type);

      if (type !== 'one') {
        this.$set(this.form, 'addressId', '');
      }
      // console.log(this.form)
      this.fileList = [];
      this.fileList2 = [];
      this.dialogSendTheChangeFormVisible = true;

    },
    //同步文件到 osorderfile
    syncOsOrderFile(obj) {
      let list = [];
      let url = "";
      let ossId = "";
      let size = "";
      let ext = "";
      let name = "";
      if (this.fileList && this.fileList.length > 0) {
        url = this.fileList[0].url;
        ossId = this.fileList[0].id;
        size = this.fileList[0].size;
        ext = this.fileList[0].ext;
        name = this.fileList[0].name;
      }
      if (this.fileList2 && this.fileList2.length > 0) {
        url = this.fileList2[0].url;
        ossId = this.fileList2[0].id;
        size = this.fileList2[0].size;
        ext = this.fileList2[0].ext;
        name = this.fileList2[0].name;
      }
      if (url && ossId) {
        if (obj && obj.labelList && obj.labelList.length > 0) {
          obj.labelList.forEach(l => {
            if (this.fileList && this.fileList.length > 0) {
              for (var i = 0; i < this.fileList.length; i++) {
                let aa = this.fileList[i];
                if (aa) {
                  url = aa.url;
                  ossId = aa.id;
                  size = aa.size;
                  ext = aa.ext;
                  name = aa.name;
                  list.push({orderNo: l.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
                }
              }
            }
            if (this.fileList2 && this.fileList2.length > 0) {
              for (var i = 0; i < this.fileList2.length; i++) {
                let aa = this.fileList2[i];
                if (aa) {
                  url = aa.url;
                  ossId = aa.id;
                  size = aa.size;
                  ext = aa.ext;
                  name = aa.name;
                  list.push({orderNo: l.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
                }
              }
            }
          })
        } else {
          if (this.fileList && this.fileList.length > 0) {
            for (var i = 0; i < this.fileList.length; i++) {
              let aa = this.fileList[i];
              if (aa) {
                url = aa.url;
                ossId = aa.id;
                size = aa.size;
                ext = aa.ext;
                name = aa.name;
                list.push({orderNo: obj.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
              }
            }
          } else if (this.fileList2 && this.fileList2.length > 0) {
            for (var i = 0; i < this.fileList2.length; i++) {
              let aa = this.fileList2[i];
              if (aa) {
                url = aa.url;
                ossId = aa.id;
                size = aa.size;
                ext = aa.ext;
                name = aa.name;
                list.push({orderNo: obj.orderNo, type: '55', url, ossId, size, ext, name, fileName: name})
              }
            }
          }

        }
        if (list && list.length > 0) {
          postAction('/order/os/file/saveFileList', list).then(res => {

          })
        }

      }

    },
    handleSendComand(row, type, actionName,formName) {
      var r = /^[1-9]\d{0,10}$/;　　//正整数
      console.log(r.test(row.partCtn))
      if((row.partCtn  && row.partCtn != '' && row.partCtn !='' && !r.test(row.partCtn))){
        this.$message.error("派送数量必须为正整数")
        return;
      }
      let form = JSON.parse(JSON.stringify(row));
      form.type = type;
      form.actionType = "1";
      form.actionName = actionName;
      form.page = 'ForHandOut'
      if (this.fileList && this.fileList.length > 0) {
        form.bolId = this.fileList[0].id;
        form.bolUrl = this.fileList[0].url;
      } else if (this.fileList2 && this.fileList2.length > 0) {
        form.bolId = this.fileList2[0].id;
        form.bolUrl = this.fileList2[0].url;
      }
      // console.log(form);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          // console.log(this.ArrivalAtPortFrom)
          if (form.labelList && form.labelList.length > 0) {
            form.labelList.forEach(l => {
              if (this.fileList && this.fileList.length > 0) {
                l.bolId = this.fileList[0].id;
                l.bolUrl = this.fileList[0].url;
              } else if (this.fileList2 && this.fileList2.length > 0) {
                l.bolId = this.fileList2[0].id;
                l.bolUrl = this.fileList2[0].url;
              }
            })
          }
          this.syncOsOrderFile(form);
          let url = this.url.saveForLabeling

          putAction(url, form)
              .then((res) => {
                this.fullscreenLoading = false;
                // console.log(res);
                if (res.success) {
                  this.$message.success("操作成功");
                  this.dialogFormVisible = false;
                  this.appointmentFormVisible = false;
                  this.dialogStopFormVisible = false;
                  this.dialogRejectionFormVisible = false;
                  this.form = {};
                  this.fileList = [];
                  this.fileList2 = [];
                  this.handleQuery();
                }
              })
              .catch((err) => {
                this.fullscreenLoading = false;
              });
        } else {
          return false;
        }
      })

    },
    async save(type) {
      let messagetype = "";
      let bb = true;
      let formName = "form";
      if (type == "appointemt") {
        formName = "yuyue";
        this.form.status = "0";
        if (this.form.shipmentType == "0") {
          messagetype = "40";
        } else {
          messagetype = "41";
        }
      } else if (type == "delivery") {
        formName = "paisong";
        //派送成功 判断 是否包含 已经扣货的订单
        if (this.selectionRows && this.selectionRows.length > 0) {
          let isAllDelete = true;
          let warningMsg = '';
          let indexList = [];
          this.selectionRows.forEach(s => {

            let delivery = s.delivery;
            if (delivery === '1') {
              warningMsg = '存在已经扣货的订单不允许派送';
              isAllDelete = false;
              return false;
            }
          })
          if (!isAllDelete) {
            this.$message.warning(warningMsg);
            return
          }
        } else {
          let warningMsg = '存在已经扣货的订单不允许派送';
          if (this.form && this.form.delivery === '1') {
            this.$message.warning(warningMsg);
            return
          }
        }
        this.form.status = "1";
        if (this.form.deliveryType == "1" || this.form.deliveryType == "3") {
          if (this.form.shipmentType == "0") {
            messagetype = "45";
          } else {
            messagetype = "46";
          }
        } else if (this.form.deliveryType == "2") {
          messagetype = "43";
        } else if (this.form.deliveryType == "4") {
          messagetype = "44";
        } else if (this.form.deliveryType == "5") {
          messagetype = "42";
        } else if (this.form.deliveryType == "6") {
          if (this.isTiQu) {
            messagetype = "126";
          } else {
            messagetype = "53";
          }
        } else if (this.form.deliveryType == "7") {
          if (this.isTiQu) {
            messagetype = "127";
          } else {
            messagetype = "52";
          }
        } else if (this.form.deliveryType == "8") { //客户自提
          messagetype = "128";
        } else if (this.form.deliveryType == "2222") { //客户自提
          messagetype = "2222";
        }

      } else if (type == "rejection") {
        formName = "jushou";
        //拒收点击确定 跳出弹框
        await this.$confirm(`确定拒收吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }).then(() => {
          this.form.status = "2";
          messagetype = "54";
          bb = true;
        }).catch(() => {
          bb = false;
        })

      } else if (type == "stop") {
        this.form.status = "3";
        messagetype = "55";
        if (this.form.stopReceiving == "1") {
          messagetype = "56";
        } else {
          messagetype = "55";
        }
      }
      if (bb) {
        if (this.fileList && this.fileList.length > 0) {
          this.form.bolId = this.fileList[0].id;
          this.form.bolUrl = this.fileList[0].url;
        } else if (this.fileList2 && this.fileList2.length > 0) {
          this.form.bolId = this.fileList2[0].id;
          this.form.bolUrl = this.fileList2[0].url;
        }
        this.handleSendComand(this.form, messagetype, type,formName);
      }

    },
    setExpressIdUrl() {
      this.form.expressIdUrl = this.form.expressId;
      // console.log(this.form);
      // this.$forceUpdate()
    },
    setForm(row, type) {
      let that = this;
      if (row != null) {
        this.selectRow = JSON.parse(JSON.stringify(row));
        this.form = JSON.parse(JSON.stringify(row));

      }

      if (type == "one") {
        this.selectRow = JSON.parse(JSON.stringify(row));
        this.form = JSON.parse(JSON.stringify(row));
      } else {
        let orderNos = "";
        let emails = "";
        for (let i = 0; i < this.selectionRows.length; i++) {
          // console.log(this.selectionRows[i]);
          orderNos += this.selectionRows[i].orderNo + ",";
          emails += this.selectionRows[i].email + ",";
          if (this.fileList && this.fileList.length > 0) {
            this.selectionRows[i].bolId = this.fileList[0].id
            this.selectionRows[i].bolUrl = this.fileList[0].url
          }
          if (this.fileList2 && this.fileList2.length > 0) {
            this.selectionRows[i].bolId = this.fileList2[0].id
            this.selectionRows[i].bolUrl = this.fileList2[0].url
          }

        }
        this.form.orderNos = orderNos.substring(0, orderNos.length - 1);
        this.exceptionOrderForm.orderNo =orderNos.substring(0,orderNos.length - 1)
        this.form.emails = emails.substring(0, emails.length - 1);
        this.form.labelList = this.selectionRows;
        this.form.changeTypeType = 'batch'
        this.form.shipmentType = this.selectionRows[0].shipmentType;
        this.$set(this.form, 'addressId', this.selectionRows[0].addressId);
      }
    },
    handleAppointment(row, type) {
      this.appointmentType = 'batch'
      this.setForm(row, type);
      this.fileList = [];
      this.fileList2 = [];
      this.appointmentFormVisible = true;
    },
  },
  computed: {
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('operation/DispatchOrder','0','导出报表'),
        'plyy':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量预约'),
        'plpsbg':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量派送变更'),
        'plgs':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量拒收'),
        'plpscg':this.$btnIsShow('operation/cabinet/WaitForHandOut','1','批量派送成功'),
        'pltqcg':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量提取成功'),
        'ycwjdd':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','异常完结订单'),
        'plhh':this.$btnIsShow('docpage/AllOrderModule','0','批量hold货'),
        'zcck':this.$btnIsShow('docpage/AllOrderModule','1','暂存仓库'),
        'tbtx':this.$btnIsShow('docpage/AllOrderModule', '0', '特别提醒'),
        'pod':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量上传POD'),
        'tt':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','未更新订单TT'),
        'plgwqg':this.$btnIsShow('operation/cabinet/WaitingClearance','0','批量国外清关结果'),
      }
    },
    bb() {
      let ss = localStorage.getItem("USERINFO");
      var user = JSON.parse(ss);
      let staging = this.buttonShowList.zcck;
      // 排除理员、总经理、admin的权限
      if(user && user.roleName) {
        ['管理员', '总经理'].indexOf(user.roleName) !== -1 ? staging = false : '';
      }
      if(user && user.username) {
        ['admin'].indexOf(user.username) !== -1 ? staging = false : '';
      }
      return {
        '暂存仓库': staging
      }
    },
  },
  created() {
    // 赋值默认登录人

    this.$set(this.queryForm, 'endOperatorId', this.getLoginUserId());
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
