
<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :span="5">
            <el-form-item label="周数">
              <el-input
                v-model="queryForm.week"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="柜号">
              <el-input
                v-model="queryForm.containerNo"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :span="8">
            <el-form-item label="订阅时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="是否超过7天未更新">
              <f-select
                :is-need="queryForm.isOver7DaysNoUpdate"
                v-model="queryForm.isOver7DaysNoUpdate"
                placeholder="请选择"
                filterable
                clearable
                :dict="'is_default'"
              >
              </f-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="流程是否结束">
              <f-select
                :is-need="queryForm.isCompleted"
                v-model="queryForm.isCompleted"
                placeholder="请选择"
                filterable
                clearable
                :dict="'is_default'"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        超过7天未更新轨迹信息柜子数:{{ over7DaysNoUpdateContainerCount }}
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="getStatusList(scope.row)"
              >柜子状态</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'isSubscribed'">
            <span>{{ parseDict2(scope.row.isSubscribed, "is_default") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isCompleted'">
            <span>{{ scope.row.isCompleted == "N" ? "否" : "是" }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog title="柜子状态" :visible.sync="visible">
      <div>
        <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="dialogColumns"
          :data="dialogTableData"
          :cell-style="{ 'text-align': 'center' }"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'isEst'">
              <span>{{ scope.row.isEst == "N" ? "否" : "是" }}</span>
            </template>
            <template v-else-if="scope.column.property === 'source'">
              <span>{{ parseDict2(scope.row.source, "yxd_source_code") }}</span>
            </template>
            <template v-else-if="scope.column.property === 'statusCd'">
              <span>{{ parseDict2(scope.row.statusCd, "yxd_status_cd") }}</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
            v-bind="dialogIpagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChangeDialog"
            @current-change="handleCurrentChangeDialog"
          >
          </el-pagination>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "YxdContainerSubscribeLog",
  mixins: [QueryMixins],
  data() {
    return {
      visible: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: "left",
        },
        {
          prop: "action",
          label: "操作",
          width: 140,
          fixed: "left",
        },
        {
          prop: "week",
          label: "周数",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "createTime",
          label: "订阅时间",
        },
        {
          prop: "createBy",
          label: "订阅人",
        },
        {
          prop: "etd",
          label: "etd",
        },
        {
          prop: "atd",
          label: "atd",
        },
        {
          prop: "eta",
          label: "eta",
        },
        {
          prop: "ata",
          label: "ata",
        },
        {
          prop: "isSubscribed",
          label: "是否成功订阅",
        },
        {
          prop: "isCompleted",
          label: "流程是否结束",
        },
      ],
      url: {
        list: "/operation/yxd/subscribeList",
        statusList: "/operation/yxd/statusList",
      },
      needQueryDictCodes: ["is_default", "yxd_status_cd", "yxd_source_code"],
      dialogTableData: [],
      dialogColumns: [
        {
          prop: "statusCd",
          label: "状态代码",
        },
        {
          prop: "statusTime",
          label: "状态时间",
        },
        {
          prop: "statusPlace",
          label: "当前节点地点",
        },

        {
          prop: "statusDescription",
          label: "当前状态描述",
        },
        {
          prop: "statusPlaceCode",
          label: "当前节点对应五字码",
        },
        {
          prop: "vslName",
          label: "船名称",
        },
        {
          prop: "voy",
          label: "航次",
        },
        {
          prop: "isEst",
          label: "是否预期",
        },
        {
          prop: "source",
          label: "来源",
        },
      ],
      dialogIpagination: {
        currentPage: 1,
        pageSize: 50,
        pageSizes: [50, 100, 150, 200],
        total: 0,
      },
      statusId: "",
      over7DaysNoUpdateContainerCount: 0,
    };
  },
  methods: {
    handleSizeChangeDialog(val) {
      this.dialogIpagination.currentPage = 1;
      this.dialogIpagination.pageSize = val;
      this.getStatusList();
    },
    handleCurrentChangeDialog(val) {
      this.dialogIpagination.currentPage = val;
      this.getStatusList();
    },
    handleQuery() {
      this.tableData = [];
      this.loading = true;
      let form = { ...this.queryForm, ...this.filterForm, ...this.initData };
      //清空字符串的前后空格
      this.trimParamsStr(form);
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else if (this.enabledCreatetime) {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
        //排序
        //提货中查询
        if (this.queryForm.column === "sort") {
          this.$set(params, "column", "sort");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      postAction(this.url.list, params)
        .then((res) => {
          if (res.success) {
            this.$refs.GTable &&
              this.$refs.GTable.handleScrollTop &&
              this.$refs.GTable.handleScrollTop();
            if (res.result) {
              this.tableData = res.result.list.records;
              this.ipagination.total = res.result.list.total;
              this.over7DaysNoUpdateContainerCount =
                res.result.over7DaysNoUpdateContainerCount || 0;
            }
          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1
            ? this.initMoreFuc()
            : ""; //用于初始的时候调用其他的方法
        })
        .catch((err) => {
          this.loading = false;
          // this.initTableData();
        });
    },
    getStatusList(row) {
      if (row && row.id) {
        this.statusId = row.id;
      }
      this.$store.commit("setFullscreenLoading", true);
      postAction(this.url.statusList, {
        id: this.statusId,
        ...this.dialogIpagination,
      })
        .then((res) => {
          this.dialogTableData = res.result.records;
          if (!this.visible) {
            this.visible = true;
          }
          this.dialogIpagination.total = res.result.total;
          this.$store.commit("setFullscreenLoading", false);
        })
        .catch((err) => {
          this.dialogTableData = [];
          this.$store.commit("setFullscreenLoading", false);
        });
    },
  },
  created() {},
  computed: {
    buttonShowList() {
      return {
        sh: this.$btnIsShow("supplier/FollowUp", "1", "审核"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.input_top .el-form-item {
  margin-bottom: 2px;
}
</style>
