<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      label-width="80px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item label="客户名">
              <el-input v-model="queryForm.leadsCustomer"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="联系电话">
              <el-input v-model="queryForm.leadsPhone"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="邮箱">
              <el-input v-model="queryForm.leadsEmail"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="线索来源">
              <f-select
                v-model="queryForm.cusSource"
                :dict="'cus_source'"
                :is-need="queryForm.cusSource"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户地址">
              <el-input v-model="queryForm.companyAddress"> </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="联系人">
              <el-input v-model="queryForm.leadsContacts"> </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="微信号">
              <el-input v-model="queryForm.wechatNumber"> </el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="4">
            <el-form-item label="公司名">
              <el-input v-model="queryForm.leadsCompanyName"> </el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="4">
            <el-form-item label="客户需求">
              <f-select
                v-model="queryForm.cusDemandList"
                :dict="'cus_demand'"
                :is-need="queryForm.cusDemandList"
                multiple
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="分配人">
              <f-select
                v-model="queryForm.allotUserId"
                :dict="'sys_user_id'"
                :is-need="queryForm.allotUserId"
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="跟进人">
              <f-select
                v-model="queryForm.followUpUserId"
                :dict="'sys_user_id'"
                :is-need="queryForm.followUpUserId"
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="线索状态">
              <f-select
                v-model="queryForm.leadsStatusList"
                :dict="'marketing_lead_status'"
                :is-need="queryForm.leadsStatusList"
                multiple
              >
              </f-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="录入人">
              <f-select
                v-model="queryForm.enterUserId"
                :dict="'sys_user_id'"
                :is-need="queryForm.enterUserId"
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="录入时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.enterTimeS"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  :picker-options="editStartOptions"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.enterTimeE"
                  type="datetime"
                  size="small"
                  :picker-options="editEndOptions"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="分配时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.allotTimeS"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  :picker-options="editStartOptions"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.allotTimeE"
                  type="datetime"
                  size="small"
                  :picker-options="editEndOptions"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"
          v-if="buttonShowList['新增']"
          >新增
        </el-button>
        <el-button
          :disabled="selectionRows.length === 0"
          type="primary"
          size="medium"
          @click="handleDel()"
          v-if="buttonShowList['批量删除']"
          >批量删除
        </el-button>
        <el-button
          :disabled="selectionRows.length === 0"
          type="primary"
          size="medium"
          @click="importCustomer()"
          v-if="buttonShowList['批量导入公海']"
          >批量导入公海
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="downloadModel(url.downloadLeadsModel, '营销线索模版')"
          v-if="buttonShowList['营销线索模版']"
          >模版下载
        </el-button>

        <el-button
          type="primary"
          size="medium"
          @click="batchInvalidLeads"
          v-if="buttonShowList['批量无效线索']"
          :disabled="!selectionRows.length"
          >批量无效线索
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="batchApprovalLeads"
            v-if="buttonShowList['批量审核']"
            :disabled="!selectionRows.length"
        >批量审核
        </el-button>
        <el-upload
          class="upload-demo margin_right_10"
          action=""
          :http-request="importOperation"
          :show-file-list="false"
          :before-upload="beforeUpload"
          accept=".xls,.xlsx"
          style="margin-left: 10px"
        >
          <el-button type="primary" size="medium">导入 </el-button>
        </el-upload>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="60vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">

          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList['编辑']"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleDel(scope.row.id)"
              v-if="buttonShowList['删除']"
              >删除
            </el-button>

            <el-button
              type="text"
              @click="handleAllocation(scope.row)"
              v-if="buttonShowList['分配']"
              >分配
            </el-button>

            <el-button
              type="text"
              @click="handleFeedback(scope.row)"
              v-if="buttonShowList['反馈']"
              >反馈
            </el-button>

            <el-button
              type="text"
              @click="getLog(scope.row.id)"
              v-if="buttonShowList['日志']"
              >日志
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'leadsStatus'">
            <span>{{
              parseDict(scope.row.leadsStatus, "marketing_lead_status")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'cusSource'">
            <span>{{ parseDict(scope.row.cusSource, "cus_source") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isApproval'">
            <span v-if="scope.row.isApproval ==='0'">无需审核</span>
            <span v-else-if="scope.row.isApproval ==='1'">待审核</span>
            <span v-else-if="scope.row.isApproval ==='2'">已审核</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <!-- 新增编辑营销线索 -->
    <edit-marking-leads-dialog
      :visible.sync="addVisible"
      :row="row"
      @ok="handleSearch"
    ></edit-marking-leads-dialog>

    <!-- 分配 -->
    <el-dialog
      title="分配线索"
      :visible="visible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="80px"
        size="medium"
      >
        <el-form-item label="跟进人" prop="followUpUserId">
          <f-select
            v-model="form.followUpUserId"
            :dict="'sys_user_id'"
            :is-need="form.followUpUserId"
          >
          </f-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 反馈 -->
    <el-dialog
      title="反馈"
      :visible="feedbackVisible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="feedbackClose"
    >
      <el-form
        :model="feedbackFrom"
        :rules="feedbackRules"
        ref="feedbackFrom"
        label-width="80px"
        size="medium"
      >
        <el-row>
          <el-col>
            <el-form-item prop="leadsCustomer" label="客户名">
              <el-input
                v-model="feedbackFrom.leadsCustomer"
                :is-need="feedbackFrom.leadsCustomer"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop="leadsContacts" label="联系人">
              <el-input
                v-model="feedbackFrom.leadsContacts"
                :is-need="feedbackFrom.leadsContacts"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col>
            <el-form-item label="公司名" prop="leadsCompanyName">
              <el-input v-model="feedbackFrom.leadsCompanyName"> </el-input>
            </el-form-item>
          </el-col> -->

          <el-col>
            <el-form-item label="微信号" prop="wechatNumber">
              <el-input v-model="feedbackFrom.wechatNumber"> </el-input>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="联系电话" prop="leadsPhone">
              <el-input v-model="feedbackFrom.leadsPhone"> </el-input>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item prop="leadsEmail" label="邮箱">
              <el-input
                v-model="feedbackFrom.leadsEmail"
                maxlength="50"
                show-word-limit
                style="padding: 0 45px 0 0"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="客户地址" prop="companyAddress">
              <el-input v-model="feedbackFrom.companyAddress"> </el-input>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="客户需求" prop="cusDemandList">
              <f-select
                v-model="feedbackFrom.cusDemandList"
                :dict="'cus_demand'"
                :is-need="feedbackFrom.cusDemandList"
                multiple
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col
            v-for="(item, index) in feedbackFrom.feedBackList"
            :key="index"
            style="
              margin-bottom: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-input
              type="textarea"
              :rows="2"
              :placeholder="`请填写第${index + 1}次跟进情况`"
              v-model="item.feedbackContent"
              style="width: 80%"
              show-word-limit
              maxlength="500"
            >
            </el-input>

            <el-button type="text" size="small" @click="handleAdd">
              增加
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="handleDelete(item.id, index)"
              v-if="index !== 0"
            >
              删除
            </el-button>
          </el-col>
          <el-col style="display: flex; justify-content: end">
            <el-checkbox
              v-model="feedbackFrom.flagInvalidLeads"
              false-label="0"
              true-label="1"
              >线索无效</el-checkbox
            >
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="feedbackClose">取消</el-button>
        <el-button type="primary" size="medium" @click="feedbackSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 日志 -->
    <el-dialog
      title="日志"
      :visible="logVisible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="logClose"
    >
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columnsLog"
        :data="logList"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="50vh"
      ></f-table>
      <span slot="footer">
        <el-button size="medium" @click="logClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditMarkingLeadsDialog from "@/views/forestMarket/components/EditMarkingLeadsDialog";
import { getAction } from "../../api/manage";

export default {
  name: "MarketingLeads",
  mixins: [QueryMixins],
  components: {
    EditMarkingLeadsDialog,
  },
  data() {
    return {
      dictCodes: ["cus_source", "marketing_lead_status"],
      day32: 2764800000,
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeE) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return !(
              new Date(this.queryForm.createTimeE).getTime() > time.getTime() &&
              time.getTime() >
                new Date(this.queryForm.createTimeE).getTime() - this.day32
            );
          }
        },
      },
      editEndOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeS) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return !(
              new Date(this.queryForm.createTimeS).getTime() + this.day32 >
                time.getTime() &&
              time.getTime() > new Date(this.queryForm.createTimeS).getTime()
            );
          }
        },
      },
      loading: false,
      addVisible: false,
      authVisible: false,
      row: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 200,
        },
        {
          prop: "leadsCustomer",
          label: "客户名",
        },
        {
          prop: "leadsStatus",
          label: "线索状态",
        },
        // {
        //   prop: "leadsCompanyName",
        //   label: "公司名",
        // },
        {
          prop: "leadsContacts",
          label: "联系人",
        },
        {
          prop: "leadsPhone",
          label: "联系电话",
        },
        {
          prop: "leadsEmail",
          label: "邮箱",
        },
        {
          prop: "wechatNumber",
          label: "微信号",
        },
        {
          prop: "cusSource",
          label: "线索来源",
        },
        {
          prop: "cusDemand",
          label: "客户需求",
        },
        {
          prop: "companyAddress",
          label: "客户地址",
        },
        {
          prop: "enterTime",
          label: "录入时间",
        },
        {
          prop: "allotTime",
          label: "分配时间",
        },
        {
          prop: "followUpUserName",
          label: "跟进人",
        },
        {
          prop: "enterUserName",
          label: "录入人",
        },
        {
          prop: "allotUserName",
          label: "分配人",
        },
        {
          prop: "feedbackContent",
          label: "反馈备注",
        },
        {
          prop: "isApproval",
          label: "审核状态",
        },
      ],
      columnsLog: [
        {
          prop: "createTime",
          label: "操作时间",
        },
        {
          prop: "createBy",
          label: "操作人员",
        },
        {
          prop: "logType",
          label: "操作类型",
        },
        {
          prop: "logContent",
          label: "内容",
        },
      ],
      url: {
        list: "/sys/bdMarketingLeads/list",
        delete: "/sys/bdMarketingLeads/delete",
        deleteBatch: "/sys/bdMarketingLeads/delete",
        importCustomer: "/sys/bdMarketingLeads/importCustomer",
        downloadLeadsModel: "/file/read/excel/downloadLeadsModel",
        distribution: "/sys/bdMarketingLeads/distribution",
        feedback: "/sys/bdMarketingLeads/feedback",
        logList: "/sys/bdMarketingLeads/logList",
        importBdMarketingLeads: "/file/read/excel/importBdMarketingLeads",
        feedbackList: "/sys/bdMarketingLeads/feedbackList",
        delFeedBack: "/sys/bdMarketingLeads/delFeedBack",
        batchInvalidLeads: "/sys/bdMarketingLeads/batchInvalidLeads",
        batchApprovalLeads: "/sys/bdMarketingLeads/batchApprovalLeads",
      },
      isUsePost: true,
      visible: false,
      form: {},
      rules: {
        followUpUserId: [
          { required: true, message: "请选择跟进人", trigger: "change" },
        ],
      },
      feedbackVisible: false,
      feedbackFrom: {
        feedBackList: [
          {
            feedbackContent: "",
          },
        ],
      },
      feedbackRules: {
        cusDemand: [
          { required: true, message: "请选择客户需求", trigger: "change" },
        ],
        leadsStatus: [
          { required: true, message: "请选择线索状态", trigger: "change" },
        ],
        cusSource: [
          { required: true, message: "请选择线索来源", trigger: "change" },
        ],
      },
      logVisible: false,
      logList: [],
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importSuccessCase, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.handleQuery();
        }
      });
    },
    handleDel(id) {
      this.$confirm("确定删除该营销线索?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          if (!id) id = this.selectedRowKeys.join(",");
          deleteAction(this.url.delete, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    importCustomer() {
      this.$confirm("确定导入到公海客户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.importCustomer, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleEdit(row) {
      this.row = row;
      this.addVisible = true;
    },
    handleAllocation(row) {
      this.form = { ...row };
      this.visible = true;
    },
    handleFeedback(row) {
      postAction(this.url.feedbackList, {
        id: row.id,
      }).then((res) => {
        this.feedbackFrom = {
          ...row,
          feedBackList:
            res.result && res.result.length
              ? res.result
              : [
                  {
                    feedbackContent: "",
                  },
                ],
        };
        this.feedbackVisible = true;
      });
    },
    handleClose() {
      this.visible = false;
      this.form = {};
    },
    feedbackClose() {
      this.feedbackFrom = {
        feedBackList: [
          {
            feedbackContent: "",
          },
        ],
      };
      this.feedbackVisible = false;
    },
    feedbackSubmit() {
      this.$refs.feedbackFrom.validate((valid) => {
        if (valid) {
          this.$store.commit("setFullscreenLoading", true);
          postAction(this.url.feedback, this.feedbackFrom)
            .then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message({
                type: "success",
                message: res.message,
              });
              this.feedbackClose();
              this.handleQuery();
            })
            .catch((err) => {
              this.$store.commit("setFullscreenLoading", false);
            });
        }
      });
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.commit("setFullscreenLoading", true);
          postAction(this.url.distribution, this.form)
            .then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleClose();
              this.handleQuery();
            })
            .catch((err) => {
              this.$store.commit("setFullscreenLoading", false);
            });
        }
      });
    },
    handleDelete(id, index) {
      if (id) {
        this.$store.commit("setFullscreenLoading", true);
        postAction(this.url.delFeedBack, { feedbackId: id })
          .then((res) => {
            this.$store.commit("setFullscreenLoading", false);
            this.$message.success(res.message);
            postAction(this.url.feedbackList, {
              id: this.feedbackFrom.id,
            }).then((res) => {
              this.feedbackFrom.feedBackList =
                res.result && res.result.length
                  ? res.result
                  : [{ feedbackContent: "" }];
            });
          })
          .catch((err) => {
            this.$store.commit("setFullscreenLoading", false);
          });
      } else {
        this.feedbackFrom.feedBackList.splice(index, 1);
      }
    },
    handleAdd() {
      this.feedbackFrom.feedBackList.push({
        feedbackContent: "",
      });
    },
    logClose() {
      this.logVisible = false;
    },
    getLog(id) {
      this.$store.commit("setFullscreenLoading", true);
      postAction(this.url.logList, { id: id })
        .then((res) => {
          this.logList = res.result.records || [];
          this.$store.commit("setFullscreenLoading", false);
          this.logVisible = true;
        })
        .catch((err) => {
          this.$store.commit("setFullscreenLoading", false);
        });
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isLt2M && isFile;
    },
    importOperation(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      postAction(this.url.importBdMarketingLeads, formData).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        }
      });
    },
    batchInvalidLeads() {
      postAction(this.url.batchInvalidLeads, {
        idList: this.selectedRowKeys,
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    batchApprovalLeads() {
      postAction(this.url.batchApprovalLeads, {
        idList: this.selectedRowKeys,
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
  },
  computed: {
    buttonShowList() {
      return {
        新增: this.$btnIsShow("forestMarket/MarketingLeads", "0", "新增"),
        批量删除: this.$btnIsShow(
          "forestMarket/MarketingLeads",
          "1",
          "批量删除"
        ),
        批量导入公海: this.$btnIsShow(
          "forestMarket/MarketingLeads",
          "0",
          "批量导入公海"
        ),
        编辑: this.$btnIsShow("forestMarket/MarketingLeads", "0", "编辑"),
        删除: this.$btnIsShow("forestMarket/MarketingLeads", "0", "删除"),
        模版下载: this.$btnIsShow(
          "forestMarket/MarketingLeads",
          "1",
          "模版下载"
        ),
        导入: this.$btnIsShow("forestMarket/MarketingLeads", "1", "导入"),
        分配: this.$btnIsShow("forestMarket/MarketingLeads", "0", "分配"),
        反馈: this.$btnIsShow("forestMarket/MarketingLeads", "0", "反馈"),
        日志: this.$btnIsShow("forestMarket/MarketingLeads", "0", "日志"),
        营销线索模版: this.$btnIsShow(
          "forestMarket/MarketingLeads",
          "0",
          "营销线索模版"
        ),
        批量无效线索: this.$btnIsShow(
          "forestMarket/MarketingLeads",
          "0",
          "批量无效线索"
        ),
        批量审核: this.$btnIsShow("forestMarket/MarketingLeads", "0", "批量审核"),
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
