<template>
    <div class="main-full-content">
        <preview-img :visible="imgVisible" :imgUrl="imgUrl" @setImgVisible="(val) => { imgVisible = val }"></preview-img>
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="申请编号">
                            <el-input type="textarea" v-model="queryForm.applyNo" @keyup.enter.native="handleCheck" placeholder="用逗号或者空格分割"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="结算对象">
                            <el-input v-model="queryForm.settleName" @keyup.enter.native="handleCheck" placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="申请人">
<!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                        v-model="queryForm.creator"
                                        :isNeed="queryForm.creator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="客户端申请人" label-width="120px">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'client_laoliu_kehu'"
                                      v-model="queryForm.cusCreator"
                                      :isNeed="queryForm.cusCreator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="查账状态">
                            <f-select
                                    class="full_width"
                                    v-model="queryForm.checkStatus"
                                    :dict="'receive_check_status'"
                                    :father-val="queryForm.checkStatus"
                                    :is-need="queryForm.checkStatus"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>

                    <!--<el-col :xs="24" :sm="12" :md="12" :lg="5">-->
                    <!--<el-form-item label="公司名称">-->
                    <!--<el-input v-model="queryForm.company" @keyup.enter.native="handleCheck"></el-input>-->
                    <!--</el-form-item>-->
                    <!--</el-col>-->
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="应收币种">
                            <!--                            <el-input v-model="queryForm.payCur"></el-input>-->
                            <f-select
                                    v-model="queryForm.payCur"
                                    :isNeed="queryForm.payCur"
                                    dict="bd_cur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="实收币种">
                            <!--                            <el-input v-model="queryForm.realCur"></el-input>-->
                            <f-select
                                    v-model="queryForm.realCur"
                                    :isNeed="queryForm.realCur"
                                    dict="bd_cur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="申请时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="stime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :default-time="['00:00:00', '23:59:59']"
                                    :picker-options="editStartOptions"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>

                  <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="业务类型">
                      <el-select v-model="queryForm.agentPayType">
                        <el-option value="0" label="海运"></el-option>
                        <el-option value="2" label="快递"></el-option>
<!--                        <el-option value="4" label="海外仓"></el-option>-->
                      </el-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="收款银行">
                        <f-select :dict="'receive_bank'"
                                  v-model="queryForm.receiveBankId"
                                  :isNeed="queryForm.receiveBankId">
                        </f-select>
                    </el-form-item>
                </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="业务员">
                      <f-select
                          :dict="'sys_user_id'"
                          v-model="queryForm.salesmanId"
                          :is-need="queryForm.salesmanId"
                          :father-val="queryForm.salesmanId"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="数据时间">
                      <f-select
                          class="textarea_table"
                          v-model="queryForm.dataCreateTime"
                          :is-need="queryForm.dataCreateTime"
                          :dict="'data_create_time'"
                          @keyup.enter.native="handleSearch"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleCheck">
                                查询
                            </el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="downloadWaitCheckExcel"
                           v-if="buttonShowList.dcexcel"
                >导出excel
                </el-button
                >
                <el-button size="medium" type="primary" @click="handleCheckBillBatch"
                           v-if="buttonShowList.plcz"
                >批量查账
                </el-button
                >
                <el-button size="medium" type="primary" @click="handleCheckBillBatchNew"
                           v-if="buttonShowList.plcz"
                >批量查账(新)
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="67vh"
                    :show-operation="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                                type="text"
                                v-if="scope.row.checkStatus === '0' && buttonShowList.cz
                                 || scope.row.checkStatus === '2' && buttonShowList.cz"
                                @click="handleCheckBill(scope.row)"
                        >查账
                        </el-button>
                        <el-divider
                                direction="vertical"
                                v-if="scope.row.checkStatus === '0'  || scope.row.checkStatus === '2'"/>
                        <el-popconfirm
                                title="删除操作将退回至已开账单，是否确认？"
                                confirm-button-text="确认"
                                cancel-button-text="不用了"
                                icon="el-icon-info"
                                icon-color="red"
                                @confirm="handleDelBill(scope.row)">
                            <el-button slot="reference" type="text"
                                       v-if="buttonShowList.sc"
                            > 删除</el-button>
                        </el-popconfirm>
<!--                        <el-divider-->
<!--                                direction="vertical"-->
<!--                                v-if="scope.row.checkStatus === '1'"/>-->
<!--                        <el-popconfirm-->
<!--                                title="是否设为未查账？"-->
<!--                                confirm-button-text="确认"-->
<!--                                cancel-button-text="不用了"-->
<!--                                icon="el-icon-info"-->
<!--                                icon-color="red"-->
<!--                                v-if="scope.row.checkStatus === '1'"-->
<!--                                @confirm="changeToWaitCheck(scope.row)"-->
<!--                        >-->
<!--                            <el-button slot="reference" type="text"-->
<!--                                       v-if="$btnIsShow('hyFinanceManage/modules/receive/waitCheck','1','取消查账')"-->
<!--                            > 取消查账</el-button>-->
<!--                        </el-popconfirm>-->
                        <!--                    <el-button type="text" v-if="scope.row.checkStatus==='1'">取消查账</el-button>-->
                    </template>
                    <template v-else-if="scope.column.property === 'checkStatus'">
                        <span v-if="scope.row.checkStatus === '0'">未查账</span>
                        <span v-if="scope.row.checkStatus === '1'" style="color: #229922">查账正常</span>
                        <span v-if="scope.row.checkStatus === '2'" style="color: red">查账异常</span>
                    </template>
                    <template v-else-if="scope.column.property === 'applyNo'">
                        <el-button v-if="scope.row.checkStatus !== '1'" type="text" @click="showApplyDetail(scope.row)">{{scope.row.applyNo}}</el-button>
                        <span v-if="scope.row.checkStatus === '1'">{{scope.row.applyNo}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'checkUnusualInfo'">
                        <span v-if="scope.row.checkStatus ==='1' && (scope.row.checkUnusualInfo === null || scope.row.checkUnusualInfo ==='')">无异常</span>
<!--                        <span v-if="scope.row.checkUnusualInfo !== '' && scope.row.checkStatus ==='2'">{{scope.row.checkUnusualInfo}}</span>-->
                        <span v-if="scope.row.checkUnusualInfo !== ''">{{scope.row.checkUnusualInfo}}</span>
                        <span v-if="scope.row.checkStatus ==='0'"></span>
                    </template>
                    <template v-else-if="scope.column.property === 'payMoney'">
                        <span v-if="scope.row.payMoney !== scope.row.salesMoney && scope.row.checkStatus !== '1'" style="color: red;font-weight: bold">{{scope.row.payMoney}}</span>
                        <span v-if="scope.row.payMoney === scope.row.salesMoney && scope.row.checkStatus !== '1'">{{scope.row.payMoney}}</span>
                        <span v-if="scope.row.checkStatus === '1'" style="color: green">{{scope.row.payMoney}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'salesMoney'">
                        <span v-if="scope.row.payMoney !== scope.row.salesMoney && scope.row.checkStatus !== '1'" style="color: red;font-weight: bold">{{scope.row.salesMoney}}</span>
                        <span v-if="scope.row.payMoney === scope.row.salesMoney && scope.row.checkStatus !== '1'">{{scope.row.salesMoney}}</span>
                        <span v-if="scope.row.checkStatus === '1'" style="color: green">{{scope.row.salesMoney}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'picUrl'">
                        <span v-for="(item,index) in scope.row.fileList" :key="index">
                            <a class="blue_box_bg" @click.prevent="showImg(item.url,item.ext)" target="_Blank" download>{{item.name}}</a><br>
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
                    </template>

                    <template v-else-if="scope.column.property === 'waitReceive'">
                        <span>
                            {{((scope.row.payMoney - scope.row.payed).toFixed(3))}}
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'creator'">
                        <span>{{parseDict(scope.row.creator,'sys_create_by')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'settleAccount'">
                        <span>{{scope.row.bankName + ' - '+ scope.row.account}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <check-bill-dialog ref="checkBillDialog" @ok="this.handleCheck"></check-bill-dialog>
        <check-bill-dialog-batch ref="checkBillDialogBatch" @ok="this.handleCheck"></check-bill-dialog-batch>
        <check-bill-batch ref="checkBillBatch" @ok="this.handleCheck"></check-bill-batch>
        <receive-apply-update ref="apply" @ok="this.handleCheck"></receive-apply-update>
    </div>
</template>
<script>
    import QueryMixins from "../../../../mixins/QueryMixins";
    import {getAction, postAction} from "@/api/manage";
    import checkBillDialog from "./checkBillDialog";
    import checkBillDialogBatch from "./checkBillDialogBatch";
    import checkBillBatch from "./checkBillBatch";
    import Tempcclate from "../../../isystem/Dict";
    import receiveApplyUpdate from "./receiveApplyUpdate";
    import { setUpRouteUtil } from '@/utils/util';
    import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "../../../../utils/downloadJasper";

    export default {
        mixins: [QueryMixins],
        components: {
            Tempcclate,
            checkBillDialog,
            checkBillDialogBatch,
            checkBillBatch,
            receiveApplyUpdate,
        },
        data() {

            return {
                queryForm:{agentPayType:'0'},
                dictCodes:['sys_create_by'],
                imgVisible:false,
                imgUrl:'',
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos: [],
                loading: false,
                form: {},
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 130,
                    },
                    {
                        prop: "checkStatus",
                        label: "查账状态",
                    },
                    {
                        prop: "checkUnusualInfo",
                        label: "异常原因",
                    },
                    {
                        prop: "checkTime",
                        label: "查账时间",
                    },
                    {
                        prop: "updateTime",
                        label: "更新时间",
                    },
                    {
                        prop: "checkAccountBy",
                        label: "查账人",
                    },
                    {
                        prop: "applyNo",
                        label: "申请编号",
                        width: 130,
                    },
                    {
                        prop: "picUrl",
                        label: "水单图",
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },
                    {
                        prop: "settleName",
                        label: "结算对象",
                    },
                    // {
                    //     prop: "company",
                    //     label: "公司名称",
                    // },
                    {
                        prop: "payMoney",
                        label: "应收金额",
                    },
                    {
                        prop: "payCur",
                        label: "应收币种",
                    },
                    {
                        prop: "billMoney",
                        label: "水单金额",
                    },
                    {
                        prop: "realMoney",
                        label: "账单金额",
                    },
                    {
                        prop: "realCur",
                        label: "账单币种",
                    },
                    {
                        prop: "salesMoney",
                        label: "实际到账金额",
                    },
                    {
                        prop: "salesMoneyCur",
                        label: "实际到账币种",
                    },
                    {
                        prop: "waitReceive",
                        label: "待收余额",
                        headerType:'remark',
                        detail:'提示：待收余额将在销账之后更新',
                        width:100
                    },
                    // {
                    //     prop: "waitPay",
                    //     label: "待收",
                    // },
                    {
                        prop: "creator",
                        label: "申请人",
                    },
                    {
                        prop: "createTime",
                        label: "申请时间",
                    },
                    {
                        prop: "settleAccount",
                        label: "收款银行",
                    },
                    {
                        prop: "payMan",
                        label: "付款人",
                        width: 100,
                    },
                    {
                        prop: "billBankNo",
                        label: "银行流水号",
                        width: 100,
                    },
                    {
                        prop: "billPayerName",
                        label: "水单付款人",
                        width: 100,
                    },
                    {
                        prop: "billPayerBank",
                        label: "水单开户银行",
                        width: 100,
                    },
                    {
                        prop: "billTradingDate",
                        label: "水单交易日期",
                        width: 100,
                    },
                    {
                        prop: "fVoucherDate",
                        label: "银行交易日期",
                        width: 100,
                    },
                    // {
                    //     prop: "rateMoney",
                    //     label: "水单金额",
                    // },
                    // {
                    //     prop: 'billTime',
                    //     label: '账单时间',
                    // },
                ],

                url: {
                    list: "/shipping/hySettleReceive/checkBillList",
                    changeToWaitCheck: "/shipping/hySettleReceive/changeToWaitCheck",
                    delBill: "/shipping/hySettleReceive/backToBilled",
                    checkAmount: "/shipping/hySettleReceive/checkAmount",
                    // downloadWaitCheckExcel:"/shipping/hySettleReceive/downloadWaitCheckExcel",
                  downloadWaitCheckExcel:"/shipping/hySettleReceive/downloadCaiwuDm2",
                  downloadRecApplyDetailAndUploadOss:"/file/hyFinance/downloadRecApplyDetailAndUploadOss",


                },

                rules: {
                    realCtn: [
                        {required: true, message: "请输入实际总件数", trigger: "blur"},
                        {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                    ],
                    realKg: [
                        {required: true, message: "请输入实际总重量", trigger: "blur"},
                        {
                            pattern:
                                /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                            message: "只能输入最多三位小数的数字",
                            trigger: "change",
                        },
                    ],
                },
                stime: [],
                otime: [],
                btime: [],  //开船时间
                feetime: [],  //开船时间
                rmTime: [],  //开船时间
            };
        },
        computed: {
          buttonShowList(){
            return {
              'dcexcel':this.$btnIsShow('hyFinanceManage/modules/receive/waitCheck','0','导出当前excel'),
              'plcz':this.$btnIsShow('hyFinanceManage/modules/receive/waitCheck','0','批量查账'),
              'cz':this.$btnIsShow('hyFinanceManage/modules/receive/waitCheck','1','查账'),
              'sc':this.$btnIsShow('hyFinanceManage/modules/receive/waitCheck','1','删除'),
            }
          }
        },
        created() {
          this.$set(this.queryForm,'orderType','0');
          this.$set(this.queryForm,'dataCreateTime','3');
        },
        methods: {
            showImg(url, type){
                if(type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg') {
                  this.imgUrl = url;
                  this.imgVisible = true;
                } else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
                  // 在当前浏览器直接下载
                  document.location.href = url
                }else {
                  window.open().location.href = url
                }
            },
            initCusPage({cusId}) {
                if (cusId) {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/customer/Edit');
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }

            },
            //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            showApplyDetail(row) {
                this.$refs.apply.showDetail(row);
            },
            handleDelBill(row) {
              postAction(this.url.downloadRecApplyDetailAndUploadOss+"?applyNo=" + row.applyNo)
                  .then(res => {
                    // O97104 退回的时候需要收款申请明细附件
                    row.backFileUrl = res.result;
                    postAction(this.url.delBill, row).then((res2) => {
                      console.log(res2);
                      this.handleQuery();
                    });
                  })

            },
            changeToWaitCheck(row) {
                row.checkStatus = "0";
                postAction(this.url.changeToWaitCheck, row).then((res) => {
                    //检测是同一个结算对象 才进行下载
                    if (res.success) {
                        this.visible = false;
                        this.handleQuery();
                    }
                });
            },
            openPic(url) {
                window.open(url, '_blank');
            },
            handleCheckBill(row) {
                console.log(row);
                let applyNos = [];
                let ids = [];
                ids.push(row.id)
                //检查销账申请的金额和当前费用金额是否一致
                const obj = {
                    applyNos: applyNos.push(row.applyNo),
                    agentPayIds: ids,
                    billMoney: row.billMoney,
                    needCur: row.payCur,
                    realMoney: row.realMoney
                }
                // getAction(this.url.checkAmount, obj).then((res) => {
                //obj
                //     if (res.success === true) {
                        this.$refs.checkBillDialog.edit(obj);
                //     }
                // }).catch(err => {
                //
                // })

            },
            handleCheckBillBatch() {
                let error = [];
                this.selectionRows.forEach(item => {
                    if (item.checkStatus !=='0' && item.checkStatus !=='2'){
                        error.push(item.id)
                    }
                });
                if(error.length > 0){
                    this.$message.warning("已查账的申请不能再次查账！")
                    return
                }


                if (this.selectionRows.length === 0) {
                    this.$message.warning('请勾选申请编号后再进行批量查账')
                    return
                }
                let countCur = [];
                this.selectionRows.forEach(item => {
                    countCur.push(item.payCur)
                });
                let curArr = Array.from(new Set(countCur));
                console.log('curArr->',curArr)
                if(curArr.length > 1){
                    this.$message.warning("所选申请的应收币种不一致！")
                    return
                }
                //检查销账申请的金额和当前费用金额是否一致
                // this.selectionRows
                // this.selectedRowKeys
                let applyNos = [];
                let ids = [];
                let billMoney = 0;
                let realMoney = 0;

                for(let i = 0; i < this.selectionRows.length; i++) {
                    applyNos.push(this.selectionRows[i].applyNo)
                    ids.push(this.selectionRows[i].id)
                    billMoney = (Number(billMoney) + Number(this.selectionRows[i].billMoney)).toFixed(2)
                    realMoney = (Number(realMoney) + Number(this.selectionRows[i].realMoney)).toFixed(2)
                }
                const obj = {
                    applyNos: applyNos,
                    billMoney: billMoney,
                    realMoney: realMoney,
                    needCur: curArr[0],
                    agentPayIds: ids
                }

                this.$refs.checkBillDialogBatch.edit(obj);

            },

            //批量查账-新
            handleCheckBillBatchNew() {
                let error = [];
                this.selectionRows.forEach(item => {
                    if (item.checkStatus !=='0' && item.checkStatus !=='2'){
                        error.push(item.id)
                    }
                });
                if(error.length > 0){
                    this.$message.warning("已查账的申请不能再次查账！")
                    return
                }


                if (this.selectionRows.length === 0) {
                    this.$message.warning('请勾选申请编号后再进行批量查账')
                    return
                }
                let countCur = [];
                this.selectionRows.forEach(item => {
                    countCur.push(item.payCur)
                });
                let curArr = Array.from(new Set(countCur));
                if(curArr.length > 1){
                    this.$message.warning("所选申请的应收币种不一致！")
                    return
                }

                this.$refs.checkBillBatch.edit(this.selectionRows);

            },

            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                let nos = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    nos.push(selection[i]["orderNo"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                this.selectedNos = [...nos];
            },
            handleReceive() {
                console.log("申请收款以下id的费用", this.selectedRowKeys);
            },
            handleReset() {
                this.queryForm = {};
              this.$set(this.queryForm,'agentPayType','0')
                this.otime = "";
                this.stime = "";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleCheck() {
                this.loading = true;

                if (this.stime && this.stime.length > 1) {
                    this.queryForm.sCreateTimeS = this.stime[0];
                    this.queryForm.sCreateTimeE = this.stime[1];
                }else{
                    this.queryForm.sCreateTimeS = '';
                    this.queryForm.sCreateTimeE = '';
                }
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                console.log("查询对象", params);

                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            const {records, total} = res.result;
                            this.tableData = records || res.result;
                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            noConvertBill() {
                console.log("申请收款以下id的费用", this.selectedRowKeys);
                // if (this.selectedRowKeys.length === 0){
                //     this.$message.warning("请勾选费用")
                //     return
                // }
                this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);
            },
            convertBill() {
                console.log("申请收款以下id的费用", this.selectedRowKeys);
                if (this.selectedRowKeys.length === 0) {
                    this.$message.warning("请勾选费用");
                    return;
                }
            },
            downloadWaitCheckExcel() {
                // if(this.selectionRows.length ===0){
                //     this.$message.warning("请勾选费用");
                //     return;
                // }
                if((this.queryForm.applyNo === '' || this.queryForm.applyNo === undefined)
                    && (this.queryForm.username === '' || this.queryForm.username === undefined)
                    && (this.queryForm.creator === '' || this.queryForm.creator === undefined)
                    && (this.stime ===null || this.stime.length === 0)
                    // && (this.otime ===null || this.otime.length === 0)
                    // && (this.feetime ===null || this.feetime.length === 0)
                    // && (this.rmTime ===null || this.rmTime.length === 0)
                    && (this.queryForm.company === '' || this.queryForm.company === undefined)
                    && (this.queryForm.settleName === '' || this.queryForm.settleName === undefined)
                    && (this.queryForm.payCur ==='' || this.queryForm.payCur===undefined)
                    && (this.queryForm.realCur ==='' || this.queryForm.realCur===undefined)
                    && (this.queryForm.salesmanId ==='' || this.queryForm.salesmanId===undefined)
                    && (this.queryForm.receiveBankId ==='' || this.queryForm.receiveBankId===undefined)
                ){
                    // this.$message.warning('导出excel必须添加查询条件')
                    // return
                }

                this.loading = true;

                this.queryForm.downloadExcelType='waitCheck';
                if (this.stime && this.stime.length > 1) {
                    this.queryForm.sCreateTimeS = this.stime[0];
                    this.queryForm.sCreateTimeE = this.stime[1];
                } else {
                    this.queryForm.sCreateTimeS = '';
                    this.queryForm.sCreateTimeE = '';
                }

                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                // console.log("开始下载");

                let filename = this.getDateStr() + '导出待查账'

                let url = this.url.downloadWaitCheckExcel

                getAction(url, params, {responseType: 'blob'})
                    .then(res => {
                        console.log(res)
                        if (res.size === 0) {
                            this.$message.error('导出失败，请稍后再试或联系管理员')
                            this.visible = false
                            return
                        }
                        var binaryData = [];
                        binaryData.push(res);

                        let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.setAttribute('download', filename + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);//下载完成移除元素
                        window.URL.revokeObjectURL(url) //释放掉blob对象

                        this.visible = false
                        setTimeout(() => {   //设置延迟执行
                            this.$message.success("导出成功！")
                            // this.$emit('ok')
                            this.handleCheck();
                        }, 3000)
                    })
            },

        },
    };
</script>
<style scoped>
  .el-button{
    user-select: unset;
  }
  .blue_box_bg {
    border: 1px solid #00a0e9;
    border-radius: 10px;
    padding: 0 5px;
    cursor: pointer;
  }
  .blue_box_bg:hover {
    background: #00a0e9;
    color: white;
  }
</style>
