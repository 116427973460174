<template>
    <div class="main-full-content">
        <el-dialog
            title="选择开票信息"
            :visible="invoiceInfoVisible"
            width="680px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="invoiceInfoHandleClose"
        >
            <el-form>
                <el-form-item label="开票信息">
                    <el-select v-model="invoiceInfoOrderNo" style="width: 70%">
                        <el-option
                            v-for="(item, index) in invoiceInfoList"
                            :label="item.accountName"
                            :value="item.id"
                            :key="index"
                        >
                            {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer">
        <el-button
            type="primary"
            size="medium"
            @click="invoiceInfoHandleSubmit"
            :disabled="ifDis">
            确定申请开票
        </el-button>
      </span>
        </el-dialog>

        <el-dialog
            :title="invoiceApplyTitle"
            :visible="invoiceAddVisible"
            width="880px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="invoiceAddHandleClose"
            append-to-body
        >
            <el-form :model="addForm" ref="addForm" :rules="addRules">
                <el-row class="inputs_row">
                    <el-col :span="12">
                        <el-form-item prop="usernameSearch" label="客户名" label-width="80px">
                            <el-input style="width: 90%" v-model="usernameSearch" placeholder="支持模糊查找"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="primary" @click="getUserFuzzy">查找</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="customerId" label="客户" label-width="80px">
                            <el-select v-model="addForm.customerId" clearable @change="getTitles" placeholder="请输入客户名查找后再进行选择">
                                <el-option v-for="(item) in cusList" :value="item.cusId" :label="item.username + ' - '+ parseDict(item.bussinessType,'cus_buss')" :key="item.customerId">
                                    客户：{{item.username}} - 业务类型：{{parseDict(item.bussinessType,'cus_buss')}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="invoiceTitleId" label="客户开票抬头" label-width="100px">
                            <el-select v-model="addForm.invoiceTitleId"
                                       clearable
                                       placeholder="请选择客户后再选择开票抬头"
                                        @change="changeEmail">
                                <el-option v-for="(item) in invoiceTitles" :value="item.id" :label="item.invTt + ' - '+ item.bank + ' - '+ item.account" :key="item.id" :title="'抬头：'+item.invTt+' - 银行：'+item.bank+' - 账号：' + item.account">
                                    抬头：{{item.invTt}} - 银行：{{item.bank}} - 账号：{{item.account}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="invoiceAmount" label="开票金额" label-width="80px">
                            <el-input v-model="addForm.invoiceAmount" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="invoiceMoneyType" label="开票币种" label-width="100px">
                            <f-select v-model="addForm.invoiceMoneyType"
                                      :dict="'bd_cur'"
                                      placeholder="请选择折合币种"
                                      clearable
                                      filterable
                                      :isNeed="addForm.invoiceMoneyType"
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="buyerBankShow" label="是否显示购方开户银行" label-width="80px">
                            <el-radio-group v-model="addForm.buyerBankShow">
                                <el-radio :label='"0"'>否</el-radio>
                                <el-radio :label='"1"'>是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="sellerBankShow" label="是否显示销方开户银行" label-width="80px">
                            <el-radio-group v-model="addForm.sellerBankShow">
                                <el-radio :label='"0"'>否</el-radio>
                                <el-radio :label='"1"'>是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="bankShowType" label="展示账户类型" label-width="100px">
                            <el-radio-group v-model="addForm.bankShowType">
                                <el-radio :label='"0"'>人民币</el-radio>
                                <el-radio :label='"1"'>美金</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-form-item prop="receiveEmail" label="接收邮箱" label-width="80px">
                    <el-input v-model.trim="addForm.receiveEmail"></el-input>
                </el-form-item>
                <el-form-item prop="orderNoMark" label="关联单号" label-width="80px">
                    <el-input type="textarea"  v-model.trim="addForm.orderNoMark" @change="getOrderNoNum" oninput="value=value.match(/^[a-zA-Z0-9,，\n\t\s]+/)"
                              placeholder="请输入关联的快递订单号，用逗号或者空格分开">
                              </el-input>
                </el-form-item>
                <el-form-item prop="invoiceRemark" label="发票备注" label-width="80px">
                    <el-input type="textarea" v-model="addForm.invoiceRemark" maxlength="230" show-word-limit
                    placeholder="限制230个英文或者115个中文"></el-input>
                </el-form-item>
                <el-form-item prop="invoiceRequire" label="开票要求" label-width="80px">
                  <el-input type="textarea" v-model="addForm.invoiceRequire" maxlength="500" show-word-limit
                            placeholder="限制500个中文"></el-input>
                </el-form-item>
              <el-form-item prop="fileList" label="附件上传">
                <f-upload v-model="form.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
              </el-form-item>
            </el-form>
            <span slot="footer">
        <el-button
            type="primary"
            size="medium"
            @click="invoiceInfoHandleSubmit">
            确定
        </el-button>
      </span>
        </el-dialog>

      <el-dialog
          :title="invoiceEditTitle"
          :visible="invoiceEditVisible"
          width="480px"
          custom-class="c-custom-dialog"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          :before-close="invoiceNumEditHandleClose"
          append-to-body
      >
        <el-form :model="editInvoiceNumFrom" ref="editInvoiceNumFrom" :rules="invoiceNumRules">
          <el-form-item prop="invoiceNum" label="发票号" label-width="80px">
            <el-input v-model.trim="editInvoiceNumFrom.invoiceNum"></el-input>
          </el-form-item>
          <el-form-item prop="internalRemark" label="内部备注" label-width="80px">
            <el-input type="textarea" v-model="editInvoiceNumFrom.internalRemark" maxlength="500"
                      placeholder="限制500个中文"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
        <el-button
            type="primary"
            size="medium"
            @click="invoiceNumHandleSubmit">
            确定开票
        </el-button>
      </span>
      </el-dialog>

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="发票抬头">
                            <el-input v-model="queryForm.invoiceTitle" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="申请人">
<!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.creator"
                                      :isNeed="queryForm.creator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="发票金额">
                            <el-input v-model="queryForm.invoiceAmount" @keyup.enter.native="handleCheck" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="币种">
                            <f-select v-model="queryForm.invoiceMoneyType"
                                      :dict="'bd_cur'"
                                      placeholder="请选择折合币种"
                                      clearable
                                      filterable
                                      :isNeed="queryForm.invoiceMoneyType"
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="客户名">
                            <el-input v-model="queryForm.customer" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="申请时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="ctime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                :picker-options="editStopOptions"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="开票时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="itime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                :picker-options="editStopOptions"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="发票备注">
<!--                            <f-select-->
<!--                                v-model="queryForm.remark"-->
<!--                                :fatherVal="queryForm.remark"-->
<!--                                dict="bd_cur"-->
<!--                            ></f-select>-->
                            <el-input v-model="queryForm.invoiceRemark" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="关联订单">
                            <el-input v-model="queryForm.orderNoMark" @keyup.enter.native="handleCheck" maxlength="3500" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="状态">
                            <el-select v-model="queryForm.invoiceStatus">
                                <el-option value="0" label="待申请"></el-option>
                                <el-option value="1" label="已申请"></el-option>
                                <el-option value="2" label="已开发票"></el-option>
                                <el-option value="3" label="已下载"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="数据时间">
                      <f-select
                          class="textarea_table"
                          v-model="queryForm.dataCreateTime"
                          :is-need="queryForm.dataCreateTime"
                          :dict="'data_create_time'"
                          @keyup.enter.native="handleSearch"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="发票号">
                      <el-input v-model="queryForm.invoiceNum" @keyup.enter.native="handleCheck"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button
                                size="medium"
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15 flex_wait_box">
<!--                <el-button size="medium" type="primary"-->
<!--                           v-if="$btnIsShow('hyFinanceManage/modules/receive/billed','0','批量开票申请')"-->
<!--                >批量开票申请-->
<!--                </el-button>-->

<!--                <el-divider direction="vertical"></el-divider>-->

                <el-button size="medium" type="primary" @click="addOne()"
                           v-if="buttonShowList.add"
                >新增
                </el-button>

                <el-button size="medium" type="primary" @click="exportDetail()"
                           v-if="buttonShowList.dcsqxqexcel"
                >导出申请详情excel
                </el-button>

            </div>
<!--            <div class="payApplyRow" v-if="sumFee.length > 0">-->
<!--                <span>合计：</span>-->
<!--                <div class="payApplyRow payApplyRowItem" v-for="(item,index) in sumFee" :key="index" style="color: red">-->
<!--                    <span>{{ item.cur + ' ' + item.total }}</span>-->
<!--                </div>-->
<!--            </div>-->
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                tableHeight="61vh"
                :isRecordTableScroll="true"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="editInoviceNum(scope.row)"  v-if="buttonShowList.editInvoiceNum">
<!--                                   v-if="scope.row.invoiceStatus === '0' && buttonShowList.sqkp"-->
                        发票号编辑
                        </el-button>

<!--                        <el-button type="text" @click="yiBillApply(scope.row)"-->
<!--                                   v-if="scope.row.invoiceStatus === '1' && buttonShowList.jxkp"-->
<!--                        >进行开票-->
<!--                        </el-button>-->

<!--&lt;!&ndash;                        <el-divider direction="vertical" v-if="scope.row.invoiceStatus==='1'"></el-divider>&ndash;&gt;-->
<!--                        <el-button slot="reference" type="text" @click="cancelInvApply(scope.row)"-->
<!--                                   v-if="buttonShowList.qxsq && scope.row.invoiceStatus==='1'"-->
<!--                        >取消申请-->
<!--                        </el-button>-->
                        <el-button
                            slot="reference"
                            type="text"
                            @click="delInvApply(scope.row)"
                            v-if="(scope.row.invoiceStatus === '0' || scope.row.invoiceStatus === '1') && buttonShowList.sc"
                        >删除
                        </el-button>

<!--                        <el-divider direction="vertical" v-if="scope.row.status==='0'"></el-divider>-->
<!--                        <el-button type="text" @click="downloadApply(scope.row)"-->
<!--                                   v-if="(scope.row.invoiceStatus === '2' || scope.row.invoiceStatus === '3') && $btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','下载发票')"-->
<!--                                   style="color: green"-->
<!--                        >下载发票-->
<!--                        </el-button>-->
<!--                        <span v-if="(scope.row.invoiceStatus === '2' || scope.row.invoiceStatus === '3') && buttonShowList.xzfp">-->
<!--                            <el-button type="text" @click="downloadApply(item)"-->
<!--                                        v-for="(item,d) in scope.row.urls" :key="d + 'url'"-->
<!--                                       style="color: green"-->
<!--                            >下载发票-->
<!--                        </el-button>-->
<!--                        </span>-->

                        <el-button type="text" @click="editApply(scope.row)"
                                   v-if="scope.row.invoiceStatus === '0' && buttonShowList.bj"
                        >编辑
                        </el-button>

                    </template>
                  <template v-else-if="scope.column.property === 'fileIds'">
                        <span v-for="(item,index) in scope.row.fileList" :key="index">
                            <a class="blue_box_bg" @click.prevent="showImg(item.url,item.ext)" target="_Blank" download>{{item.name}}</a><br>
                        </span>
                  </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.customerId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'realAgent'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                           style="color:#57a3f3;">{{ scope.row.realAgent }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'invoiceStatus'">
                        {{ parseDict(scope.row.invoiceStatus, 'invoice_apply_status') }}
                    </template>
                     <template v-else-if="scope.column.property === 'orderNoMarkStr'&&scope.row.orderNoMarkStr&&scope.row.orderNoMarkStr.indexOf('<')!==-1">
                        <div v-html="scope.row.orderNoMarkStr"></div>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNoMarkStr'&&scope.row.orderNoMarkStr&&scope.row.orderNoMarkStr.indexOf('<')==-1">
                        <span>{{scope.row.orderNoMarkStr}}</span>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <receive-apply ref="apply" @ok="this.handleQuery"></receive-apply>
        <email-dialog :visible.sync="dialogFormVisibleEmail" :row="form" @ok="dialogFormVisible = false"
                      :emails="emails"></email-dialog>
        <convert-bill ref="convert" @ok="handleCheck"></convert-bill>
    </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction, putAction} from "@/api/manage";
import receiveApply from "./receiveApply";
import EmailDialog from "./EmailDialog";
import {setUpRouteUtil} from '@/utils/util';
import convertBill from "./convertBill"
import {emailPattern} from "@/utils/pattern";
import {validateLength} from "@/utils/validator";
import {validateInvoiceMoney} from "@/utils/validator";

export default {
    mixins: [QueryMixins],
    components: {
        receiveApply, EmailDialog, convertBill
    },
    data() {
        return {
            dictCodes: ['invoice_apply_status','cus_buss'],
            invoiceInfoList: [],
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            dialogFormVisibleEmail: false,
            dialogFormVisible: false,
            emails: [],
            kdBillType: '',
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: 'invoiceNum',
                    label: '发票号',
                    width: 100,
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 100,
                },
                {
                    prop: "customer",
                    label: "客户名",
                },
                {
                    prop: "invoiceTitle",
                    label: "发票抬头",
                    width: 140,
                },
                {
                    prop: "invoiceStatus",
                    label: "状态",
                    width: 140,
                },

                {
                    prop: 'invoiceAmount',
                    label: '开票金额',
                    width: 140,
                },
                {
                    prop: 'invoiceMoneyType',
                    label: '开票币种',
                    width: 140,
                },
                {
                    prop: "receiveEmail",
                    label: "接受邮箱",
                    width: 140,
                },
                {
                  prop: "internalRemark",
                  label: "内部备注",
                  width: 140,
                },
                {
                    prop: "invoiceRemark",
                    label: "发票备注",
                    width: 140,
                },
                {
                    prop: "orderNoMarkStr",
                    label: "关联单号",
                    width: 140,
                },
                {
                    prop: "creator",
                    label: "申请人",
                    width: 140,
                },
                {
                    prop: "createTime",
                    label: "申请时间",
                    width: 140,
                },
                {
                    prop: "invoiceMan",
                    label: "开票人",
                },
                {
                    prop: "invoiceTime",
                    label: "开票时间",
                },
                {
                  prop: "fileIds",
                  label: "附件",
                },

            ],

            url: {
                list: "/shipping/invoiceApply/list",
                add: "/shipping/invoiceApply/add",
                edit: "/shipping/invoiceApply/edit",
                editInvoiceNum: "/shipping/invoiceApply/editInvoiceNum",
                // updateApply: "/shipping/invoiceApply/updateApply",
                getCustomer:"/shipping/invoiceApply/getCustomer",
                cancelInvApply:"/shipping/invoiceApply/cancelInvApply",
                exportXls:"/shipping/invoiceApply/exportXls",

                // invoice: "/shipping/hySettleReceive/getInvoiceInfo",
                billApply: "/shipping/invoiceApply/takeBillApply",
                downloadApply: "/shipping/invoiceApply/downloadApply",
                batchInvoiceApply: "/shipping/invoiceApply/batchInvoiceApply",
                // getEmailsByCusId: "/shipping/hySettleReceive/getEmailsByCusId",
                searchUser:"/shipping/invoiceApply/getUserFuzzy",
                getTitles:"/shipping/invoiceApply/getTitles",
                delInvApply:"/shipping/invoiceApply/delInvApply",
            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],

            },
            addRules:{

                // usernameSearch: [
                //     {required: false, message: '请填写客户名', trigger: 'change'}
                // ],
                customerId: [
                    {required: true, message: '请选择客户', trigger: 'change'}
                ],
                invoiceAmount: [
                    {required: true, message: '请输入开票金额', trigger: 'change'},
                    {validator:validateInvoiceMoney,trigger: "change"}
                ],
                invoiceTitleId: [
                    {required: true, message: '请选择客户的开票信息抬头', trigger: 'change'}
                ],
                invoiceMoneyType: [
                    {required: true, message: '请选择开票币种', trigger: 'change'}
                ],
                receiveEmail: [
                    {required: true, message: '请输入接收邮箱', trigger: 'change'},
                    {pattern: emailPattern,
                        message: "请输入正确的邮箱",
                        trigger: "change",
                    },
                ],
                invoiceRemark: [
                    {required: false, message: '请输入发票备注', trigger: 'change'},
                    {validator:validateLength,trigger: "change"}
                ],
                orderNoMark: [
                    {required: true, message: '请输入关联的订单号', trigger: 'change'}
                ],
            },
            invoiceNumRules:{
              invoiceNum: [
                {required: true, message: '请输入发票号', trigger: 'change'}
              ],
            },
            ctime: [],
            itime: [],
            ifDis: false,
            invoiceInfoVisible: false,
            invoiceInfoOrderNo: "",
            sumFee: [],
            invoiceAddVisible:false,
            invoiceEditVisible:false,
            cusList:[],
            addForm:{},
            editInvoiceNumFrom: {invoiceStatus: '',invoiceNum: '', id: '', isKd: '1' ,internalRemark: ''},
            usernameSearch:'',
            invoiceTitles:[],
            invoiceApplyTitle:'添加开票申请',
            invoiceEditTitle:'发票号编辑'
        };
    },
    computed: {
      buttonShowList(){
        return {
          'add':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','0','新增'),
          'dcsqxqexcel':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','0','导出申请详情excel'),
          'sqkp':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','申请开票'),
          'jxkp':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','进行开票'),
          'qxsq':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','取消申请'),
          'sc':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','删除'),
          'xzfp':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','下载发票'),
          'bj':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','编辑'),
          'editInvoiceNum':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceApply','1','编辑发票号'),
        }
      }
    },
    created() {
      this.$set(this.queryForm,'dataCreateTime','3');
      this.$set(this.queryForm,'isKd','1');
    },
    methods: {
      showImg(url, type){
        if(type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg') {
          this.imgUrl = url;
          this.imgVisible = true;
        } else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
          // 在当前浏览器直接下载
          document.location.href = url
        }else {
          window.open().location.href = url
        }
      },
        //开票申请
        yiBillApply(obj) {
            // console.log('ooobj',obj);
            this.$confirm('确定前往易票云进行开票？如果金额超过99999元，会自动进行拆分开票', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info',
                closeOnClickModal: false,
            }).then(() => {

                postAction(this.url.billApply, obj).then((res) => {
                    // console.log(res);
                    //this.invoiceInfoList = res.result;   this.tableData
                    if (res.code === 200) {
                        this.$message.success("开票成功！");
                        this.handleQuery();
                    }
                })
            }).catch(res => {
                this.$message.error(res.message);
            })
        },
        //下载发票
        downloadApply(obj) {
            // console.log(obj);
            var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
            postAction(this.url.downloadApply, {id:obj}).then((res) => {
                // console.log(res);
                // this.invoiceInfoList = res.result;
                let applypdfurl = res.result;
                // window.open(applypdfurl,"_blank");
                newWindow.location.href = applypdfurl;
            });
        },


        initCusPage({cusId}) {
            if (cusId) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?id=${cusId}`)
            }

        },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD") !== -1) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            } else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },

        invoiceInfoHandleSubmit() {
            // console.log('进入invoiceInfoHandleSubmit')
            // console.log(this.addForm.customerId)
            this.$refs.addForm.validate((valid) => {
              if (this.form.fileList){
                let fileIds =  this.form.fileList.map(x=>x.id).join(",");
                this.addForm.fileIds = fileIds ;
              }
                if (valid) {
                    //编辑
                   this.addForm.isKd = "1";
                    if (this.addForm.id) {
                        putAction(this.url.edit, this.addForm).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.invoiceAddVisible = false;
                                this.handleQuery();
                            }
                        });
                    } else {
                        if (this.invoiceTitles && this.invoiceTitles.length > 0) {
                          let one= this.invoiceTitles.filter(i=>{
                            return i.id === this.addForm.invoiceTitleId;
                          })
                          // console.log(one)
                          // console.log(one[0].invTt)
                          if (one && one.length > 0) {
                            this.addForm.invoiceTitle = one[0].invTt
                          }
                        }

                        // console.log('addForm',this.addForm)
                        postAction(this.url.add, this.addForm).then((res) => {

                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.invoiceAddVisible = false;
                                this.handleQuery();
                            }
                        });
                    }
                }
            });


        },
        invoiceNumHandleSubmit() {
          if (this.editInvoiceNumFrom.invoiceStatus == "2"){
            this.$message.warning("请勿重复开票");
            return ;
          }
          putAction(this.url.editInvoiceNum, this.editInvoiceNumFrom).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              //关闭弹窗
              this.invoiceEditVisible = false;
              this.handleQuery();
            }
          });
        },
        invoiceApply(orderNo) {
            // console.log(orderNo);
            getAction(this.url.invoice, {orderNo: orderNo}).then((res) => {
                // console.log(res);
                this.invoiceInfoList = res.result;
            });

            this.invoiceInfoVisible = true;
        },

        collectionLetter() {
            if (this.selectionRows.length === 0) {
                this.$message.warning("请勾选费用再进行发送催款函");
                return;
            }
            this.$message.warning("此功能后续开放！");
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            this.sumFee = [];
            let allCur = []
            selection.forEach(row => {
                allCur.push(row.realMoneyType)
            })
            let curNum = Array.from(new Set(allCur))
            //遍历每个币种，计算总和后放入sumFee中
            // curNum.forEach(cur => {
            //     let curRow = selection.filter(row => {
            //         return row.realMoneyType === cur
            //     })
            //     let total = Number(0);
            //     curRow.forEach(i => {
            //         total = total + Number(i.exchangeTotal)
            //     })
            //     this.sumFee.push({cur: cur, total: total.toFixed(3)})
            // })

            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleReset() {
            this.queryForm = {};
            this.ctime = "";
            this.itime = "";
            this.ipagination.currentPage = 1;
            this.queryForm.isKd = "1";
            this.handleQuery();
        },
        handleSearch() {
            this.ipagination.currentPage = 1
            this.handleCheck()
        },
        handleCheck() {
            this.queryForm.ids = []
            this.loading = true;
            if (this.ctime && this.ctime.length > 1) {
                this.queryForm.createTimeS = this.ctime[0];
                this.queryForm.createTimeE = this.ctime[1];
            } else {
                this.queryForm.createTimeS = '';
                this.queryForm.createTimeE = '';
            }
            if (this.itime && this.itime.length > 1) {
                this.queryForm.invoiceTimeS = this.itime[0];
                this.queryForm.invoiceTimeE = this.itime[1];
            } else {
                this.queryForm.invoiceTimeS = '';
                this.queryForm.invoiceTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);
            params.isKd = "1";
            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addOne(){
            this.addForm = Object.assign({}, '')
            this.usernameSearch = ''
            this.invoiceApplyTitle='添加开票申请'
            this.invoiceAddVisible = true
            this.form.fileList = [];
            this.$nextTick(()=>{
                this.$refs.addForm.clearValidate()
            })
        },
        exportDetail(){
            if((this.queryForm.invoiceTitle === '' || this.queryForm.invoiceTitle === undefined)
                && (this.queryForm.creator === '' || this.queryForm.creator === undefined)
                && (this.queryForm.invoiceAmount ===null || this.queryForm.invoiceAmount === undefined)
                && (this.queryForm.invoiceMoneyType ==='' || this.queryForm.invoiceMoneyType===undefined)
                && (this.ctime ===null || this.ctime.length === 0)
                && (this.itime ===null || this.itime.length === 0)
                && (this.queryForm.invoiceRemark ==='' || this.queryForm.invoiceRemark===undefined)
                && (this.queryForm.customer ==='' || this.queryForm.customer===undefined)
                && (this.queryForm.invoiceNum ==='' || this.queryForm.invoiceNum===undefined)
                && (this.queryForm.invoiceStatus ==='' || this.queryForm.invoiceStatus===undefined)
            ){
                this.$message.warning('导出excel必须添加查询条件')
                return
            }

            if(this.selectedRowKeys.length > 0){
                this.queryForm.ids = JSON.parse(JSON.stringify(this.selectedRowKeys)).join(",")
            }

            this.loading = true;

            if (this.ctime && this.ctime.length > 1) {
                this.queryForm.createTimeS = this.ctime[0];
                this.queryForm.createTimeE = this.ctime[1];
            } else {
                this.queryForm.createTimeS = '';
                this.queryForm.createTimeE = '';
            }
            if (this.itime && this.itime.length > 1) {
                this.queryForm.invoiceTimeS = this.itime[0];
                this.queryForm.invoiceTimeE = this.itime[1];
            } else {
                this.queryForm.invoiceTimeS = '';
                this.queryForm.invoiceTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("开始下载");

            let filename = this.getDateStr() + '导出开票申请详情'

            let url = this.url.exportXls
            params.isKd = "1";
            getAction(url, params, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if (res.size === 0) {
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        this.visible = false
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象

                    this.visible = false
                    setTimeout(() => {   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        this.handleCheck();
                    }, 3000)
                })
        },

        invoiceInfoHandleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.invoiceInfoVisible = false;
        },
        invoiceAddHandleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.invoiceAddVisible = false;
        },
        invoiceNumEditHandleClose() {
          // this.$emit('update:visible', false)
          // this.$refs.form.resetFields()
          this.invoiceEditVisible = false;
        },
        getOrderPre() {
            let count = [];
            this.selectionRows.forEach(item => {
                let pre = this.deleteNum(item.orderNo)
                if (pre === 'KD') {
                    count.push(pre)
                } else {
                    count.push('OS')
                }
                // count.push(this.deleteNum(item.orderNo))
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            // console.log('账单前缀', arr)

            return arr
        },
        deleteNum(str) {
            let reg = /[0-9]+/g;
            return str.replace(reg, "");

        },
        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.handleCheck()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.handleCheck()
        },
        getUserFuzzy(){
            if(this.usernameSearch===""){
                this.$message.warning("请输入客户名再进行查找")
                return;
            }
            this.cusList = []
            getAction(this.url.searchUser, {userFuzzy:this.usernameSearch}).then((res)=>{
                // console.log(res)
                if(res.result.length > 0){
                    this.cusList=res.result
                    this.cusList.forEach(cus=>{
                        if(cus.bussinessType=='0'){
                            cus.bussinessType= '海运'
                        }
                        if(cus.bussinessType=='1'){
                            cus.bussinessType= '空运'
                        }
                        if(cus.bussinessType=='2'){
                            cus.bussinessType= '快递'
                        }
                        if(cus.bussinessType=='3'){
                            cus.bussinessType= '海外仓'
                        }
                    })
                }
                // this.cusList=res.result

            })
        },
        getTitles(){
            this.invoiceTitles = []
            getAction(this.url.getTitles,{customerId:this.addForm.customerId}).then((res)=>{
                // console.log('titles',res)
                if(res.result.length ===0 ){
                    this.$message.warning("此客户下无开票信息，请确认")
                    return
                }
                this.invoiceTitles = res.result
            })
        },
      editInoviceNum(row){
        this.invoiceApplyTitle = '修改开票申请'
        this.invoiceEditVisible = true;
        this.editInvoiceNumFrom.id = row.id;
        this.editInvoiceNumFrom.invoiceNum = row.invoiceNum;
        this.editInvoiceNumFrom.internalRemark = row.internalRemark;
        this.editInvoiceNumFrom.invoiceStatus = row.invoiceStatus;
      },

        // updateApply(row){
        //     this.$confirm("是否提交开票申请？请确认", "提示", {
        //         confirmButtonText: "确定",
        //         cancelButtonText: "取消",
        //         type: "warning",
        //         closeOnClickModal: false,
        //     }).then(()=>{
        //         this.$store.commit("setFullscreenLoading", true);
        //         putAction(this.url.updateApply, row).then((res)=>{
        //             this.$store.commit("setFullscreenLoading", false);
        //             // console.log(res)
        //             this.handleCheck()
        //         }).catch(err => {
        //             this.$store.commit("setFullscreenLoading", false);
        //         })
        //     })
        // },
        cancelInvApply(row){
            this.$confirm("是否取消开票申请？请确认", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).then(()=>{
                putAction(this.url.cancelInvApply, row).then((res)=>{
                    // console.log(res)
                    this.handleCheck()
                })
            })
        },
        delInvApply(row){
            this.$confirm("是否【删除】开票申请？请确认", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).then(()=>{
                putAction(this.url.delInvApply, row).then((res)=>{
                    // console.log(res)
                    this.handleCheck()
                })
            })
        },
        getCustomer(cusId){
            getAction(this.url.getCustomer,cusId).then((res=>{

            }))
        },
        editApply(row){
            this.invoiceApplyTitle='修改开票申请'
            getAction(this.url.getCustomer,{customerId:row.customerId}).then((res=>{
                this.cusList = res.result
            }))

            getAction(this.url.getTitles,{customerId:row.customerId}).then((res)=>{
                // console.log(res)
                if(res.result.length ===0 ){
                    this.$message.warning("此客户下无开票信息，请确认")
                    return
                }
                this.invoiceTitles = res.result
            })
            this.addForm = JSON.parse(JSON.stringify(row));
            if (this.addForm.orderNoMark){
              this.addForm.orderNoMark=this.addForm.orderNoMark.replace(/<[^>]*>/g, '')
            }
            console.log(row.fileList)
            this.form.fileList = row.fileList;
            this.invoiceAddVisible = true
        },
        changeEmail(){
            let title = this.invoiceTitles.filter(i=>{
                return i.id === this.addForm.invoiceTitleId
            })
            // console.log(title);
            if (title && title.length > 0) {
              this.$set(this.addForm,'receiveEmail',title[0].mailAddress);
              this.$set(this.addForm,'invoiceTitle',title[0].invTt);
            }


        },
        getOrderNoNum(){
            //Pattern pattern = Pattern.compile("[ ,，(\r\n)\t]+");
            //String[] strs = pattern.split(orderNoTemp);
            let arr = this.addForm.orderNoMark.split(/[ ,，\r\n\t]/)
            console.log(this.addForm.orderNoMark,'this.addForm.orderNoMark');
            // console.log(arr)
            if(arr.length > 300){
                this.$message.warning('关联订单的数量超过300')
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.flex_wait_box {
    display: flex;

    .select_width {
        width: 200px;
        margin: 0 10px;
    }
}

.blue_box_bg {
  border: 1px solid #00a0e9;
  border-radius: 10px;
  padding: 0 5px;
  cursor: pointer;
}
.blue_box_bg:hover {
  background: #00a0e9;
  color: white;
}
</style>

