<!--带入仓-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div style="display:flex;">
                <el-card class="box-card">
                  <el-row :gutter="24" class="inputs_row">
                    <el-col :md="4" :sm="24">
                  <el-form-item label="订单号">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="客户名">
                          <el-input v-model.trim="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="渠道名称">
                      <f-select :dict="'transportStatus'"
                                v-model="queryForm.channelId"
                                :isNeed="queryForm.channelId"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="订单状态">
                      <f-select
                          v-model="queryForm.orderStatus"
                          :isNeed="queryForm.orderStatus"
                          :dict="'orderStatus'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="业务员">
                      <f-select
                          v-model="queryForm.salesmanId"
                          :isNeed="queryForm.salesmanId"
                          :dict="'salesmanId'">
                      </f-select>
                    </el-form-item>
                  </el-col>

                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                      <el-form-item label="国家">
                          <f-select v-model="queryForm.shipmentCountry" :dict="'bd_country'" :isNeed="queryForm.shipmentCountry"></f-select>
                      </el-form-item>
                  </el-col>

                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                      <el-form-item label="地址类型">
                          <f-select :dict="'shipment_type'" v-model="queryForm.shipmentType"
                                    :is-need="queryForm.shipmentType"></f-select>
                      </el-form-item>
                  </el-col>

                  <el-col class="no_margin" :md="6" :sm="24">
                    <el-form-item label="下单时间" label-width="100px">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeS"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeE"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>

                      <el-col class="no_margin" :md="6" :sm="24">
                          <el-form-item label="入仓时间" label-width="100px">
                              <div class="f-flex">
                                  <el-date-picker
                                          class="f-flex-1"
                                          v-model="queryForm.cargoArrivedS"
                                          type="datetime"
                                          size="small"
                                          placeholder="开始时间"
                                          default-time="00:00:00"
                                          value-format="yyyy-MM-dd HH:mm:ss"
                                  >
                                  </el-date-picker>
                                  <span class="f-p-h-2">~</span>
                                  <el-date-picker
                                          class="f-flex-1"
                                          v-model="queryForm.cargoArrivedE"
                                          type="datetime"
                                          size="small"
                                          placeholder="结束时间"
                                          default-time="23:59:59"
                                          value-format="yyyy-MM-dd HH:mm:ss"
                                  >
                                  </el-date-picker>
                              </div>
                          </el-form-item>
                      </el-col>

                      <el-col class="no_margin" :md="6" :sm="24">
                          <el-form-item label="开船时间" label-width="100px">
                              <div class="f-flex">
                                  <el-date-picker
                                          class="f-flex-1"
                                          v-model="queryForm.shipmentBoardS"
                                          type="datetime"
                                          size="small"
                                          placeholder="开始时间"
                                          default-time="00:00:00"
                                          value-format="yyyy-MM-dd HH:mm:ss"
                                  >
                                  </el-date-picker>
                                  <span class="f-p-h-2">~</span>
                                  <el-date-picker
                                          class="f-flex-1"
                                          v-model="queryForm.shipmentBoardE"
                                          type="datetime"
                                          size="small"
                                          placeholder="结束时间"
                                          default-time="23:59:59"
                                          value-format="yyyy-MM-dd HH:mm:ss"
                                  >
                                  </el-date-picker>
                              </div>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="4">
                          <el-form-item label="客服" label-width="80px">
                              <f-select :dict="'docId'" v-model="queryForm.docId" :is-need="queryForm.docId"></f-select>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="4">
                          <el-form-item label="收款人" label-width="80px">
                              <f-select :dict="'billman_id'" v-model="queryForm.billmanId"
                                        :is-need="queryForm.billmanId"></f-select>
                          </el-form-item>
                      </el-col>
                      <el-col  :xs="24" :sm="12" :md="12" :lg="4">
                          <el-form-item label="FBA CODE" label-width="90px">
                              <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="4">
                          <el-form-item label="CODE or ZIP" label-width="90px">
                              <el-input v-model="queryForm.shipmentZip" @keyup.enter.native="handleSearch"></el-input>
                          </el-form-item>
                      </el-col>

                  <el-col :span="4">
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置</el-button
                    >
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card last_card">
            <div>
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="batchCreateByOrderNos">批量生成海运费</el-button>
                <el-button
            size="medium"
            type="primary"
            :disabled="selectionRows.length === 0"
            @click="$intoDocPageBatch(selectedRowOrderNos,'/sale/noCost/noCreate')"
            v-if="buttonShowList.pldkdd"
        >批量打开订单
        </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="65vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'orderStatus'">
                        <span>{{parseDict(scope.row.orderStatus,'orderStatus')}}<br></span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipmentType'">
                        <span>{{parseDict(scope.row.shipmentType,'shipment_type')}}<br></span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'shipment_type','pglx'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 100,
                    },
                    {
                        prop: "createTime",
                        label: "下单时间",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "cargoArrivedTime",
                        label: "入仓时间",
                    },
                    {
                        prop: "shipmentBoardTime",
                        label: "开船时间",
                    },
                    {
                        prop: "transportStatus",
                        label: "渠道名称",
                        minWidth: 100,
                    },
                    {
                        prop: "orderStatus",
                        label: "订单状态",
                        minWidth: 100,
                    },
                    {
                        prop: "ctn",
                        label: "预计件重体",
                    },
                    {
                        prop: "realCtn",
                        label: "实际件重体",
                    },
                    {
                        prop: "salesman",
                        label: "业务员",
                        minWidth: 80,
                    },
                    {
                        prop: "shipmentType",
                        label: "地址类型",
                    },

                    // {
                    //     prop: "pglx",
                    //     label: "拼柜类型",
                    //     minWidth: 80,
                    // },
                    {
                        prop: "doc",
                        label: "客服",
                        minWidth: 80,
                    },
                    {
                        prop: "billman",
                        label: "收款人",
                        minWidth: 80,
                    },
                    {
                        prop: "shipmentCountry",
                        label: "目的地",
                        minWidth: 80,
                    },

                    // {
                    //     prop: "froRemark",
                    //     label: "客户备注",
                    //     minWidth: 100,
                    // },
                    {
                        prop: "remark",
                        label: "内部备注",
                        minWidth: 100,
                    },
                ],
                url: {
                    list: "finance/fee/settleCommission/noCostList",
                    batchCreateByOrderNos: "finance/fee/order/batchCreateByOrderNos",
                    // exportXlsUrl: "/order/sale/enterWarehouseListExportXls",
                },
            };
        },
        methods: {
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$intoDocPage(orderNo);
                // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },

            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.queryForm.isWhether='0'
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            batchCreateByOrderNos() {
                let list = this.selectionRows;
                if (!list || list.length === 0) {
                    this.$message.warning("请勾选要生成的订单号");
                    return
                }
                let orderNoList = [];
                list.forEach(l => {
                    orderNoList.push(l.orderNo);
                })
                postAction(this.url.batchCreateByOrderNos, orderNoList).then(res => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();

                    } else {
                        this.$message.warning(res.message);
                        this.handleQuery();

                    }
                })

            },
        },
        created() {
            this.queryForm.isWhether='0'
        },
        computed:{
        buttonShowList() {
          return {
            'pldkdd':this.$btnIsShow('sale/noCost/noCreate','0','批量打开订单'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
