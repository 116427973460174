<template xmlns:margin-top="http://www.w3.org/1999/xhtml">
    <div class="main-full-content main_volume_box" style="height: 100%;">
        <el-tabs tab-position="left" @tab-click="handleClick" v-model="clickLabel">
            <el-tab-pane
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :label="tab.label"
                    :lazy="true"
                    @changeOrderNo="changeOrderNo"
                    :name="tab.label"
            >
                <component :is="tab.component"></component>

            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import VolumeInfo from "@/views/warehouse/VolumeInfo";
    // import BasicModule from "./modules/orderInfo/BasicModule";
    // import ShipmentAndInvoiceModule from "./modules/orderInfo/ShipmentAndInvoiceModule";
    // import PayModule from "./modules/orderInfo/PayModule";
    // import RecModule from "./modules/orderInfo/RecModule";
    // import StatusModule from "./modules/orderInfo/StatusModule";
    // import LogModule from "./modules/orderInfo/LogModule";

    // import BasicModule from "@/views/docpage/modules/BasicModule";
    // import ShipmentAndInvoiceModule from "@/views/docpage/modules/ShipmentAndInvoiceModule";
    // import PayModule from "@/views/docpage/modules/PayModule";
    // import RecModule from "@/views/docpage/modules/RecModule";
    // import StatusModule from "@/views/docpage/modules/StatusModule";
    // import LogModule from "@/views/docpage/modules/LogModule";

    import ShowOrderInfo from "@/views/warehouse/modules/orderInfo/ShowOrderInfo"
    import { setUpRouteUtil } from '@/utils/util';


    import {getUrlKey} from "@/utils/route";
    import {getAction} from "@/api/manage";

    import {orderBase} from "@/api/manage";
    //都us收


    export default {
        name: "Main",
        components: {
            // VolumeInfo, BasicModule, ShipmentAndInvoiceModule, PayModule, RecModule, StatusModule, LogModule
            VolumeInfo,ShowOrderInfo
        },
        data() {
            return {
                activeTab: 'VolumeInfo',
                tabs: [
                    {label: "材积信息", component: "VolumeInfo", show: true},
                    {label: "订单详情", component: "showOrderInfo", show: false},
                    // {label: "材积信息", component: "VolumeInfo", show: true},
                    // {label: '基本信息', component: 'BasicModule', show: true},
                    // {label: '派送及发票', component: 'ShipmentAndInvoiceModule', show: true},
                    // {label: '应付账单', component: 'PayModule', show: true},
                    // {label: '应收账单', component: 'RecModule', show: true},
                    // {label: '物流信息', component: 'StatusModule', show: true},
                    // {label: '操作日志', component: 'LogModule', show: true},


                    // { label: '应付信息', component: 'PayModule', show: true },
                    // { label: '操作记录', component: 'LogModule', show: true },
                    // { label: '物流信息', component: 'ExpressInfoModule', show: true },
                ],
                clickLabel: '材积信息', //选中的tab
                //将页面的id传递至下一层
                pageId: "",
                orderNo: '',
                url: {
                    getById: "/customer/getById",
                },
                //是否显示
                isShow: !!this.$route.query.id,
                parentId: "",
            };
        },
        watch: {
          '$route': {
              deep: true,
              handler(to, from) {
                  this.tabs.forEach((item, index) => {
                    item.show = false;
                  })
                  this.clickLabel = '材积信息';
                  setTimeout(() => {this.tabs[0].show = true;},50);
              }
          }
        },
        methods: {
            handleClick(tab, event) {
                // console.log('tab',tab.label)
                // console.log('orderNo',this.orderNo)

                if (tab.label === '订单详情'){
                    // console.log('进入了订单详情')
                    // console.log(sessionStorage.getItem('volumeOrderNo'))
                    if (!sessionStorage.getItem('volumeOrderNo')){
                        this.$message.warning("请在材积信息搜索订单后进行查看订单详情")
                    }
                    this.$router.replace({
                      query: {
                        orderNo: sessionStorage.getItem('volumeOrderNo'),
                        onlyShow: true,
                        queryType : 21
                      },
                    })
                    // else{
                    //     this.intoDocpage(sessionStorage.getItem('volumeOrderNo'))
                    // }

                }
            },
            //进入客服页
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo + '&onlyShow=true&queryType=21');
            },
            changeOrderNo(orderNo){
                // console.log('父组件修改orderNo')
                this.orderNo = orderNo
            },
            initPageid() {
                var id = getUrlKey("id");
                if (id == null || id == "" || id == "undefined") {
                    this.pageId = "";
                } else {
                    this.pageId = id;
                    //根据id获取客户名字
                    getAction(this.url.getById, {id: id}).then((res) => {
                        if (res.success) {
                            this.userName = res.result.name;
                            this.parentId = res.result.parentId;
                        }
                    });
                }
            },
            getSendType(type) {
                if (type == 1) {
                    return "海运";
                }
                if (type == 2) {
                    return "陆运";
                }
                if (type == 3) {
                    return "铁运";
                }
            },
            getBusType(type) {
                let str = "";
                if (this.order.containerType == "1") {
                    //fcl

                    if (type.indexOf("0") !== -1) {
                        str += "订舱,";
                    }
                    if (type.indexOf("1") !== -1) {
                        str += "拖车,";
                    }
                    if (type.indexOf("2") !== -1) {
                        str += "报关,";
                    }
                    if (type.indexOf("3") !== -1) {
                        str += "清关,";
                    }
                    if (type.indexOf("4") !== -1) {
                        str += "派送到门,";
                    }
                }
                if (this.order.containerType == "0") {
                    //fcl

                    if (type.indexOf("0") !== -1) {
                        str += "提货,";
                    }
                    if (type.indexOf("1") !== -1) {
                        str += "报关,";
                    }
                    if (type.indexOf("2") !== -1) {
                        str += "到港,";
                    }
                    if (type.indexOf("3") !== -1) {
                        str += "清关,";
                    }
                    if (type.indexOf("4") !== -1) {
                        str += "派送,";
                    }
                }
                return str;
            },
        },
        created() {
            // this.initPageid();
        },
    };
</script>


<style lang="scss">
.main_volume_box {
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: 100%;
      overflow-y: scroll;
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
