<template>
  <all-container :exportBthShow="true" />
</template>

<script>
import AllContainer from "@/views/operation/cabinet/AllContainer";
export default {
  name: "AllContainerModel",
  components: { AllContainer },
};
</script>

<style lang="scss" scoped>
</style>
