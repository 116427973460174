<template>
  <!-- 拼箱杂费标准 -->
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="订单号">
              <el-input type="textarea" class="textarea_table" v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户">
              <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="扣货日期">
              <div class="f-flex">
                <el-date-picker class="f-flex-1" v-model="queryForm.holdTimeStart" type="datetime" size="small"
                  placeholder="开始时间" default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss"
                  @keyup.enter.native="handleSearch">
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker class="f-flex-1" v-model="queryForm.holdTimeEnd" type="datetime" size="small"
                  placeholder="结束时间" default-time="23:59:59" value-format="yyyy-MM-dd HH:mm:ss"
                  @keyup.enter.native="handleSearch">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单已有的状态" label-width="100px">
              <f-select :dict="'order_time_dian'" v-model="queryForm.manZuColumnList" :multiple="true"
                :is-need="queryForm.manZuColumnList"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="放货状态">
              <f-select :dict="'delivery_order_status'" :multiple="true" :isAll="true" v-model="queryForm.deliveryList"
                :is-need="queryForm.deliveryList"></f-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" style="margin-top: 10px;">
            <el-form-item label="收款状态">
              <f-select :dict="'os_rec_status'" :isAll="true" :multiple="true" v-model="queryForm.recStatusList"
                :is-need="queryForm.recStatusList"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="margin-top: 10px;" :span="4">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <div lass="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" v-if="buttonShowList['dc']"
          @click="exportExcelPlus(url.exportHoldOrder, '扣货订单数据')">导出
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" @selection-change="handleSelectionChange" @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }" tableHeight="63vh">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="handleEdit(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="handleEditCustomer(scope.row)" style="color: #57a3f3">{{ scope.row.customer
              }}</a>
          </template>
          <template v-else-if="scope.column.property === 'orderStatus'">
            <span>
              {{ scope.row.statusContent }}
              <br></span>
            <span v-if="Number(scope.row.realCmb) > 0" style="color:green">(已录材积)<br></span>
            <span v-else style="color:red">(未录材积)<br></span>
            <template v-if="scope.row.insurance === '1'">
              <p v-if="scope.row.isInsured === '1'" style="color: green">已买保险<br></p>
              <p v-else style="color: red">未买保险<br></p>
            </template>
            <span v-if="scope.row.insurance === '0'" style="color: green">不需要保险<br></span>
            <span v-if="scope.row.invoiceStatus === '1'" style="color: green">发票已审核<br></span>
            <span v-if="scope.row.invoiceStatus === '0'" style="color: red">发票未审核<br></span>
            <span v-if="scope.row.receiptUrl"><a style="margin-left: -18px"
                :href="scope.row.receiptUrl">签收单</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </template>
          <template v-else-if="scope.column.property === 'delivery'">
            {{ parseDict2(scope.row.delivery, 'delivery_order_status') }}
          </template>
        </template>
      </f-table>
    </div>

    <el-row class="f-text-right f-p-v-8">
      <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from "@/utils/util";
export default {
  mixins: [QueryMixins],
  data() {
    return {
      columns: [
        {
          type: "selection",
        },
        {
          prop: "customer",
          label: "客户",
          width: 120,
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 120,
        },
        {
          prop: "orderStatus",
          label: "订单状态",
        },
        {
          prop: "type",
          label: "业务类型",
        },
        {
          prop: "delivery",
          label: "放货状态",
        },
        {
          prop: "caiwuRemark",
          label: "财务备注",
        },
        {
          prop: "holdTime",
          label: "扣货时间",
        },
        {
          prop: "holdClassify",
          label: "扣货情况分类",
        },
        {
          prop: "settleAmount",
          label: "订单应收",
        },
        {
          prop: "removeAmount",
          label: "客户总应收",
        },
        {
          prop: "removeDeliveredAmount",
          label: "客户逾期应收",
        },
        {
          prop: "realCtn",
          label: "实件",
        },
        {
          prop: "ctn",
          label: "预报件数",
        },
        {
          prop: "cmb",
          label: "预报体积",
        },
        {
          prop: "realCmb",
          label: "实体",
        },
        {
          prop: "kg",
          label: "预报重量",
        },
        {
          prop: "realKg",
          label: "实际重量",
        },
        {
          prop: "cbmKg",
          label: "体积重",
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "salesGroup",
          label: "销售组别",
        },
        {
          prop: "operator",
          label: "派送操作",
        },
        {
          prop: "shipmentCountry",
          label: "国家",
        },
      ],
      url: {
        list: "/interface/ttOrderHold/getBackAllOrderPage", 
        exportHoldOrder: "/file/export/excel/exportHoldOrder"
      },
      isUsePost: true,
      dictCodes: ['delivery_order_status']
    };
  },
  methods: {
    //进入编辑页面
    handleEdit(val) {
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push('/docpage/Edit?orderNo=' + val);
    },
    handleEditCustomer({ cusId }) {
      setUpRouteUtil(this.$route.path, "/customer/Edit");
      this.$router.push(`/customer/Edit?id=${cusId}`);
    }
  },
  computed: {
    buttonShowList() {
      return {
        'dc': this.$btnIsShow('hyFinanceManage/HoldGoodsOrder', '0', '导出'),
      }
    }
  }
};
</script>

<style></style>