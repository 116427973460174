<template>
    <el-card class="volume_box">
        <p style="display: flex; margin: 0 0 10px 0">
      <span style="font-weight: bold;margin-right: 10px;width: 30%">材积录入:
        <el-input v-model="orderNo" placeholder="请输入订单号..." ref="orderno_input" @keyup.tab.native="nextFocusRef('realt')"
                  @keydown.enter.native="onSearch">
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </span>
            <span style="font-weight: bold;margin-right: 10px;width: 30%">收货人:
                <f-select
                        v-model="form.receiveBy"
                        :isNeed="form.receiveBy"
                        dict="warehouse_men" filterable>
                </f-select>
            </span>
            <span style="font-weight: bold;margin-right: 10px;width: 30%">卸货人:
                <f-select
                        v-model="form.dropBy"
                        :isNeed="form.dropBy"
                        dict="warehouse_men" filterable>
                </f-select>
            </span>


        </p>
        <el-tooltip class="item" effect="dark" :content="form.problemTypeStr" placement="top-start" v-if="form.problemTypeStr">
                <div class="a_ellipsis_box" >
                   pda详细信息：<span v-html="form.problemTypeStr"></span>
                </div>
        </el-tooltip>
        <div class="a_ellipsis_box" v-else>
<!--                  pda详细信息：-->
        </div>


        <el-form :model="form" :form="form" :rules="rules" ref="form">
            <el-row>
                <el-col :span="7">
                    <el-form-item class="f-m-b-15" label="实际件数" label-width="70px" prop="realCtn" :rules="rules.number">
                        <el-input v-model="form.realCtn" placeholder="请输入实际总件数" ref="realt" @keyup.tab.native="nextFocusRef('length.0')">
                            <el-button slot="append"></el-button>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item class="f-m-b-15" label="过磅重" label-width="70px" prop="realKg" :rules="rules.sanweiCanNotNull">
                        <el-input v-model="form.realKg" placeholder="请输入过磅重"></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="5">
                <el-form-item class="f-m-b-15" label="超FBA" label-width="70px" prop="overFBA">
                  <el-input v-model="form.overFBA" placeholder="请输入超FBA"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item class="f-m-b-15" label="超长" label-width="70px" prop="overLength">
                  <el-input v-model="form.overLength" placeholder="请输入超超长"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item class="margin_bottom_10" label="超重" label-width="70px" prop="overWeight">
                  <el-input v-model="form.overWeight" placeholder="请输入超重"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item class="margin_bottom_10" label="UL:E" label-width="70px" prop="ul">
                  <el-input v-model="form.ul" placeholder="请输入UL"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item class="margin_bottom_10" label="ETL" label-width="70px" prop="etl">
                  <el-input v-model="form.etl" placeholder="请输入ETL"></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :span="5">-->
<!--                <el-form-item class="margin_bottom_10" label="bluetooth" label-width="70px" prop="bluetooth">-->
<!--                  <el-input v-model="form.bluetooth" placeholder="请输入Bluetooth"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
                <!--                <el-col style="flex: 1">-->
                <!--                    <el-form-item label="运输方式" style="min-width: 80px; display: flex">-->
                <!--                        <el-input read-only v-model="form.ysfs" style="width: 200px" onmouseover="this.title=this.value"/>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
            </el-row>

            <el-row class="inputs_row">
                <el-col :span="12">
                    <el-form-item class="margin_bottom_10" label="入库库位" label-width="70px">
                        <!--<el-input type="text" disabled="disabled" style="width: 290px;" onmouseover="this.title=this.value" :disabled="locationInVisible"/>-->
                        <el-select v-model="form.location" @change="locationChange" clearable filterable>
                            <el-option v-for="(item,index) in allLocation"
                                       :key="index"
                                       :value="item.warehouseLocationCode"
                                       :label="item.label"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>


                <el-col :span="12">
                    <el-form-item class="margin_bottom_10" label="入库仓库" label-width="70px">
                        <el-input type="text" v-model="form.warehouse"/>
                        <!--                        <el-select v-model="form.warehouse" multiple style="width: 250px;" :disabled="warehouseVisible">-->
                        <!--                            <el-option v-for="(item,index) in allWarehouses"-->
                        <!--                                       :key="index"-->
                        <!--                                       :value="item"-->
                        <!--                                       :label="item"-->
                        <!--                                       >-->
                        <!--                                {{item}}-->
                        <!--                            </el-option>-->
                        <!--                        </el-select>-->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="margin_bottom_10" label="临时库位" label-width="70px">
                        <el-select v-model="form.tempWareHouseLocations" filterable clearable>
                            <el-option v-for="(item,index) in allLocation"
                                       :key="index"
                                       :value="item.warehouseLocationCode"
                                       :label="item.label">

                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="margin_bottom_10" label="货物所在仓:" label-width="80px">
                       <span v-show="form&&form.allVolumeLocation">{{form.allVolumeLocation}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item class="margin_bottom_10" label="仓库备注" label-width="70px">
                        <el-input type="textarea" style="width: 650px" placeholder="请输入仓库备注.."
                                  v-model="form.storehouseRemark"
                                  :auto-size="{ minRows: 3, maxRows: 7 }"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="flex_wrap_start">
            <!-- <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :md="12" :lg="7"> -->
                    <el-form-item class="f-m-b-5" label="FBA外箱标签">
<!--                        <el-radio v-model="form.fbaLable" label="2">无</el-radio>-->
                        <el-radio v-model="form.fbaLable" :label="'2'" @click.native.prevent="radioClick('2','fbaLable')">无</el-radio>
                      <!--                    <el-radio v-model="form.saleLockPrice" :label="'2'" @click.native.prevent="radioClick4('2')">是+分</el-radio>-->
<!--                        <el-checkbox v-model="form.fbaLable" label="2">无</el-checkbox>-->
                    </el-form-item>
                <!-- </el-col>
                <el-col :xs="24" class="f-m-b-5" :sm="12" :md="12" :lg="7"> -->
                    <el-form-item label="Made in China">
<!--                        <el-radio v-model="form.maker" label="2">无</el-radio>-->
                      <el-radio v-model="form.maker" :label="'2'" @click.native.prevent="radioClick('2','maker')">无</el-radio>
<!--                        <el-checkbox v-model="form.maker" label="2">无</el-checkbox>-->
                    </el-form-item>
                <!-- </el-col>
              <el-col :xs="24" class="f-m-b-5" :sm="12" :md="12" :lg="10"> -->
                <el-form-item label="Bluetooth">
<!--                  <el-radio v-model="form.bluetooth" label="1">是</el-radio>-->
                  <el-radio v-model="form.bluetooth" :label="'1'" @click.native.prevent="radioClick('1','bluetooth')">是</el-radio>
<!--                  <el-checkbox v-model="form.bluetooth" label="1">是</el-checkbox>-->
                </el-form-item>
              <!-- </el-col>
            </el-row> -->

          <!-- <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="12" :lg="7"> -->
              <el-form-item class="f-m-b-5" label="CE">
<!--                <el-radio v-model="form.ce" label="1">是</el-radio>-->
<!--                <el-checkbox v-model="form.ce" label="1">是</el-checkbox>-->
                <el-radio v-model="form.ce" :label="'1'" @click.native.prevent="radioClick('1','ce')">是</el-radio>
              </el-form-item>
            <!-- </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="7"> -->
              <el-form-item class="f-m-b-5" label="FCC">
<!--                <el-radio v-model="form.fcc" label="1">是</el-radio>-->
                <el-radio v-model="form.fcc" :label="'1'" @click.native.prevent="radioClick('1','fcc')">是</el-radio>
<!--                <el-checkbox v-model="form.fcc" label="1">是</el-checkbox>-->
              </el-form-item>
            <!-- </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="10"> -->
              <el-form-item class="f-m-b-5" label="Rohs">
<!--                <el-radio v-model="form.rohs" label="1">是</el-radio>-->
<!--                <el-checkbox v-model="form.rohs" label="1">是</el-checkbox>-->
                <el-radio v-model="form.rohs" :label="'1'" @click.native.prevent="radioClick('1','rohs')">是</el-radio>
              </el-form-item>
            <!-- </el-col>

          </el-row> -->
              <el-form-item class="f-m-b-5" label="查验">
                <el-radio v-model="form.isCheck" :label="'1'" @click.native.prevent="radioClick('1','isCheck')">是</el-radio>
              </el-form-item>

            <!-- <el-row :gutter="24">
                <el-col :xs="24" class="f-m-b-5" :sm="12" :md="12" :lg="7"> -->
                    <el-form-item label="产品标签">
<!--                        <el-radio v-model="form.productLable" label="2">无</el-radio>-->
                        <el-radio v-model="form.productLable" :label="'2'" @click.native.prevent="radioClick('2','productLable')">无</el-radio>
<!--                        <el-checkbox v-model="form.productLable" label="2">无</el-checkbox>-->
                    </el-form-item>
                <!-- </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="7"> -->
                    <el-form-item class="f-m-b-5" label="大森林唛头">
<!--                        <el-radio v-model="form.mark" label="2">无</el-radio>-->
                      <el-radio v-model="form.mark" :label="'2'" @click.native.prevent="radioClick('2','mark')">无</el-radio>
<!--                        <el-checkbox v-model="form.mark" label="2">无</el-checkbox>-->
                    </el-form-item>
                <!-- </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="10"> -->
                    <el-form-item class="f-m-b-5" label="包装类型">
                        <el-radio v-model="form.cartons" label="1">纸箱</el-radio>
                        <el-radio v-model="form.cartons" label="2">托盘</el-radio>
                    </el-form-item>
                <!-- </el-col> -->
              <!-- </el-row> -->
              </div>
                <el-row :gutter="24">
                <el-col class="flex_wrap_between">
                    <div>
                      <el-button @click="save(false)" type="primary" icon="check"
                                :disabled="saveDisabled"
                                v-if="buttonShowList.bc"
                                size="mini"

                      >
                          {{buttonContent}}
                      </el-button>
                      <el-button style="margin-left: 10px" @click="handleAdd('length.'+(form.data.length))" type="primary" icon="plus"
                                size="mini"

                                v-if="buttonShowList.add"
                      >新增一行
                      </el-button>
                      <el-button style="margin-left: 10px" @click="checkVolume" type="primary" icon="plus"
                                size="mini"
                                v-if="buttonShowList.checkVolume"
                      >审核材积
                      </el-button>
                       <el-button style="margin-left: 10px" @click="changeWarehouseFn" type="primary"
                                size="mini" :disabled='!selectionRows.length' v-if="buttonShowList.xgszc"
                      >修改所在仓
                      </el-button>
                    </div>
                    <div class="f-m-r-10">
                      <span class="f-m-l-20">到仓时间：</span>
                      <el-date-picker v-if="buttonShowList.bcdcsj"
                        v-model="newCargoArrivedTime"
                        type="datetime"
                        size="small"
                        placeholder="到仓时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 180px"
                      >
                      </el-date-picker>
                      <span v-else>{{ cargoArrivedStart || '' }}</span>
                      <el-button class="f-m-l-20" type="primary" size="small" icon="check" @click="handleUpdateCargoArrivedTime" :disabled="orderNo ? false : true"
                                v-if="buttonShowList.bcdcsj"
                      >保存到仓时间
                      </el-button>
                    </div>
                </el-col>
                <el-col :span="24">
                    <virtual-scroll v-if="form.data && form.data.length > 300"
                        :data="form.data"
                        :item-size="46"
                        key-prop="key"
                        @change="virtualScrollChange"
                        :virtualized="true"
                    >
                    <el-table v-loading="loading"
                              ref="GGTable"
                              border
                              size="medium"
                              show-summary
                              :summary-method="getSummaries"
                              row-key="id"
                              class="c-th-has-bgcolor"
                              :data="form.dataShow"
                              :show-operation="true"
                              :header-cell-style="headerClass"
                              :cell-style="{'text-align':'center','padding': '0'}"
                              max-height="330"
                              :row-style="{height: '0'}"
                               @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">

                               <el-table-column
      type="selection"
      width="30">
    </el-table-column>
                        <el-table-column prop="name" label="操作" width="70px">
                            <template slot-scope="scope">
                                <el-button v-if="form.data.length > 1" type="text" @click="handleDel(scope.row.key)" :disabled="scope.row.volumeLocation==='转仓中'">
                                    删除
                                </el-button>
                                <el-button type="text" @click="handleCopy(scope.row)">复制</el-button>
                            </template>
                        </el-table-column>
                        <virtual-column label="索引" width="35" type="index"></virtual-column>
                        <el-table-column prop="length" label="长(CM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                              :rules="rules.sanweiCanNotNull" :prop="'dataShow.'+ scope.$index + '.length'"
                                              :key="'dataShow.'+ scope.$index + '.length'">
                                    <el-input :ref="`length.${scope.$index}`" size="small" v-model="scope.row.length" placeholder="请输入"
                                              oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                              @change="e => handleChange(e, scope.row.key, 'length',scope.$index,scope.row.myindex)"
                                              class="classi"
                                              @keydown.enter.native="nextFocus(scope.$index,'length')" :disabled="scope.row.volumeLocation==='转仓中'"> </el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="width" label="宽(CM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                              :rules="rules.sanweiCanNotNull" :prop="'dataShow.'+ scope.$index + '.width'"
                                              :key="'dataShow.'+ scope.$index + '.width'">
                                    <el-input size="small" v-model="scope.row.width" placeholder="请输入"
                                              @change="e => handleChange(e, scope.row.key, 'width',scope.$index,scope.row.myindex)"
                                              class="classi" oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                              @keydown.enter.native="nextFocus(scope.$index,'width')"  :disabled="scope.row.volumeLocation==='转仓中'"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="height" label="高(CM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                              :rules="rules.sanweiCanNotNull" :prop="'dataShow.'+ scope.$index + '.height'"
                                              :key="'dataShow.'+ scope.$index + '.height'">
                                    <el-input size="small" v-model="scope.row.height" placeholder="请输入"
                                              @change="e => handleChange(e, scope.row.key, 'height',scope.$index,scope.row.myindex)"
                                              class="classi" oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                              @keydown.enter.native="nextFocus(scope.$index,'height')"  :disabled="scope.row.volumeLocation==='转仓中'"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="总件数/卡板" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                              :rules="rules.number" :prop="'dataShow.'+ scope.$index + '.number'"
                                              :key="'dataShow.'+ scope.$index + '.number'">
                                    <el-input size="small" v-model.number="scope.row.number" placeholder="请输入"
                                              @change="e => handleChange(e, scope.row.key, 'number',scope.$index,scope.row.myindex)"
                                              class="classi"
                                              @keydown.enter.native="nextFocus(scope.$index,'number')"  :disabled="scope.row.volumeLocation==='转仓中'"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>

                        <el-table-column prop="weight" label="实重(KG)" width="70px">
                            <template slot-scope="scope">
                              <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                            :rules="rules.sanweiCanNotNull" :prop="'dataShow.'+ scope.$index + '.weight'"
                                            :key="'dataShow.'+ scope.$index + '.weight'">
                                <el-input size="small" v-model="scope.row.weight" placeholder="请输入"
                                          @change="e => handleChange(e, scope.row.key, 'weight',scope.$index,scope.row.myindex)"
                                          class="classi"
                                          @keydown.enter.native="nextFocus(scope.$index,'weight')"  :disabled="scope.row.volumeLocation==='转仓中'"></el-input>
                              </el-form-item>

                            </template>
                        </el-table-column>
                        <el-table-column prop="volume" label="实体(CBM)" width="70px">
                            <template slot-scope="scope">
                              <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                            :rules="rules.sanweiCanNotNull" :prop="'dataShow.'+ scope.$index + '.volume'"
                                            :key="'dataShow.'+ scope.$index + '.volume'">
                                <el-input size="small" v-model="scope.row.volume" placeholder="请输入"
                                          @change="e => handleChange(e, scope.row.key, 'volume',scope.$index,scope.row.myindex)"
                                          class="classi"
                                          @keydown.enter.native="nextFocus(scope.$index,'volume')"  :disabled="scope.row.volumeLocation==='转仓中'"></el-input>
                              </el-form-item>
                                <!--                                <span>{{// scope.row.volume}}</span>-->
                            </template>
                        </el-table-column>
                        <el-table-column prop="largeInfo" label="超大件" width="130">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                                              :prop="'dataShow.'+ scope.$index + '.largeInfo'"
                                              :key="'dataShow.'+ scope.$index + '.largeInfo'">
<!--                                    <f-select :dict="'volume_large_info'" v-model="scope.row.largeInfo" :is-need="scope.row.largeInfo"-->
<!--                                              @changet="e => handleChange(e, scope.row.key, 'largeInfo',scope.$index)" class="classi"-->
<!--                                              @keydown.enter.native="nextFocus(scope.$index,'largeInfo')"></f-select>-->
                                    <el-select
                                            :ref="`selectLargeInfo.${scope.$index}`"
                                            class="classi" :dict="'volume_large_info'" v-model="scope.row.largeInfo" :is-need="scope.row.largeInfo"
                                            @change="e => handleChange(e, scope.row.key, 'largeInfo',scope.$index,scope.row.myindex)"
                                            @keydown.enter.native="nextFocus(scope.$index,'largeInfo')"
                                            clearable filterable  :disabled="scope.row.volumeLocation==='转仓中'">
                                        <el-option v-for="(i,d) in GLOBAL.dictListMap['volume_large_info']" :key="d" :value="i.value" :label="i.label"></el-option>
                                    </el-select>
                                    <!--                                    <el-input size="small" v-model="scope.row.volume" placeholder="请输入"-->
                                    <!--                                              @change="e => handleChange(e, scope.row.key, 'volume',scope.$index)"-->
                                    <!--                                              class="classi"-->
                                    <!--                                              type="number"-->
                                    <!--                                              @keydown.enter.native="nextFocus(scope.$index,'volume')"></el-input>-->
                                </el-form-item>

                            </template>
                        </el-table-column>
                          <el-table-column prop="volumeLocationId" label="货物所在仓" width="180px">
                            <template slot-scope="scope">
                              <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                                            :rules="rules.volumeLocationId" :prop="'dataShow.'+ scope.$index + '.volumeLocationId'"
                                            :key="'dataShow.'+ scope.$index + '.volumeLocationId'">
                                              <f-select :data-list="deliveryPointDictList" :is-need="scope.row.volumeLocationId" v-model="scope.row.volumeLocationId" autocomplete="off" @changetAllData="(e)=>changetLocations(e,scope.row.myindex)"  :disabled="scope.row.volumeLocation==='转仓中'" v-if="scope.row.volumeLocation!=='转仓中'"></f-select>
                                               <span v-else>{{ scope.row.volumeLocation }}</span>
                              </el-form-item>

                            </template>
                        </el-table-column>
                         <el-table-column prop="busNo" label="托盘编号/散货编号" width="150px">
                            <template slot-scope="scope">
                                <span>{{scope.row.busNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="volumeWeight" label="体积重(KG)" width="70px">
                            <template slot-scope="scope">
                                <span>{{scope.row.volumeWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="weightVolume" label="重量体(CBM)" width="75px">
                            <template slot-scope="scope">
                                <span>{{scope.row.weightVolume}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="countFeeWeight" label="计费重(KG)" width="70px">
                            <template slot-scope="scope">
                                <span>{{scope.row.countFeeWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="countFeeVolume" label="计费体(CBM)" width="75px">
                            <template slot-scope="scope">
                                <span>{{scope.row.countFeeVolume}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="70px">
                            <template slot-scope="scope">

                                <span>{{scope.row.createTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="录入人" width="70px">
                            <template slot-scope="scope">

                                <span>{{scope.row.createBy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="updateTime" label="修改时间" width="70px">
                            <template slot-scope="scope">

                                <span>{{scope.row.updateTime}}</span>
                            </template>
                        </el-table-column>

                    </el-table>
                    </virtual-scroll>
                    <el-table v-else v-loading="loading"
                                ref="GGTable"
                                border
                                size="medium"
                                show-summary
                                :summary-method="getSummaries"
                                row-key="id"
                                class="c-th-has-bgcolor"
                                :data="form.data"
                                :show-operation="true"
                                :header-cell-style="headerClass"
                                :cell-style="{'text-align':'center','padding': '0'}"
                                max-height="330"
                                :row-style="{height: '0'}"
                                 @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
                                 <el-table-column
      type="selection"
      width="30">
    </el-table-column>
                        <el-table-column prop="name" label="操作" width="70px">
                            <template slot-scope="scope">
                                <el-button v-if="form.data.length > 1" type="text" @click="handleDel(scope.row.key)">
                                    删除
                                </el-button>
                                <el-button type="text" @click="handleCopy(scope.row)">复制</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id" label="编号" width="35px">
                            <template slot-scope="scope">
                                <span>{{scope.row.key}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="length" label="长(CM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                                :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.length'"
                                                :key="'data.'+ scope.$index + '.length'">
                                    <el-input :ref="`length.${scope.$index}`" size="small" v-model="scope.row.length" placeholder="请输入"
                                                oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                                @change="e => handleChange(e, scope.row.key, 'length',scope.$index,scope.row.myindex)"
                                                class="classi" :disabled="scope.row.volumeLocation==='转仓中'"
                                                @keydown.enter.native="nextFocus(scope.$index,'length')"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="width" label="宽(CM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                                :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.width'"
                                                :key="'data.'+ scope.$index + '.width'">
                                    <el-input size="small" v-model="scope.row.width" placeholder="请输入" :disabled="scope.row.volumeLocation==='转仓中'"
                                                @change="e => handleChange(e, scope.row.key, 'width',scope.$index,scope.row.myindex)"
                                                class="classi" oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                                @keydown.enter.native="nextFocus(scope.$index,'width')"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="height" label="高(CM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                                :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.height'"
                                                :key="'data.'+ scope.$index + '.height'">
                                    <el-input size="small" v-model="scope.row.height" placeholder="请输入" :disabled="scope.row.volumeLocation==='转仓中'"
                                                @change="e => handleChange(e, scope.row.key, 'height',scope.$index,scope.row.myindex)"
                                                class="classi" oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                                @keydown.enter.native="nextFocus(scope.$index,'height')"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="总件数/卡板" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                                :rules="rules.number" :prop="'data.'+ scope.$index + '.number'"
                                                :key="'data.'+ scope.$index + '.number'">
                                    <el-input size="small" v-model.number="scope.row.number" placeholder="请输入" :disabled="scope.row.volumeLocation==='转仓中'"
                                                @change="e => handleChange(e, scope.row.key, 'number',scope.$index,scope.row.myindex)"
                                                class="classi"
                                                @keydown.enter.native="nextFocus(scope.$index,'number')"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>

                        <el-table-column prop="weight" label="实重(KG)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                            :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.weight'"
                                            :key="'data.'+ scope.$index + '.weight'">
                                <el-input size="small" v-model="scope.row.weight" placeholder="请输入" :disabled="scope.row.volumeLocation==='转仓中'"
                                            @change="e => handleChange(e, scope.row.key, 'weight',scope.$index,scope.row.myindex)"
                                            class="classi"
                                            @keydown.enter.native="nextFocus(scope.$index,'weight')"></el-input>
                                </el-form-item>

                            </template>
                        </el-table-column>
                        <el-table-column prop="volume" label="实体(CBM)" width="70px">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" label=" "
                                            :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.volume'"
                                            :key="'data.'+ scope.$index + '.volume'">
                                <el-input size="small" v-model="scope.row.volume" placeholder="请输入" :disabled="scope.row.volumeLocation==='转仓中'"
                                            @change="e => handleChange(e, scope.row.key, 'volume',scope.$index,scope.row.myindex)"
                                            class="classi"
                                            @keydown.enter.native="nextFocus(scope.$index,'volume')"></el-input>
                                </el-form-item>
                                <!--                                <span>{{// scope.row.volume}}</span>-->
                            </template>
                        </el-table-column>
                        <el-table-column prop="largeInfo" label="超大件" width="130">
                            <template slot-scope="scope">
                                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                                                :prop="'data.'+ scope.$index + '.largeInfo'"
                                                :key="'data.'+ scope.$index + '.largeInfo'">
            <!--                                    <f-select :dict="'volume_large_info'" v-model="scope.row.largeInfo" :is-need="scope.row.largeInfo"-->
            <!--                                              @changet="e => handleChange(e, scope.row.key, 'largeInfo',scope.$index)" class="classi"-->
            <!--                                              @keydown.enter.native="nextFocus(scope.$index,'largeInfo')"></f-select>-->
                                    <el-select
                                            :ref="`selectLargeInfo.${scope.$index}`"
                                            class="classi" :dict="'volume_large_info'" v-model="scope.row.largeInfo" :is-need="scope.row.largeInfo"
                                            @change="e => handleChange(e, scope.row.key, 'largeInfo',scope.$index,scope.row.myindex)"
                                            @keydown.enter.native="nextFocus(scope.$index,'largeInfo')"
                                            :disabled="scope.row.volumeLocation==='转仓中'"
                                            clearable filterable>
                                        <el-option v-for="(i,d) in GLOBAL.dictListMap['volume_large_info']" :key="d" :value="i.value" :label="i.label"></el-option>
                                    </el-select>
                                    <!--                                    <el-input size="small" v-model="scope.row.volume" placeholder="请输入"-->
                                    <!--                                              @change="e => handleChange(e, scope.row.key, 'volume',scope.$index)"-->
                                    <!--                                              class="classi"-->
                                    <!--                                              type="number"-->
                                    <!--                                              @keydown.enter.native="nextFocus(scope.$index,'volume')"></el-input>-->
                                </el-form-item>

                            </template>
                        </el-table-column>
                         <el-table-column prop="volumeLocationId" label="货物所在仓" width="180px">
                            <template slot-scope="scope">
                              <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                                            :rules="rules.volumeLocationId" :prop="'data.'+ scope.$index + '.volumeLocationId'"
                                            :key="'data.'+ scope.$index + '.volumeLocationId'">
                                              <f-select :data-list="deliveryPointDictList" :is-need="scope.row.volumeLocationId" v-model="scope.row.volumeLocationId" autocomplete="off"
                                              @changetAllData="(e)=>changetLocations(e,scope.row.myindex)" :disabled="scope.row.volumeLocation==='转仓中'" v-if="scope.row.volumeLocation!=='转仓中'"></f-select>
                                                <span v-else>{{ scope.row.volumeLocation }}</span>
                              </el-form-item>

                            </template>
                        </el-table-column>
                         <el-table-column prop="busNo" label="托盘编号/散货编号" width="150px">
                            <template slot-scope="scope">
                                <span>{{scope.row.busNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="volumeWeight" label="体积重(KG)" width="70px">
                            <template slot-scope="scope">
                                <span>{{scope.row.volumeWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="weightVolume" label="重量体(CBM)" width="75px">
                            <template slot-scope="scope">
                                <span>{{scope.row.weightVolume}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="countFeeWeight" label="计费重(KG)" width="70px">
                            <template slot-scope="scope">
                                <span>{{scope.row.countFeeWeight}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="countFeeVolume" label="计费体(CBM)" width="75px">
                            <template slot-scope="scope">
                                <span>{{scope.row.countFeeVolume}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="70px">
                            <template slot-scope="scope">

                                <span>{{scope.row.createTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="录入人" width="70px">
                            <template slot-scope="scope">

                                <span>{{scope.row.createBy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="updateTime" label="修改时间" width="70px">
                            <template slot-scope="scope">

                                <span>{{scope.row.updateTime}}</span>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-col>
            </el-row>
            <!--            <div class="totalBottomVolume">-->
            <!--                <div>-->
            <!--                    共{{keyTotal}}-->
            <!--                </div>-->
            <!--                <el-input v-model="lengthTotal" placeholder="0.00" style="text-align: center"-->
            <!--                          readOnly></el-input>-->
            <!--                <el-input v-model="widthTotal" placeholder="0.00" style="text-align: center"-->
            <!--                          readOnly></el-input>-->
            <!--                <el-input v-model="heightTotal" placeholder="0.00" style="text-align: center"-->
            <!--                          readOnly></el-input>-->
            <!--                <el-input v-model="numberTotal" placeholder="0" style="text-align: center" readOnly></el-input>-->
            <!--                <el-input v-model="cmbTotal" placeholder="0.000" style="text-align: center" readOnly></el-input>-->
            <!--                <el-input v-model="weightTotal" placeholder="0.000" style="text-align: center" readOnly></el-input>-->
            <!--            </div>-->


        </el-form>
        <hs-pay-modal ref="hspay"></hs-pay-modal>
        <money-out-pay-modal ref="moneyOutPay"></money-out-pay-modal>
        <order-detail-modal ref="orderDetail"></order-detail-modal>
        <opera-record-modal ref="operaRecord"></opera-record-modal>

    <el-dialog
      title="修改所在仓"
      :visible="visible"
      width="30%"
      append-to-body
      custom-class="c-custom-dialog"
      :before-close="handleClose"
    >
      <el-form
        :model="formWarehouse"
        :rules="rulesWarehouse"
        ref="formWarehouse"
        label-width="94px"
        size="medium"
      >
      <el-form-item prop="volumeLocationId" label="货物所在仓:">
        <f-select :dict="'delivery_point'" :is-need="formWarehouse.volumeLocationId" v-model="formWarehouse.volumeLocationId" autocomplete="off" @changetAllData="changetLocation"></f-select>
            </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="changeWarehouse"
          >确定</el-button
        >
      </span>
    </el-dialog>
    </el-card>
</template>
<script>
    import {getAction, httpAction, postAction} from '@/api/manage'
    import pick from 'lodash.pick'
    import hsPayModal from "./hsPayModal";
    import moneyOutPayModal from "./moneyOutPayModal";
    // import {ACCESS_TOKEN, ENCRYPTED_STRING} from "@/store/mutation-types"
    import imageDetail from "./ImageDetail";
    import OrderDetailModal from "./OrderDetailModal";
    import OperaRecordModal from "./OperaRecordModal";
    import {sanweixiaoshu} from "../../../utils/pattern";
    import {validateCmb, validateCtn} from "@/utils/validator";
    import VirtualScroll from 'el-table-virtual-scroll';
    import { VirtualColumn } from 'el-table-virtual-scroll';
    import QueryMixins from "@/mixins/QueryMixins.js";


    export default {
        components: {
            hsPayModal,
            moneyOutPayModal, imageDetail,
            OrderDetailModal,
            OperaRecordModal,
            VirtualScroll,
            VirtualColumn
        },
        mixins: [QueryMixins],
        data() {
            return {
                newCargoArrivedTime: '',   //编辑到仓时间
                cargoArrivedStart: '',  //初始的到仓时间
                isFirst: false,//判断是否是第一次录入材积
                First: false,
                loading: false,
                form: {
                    realCtn: '',
                    realKg: '',
                    overFBA: '',
                    overLength: '',
                    overWeight: '',
                    ul: '',
                    etl: '',
                    bluetooth:'',
                    ysfs: '',
                    tempWareHouseLocations: [],
                    storehouseRemark: '',
                    data: [],
                    fbaLable: '',
                    ce:'',
                    fcc:'',
                    rohs:'',
                    maker: '',
                    isCheck:'',
                    productLable: '',
                    mark: '',
                    cartons: '',
                    receiveBy: '',
                    dropBy: '',
                    warehouse: '',
                    location: [],
                    volumes: [],
                    dataShow: [],   //表格显示的区域
                },
                id: '',
                uploadAction: "/file/upload",
                warehouseVisible: true,
                allWarehouses: [],
                headers: {},
                orderNo: '',
                customerName: '',
                estimcatedTotalNumber: '',
                kg: '',
                estimcatedTotalVolume: '',
                ysfs: '',
                numpl: '',
                fileList: [],
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
                fbaLable: '',
                ce:'',
                isCheck:'',
                fcc:'',
                rohs:'',
                bluetooth:'',
                locationInVisible: false,
                maker: '',
                productLable: '',
                mark: '',
                cartons: '',
                columns: [],
                editingKey: '',
                keyTotal: '',
                lengthTotal: '',
                widthTotal: '',
                heightTotal: '',
                numberTotal: '',
                weightTotal: '',
                cmbTotal: '',
                //总体积重
                totalVolumeWeight: 0,
                //总重量体
                totalWeightVolume: 0,
                //总计费重
                totalCountFeeWeight: 0,
                //总计费体
                totalCountFeeVolume: 0,
                countFeeNum: '',
                url: {
                    add: '/interface/ttOrder/updateVolumeInfo',
                    // updateFeeNum: "/interface/ttOrder/updateVolumeCountFeeNum",
                    list: '/interface/ttOrder/getWarehouseVolumeInfo',
                    // delete: '/interface/warehouse/deleteVolume',
                    delete: '/interface/ttOrder/deleteOneVolumeById',
                    getAllLocation: '/interface/warehouse/getAllLocation',
                    updateCargoArrivedTime: '/interface/ttOrder/updateCargoArrivedTime',  //编辑到仓时间接口
                    updateVolumeLocation:"/interface/ttOrder/updateVolumeLocation"
                },
                inputIndex: 0,
                modal: {},
                headeInpLen: ['length', 'width', 'height', 'number', 'weight', 'volume','largeInfo'],
                locations: [],
                wareHouseLocations: [],
                // tempWareHouseLocations: [],
                allLocation: [],
                saveDisabled: false,
                time: 5,
                buttonContent: '保存',
                rules: {
                    realCtn: [
                        {required: true, message: '请输入实际总件数', trigger: 'blur'},
                        {pattern: /^[0-9]\d*$/, message: '只能输入整数', trigger: 'blur'},

                    ],
                    realKg: [
                        {required: false, message: '请输入实际总重量', trigger: 'blur'},
                        {pattern: sanweixiaoshu, message: '最多是10位整数的三位小数', trigger: 'blur'}
                    ],
                    length: [{required: true, message: '请输入长', trigger: 'blur'}],
                    width: [{required: true, message: '请输入宽', trigger: 'blur'}],
                    height: [{required: true, message: '请输入高', trigger: 'blur'}],
                    number: [{required: true, message: '不能为空', trigger: 'blur'},
                      {validator:validateCtn,trigger:'change'}],
                  sanweiCanNull:[{validator:validateCmb,trigger:'change'}],
                  sanweiCanNotNull:[
                    {required: true, message: '不能为空', trigger: 'blur'},
                    {validator:validateCmb,trigger:'change'}
                  ],
                    // location: [
                    //     {required: true, message: '请选择入库库位!', trigger: 'blur'},
                    //
                    // ],
                },
                visible:false,
                formWarehouse:{},
                rulesWarehouse:{
                     volumeLocationId: [
                        {required: true, message: '请选择货物所在仓', trigger: 'blur'},

                    ],
                },
                disableMixinMounted:true,
                deliveryPointDictList:[],
            };
        },
        computed: {
            importExcelUrl: function () {
                // return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
            },
            // 计算总和
            summary () {
                let lengthSum = 0,widthSum = 0, heightSum = 0, numberSum = 0, weightSum = 0, volumeSum = 0,volumeWeightSum = 0, weightVolumeSum = 0, countFeeWeightSum = 0, countFeeVolumeSum = 0;
                this.form.data.forEach(item => {
                    lengthSum = lengthSum + (parseFloat(item.length) || 0);
                    widthSum = widthSum + (parseFloat(item.width) || 0);
                    heightSum = heightSum + (parseFloat(item.height) || 0);
                    numberSum = numberSum + (parseFloat(item.number) || 0);
                    weightSum = weightSum + (parseFloat(item.weight) || 0);
                    volumeSum = volumeSum + (parseFloat(item.volume) || 0);
                    volumeWeightSum = volumeWeightSum + (parseFloat(item.volumeWeight) || 0);
                    weightVolumeSum = weightVolumeSum + (parseFloat(item.weightVolume) || 0);
                    countFeeWeightSum = countFeeWeightSum + (parseFloat(item.countFeeWeight) || 0);
                    countFeeVolumeSum = countFeeVolumeSum + (parseFloat(item.countFeeVolume) || 0);
                })
                return { lengthSum: lengthSum.toFixed(3),widthSum: widthSum.toFixed(3), heightSum: heightSum.toFixed(3), numberSum: numberSum.toFixed(0), weightSum : weightSum.toFixed(3), volumeSum: volumeSum.toFixed(3),volumeWeightSum: volumeWeightSum.toFixed(3), weightVolumeSum : weightVolumeSum.toFixed(3), countFeeWeightSum: countFeeWeightSum.toFixed(3), countFeeVolumeSum: countFeeVolumeSum.toFixed(3)}
            },
          buttonShowList() {
            return {
              'bcdcsj':this.$btnIsShow('warehouse/Main','0','保存到仓时间'),
              'xgszc':this.$btnIsShow('warehouse/Main','0','修改所在仓'),

              'checkVolume':this.$btnIsShow('warehouse/Main','0','审核材积'),
              'add':this.$btnIsShow('warehouse/Main','0','新增一行'),
              'bc':this.$btnIsShow('warehouse/Main','0','保存'),
            }
          }
        },
        mounted() {
            document.addEventListener('keyup', this.handleKeyCombination);
        },
        beforeDestroy() {
            // 在组件销毁前移除事件监听
            document.removeEventListener('keyup', this.handleKeyCombination);
        },
        created() {
            // const token = Vue.ls.get(ACCESS_TOKEN);
            const token = "";
            this.headers = {"X-Access-Token": token};
            this.getAllLocation()
            sessionStorage.setItem('volumeOrderNo', '')
        },
        watch: {
            '$route': {
                deep: true,
                immediate:true,
                handler(to, from) {
                    if(from && from.path && from.path == '/warehouse/Main') {
                        console.log('移出')
                        document.removeEventListener('keyup', this.handleKeyCombination);
                    }
                    if(to && to.path && to.path == '/warehouse/Main') {
                        console.log('添加')
                        document.addEventListener('keyup', this.handleKeyCombination);
                    }
                    if(this.$route.query&&this.$route.query.orderNo){
                        this.orderNo = this.$route.query.orderNo
                        this.onSearch()
                    }
                }
            }
        },
        methods: {
            virtualScrollChange(val) {
                this.$set(this.form, 'dataShow', val);
            },
            handleKeyCombination(e) {
                if(e.key == 'F8') {
                    this.save(false);
                }
                if(e.key == 'F9' || (e.altKey && e.key == 'n')) {
                    this.handleAdd('length.'+(this.form.data.length));
                }
            },
          handleUpdateCargoArrivedTime() {
            this.$store.commit("setFullscreenLoading", true);
            postAction(this.url.updateCargoArrivedTime, {orderNo: this.orderNo, cargoArrivedTime: this.cargoArrivedStart, newCargoArrivedTime: this.newCargoArrivedTime,isWareHourse : "1",isCheck:"0"})
              .then((res) => {
                this.$store.commit("setFullscreenLoading", false);
                if(res.success){
                    this.$message.success("操作成功！")
                }
              }).catch(err => {
                this.$store.commit("setFullscreenLoading", false);
              })
          },
          radioClick(e,item){
              console.log(e,item)
            e === this.form[item]? (this.form[item] ='') :(this.form[item] = e)
          },
            headerClass({row, column, rowIndex, columnIndex}) {
                if (['实重(KG)', '体积重(KG)', '计费重(KG)'].indexOf(column.label) !== -1) {
                    return 'text-align: center; fontSize: 13px; color: #2d8cf0'
                } else if (['实体(CBM)', '重量体(CBM)', '计费体(CBM)'].indexOf(column.label) !== -1) {
                    return 'text-align: center; fontSize: 13px; color: green'
                }
                return 'text-align: center; fontSize: 13px'
            },
            //审核材积
            checkVolume() {
                let that = this;
                //先保存 在审核

                if (!that.orderNo) {
                    this.$message.warning('请输入单号在审核材积');
                    return false;
                }

                this.$confirm("确定审核材积，审核成功之后该单号的材积无法在修改并且返回下一个需要审核的单号?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                    closeOnClickModal: false,
                }).then(() => {
                    postAction('/warehouse/arrive/checkAgain', {
                        orderNo: this.orderNo,
                        warehouseId: this.form.warehouseId,
                        warehouseName: this.form.warehouse
                    })
                        .then(res => {
                            if (res.success) {
                                this.$confirm("审核成功，获取到下一个需要审核的单号：是否继续审核" + res.result + "?", "提示", {
                                    confirmButtonText: "是",
                                    cancelButtonText: "否",
                                    type: "success",
                                    closeOnClickModal: false,
                                }).then(() => {
                                    that.$set(that, 'orderNo', res.result);
                                    that.onSearch();
                                })
                            } else {
                                this.$alert('成功；' + res.message, '审核结果', {
                                    dangerouslyUseHTMLString: true
                                });
                            }
                        })
                })

            },
            oninput(e) {
                e.target.value = e.target.value.match(/^\d*(\.?\d{0,3})/g)[0]

            },
            getAllLocation() {
                getAction(this.url.getAllLocation).then((res) => {
                    // console.log(res)
                    let list = res.result;
                    if (list && list.length > 0) {
                        for (let i = 0; i < list.length; i++) {
                            let obj = list[i];
                            //{{ item.warehouse + " - " + item.warehouseLocationCode }}
                            obj.label = list[i].warehouse + "-" + list[i].warehouseLocationCode;
                        }
                    }
                    this.totalLocations = list
                    this.allLocation = list
                    // let count = [];
                    // this.allLocation.forEach(item => {
                    //     count.push(item.warehouse)
                    // });
                    // this.allWarehouses = Array.from(new Set(count));
                    // console.log('w',this.allWarehouses)
                })
            },
            locationChange(val) {
                // console.log('locationChange',val)
                if (val.length === 0) {
                    this.allLocation = {...this.totalLocations};
                    this.$set(this.form, 'warehouse', '');
                    this.$set(this.form, 'warehouseId', '');
                } else {
                    // console.log('选择库位更改显示的仓库',val)
                    let fi = this.totalLocations.filter(location => {
                        return location.warehouseLocationCode === val
                    })
                    // this.form.warehouse = fi[0].warehouse
                    // console.log('选的仓库',fi)
                    if (fi && fi.length > 0){
                        this.$set(this.form, 'warehouse', fi[0].warehouse);
                        this.$set(this.form, 'warehouseId', fi[0].warehouseId);
                    }else {
                        this.$set(this.form, 'warehouse', '');
                        this.$set(this.form, 'warehouseId', '');
                    }

                    // this.allLocation = this.totalLocations.filter(i =>{return i.warehouseId === fi[0].warehouseId})

                }

            },
            nextFocusRef(refs) {
                let that = this;
                let sel = that.$refs[refs];
                if (sel) {
                    sel.focus();
                }
            },
            nextFocus(i, idx, e) {
                let that = this;
                // console.log('i->',i)
                // console.log('idx->',idx)
                let inputArray = document.getElementsByClassName("classi");  //通过class去获取
                let size = this.headeInpLen.length;
                //判断第几行
                let rowNum = i * (size - 1)
                // console.log('第几行->',rowNum)
                //判断第几个input
                let inputLineNum = this.headeInpLen.indexOf(idx);
                // consoloe.log('第几个input->', inputLineNum)
                //最后一个input 再点就要换行
                if (inputLineNum >= (size - 1) || inputLineNum === -1) {
                    // 计算换行后聚焦第几个
                    rowNum = (i + 1) * size - i
                    inputLineNum = rowNum;
                } else {
                    inputLineNum = rowNum + inputLineNum + 1;
                }
                if (inputLineNum + i === inputArray.length) {
                    return
                }
                let nextInput = inputArray[i + inputLineNum].childNodes;
                // console.log('第节点->',i+inputLineNum)

                //去打印nextInput就会发行我们要的节点在第二个，所以去取nextInput[1]
                let sel = that.$refs['selectLargeInfo.' + i];
                if (idx === 'volume') {

                    // nextSelect.focus();
                    // nextInput[1].focus();
                    // nextInput[2].focus();
                    // console.log("是否获取到下拉框",sel);
                    if (sel) {
                        sel.focus();
                    }
                }else {
                    nextInput[1].focus();
                    if (sel) {
                        sel.blur();
                    }
                }
                // console.log('-------------------')

            },
            calcVolAndCalc() {
                // consoloe.log('统计材积信息', this.form.data)
                let lgt = 0
                let wdt = 0
                let hgt = 0
                let nbt = 0
                let cbt = 0
                let wgt = 0
                this.form.data.forEach((obj, index) => {
                    obj.key = index + 1
                    if (obj.length !== '') {
                        lgt = lgt + parseFloat(obj.length)
                    }
                    if (obj.width !== '') {
                        wdt = wdt + parseFloat(obj.width)
                    }
                    if (obj.height !== '') {
                        hgt = hgt + parseFloat(obj.height)
                    }
                    if (obj.number !== '') {
                        nbt = nbt + parseInt(obj.number)
                    }
                    if (obj.volume !== '') {
                        cbt = cbt + parseFloat(obj.volume)
                    }
                    if (obj.weight !== '') {
                        wgt = wgt + parseFloat(obj.weight)
                    }
                })
                this.lengthTotal = lgt.toFixed(2)
                this.widthTotal = wdt.toFixed(2)
                this.heightTotal = hgt.toFixed(2)
                this.numberTotal = nbt
                this.weightTotal = wgt.toFixed(2)
                this.cmbTotal = cbt.toFixed(3)
                let num = this.form.data.length
                this.keyTotal = num + '条'
            },
            volumeDetail(id, record) {
                let that = this
                that.id = id
                that.modal = record
                that.orderNo = that.modal.orderNo
                that.customerName = that.modal.customerName
                that.estimcatedTotalNumber = that.modal.estimcatedTotalNumber
                that.kg = that.modal.kg
                that.estimcatedTotalVolume = that.modal.estimcatedTotalVolume
                that.ysfs = that.modal.ysfs

                getAction(this.url.list, {"id": id}).then((res) => {
                    if (res.code === 200) {
                      this.orderNo = res.result.osOrder.orderNo;
                      that.deliveryPointDictList = res.result.deliveryPoints;
                      console.log("赋值货物所在仓库列表",that.deliveryPointDictList);
                        if (!res.result.volumes || res.result.volumes.length === 0) {
                            this.isFirst = true;
                        } else {
                            this.isFirst = false;
                        }
                        that.allLocation = res.result.allLocation;
                        // 增加key值用于删除
                        let data = res.result.volumes;
                        if (data.length > 0) {
                            data.forEach((item, index) => {
                                item.key = index + 1;
                                item.myindex = index;
                            })
                        }
                        // if (res.result.volumes.length <= 0) {
                        //     this.handleAddpl(5)
                        // }

                        if (res.result.fbaLable === 'YES') {
                            that.fbaLable = 1

                        } else if (res.result.fbaLable === 'NO') {
                            that.fbaLable = 2
                        } else {
                            that.fbaLable = 0
                        }
                        if (res.result.ce === 'YES'){
                          that.ce = 1
                        }else{
                          that.ce = 0
                        }
                        if (res.result.fcc === 'YES'){
                          that.fcc = 1
                        }else {
                          that.fcc = 0
                        }
                        if (res.result.rohs === 'YES'){
                          that.rohs = 1
                        }else {
                          that.rohs = 0
                        }
                        if (res.result.bluetooth === 'YES'){
                          that.bluetooth = 1
                        }else {
                          that.bluetooth = 0
                        }

                        if (res.result.maker === 'YES') {
                            that.maker = 1
                        } else if (res.result.maker === 'NO') {
                            that.maker = 2
                        } else {
                            that.maker = 0
                        }

                        if (res.result.productLable === 'YES') {
                            that.productLable = 1
                        } else if (res.result.productLable === 'NO') {
                            that.productLable = 2
                        } else {
                            that.productLable = 0
                        }
                        if (res.result.mark === 'YES') {
                            that.mark = 1
                        } else if (res.result.mark === 'NO') {
                            that.mark = 2
                        } else {
                            that.mark = 0
                        }

                        if (res.result.cartons === 'YES') {
                            that.cartons = 1
                        } else if (res.result.cartons === 'NO') {
                            that.cartons = 2
                        } else {
                            that.cartons = 0
                        }
                        that.isCheck = res.result.isCheck
                        this.$set(this.form, 'warehouse', res.result.warehouse);
                        this.form.allVolumeLocation = res.result.allVolumeLocation
                        let model = {
                            'realCtn': res.result.ctn,
                            'realKg': res.result.osOrder.realKg,
                            'overFBA' : res.result.overFBA,
                             'overLength' : res.result.overLength,
                             'overWeight' : res.result.overWeight,
                             'ul': res.result.ul,
                             'etl': res.result.etl,
                             'bluetooth':res.result.bluetooth,
                            'storehouseRemark': res.result.osOrder.storehouseRemark,
                            'location': res.result.wareHouseLocations.join(','),
                            'tempWareHouseLocations': res.result.tempWareHouseLocations.join(','),
                            'warehouse': res.result.warehouse,
                            'warehouseId': res.result.warehouseId,
                            'data': data,
                            'problemTypeStr' : res.result.problemTypeStr,
                            'isCheck':res.result.isCheck
                        }
                        console.log(res.result.allVolumeLocation,'res.result.allVolumeLocation1');
                        that.$nextTick(() => {
                            that.form = pick(model, 'realCtn', 'realKg','overFBA','overLength','overWeight','ul','etl','bluetooth','storehouseRemark',
                                'location', 'tempWareHouseLocations', 'warehouse', 'data','problemTypeStr')
                            if (res.result.volumes.length <= 0) {
                                that.handleAddpl(5)
                            }
                        })

                    } else {
                        that.isFirst = false;
                        that.keyTotal = ''
                        that.lengthTotal = ''
                        that.widthTotal = ''
                        that.heightTotal = ''
                        that.numberTotal = ''
                        that.weightTotal = ''
                        that.cmbTotal = ''
                        that.customerName = ''
                        that.estimcatedTotalNumber = ''
                        that.kg = ''
                        that.estimcatedTotalVolume = ''
                        that.ysfs = ''
                        let model = {
                            'realCtn': '',
                            'realKg': '',
                            'overFBA': '',
                             'overLength' : '',
                             'overWeight' : '',
                             'ul' : '' ,
                             'etl' :'',
                             'bluetooth':'',
                            'location': '',
                            'storehouseRemark': '',
                            'data': [],
                          'problemTypeStr' : ''
                        }
                        that.$nextTick(() => {
                            that.form = pick(model, 'realCtn', 'realKg','overFBA','overLength','overWeight',
                                'ul','etl','bluetooth','location', 'storehouseRemark', 'data','problemTypeStr')
                        })
                    }
                    that.calcVolAndCalc()
                })
            },
            //组装数据 方法
            pinVolumeData(isChecked) {
                let that = this;
                let location = [];
                let tempWareHouseLocations = [];
                if (this.form.location) {
                    location.push(this.form.location);
                }
                // consoloe.log(this.form.location);
                if (this.form.tempWareHouseLocations) {
                    tempWareHouseLocations.push(this.form.tempWareHouseLocations);
                }
                this.saveDisabled = true;
                let fbaLable = ''
                if (that.fbaLable === 1) {
                    fbaLable = 'YES'
                } else if (that.fbaLable === 2) {
                    fbaLable = 'NO'
                }
                let ce = ''
                if(that.ce === 1){
                  ce = 'YES'
                }else{
                  ce = 'No'
                }
                let fcc = ''
               if (that.fcc === 1){
                 fcc = 'YES'
               }else {
                 fcc = 'NO'
               }
               let rohs = ''
               if (that.rohs === 1){
                 rohs = 'YES'
               }else {
                 rohs = 'NO'
               }
              let bluetooth = ''
              if (that.bluetooth === 1){
                bluetooth = 'YES'
              }else {
                bluetooth = 'NO'
              }
                let maker = ''
                if (that.maker === 1) {
                    maker = 'YES'
                } else if (that.maker === 2) {
                    maker = 'NO'
                }
                let productLable = ''
                if (that.productLable === 1) {
                    productLable = 'YES'
                } else if (that.productLable === 2) {
                    productLable = 'NO'
                }
                let mark = ''
                if (that.mark === 1) {
                    mark = 'YES'
                } else if (that.mark === 2) {
                    mark = 'NO'
                }
                let cartons = ''
                if (that.cartons === 1) {
                    cartons = 'YES'
                } else if (that.cartons === 2) {
                    cartons = 'NO'
                }
                for (let i = 0; i < that.form.data.length; i++) {
                    let ind = i + 1
                    let obj = that.form.data[i]

                    // if (Number(obj.length) > 300) {
                    //     that.$message.warning('第' + ind + '行长度不能超过300cm,请修改！');
                    //     that.saveDisabled = false;
                    //     return;
                    // }
                    // if (Number(obj.width) > 300) {
                    //     that.$message.warning('第' + ind + '行宽度不能超过300cm,请修改！');
                    //     that.saveDisabled = false;
                    //     return;
                    // }
                    // if (Number(obj.height) > 300) {
                    //     that.$message.warning('第' + ind + '行高度不能超过300cm,请修改！');
                    //     that.saveDisabled = false;
                    //     return;
                    // }
                    if (obj.volume === 0) {
                        that.$message.warning('第' + ind + '行，材积信息不正确！');
                        that.saveDisabled = false;
                        return;
                    }
                }
                let mod = {
                    id: that.id,
                    orderNo: that.orderNo,
                    ctn: this.form.realCtn,
                    realKg: this.form.realKg,
                    overFBA: this.form.overFBA,
                    overLength : this.form.overLength,
                    overWeight : this.form.overWeight,
                    ul: this.form.ul,
                    etl: this.form.etl,
                    bluetooth: this.form.bluetooth,
                    location: location,
                    tempLocation: tempWareHouseLocations,
                    storehouseRemark: this.form.storehouseRemark,
                    fbaLable: this.form.fbaLable,
                    ce: this.form.ce,
                    fcc: this.form.fcc,
                    rohs: this.form.rohs,
                    maker: this.form.maker,
                    productLable: this.form.productLable,
                    mark: this.form.mark,
                    cartons: this.form.cartons,
                    realCmb: that.cmbTotal,
                    volumes: this.form.data,
                    volumes2: this.form.data,
                    numberTotal: that.numberTotal,
                    receiveBy: this.form.receiveBy,
                    dropBy: this.form.dropBy,
                    warehouse: this.form.warehouse,
                    warehouseId: this.form.warehouseId,
                    countFeeNum: this.countFeeNum,
                    isCheck: this.form.isCheck,
                    isWarehouse:'1'
                }
                let httpurl = that.url.add;
                let method = 'post';
                httpAction(httpurl, method, mod).then((res) => {
                    if (res.success) {
                      // this.forceByOrder();
                        if (!isChecked) {
                            // that.$message.success(res.message);
                            this.$listeners.updataDemo();
                            if (that.id === '') {
                                this.updateAfterQuery(that.orderNo)
                            } else {
                                this.volumeDetail(that.id, this.modal)
                            }
                            that.$message.success(res.message);
                        } else {                                console.log('5')
                            that.checkVolume();
                        }

                    }

                }).finally(() => {
                    that.confirmLoading = false;
                    that.changeSaveDisabled();
                })


            },

             updateFeeNum() {
              // console.log('updateFeeNum')
              // let parm = {
              //   orderNo: this.orderNo,
              //   countFeeNum: this.countFeeNum,
              // }
              // console.log("后" + parm.countFeeNum)
              // await postAction(this.url.updateFeeNum, parm)
              //     .then((res) => {
              //     })
            },

            forceByOrder(){
              this.handleQuery().then(res => {
                  // this.updateFeeNum().then(res => {
                      this.$emit("init");
                  // });
              })
            },
            // 数据量大于300条的时候保存需要校验数据
            tableDataMore() {
                let checkIndex = null;
                console.log(this.form.data, 'this.form.data')
                if(this.form.data.length > 300) {
                    try {
                        this.form.data.forEach((item, index) => {
                            if(item.length.toString() && item.width.toString() && item.height.toString() && item.number.toString() && item.weight.toString() && item.volume.toString()) {
                                // console.log(item.length && item.width, item.length, checkIndex, 'index')
                            } else {
                                checkIndex = item.key;
                                console.log(item.length && item.width, item.length, checkIndex, 'index')
                                throw Error(checkIndex); //满足条件，跳出循环
                            }
                        });
                    } catch (e) {
                    }
                }
                return checkIndex;
            },
            save(isChecked) {
                // 使用document.activeElement来找到当前拥有焦点的元素，并使其失去焦点
                if (document.activeElement && document.activeElement.blur) {
                    document.activeElement.blur();
                }
                // 保存按钮禁用不触发保存
                if(this.saveDisabled) {
                    this.$message.warning('5s之后再进行保存');
                    return;
                }
                let that = this;
                this.$refs['form'].validate(valid => {
                    if (valid) {
                        let checkIndex = that.tableDataMore();
                        if(checkIndex !== null) {
                            this.$message.warning(checkIndex+'条数据不符合规范， 请修改之后重新保存');
                            return;
                        }
                        that.confirmLoading = true;
                        let httpurl = that.url.add;
                        let method = 'post';
                        let tishi = "";
                        if (Number(that.numberTotal) > Number(this.form.realCtn)) {
                            tishi = "实际件数小于总件数是否进行保存?";
                        } else if (that.form.cartons !== 2 && Number(that.numberTotal) < Number(this.form.realCtn)) {
                            tishi = "实际件数大于总件数是否进行保存?";
                        } else {
                            tishi = "是否确定保存！";
                        }
                        if (this.isFirst && !isChecked) {
                            this.$confirm(tishi, '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'success',
                                closeOnClickModal: false,
                            }).then(() => {

                             that.pinVolumeData(isChecked);
                            })

                        } else {
                            // 第2次保存  等于总件数不提示  实际件数大于总数  又是托盘不提示
                            if (((!isChecked && Number(that.numberTotal) < Number(this.form.realCtn)) && that.form.cartons === 2)
                                || Number(that.numberTotal) === Number(this.form.realCtn)) {
                               that.pinVolumeData(isChecked);
                            } else {
                                if (!isChecked) {
                                    this.$confirm(Number(that.numberTotal) > Number(this.form.realCtn) ? '实际件数小于总件数是否进行保存?' : '实际件数大于总件数是否进行保存?？', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'success',
                                        closeOnClickModal: false,
                                    }).then(() => {
                                        that.pinVolumeData(isChecked);
                                    }).catch(() => {

                                    })
                                }else {
                                    that.pinVolumeData(isChecked);
                                }

                            }
                        }
                    } else {
                        that.saveDisabled = false;
                    }
                })
            },
            async changeSaveDisabled() {
                let clock = window.setInterval(() => {
                    this.time--
                    this.buttonContent = this.time + 's后可再次保存'
                    if (this.time < 0) {     //当倒计时小于0时清除定时器
                        window.clearInterval(clock)
                        this.saveDisabled = false
                        this.buttonContent = '保存'
                        this.time = 5
                    }
                }, 1000)
            },
            handleDel(key) {
                const obj = this.form.data[key - 1]
                let that = this
                if (obj.hasOwnProperty('id')) {
                    this.$confirm('确定删除？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error',
                        closeOnClickModal: false,
                    }).then(() => {
                        getAction(that.url.delete, {'id': obj.id}).then(res => {
                            if (res.success) {
                                that.$message.success(res.message);
                                that.form.data.splice(key - 1, 1)
                                that.calcVolAndCalc()
                            }
                        })
                    }).catch(() => {
                    });
                } else {
                    that.form.data.splice(key - 1, 1)
                    that.calcVolAndCalc()
                }


            },
            handleCopy(record) {
                var num = this.form.data.length - 1
                var nextKey = this.form.data[num].key + 1
                this.form.data.push({
                    key: nextKey,
                    length: record.length,
                    width: record.width,
                    height: record.height,
                    number: record.number,
                    volume: record.volume,
                    largeInfo : record.largeInfo,
                    weight: record.weight,
                    volumeWeight: record.volumeWeight,
                    weightVolume: record.weightVolume,
                    countFeeWeight: record.countFeeWeight,
                    countFeeVolume: record.countFeeVolume,
                });
                const newData = [...this.form.data];
                const target = newData.filter(item => nextKey === item.key)[0];
                this.editingKey = nextKey;
                if (target) {
                    target.editable = true;
                    this.form.data = newData;
                }
                this.calcVolAndCalc()


            },
            handleChange(value, key, column, index,myindex) {
                this.form.data[index][column] = value
                let cul = column
                let lgt = 0;
                let wdt = 0;
                let hgt = 0;
                let nbt = 0;
                let cbt = 0;
                let wgt = 0;
                this.form.data.forEach((obj, indexobj) => {
                    if (cul === 'length') {
                        if (obj.length !== '') {
                            lgt = lgt + parseFloat(obj.length)
                        }
                        this.lengthTotal = parseFloat(lgt).toFixed(2)
                    }
                    if (cul === 'width') {
                        if (obj.width !== '') {
                            wdt = wdt + parseFloat(obj.width)
                        }
                        this.widthTotal = wdt.toFixed(2)
                    }
                    if (cul === 'height') {
                        if (obj.height !== '') {
                            hgt = hgt + parseFloat(obj.height)
                        }
                        this.heightTotal = hgt.toFixed(2)
                    }
                    if (cul === 'number') {
                        if (obj.number !== '') {
                            nbt = nbt + parseInt(obj.number)
                        }
                        this.numberTotal = nbt
                    }
                    if (cul === 'weight') {
                        if (obj.weight !== '') {
                            wgt = wgt + parseFloat(obj.weight)
                        }
                        this.weightTotal = wgt.toFixed(2);
                        this.$set(this.form,'realKg',this.weightTotal);
                    }
                    if (cul === 'volume') {
                        if (obj.volume !== '') {
                            cbt = cbt + parseFloat(obj.volume)
                        }
                        this.cmbTotal = cbt.toFixed(3)
                    } else if (indexobj == myindex) {
                        let real_cmb = (obj.length * obj.width * obj.height * obj.number) / 1000000;
                        real_cmb = real_cmb.toFixed(3);
                        obj.volume = real_cmb

                        if (obj.volume !== '') {
                            cbt = Number(cbt) + Number(obj.volume)
                        }
                        this.cmbTotal = cbt.toFixed(3)
                    } else {
                        if (obj.volume !== '') {
                            cbt = Number(cbt) + Number(obj.volume)
                        }
                        this.cmbTotal = cbt.toFixed(3)
                    }
                })
                let num = this.form.data.length
                this.keyTotal = num + '条'
            },
            edit(key) {
                const newData = [...this.form.data];
                const target = newData.filter(item => key === item.key)[0];
                this.editingKey = key;
                if (target) {
                    target.editable = true;
                    this.$set(this.form, 'data', newData);
                }
            },

            handleAdd(refs) {
                let data = this.form.data;
                if (this.form.data.length === 0) {
                    let nkey = 1
                    data.push({
                        key: nkey,
                        length: '',
                        width: '',
                        height: '',
                        number: '',
                        volume: '',
                        weight: '',
                        myindex:this.form.data.length
                    });
                    this.$set(this.form, 'data', data);
                    this.keyTotal = nkey
                    this.edit(nkey)

                } else {
                    let num = this.form.data.length
                    let nextKey = num + 1
                    data.push({
                        key: nextKey,
                        length: '',
                        width: '',
                        height: '',
                        number: '',
                        volume: '',
                        myindex:this.form.data.length
                    });
                    this.$set(this.form, 'data', data);
                    this.edit(nextKey)
                }
                let num = this.form.data.length
                this.keyTotal = num + '条'
                if(refs) {
                    this.$nextTick(() => {
                        this.nextFocusRef(refs);
                    })
                }
            },
            handleAddpl(defaultVal) {
                let that = this
                let num = this.numpl || defaultVal

                if (num === '') {
                    that.$message.error('请输入新增行数！');
                    return;
                }
                for (let i = 0, len = num; i < len; i++) {
                    that.handleAdd()
                }

            },

            hsdetail() {
                this.modal.customerName = this.customerName
                this.modal.orderNo = this.orderNo;
                this.$refs.hspay.editHsPay(this.modal)
            },

            modetail() {
                this.modal.customerName = this.customerName
                this.modal.orderNo = this.orderNo;
                this.$refs.moneyOutPay.editMoPay(this.modal)
            },
            nextOrder() {
                this.$listeners.selectKeys();
            },
            updateAfterQuery(){
                let that = this
                sessionStorage.setItem('volumeOrderNo', that.orderNo)
                this.$emit('changeOrderNo', this.orderNo)
                this.$emit("getImageMessage", {orderNo: that.orderNo})
                getAction(this.url.list, {"orderNo": that.orderNo}).then((res) => {
                    // console.log(res)
                    if (res.code === 200) {
                      this.orderNo = res.result.osOrder.orderNo;
                        // console.log("2222",res.result.id)
                        // if (res.result.id !== '') {
                        const obj = {}
                        obj.id = res.result.id;
                        obj.orderNo = this.orderNo

                        // }
                        // 增加key值用于删除
                        let data = res.result.volumes;
                        if (data.length > 0) {
                            data.forEach((item, index) => {
                                item.key = index + 1;
                                item.myindex = index;
                            })
                        }
                        // if (res.result.volumes.length <= 0) {
                        //     this.handleAddpl(1)
                        // }

                        let newObj = {};
                        // console.log(res.result.wareHouseLocations)
                        // if (res.result.wareHouseLocations.length > 0){
                        //     newObj.location = res.result.wareHouseLocations
                        // that.form.location = {...newObj.location}
                        // }

                        // newObj.tempWareHouseLocations = res.result.tempWareHouseLocations
                        // console.log("65465465456",res.result)
                        this.locationInVisible = res.result.locationInVisible   //0可修改 1不可修改  必填可以修改
                        // 如果有入库库位就不能修改，没有就可以
                        if (res.result.wareHouseLocations.length == 0) {
                            this.locationInVisible = false;
                        }
                        // this.wareHouseLocations = res.result.wareHouseLocations
                        // this.allLocation = res.result.allLocation
                        // that.form= pick(newObj, 'location', 'tempWareHouseLocations')


                        // that.form.tempWareHouseLocations = {...newObj.tempWareHouseLocations}

                        if (res.result.fbaLable === 'YES') {
                            that.fbaLable = 1

                        } else if (res.result.fbaLable === 'NO') {
                            that.fbaLable = 2
                        } else {
                            that.fbaLable = 0
                        }
                        if(res.result.ce === 'YES'){
                          that.ce = 1
                        }else{
                          that.ce = 0
                        }
                        if (res.result.fcc === 'YES'){
                          that.fcc = 1
                        }else {
                          that.fcc = 0
                        }
                        if (res.result.rohs === 'YES'){
                          that.rohs = 1
                        }else {
                          that.rohs = 0
                        }
                        if (res.result.bluetooth === 'YES'){
                          that.bluetooth = 1
                        }else {
                          that.bluetooth = 0
                        }
                        if (res.result.maker === 'YES') {
                            that.maker = 1
                        } else if (res.result.maker === 'NO') {
                            that.maker = 2
                        } else {
                            that.maker = 0
                        }
                        // console.log("333")
                        if (res.result.productLable === 'YES') {
                            that.productLable = 1
                        } else if (res.result.productLable === 'NO') {
                            that.productLable = 2
                        } else {
                            that.productLable = 0
                        }
                        if (res.result.mark === 'YES') {
                            that.mark = 1
                        } else if (res.result.mark === 'NO') {
                            that.mark = 2
                        } else {
                            that.mark = 0
                        }

                        if (res.result.cartons === 'YES') {
                            that.cartons = 1
                        } else if (res.result.cartons === 'NO') {
                            that.cartons = 2
                        } else {
                            that.cartons = 0
                        }
                        that.isCheck = res.result.isCheck;
                        that.customerName = res.result.osOrder.customer
                        that.estimcatedTotalNumber = res.result.osOrder.ctn
                        that.kg = res.result.osOrder.kg
                        that.estimcatedTotalVolume = res.result.osOrder.cmb
                        that.ysfs = res.result.osOrder.transportName
                        that.form.receiveBy = res.result.receiveBy;
                        that.form.dropBy = res.result.dropBy;
                        that.newCargoArrivedTime = res.result.osOrder.cargoArrivedStart
                        this.countFeeNum = res.result.countFeeNum;
                        if (res.result.osOrder.realCtn != null && res.result.osOrder.realCtn > 0) {
                            this.First = true;
                        }

                        let model = {
                            'realCtn': res.result.osOrder.realCtn,
                            'realKg': res.result.osOrder.realKg,
                            'overFBA': res.result.overFBA,
                            'overLength' : res.result.overLength,
                            'overWeight' : res.result.overWeight,
                            'ul' : res.result.ul,
                            'etl' : res.result.etl,
                            'bluetooth' : res.result.bluetooth,
                            'storehouseRemark': res.result.osOrder.storehouseRemark,
                            'tempWareHouseLocations': res.result.tempWareHouseLocations.join(','),
                            'location': res.result.wareHouseLocations.join(','),
                            'warehouse': res.result.warehouse,
                            'warehouseId': res.result.warehouseId,
                            'fbaLable': res.result.fbaLable,
                            'ce':res.result.ce,
                            'fcc':res.result.fcc,
                            'rohs':res.result.rohs,
                            'isCheck': res.result.isCheck,
                            'maker': res.result.maker,
                            'productLable': res.result.productLable,
                            'mark': res.result.mark,
                            'cartons': res.result.cartons,
                            'data': data,
                            'receiveBy': res.result.receiveBy,
                            'dropBy': res.result.dropBy,
                            'allVolumeLocation':res.result.allVolumeLocation,
                            'problemTypeStr' : res.result.problemTypeStr
                        }

                        let fatherPage = {
                            customerName: res.result.osOrder.customer,
                            orderNo: res.result.osOrder.orderNo,
                            orderType: res.result.osOrder.isHaiyun,
                            ctn: res.result.osOrder.ctn,
                            kg: res.result.osOrder.kg,
                            cmb: res.result.osOrder.cmb,
                            transportStatus: res.result.osOrder.transportStatus,
                            sendType: res.result.osOrder.sendType,
                            solicitation: res.result.osOrder.solicitation,
                            solicitationText: res.result.osOrder.solicitationText,
                            containerType:res.result.osOrder.containerType
                        }
                        // console.log("待录材积1",fatherPage)
                        // that.calcVolAndCalc()
                        this.$emit('func', fatherPage)
                        that.$nextTick(() => {
                            that.form = pick(model, 'realCtn', 'realKg', 'overFBA','overLength','overWeight','ul' ,
                                'etl','storehouseRemark', 'tempWareHouseLocations', 'location', 'warehouse', 'warehouseId',
                                'fbaLable','ce','fcc','rohs','isCheck','bluetooth' ,'maker', 'productLable', 'cartons', 'mark', 'data',
                                'receiveBy', 'dropBy','allVolumeLocation','problemTypeStr')
                            if (that.form.data.length === 0) {
                                that.handleAdd();
                            }
                            that.calcVolAndCalc()
                        })
                    } else {
                        that.keyTotal = ''
                        that.lengthTotal = ''
                        that.widthTotal = ''
                        that.heightTotal = ''
                        that.numberTotal = ''
                        that.cmbTotal = ''
                        this.weightTotal = ''
                        let model = {
                            'realCtn': '',
                            'realKg': '',
                            'overFBA': '',
                             'overLength': '',
                             'overWeight': '',
                             'ul': '',
                             'etl': '',
                             'bluetooth':'',
                            'storehouseRemark': '',
                            'data': []
                        }
                        that.$nextTick(() => {
                            that.form = pick(model, 'realCtn', 'realKg','overFBA' ,'overLength','overWeight','ul','etl','bluetooth','storehouseRemark', 'data', 'receiveBy', 'dropBy');
                            that.calcVolAndCalc()
                        })
                        // that.calcVolAndCalc()
                        that.customerName = ''
                        that.estimcatedTotalNumber = ''
                        that.kg = ''
                        that.estimcatedTotalVolume = ''
                        that.ysfs = ''
                        that.$message.warning(res.message)
                    }
                    // that.calcVolAndCalc()
                    this.updateFeeNum()
                })
            },
            onSearch() {
                let that = this
                if (that.orderNo === '') {
                    that.$message.warning('请输入订单号...')
                    that.$refs.orderno_input.focus()
                    return;
                }
                // that.orderNo = value
                // if (that.orderNo.indexOf('EFST') === -1 && that.orderNo.indexOf('AIR') && that.orderNo.indexOf('KD')) {
                //     that.orderNo = 'EFST' + that.orderNo
                // }

                getAction(this.url.list, {"orderNo": that.orderNo}).then((res) => {
                    console.log("zzzz",res)
                    if (res.code === 200) {
                      this.orderNo = res.result.osOrder.orderNo;
                      sessionStorage.setItem('volumeOrderNo', that.orderNo)
                      this.$emit('changeOrderNo', this.orderNo)
                      this.$emit("getImageMessage", {orderNo: that.orderNo})
                        // console.log("2222",res.result.id)
                        // if (res.result.id !== '') {
                        const obj = {}
                        obj.id = res.result.id;
                        obj.orderNo = this.orderNo

                        // }
                        // 增加key值用于删除
                        let data = res.result.volumes;
                        if (data.length > 0) {
                            data.forEach((item, index) => {
                                item.key = index + 1;
                                item.myindex = index
                            })
                        }
                        // if (res.result.volumes.length <= 0) {
                        //     this.handleAddpl(1)
                        // }

                        let newObj = {};
                        // console.log(res.result.wareHouseLocations)
                        // if (res.result.wareHouseLocations.length > 0){
                        //     newObj.location = res.result.wareHouseLocations
                        // that.form.location = {...newObj.location}
                        // }

                        // newObj.tempWareHouseLocations = res.result.tempWareHouseLocations
                        // console.log("65465465456",res.result)
                        this.locationInVisible = res.result.locationInVisible   //0可修改 1不可修改  必填可以修改
                      this.deliveryPointDictList = res.result.deliveryPoints;
                        // 如果有入库库位就不能修改，没有就可以
                        if (res.result.wareHouseLocations.length == 0) {
                            this.locationInVisible = false;
                        }
                        // this.wareHouseLocations = res.result.wareHouseLocations
                        // this.allLocation = res.result.allLocation
                        // that.form= pick(newObj, 'location', 'tempWareHouseLocations')


                        // that.form.tempWareHouseLocations = {...newObj.tempWareHouseLocations}

                        if (res.result.fbaLable === 'YES') {
                            that.fbaLable = 1

                        } else if (res.result.fbaLable === 'NO') {
                            that.fbaLable = 2
                        } else {
                            that.fbaLable = 0
                        }
                        if (res.result.ce === 'YES'){
                          that.ce = 1
                        }else{
                          that.ce = 0
                        }
                        if (res.result.fcc === 'YES'){
                          that.fcc = 1
                        }else{
                          that.fcc = 0
                        }
                        if (res.result.rohs === 'YES'){
                          that.rohs = 1
                        }else {
                          that.rohs = 0
                        }
                        if (res.result.bluetooth === 'YES'){
                          that.bluetooth = 1
                        }else {
                          that.bluetooth = 0
                        }
                        if (res.result.maker === 'YES') {
                            that.maker = 1
                        } else if (res.result.maker === 'NO') {
                            that.maker = 2
                        } else {
                            that.maker = 0
                        }
                        // console.log("333")
                        if (res.result.productLable === 'YES') {
                            that.productLable = 1
                        } else if (res.result.productLable === 'NO') {
                            that.productLable = 2
                        } else {
                            that.productLable = 0
                        }
                        if (res.result.mark === 'YES') {
                            that.mark = 1
                        } else if (res.result.mark === 'NO') {
                            that.mark = 2
                        } else {
                            that.mark = 0
                        }

                        if (res.result.cartons === 'YES') {
                            that.cartons = 1
                        } else if (res.result.cartons === 'NO') {
                            that.cartons = 2
                        } else {
                            that.cartons = 0
                        }
                        that.isCheck = res.result.isCheck;
                        that.customerName = res.result.osOrder.customer
                        that.estimcatedTotalNumber = res.result.osOrder.ctn
                        that.kg = res.result.osOrder.kg
                        that.estimcatedTotalVolume = res.result.osOrder.cmb
                        that.ysfs = res.result.osOrder.transportName
                        that.cargoArrivedStart = res.result.osOrder.cargoArrivedStart
                        that.newCargoArrivedTime = res.result.osOrder.cargoArrivedStart
                        that.form.receiveBy = res.result.receiveBy;
                        that.form.dropBy = res.result.dropBy;
                        that.form.problemTypeStr = res.result.problemTypeStr;
                        console.log(that.form,'that.form');

                        this.countFeeNum = res.result.countFeeNum;
                        console.log(res.result.allVolumeLocation,'res.result.allVolumeLocation');
                        that.form.allVolumeLocation = res.result.allVolumeLocation

                        if (res.result.osOrder.realCtn != null && res.result.osOrder.realCtn > 0) {
                            this.First = true;
                        }

                        let model = {
                            'realCtn': res.result.osOrder.realCtn,
                            'realKg': res.result.osOrder.realKg,
                            'overFBA': res.result.overFBA,
                            'overLength' : res.result.overLength,
                            'overWeight' : res.result.overWeight,
                            'ul': res.result.ul,
                             'etl': res.result.etl,
                             'bluetooth': res.result.bluetooth,
                            'storehouseRemark': res.result.osOrder.storehouseRemark,
                            'tempWareHouseLocations': res.result.tempWareHouseLocations.join(','),
                            'location': res.result.wareHouseLocations.join(','),
                            'warehouse': res.result.warehouse,
                            'warehouseId': res.result.warehouseId,
                            'fbaLable': res.result.fbaLable,
                            'ce': res.result.ce,
                            'fcc':res.result.fcc,
                            'rohs':res.result.rohs,
                            'isCheck': res.result.isCheck,
                            'maker': res.result.maker,
                            'productLable': res.result.productLable,
                            'mark': res.result.mark,
                            'cartons': res.result.cartons,
                            'data': data,
                            'receiveBy': res.result.receiveBy,
                            'dropBy': res.result.dropBy,
                            'allVolumeLocation':res.result.allVolumeLocation,
                            dataShow: [],
                            problemTypeStr:res.result.problemTypeStr,
                        }

                        let fatherPage = {
                            customerName: res.result.osOrder.customer,
                            orderNo: res.result.osOrder.orderNo,
                            orderType: res.result.osOrder.isHaiyun,
                            ctn: res.result.osOrder.ctn,
                            kg: res.result.osOrder.kg,
                            cmb: res.result.osOrder.cmb,
                            transportStatus: res.result.osOrder.transportStatus,
                            sendType: res.result.osOrder.sendType,
                            solicitation: res.result.osOrder.solicitation,
                            solicitationText: res.result.osOrder.solicitationText,
                            containerType:res.result.osOrder.containerType
                        }
                        // that.calcVolAndCalc()
                        this.$emit('func', fatherPage)
                        that.$nextTick(() => {
                            that.form = pick(model, 'problemTypeStr','realCtn', 'realKg','overFBA','overLength','overWeight','ul',
                                'etl','bluetooth','storehouseRemark', 'tempWareHouseLocations', 'location', 'warehouse', 'warehouseId',
                                'fbaLable','ce','fcc','rohs','isCheck','bluetooth', 'maker', 'productLable', 'cartons', 'mark', 'data', 'receiveBy',
                                'dropBy','allVolumeLocation')
                            if (that.form.data.length === 0) {
                                that.handleAdd();
                            }
                            that.calcVolAndCalc()
                        })
                    } else {
                        that.keyTotal = ''
                        that.lengthTotal = ''
                        that.widthTotal = ''
                        that.heightTotal = ''
                        that.numberTotal = ''
                        that.cmbTotal = ''
                        this.weightTotal = ''
                        let model = {
                            'realCtn': '',
                            'realKg': '',
                            'overFBA':'',
                            'overLength' : '',
                            'overWeight' : '',
                            'ul': '' ,
                            'etl':'',
                             'bluetooth':'',
                            'storehouseRemark': '',
                            'data': [],
                            dataShow: [],
                        }
                        that.$nextTick(() => {
                            that.form = pick(model, 'realCtn', 'realKg','overFBA', 'overLength','overWeight','ul' ,'etl','bluetooth','storehouseRemark', 'data', 'receiveBy', 'dropBy');
                            that.calcVolAndCalc()
                        })
                        // that.calcVolAndCalc()
                        that.customerName = ''
                        that.estimcatedTotalNumber = ''
                        that.kg = ''
                        that.estimcatedTotalVolume = ''
                        that.ysfs = ''
                        that.$message.warning(res.message)
                    }
                    // that.calcVolAndCalc()
                })
            },

            catOrderDetail() {
                // 获取receiveList内部选择的订单数据
                this.modal.orderNo = this.orderNo;
                this.modal.customerName = this.customerName
                this.$refs.orderDetail.catOrdersDetail(this.modal);
            },

            // 打开操作记录
            operaRecord() {
                this.modal.orderNo = this.orderNo;
                this.$refs.operaRecord.showDatas(this.modal);
            },
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        // sums[index] = '共' + this.keyTotal;
                        sums[index] = this.keyTotal;
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (column.property === "length") {
                            this.lengthTotal = this.summary.lengthSum;
                            sums[index] = this.summary.lengthSum;
                        } else if (column.property === "width") {
                            this.widthTotal = this.summary.widthSum;
                            sums[index] = this.summary.widthSum;
                        } else if (column.property === "height") {
                            this.heightTotal = this.summary.heightSum;
                            sums[index] = this.summary.heightSum;
                        } else if (column.property === "number") {
                            this.numberTotal = this.summary.numberSum;
                            sums[index] = this.summary.numberSum;
                        } else if (column.property === "weight") {
                            this.weightTotal = this.summary.weightSum;
                            sums[index] = this.summary.weightSum;
                            this.$set(this.form,'realKg',this.weightTotal);
                        } else if (column.property === "volume") {
                            this.cmbTotal = this.summary.volumeSum;
                            sums[index] = this.summary.volumeSum;
                        } else if (column.property === "volumeWeight") {
                            this.totalVolumeWeight = this.summary.volumeWeightSum;
                            sums[index] = this.summary.volumeWeightSum;
                        } else if (column.property === "weightVolume") {
                            this.totalWeightVolume = this.summary.weightVolumeSum;
                            sums[index] = this.summary.weightVolumeSum;
                        } else if (column.property === "countFeeWeight") {
                            this.totalCountFeeWeight = this.summary.countFeeWeightSum;
                            sums[index] = this.summary.countFeeWeightSum;
                        } else if (column.property === "countFeeVolume") {
                            this.totalCountFeeVolume = this.summary.countFeeVolumeSum;
                            sums[index] = this.summary.countFeeVolumeSum;
                        } else {
                            sums[index] = "";
                        }
                    } else {
                        sums[index] = '';
                    }
                });

                return sums;
            },
            changeWarehouse(){
                  this.$refs.formWarehouse.validate((valid) => {
                    if(valid){
const params = {
                    ids:this.selectedRowKeys,
                    volumeLocationId:this.formWarehouse.volumeLocationId,
                    volumeLocation:this.formWarehouse.volumeLocation
                }
                postAction(this.url.updateVolumeLocation, params).then((res) => {
                    if(res.success){
                        this.onSearch()
                        this.handleClose()
                    }
                });
                    }
                  })

            },
            changetLocation(e){
                if(e && e.title){
                    this.formWarehouse.volumeLocation = e.title
                }else{
                    this.formWarehouse.volumeLocation = ''
                }
            },
            changetLocations(e,index){
                if(e&&e.title){
                    this.form.data[index].volumeLocation = e.title
                }else{
                    this.form.data[index].volumelocation = ''
                }
            },
            handleClose(){
                this.visible=false
            },
            changeWarehouseFn(){
                 this.visible=true
//               if(this.selectionRows.some(item=>item.volumeLocation==='转仓中')){
//     this.$message.warning('转仓中状态不支持修改货物所在仓');
//               }else{
// this.visible=true
//               }


            }

        },

    };
</script>
<style lang="scss">
    .no_padding_right {
        .el-form-item__label {
            padding-right: 0px;
        }
    }
.volume_box {
    .a_ellipsis_box {
        white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    //   width: 100%;
    }
  .flex_wrap_start {
    .el-radio {
      margin-right: 10px;
    }
    .el-radio__label {
      padding-left: 3px;
    }
    .el-form-item__label {
      padding-right: 5px;
    }}}
</style>
<style scoped lang="scss">
.volume_box {
  .flex_wrap_start {
    .el-form-item {
      display: flex;
      margin-right: 20px;
    }
  }
  .el-button--text {
    padding: 0;
  }
  .el-button--text + .el-button--text {
    margin-left: 5px;
  }
}
    .el-form-item {
        margin-bottom: 2px;
    }

    .margin_bottom_10 {
        margin-bottom: 10px;
    }

    .totalBottomVolume {
        margin-bottom: 20px;
        margin-top: 10px;
        display: flex;
        align-items: center;

        div {
            width: 50px;
            text-align: center;
        }

        .el-input {
            width: 81px;
            margin: 0 6px;
        }
    }

    .margin_tb_10 {
        margin-top: 5px;
        margin-bottom: 15px;
    }
</style>
