<template>
  <el-dialog :title="title" :visible="visible" width="50%" append-to-body
             custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">

    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="selectedNos" label="订单号">
            <el-input v-model="addForm.selectedNos" type="textarea"
                      ></el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button  type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { putAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";

export default {
  name: 'editBackNos',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {
      }
    },

    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,

      dialogWidth: '',

      addForm: {},
      rules: {

      },
      url: {
        edit:'warehouse/receipt/setReceiptsToNoReceipt',

      },
    }
  },
  watch: {
    visible(val) {
      this.dialogWidth = '560px';
      if (val && this.row.id ) {
          this.addForm = JSON.parse(JSON.stringify(this.row));
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
        this.$refs.addForm.validate((valid) => {
          if (valid) {

            putAction(this.url.edit, this.addForm).then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose();
              this.$emit('ok')
            })
          }
        })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
