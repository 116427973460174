<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="订单号">
              <el-input
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="客户名">
              <el-input
                v-model="queryForm.customer"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="是否完成分配">
              <f-select
                v-model="queryForm.isAllocate"
                dict="is_default"
                :is-need="queryForm.isAllocate"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="业务员">
              <f-select
                v-model="queryForm.salesmanId"
                dict="sys_user_id"
                :is-need="queryForm.salesmanId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="客服">
              <f-select
                v-model="queryForm.docId"
                dict="sys_user_id"
                :is-need="queryForm.docId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="操作">
              <f-select
                v-model="queryForm.operateId"
                dict="sys_user_id"
                :is-need="queryForm.operateId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="20px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'action'">
              <el-button
                type="text"
                @click="handleEdit(scope.row)"
                v-if="buttonShowList.edit"
                >编辑</el-button
              >
            </template>
            <template v-else-if="scope.column.property === 'isAllocate'">
              <span>{{ parseDict(scope.row.isAllocate, "is_default") }}</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </el-card>
    </el-form>

    <el-dialog
      :title="form.id ? '编辑' : '新增'"
      append-to-body
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form :model="form" :rules="rules" ref="form" class="addEditBox">
        <el-form-item
          label="业务员"
          :label-width="formLabelWidth"
          prop="salesmanId"
        >
          <f-select
            :is-need="form.salesmanId"
            dict="sys_user_id"
            v-model="form.salesmanId"
            autocomplete="off"
          ></f-select>
        </el-form-item>
        <el-form-item label="客服" :label-width="formLabelWidth" prop="docId">
          <f-select
            :is-need="form.docId"
            dict="sys_user_id"
            v-model="form.docId"
            autocomplete="off"
          ></f-select>
        </el-form-item>
        <el-form-item
          label="排柜操作员"
          :label-width="formLabelWidth"
          prop="operateId"
        >
          <f-select
            :is-need="form.operateId"
            dict="sys_user_id"
            v-model="form.operateId"
            autocomplete="off"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="handleSave"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postAction } from "@/api/manage";
import QueryMixins from "../../mixins/QueryMixins";

export default {
  name: "CustomerAllocation",
  mixins: [QueryMixins],
  data() {
    return {
      rules: {
        salesmanId: [
          { required: true, message: "请选择业务员", trigger: "change" },
        ],
        docId: [{ required: true, message: "请选择客服", trigger: "change" }],
        operateId: [
          { required: true, message: "请选择操作", trigger: "change" },
        ],
      },
      formLabelWidth: "120px",
      columns: [
        {
          type: "selection",
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "temuCompanyName",
          label: "客户名称",
        },
        {
          prop: "temuFullName",
          label: "客户全称",
        },
        {
          prop: "isAllocate",
          label: "是否完成分配",
        },
        {
          prop: "salesman",
          label: "销售",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "operator",
          label: "操作",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      url: {
        list: "/express/temu/waitAllocateList",
        allocate: "/express/temu/allocate",
      },
      isUsePost: true,
      dialogFormVisible: false,
      rules: {
        salesmanId: [
          { required: true, message: "请选择业务员", trigger: "change" },
        ],
        docId: [{ required: true, message: "请选择客服", trigger: "change" }],
        operateId: [
          { required: true, message: "请选择操作", trigger: "change" },
        ],
      },
      form: {},
      dictCodes: ["is_default"],
    };
  },
  methods: {
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    postOrPut() {
      postAction(this.url.allocate, this.form).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          //关闭弹窗
          this.dialogFormVisible = false;
          this.handleQuery();
        }
      });
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postOrPut();
        }
      });
    },
  },
  created() {},
  computed: {
    buttonShowList() {
      return {
        edit: this.$btnIsShow("TEMU/CustomerAllocation", "1", "编辑"),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
