<!--待提交-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"
                                      type="textarea" placeholder="多个周数精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订舱号">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"
                                      type="textarea" placeholder="多个订舱号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否订阅报关">
                            <f-select v-model="queryForm.isBaoguanBooking" @keyup.enter.native="handleSearch"
                                      :is-need="queryForm.isBaoguanBooking" :dict="'unit_whether'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否订阅轨迹">
                            <f-select v-model="queryForm.isUsaPort" @keyup.enter.native="handleSearch"
                                      :is-need="queryForm.isUsaPort" :dict="'unit_whether'"></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="柜子状态">
                      <el-select v-model="queryForm.stauts" clearable filterable>
                        <el-option value="0" label="待装柜"></el-option>
                        <el-option value="1" label="待开船"></el-option>
                        <el-option value="2" label="待到港"></el-option>
                        <el-option value="3" label="待提柜"></el-option>
                        <el-option value="4" label="已提柜"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <!--<div class="f-p-b-15">-->
                <!--<div>-->
                    <!--<el-button size="medium" type="primary" @click="jumpBindBillView">批量更新</el-button>-->
                <!--</div>-->
            <!--</div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="$intoOperationCabinetEditPage(scope.row.id)">{{ scope.row.week }}</a>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>

<script>
    import {postAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        name: "Yidingyue",
        components: {},
        mixins: [QueryMixins],
        data() {
            return {
                sendEmailResult:[],
                alertVisible:false,
                containerId:'',
                week:'',
                selectWeek:'',
                formLabelWidth: "150px",
                //控制弹框显示
                emailVisible:false,
                columns: [
                    // {
                    //     type: "selection",
                    //     width: 50,
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "week",
                        label: "周数",
                    },

                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                    {
                        prop: "bookNo",
                        label: "订舱号",
                    },
                    {
                        prop: "isUsaPortStr",
                        label: "是否订阅轨迹",
                    },
                    {
                        prop: "isBaoguanBookingStr",
                        label: "是否订阅报关",
                    },
                    {
                        prop: "circulationStateStr",
                        label: "柜子状态",
                    },
                    {
                        prop: "etd",
                        label: "ETD",
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                    },
                ],
                url: {
                    list: "/operation/container/getYijingDingyueGuizi",


                },
                isUsePost:true,
            };
        },
        methods: {
            closeEmail(){
                this.alertVisible = false;
            },
            close(){
                this.emailVisible = false;
                this.handleSearch();
            },
            chakanyoujian(row){
                this.week = row.week;
                this.containerId = row.id;
                this.emailVisible = true;
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                // this.$router.push("/operation/cabinet/Edit?id=" + id);
                // console.log(id);
                // // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },

        },
        created() {
            this.queryForm.pageType = "1";
        },
    };
</script>

<style lang="scss" scoped>
.msgBox {
  width: 60%;
  height: 70%;
}
</style>
