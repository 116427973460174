<template>
  <div class="main-full-content">
    <el-form class="multiline_select" ref="queryForm" :model="queryForm" label-width="86px">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="5">
            <el-form-item label="交货仓库">
              <f-select v-model="queryForm.deliveryPoint" :dict="'delivery_point'" :is-need="queryForm.deliveryPoint"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="渠道名称">
              <f-select
                      v-model="queryForm.channelId"
                      :isNeed="queryForm.channelId"
                      :dict="'transportStatus'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="起运港">
              <f-select v-model="queryForm.pol"
                        :is-need="queryForm.pol"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="shippingWeek" label="船期周数">
              <el-input v-model="queryForm.shippingWeek" @keyup.enter.native="handleSearchSelf"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="开船时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.etdStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.etdEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </el-form-item>
        </el-col>
          <el-col :span="4">
            <el-form-item label-width="20px">
              <el-button size="medium" type="primary" icon="el-icon-search"  @click="handleSearchSelf"
              >查询
              </el-button>
              <el-button size="medium" plain @click="()=>{ productNameStr = null ; handleReset() }">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card>

      <div class="f-p-b-15">
        <el-button
                type="primary"
                size="medium"
                icon="el-icon-plus"
                @click="handleAdd()"
                v-if="buttonShowList.add">新增</el-button>
        <el-button
                style="margin-right: 10px"
                size="primary"
                :disabled="selectedRowKeys.length === 0"
                @click="handleDelBatch"
                v-if="buttonShowList.plsc"
        >批量删除</el-button>
        <el-upload
                class="upload-demo margin_right_10"
                action=""
                :http-request="importOperation"
                :show-file-list="false"
                :before-upload="beforeUpload"
                accept=".xls,.xlsx">
          <el-button type="primary" v-if="buttonShowList.imClCase">导入船期信息</el-button>
        </el-upload>
        <el-button v-if="buttonShowList.doClCase" type="primary" size="medium" class="f-m-l-10"
                   @click="downloadModel(url.downloadModel, '导入船期信息例模板下载')">导入船期信息模板下载
        </el-button>
        <el-button type="primary" size="medium" @click="handleExportXls('船期信息')" v-if="buttonShowList['导出船期信息']"
                  >导出船期信息</el-button>
      </div>

      <f-table ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor" :columns="columns"
        :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="61vh"   @selection-change="handleSelectionChange"
               @sort-change="handleTableChange">>
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <!--<el-button type="text" @click="() => {visible = true}">新增-->
            <!--</el-button>-->
            <!--<el-button type="text">删除-->
            <!--</el-button>-->
            <el-button type="text"  @click="handleEdit(scope.row)" v-if="buttonShowList.edit">编辑
            </el-button>
            <el-button type="text"  @click="handleDel(scope.row.id)" v-if="buttonShowList.plsc">删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'shipCompanyId'">
            <span>{{parseDict(scope.row.shipCompanyId,'ship_company')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'isEmptyClass'">
            <span v-if="scope.row.isEmptyClass =='0'">正常</span>
            <span v-else>空班</span>
          </template>


        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
            title="添加中文品名"
            :visible="addProductVisible"
            custom-class="c-custom-dialog"
            width="600px"
            :before-close="()=> this.addProductVisible = false"
            :close-on-click-modal="false">
      <el-form class="addEditBox" :model="productForm" :rules="productRules" ref="productForm" label-width="80px" size="medium">
        <el-row >
          <el-col :span="24">
            <el-form-item prop="cname" label="中文品名">
              <el-input v-model="productForm.productName" show-word-limit maxlength="255" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
          <el-button size="medium" @click="addProductVisible = false">取消</el-button>
          <el-button type="primary" size="medium" @click="addProduct">确定</el-button>
      </span>
    </el-dialog>


    <add-shipping-date :dialogVisible.sync="visible" :row="row"></add-shipping-date>
  </div>
</template>
<script>
import QueryMixins from "@/mixins/QueryMixins";
import AddShippingDate from "./modules/AddShippingDate";
import {postAction} from "@/api/manage";
import {caculateSishewuru} from "../../utils/caculate";
export default {
  mixins: [QueryMixins],
  components: {
    AddShippingDate,
  },
  props: {
  },
  data() {
    return {
      dictCodes: ['ship_company'],
      productForm:{},
      caculateRaxForm:{},
      addProductVisible:false,
      caculateRaxVisible:false,
      visible: false,
      isUsePost:true,
      row: {},
      queryForm: {},
      url:{
        list: "/order/channel/shippingDate/getShippingDatePage",
        saveHsCodeChinesProduct: "/sys/bdCountryHsCode/saveHsCodeChinesProduct",
        importShippingDateXls:"/file/read/excel/importShippingDateXls",
        downloadModel:"/file/read/excel/downloadShippingDateModel",
        deleteBatch:"/order/channel/shippingDate/delShippingDate",
        exportXlsUrl:'/file/export/excel/exportShippingDate'
      },
      productRules:{
        chineseProductName: [
            {required: true, message: "请输入中文品名", trigger: "change"},
        ],
      },
      tableData: [],
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 100,
        },
        {
          prop: "shippingDate",
          label: "船期年份",
        },
        {
          prop: "shippingWeek",
          label: "船期周数",
        },

        {
          prop: "podEndCountry",
          label: "目的港国家",
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "deliveryPointName",
          label: "交货地点",
        },
        {
          prop: "polCname",
          label: "起运港",
        },
        {
          prop: "podEndCname",
          label: "目的港",
        },
        {
          prop: 'shipCompanyId',
          label: '船公司',
        },
        {
          prop: "shippingSchedule",
          label: "船期截开",
        },
        {
          prop: "solicitationCode",
          label: "航线代码",
        },
        {
          prop: "shipNo",
          label: "船名航次",
        },
        {
          prop: "cutOffDateStr",
          label: "截货日期",
          width: 110,
        },
        {
          prop: "etdStr",
          label: "预计开船日期",
          width: 110,
        },
        {
          prop: "etaStr",
          label: "预计到港日期",
          width: 110,
        },
        {
          prop: "shippingVoyage",
          label: "航程",
        },
        {
          prop: "isEmptyClass",
          label: "是否空班",
        },
        {
          prop: "remark",
          label: "备注",
        },



      ],
      productNameStr:null
    }
  },
  watch: {
    visible(val) {
     if(!val){
       this.handleQuery()
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleDel(id) {
      this.$confirm("确定删除该船期信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
              .then(() => {
                postAction(this.url.deleteBatch, {ids:[id]}).then((res) => {
                  this.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.handleQuery();
                });
              })
              .catch(() => {
              });
    },
    handleDelBatch() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
              .then(() => {
                postAction(this.url.deleteBatch, {ids:this.selectedRowKeys}).then((res) => {
                  this.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.handleQuery();
                });
              })
              .catch(() => { });
    },
    handleAdd(){
      this.row = {};
      this.visible = true;
    },
    //下载excel模板
    //下载产品导入模板
    downloadModel(url, fileName) {
      let that = this;
      let params = {...this.queryForm, ...this.filterForm}
      postAction(url, params, {responseType: 'blob'})
              .then(response => {
                if (!response) {
                  return
                }

                const blob = new Blob([response])
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  navigator.msSaveBlob(blob, fileName)
                } else {
                  let u = window.URL.createObjectURL(response)
                  let aLink = document.createElement('a')
                  aLink.style.display = 'none'
                  aLink.href = u
                  aLink.setAttribute('download', fileName + '.xls')
                  document.body.appendChild(aLink)
                  aLink.click()
                  document.body.removeChild(aLink)
                  window.URL.revokeObjectURL(u)
                }
                // var blob = new Blob([response],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                // var downloadElement = document.createElement('a');
                // var herf = window.URL.createObjectURL(blob);
                // downloadElement.herf = herf;
                // downloadElement.download = fileName + ".xlsx";
                // document.body.appendChild(downloadElement);
                // downloadElement.click();
                // document.body.removeChild(downloadElement);
                // window.URL.revokeObjectURL(herf);
              })
    },
    caculateFee() {
      let dutyRate = "0%"
      if(this.caculateRaxForm.dutyRate != 'FREE'){
        dutyRate = this.caculateRaxForm.dutyRate
      }
      let goodValue = Number(this.caculateRaxForm.goodValue)
      dutyRate = Number(dutyRate.replace("%",""))
      let addtionalDutyRate = Number(this.caculateRaxForm.addtionalDutyRate.replace("%",""))
      if(isNaN(dutyRate) || isNaN(goodValue) ||isNaN(addtionalDutyRate) ){
        this.$message.error("请确保货值和税率格式正确！")
        return
      }
      this.$set(this.caculateRaxForm,"budgetTariffs",caculateSishewuru(goodValue,dutyRate)/100)
      this.$set(this.caculateRaxForm,"budgetSurtax",caculateSishewuru(goodValue,addtionalDutyRate)/100)
      this.$set(this.caculateRaxForm,"totalTaxes2Fees",this.caculateRaxForm.budgetTariffs +  this.caculateRaxForm.budgetSurtax)

    },
    addProduct(){
      postAction(this.url.saveHsCodeChinesProduct,this.productForm).then(res=>{
        if(res.success){
          this.$message.success(res.message)
        }
        this.addProductVisible = false
        this.handleQuery()
      })
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      const len = file.name.length < 100;
      if (!len) {
        this.$message.warning("该文件名太长，请修改文件名最多100个字符");
      }
      return isLt2M && isFile && len;
    },
    //导入清关要求和案例
    importOperation(data) {
      console.log("上传文件", data);
      const formData = new FormData();
      formData.append("file", data.file);
      postAction(this.url.importShippingDateXls, formData)
              .then((res) => {
                console.log("产品返回数据", res);
                if (res.success) {
                  this.$message.success(res.message);
                  this.handleQuery();
                }
              })
    },
    handleEdit(row) {
      for (var name in row) {
        this.$set(this.row, name, row[name]);
      }
      this.visible = true;
    },
    caculateRax(row){
      console.log(row)
      this.caculateRaxForm = JSON.parse(JSON.stringify(row));
      if(this.caculateRaxForm.chineseProductName){
        this.caculateRaxForm.productName = this.caculateRaxForm.chineseProductName.map((obj)=>{return obj.productName}).join(",");
      }
      if(!this.caculateRaxForm.addtionalDutyRate){
        this.caculateRaxForm.addtionalDutyRate = '0%'
      }
      if(!this.caculateRaxForm.dutyRate){
        this.caculateRaxForm.dutyRate = '0%'
      }else{
        let tempRate = this.caculateRaxForm.dutyRate.substring(0, this.caculateRaxForm.dutyRate.indexOf("%")) ==''?'0':this.caculateRaxForm.dutyRate.substring(0, this.caculateRaxForm.dutyRate.indexOf("%"))
        this.caculateRaxForm.dutyRate = tempRate+'%';
      }
      this.caculateRaxVisible = true
    },
    addProductDialog(row) {
      this.$set(this.productForm,"hsCodeId",row["id"])
      this.$set(this.productForm,"hsCode",row["hsCode"])
      this.addProductVisible = true;
    },
    handleDetail(detail, title,type) {
      let showStr =''
      detail.forEach(item=>{
        if (type =='0'&& item) {
          showStr = showStr +item.productCategories + '<br/>' + item.clearanceRequirementsSort +'<br/>'
          return;
        }
        if(type =='2' && item){
          showStr = showStr + item.productName + '<br/>'
          return;
        }
        if(item) showStr = showStr + item.productName + '<br/>' + item.clearanceCases +'<br/>'
      })
      showStr = showStr.replace(/\n/g,"</br>") || '暂无'
      this.$alert(showStr, title, {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true,
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${action}`
          // });
        }
      });
    },
    handleSearchSelf() {
      this.handleSearch()
    }
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('channel/BdShippingDate','0','新增'),
        'plsc':this.$btnIsShow('channel/BdShippingDate','0','删除'),
        'imClCase':this.$btnIsShow('channel/BdShippingDate','0','导入船期信息'),
        'doClCase':this.$btnIsShow('channel/BdShippingDate','0','导入船期信息例模板下载'),
        'edit':this.$btnIsShow('channel/BdShippingDate','0','编辑'),
        '导出船期信息':this.$btnIsShow('channel/BdShippingDate','0','导出船期信息'),
        
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.target_class {
  color: red;
  font-weight: bold;
}
</style>