<template>
  <el-dialog :title="title" :visible="visible" width="50%" append-to-body
             custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type === 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
             class="c-th-has-bgcolor" :columns="columns" :data="tableData"
             :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
             @sort-change="handleTableChange">
      <template slot-scope="scope">
        <template v-if="scope.column.property === 'remark'">
          <span v-html="scope.row.remark"></span>
        </template>
      </template>
    </f-table>
    <el-form v-else  :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="points" label="积分">
            <el-input v-model="addForm.points" type="number" show-word-limit
                      placeholder="请输入积分"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="remark" label="备注">
            <el-input v-model.trim="addForm.remark" placeholder="请输入备注" type="textarea" maxlength="255" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button  type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {postAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";
import {validateUnitValue} from "@/utils/validator";

export default {
  name: 'editPointsDialog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {
      }
    },

    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,
      columns: [
        {
          prop: "remark",
          label: "详情",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],
      dialogWidth: '',

      addForm: {},
      rules: {

        points: [
          {required: true, message: '请输入积分', trigger: 'change'},
          {validator: validateUnitValue, trigger: 'change'}
        ],
      },
      url: {
        edit:'shipping/cus/points/detail/updatePoints',
        logPage:'shipping/cus/points/detail/log/page'
      },
    }
  },
  watch: {
    visible(val) {
      this.dialogWidth = '560px';
      if (val && this.row.id ) {
        if (this.type === 'log') {
          postAction(this.url.logPage,{busId : this.row.id})
              .then(res => {
                this.tableData = res.result.records;
              })
        }else {
          this.addForm = JSON.parse(JSON.stringify(this.row));
        }
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            postAction(this.url.edit, this.addForm).then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose()
              this.$emit('ok')
            })
          }
        })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
