<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="原始币种">
              <el-input v-model="queryForm.amout" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="兑换后币种">
              <el-input v-model="queryForm.amoutTo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd"
          :disabled="$btnIsDisabled('isystem/BaseData/BdCurRate','0','新增')"
          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="67vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"

                       v-if="buttonShowList.bj"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDele(scope.row)"

                       v-if="buttonShowList.sc"
              >删除</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'amout'">
            <span>{{parseDict(scope.row.amout,'bd_cur')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'amoutTo'">
            <span>{{parseDict(scope.row.amoutTo,'bd_cur')}}</span>
          </template>

        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
      :title="form.id ? '编辑币种汇率' : '新增币种汇率'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="原始币种"
          :label-width="formLabelWidth"
          prop="amout"
        >
          <!-- <el-input v-model="form.amout" autocomplete="off"></el-input>-->
          <f-select
              :isNeed="form.amout"
            v-model="form.amout"
            :dict="'bd_cur'"
          ></f-select>
        </el-form-item>
        <el-form-item label="原始汇率" :label-width="formLabelWidth" prop="originalRate" v-if="buttonShowList.hl">
          <el-input v-model="form.originalRate" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="汇率" :label-width="formLabelWidth" prop="rate">
          <el-input v-model="form.rate" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="兑换后的币种"
          :label-width="formLabelWidth"
          prop="amoutTo"
        >
          <!-- :isNeed="scope.row.amoutTo" <el-input v-model="form.amoutTo" autocomplete="off"></el-input>-->
          <f-select
            v-model="form.amoutTo"
            :isNeed="form.amoutTo"
            :dict="'bd_cur'"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>
    <el-divider></el-divider>
    <cur-rate-log-module ref="fee" @ok="init" :type="'1'"></cur-rate-log-module>
  </div>
</template>

<script>
import { deleteAction, postAction, putAction } from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";
import CurRateLogModule from "./CurRateLogModule"

export default {
  name: "Importer",
  mixins: [QueryMixins],
  components: {
    CurRateLogModule,
  },
  data() {
    return {
      dictCodes: ["bd_cur"],
      rules: {
        amout: [
          { required: true, message: "请输入原始币种", trigger: "change" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        rate: [{ required: true, message: "请输入汇率", trigger: "change" }],
        originalRate: [{ required: true, message: "请输入原始汇率", trigger: "change" }],
        amoutTo: [
          { required: true, message: "请输入兑换后的币种", trigger: "change" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
      },
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "110px",
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          prop: "amout",
          label: "原始币种",
          sortable: "custom",
        },
        {
          prop: "rate",
          label: "汇率",
          sortable: "custom",
        },
        {
          prop: "amoutTo",
          label: "兑换后币种",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
        {
          prop: "createBy",
          label: "创建人",
          sortable: "custom",
        },
        {
          prop: "updateBy",
          label: "修改人",
          sortable: "custom",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],
      url: {
        list: "/sys/bdCurRate/list",
        add: "/sys/bdCurRate/add",
        edit: "/sys/bdCurRate/edit",
        getById: "/sys/bdCurRate/queryById",
        delete: "/sys/bdCurRate/delete",
      },
    };
  },
  methods: {
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleAdd() {
      this.form = {};
      this.dialogFormVisible = true;
    },
    init() {
      console.log('币种操作记录', this.$refs['fee']);
      if (this.$refs['fee']) {
        this.$refs['fee'].initVue();
      }
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.form = JSON.parse(JSON.stringify(row));

      this.$refs.form=this.form
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //编辑
          this.form.id='';
          postAction(this.url.add, this.form).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              //关闭弹窗
              this.dialogFormVisible = false;
              localStorage.removeItem("dict:sys_cur_rate");
              this.handleQuery();
              this.init();
            }
          });
        }
      });
    },
    handleDele(row) {
      this.$confirm(`确定删除该币种吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            localStorage.removeItem("dict:sys_cur_rate");
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleBatDel() {
      console.log("获取到的id：" + this.selectedRowKeys),
        this.$confirm("确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
          closeOnClickModal: false,
        })
          .then(() => {
            deleteAction(this.url.deleteBatch, {
              ids: this.selectedRowKeys.join(","),
            }).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {});
    },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  created() {
    if(this.buttonShowList.khl){
      this.columns.splice(2,0,{
        prop: "originalRate",
        label: "原始税率",
        sortable: "custom",
      })
    }
    console.log(this.columns)
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/BdCurRate','0','新增'),
        'bj':this.$btnIsShow('isystem/BaseData/BdCurRate','1','编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/BdCurRate','1','删除'),
        'hl':this.$btnIsShow('isystem/BaseData/BdCurRate','0','是否可以维护原始汇率'),
        'khl':this.$btnIsShow('isystem/BaseData/BdCurRate','0','是否查看原始税率'),
      }
    }
  }
};

</script>

<style lang="scss" scoped>
</style>
