<!--待提交-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="订舱代理">
                        <f-select
                            v-model="queryForm.bookAgentId"
                            :is-need="queryForm.bookAgentId"
                            :dict="'bookAgent_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="船公司">
                        <f-select
                            v-model="queryForm.shipCompanyId"
                            :is-need="queryForm.shipCompanyId"
                            :dict="'ship_company'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订舱单号">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                      <el-form-item label="ETA">
                        <div class="f-flex">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.eta"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                          <span class="f-p-h-2">~</span>
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.etaEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                            <f-select v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="派送操作">
                        <f-select
                                v-model="queryForm.endOperateId"
                                :isNeed="queryForm.endOperateId"
                                :dict="'operationsId'">
                        </f-select>
                      </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <el-button type="primary" :disabled="selectionRows.length === 0"
                       v-if="buttonShowList.tx" @click="tieReminder">特别提醒
            </el-button>
            <el-button type="primary" :disabled="selectionRows.length === 0"
                       v-if="buttonShowList.pltg" @click="handMentionArkForm(null)">批量提柜
            </el-button>
            <el-button type="primary" :disabled="selectionRows.length === 0"
                       v-if="buttonShowList.plxc" @click="handUnloadShip(null)">批量卸船
            </el-button>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handMentionArkForm(scope.row)"
                                   v-if="scope.row.circulationState == '4' && buttonShowList.tg"
                        >提柜
                        </el-button
                        >
                        <el-button type="text" @click="handStrippingForm(scope.row)"
                                   v-if="scope.row.circulationState == '5' && buttonShowList.cgz"

                        >拆柜中
                        </el-button
                        >
                        <!--<el-button type="text" @click="handUnloadShip(scope.row)"-->
                                   <!--v-if="datedifference(scope.row.eta,new Date()) && $btnIsShow('operation/cabinet/WaitingToMentionArk','1','卸船')"-->

                        <!--&gt;卸船-->
                        <!--</el-button-->
                        <el-button type="text" @click="handUnloadShip(scope.row)"
                        v-if="buttonShowList.xc"

                        >卸船
                        </el-button
                        >
                        <el-upload
                                :action="'/operation/container/taxUpload'"
                                :file-list="fileList"
                                :data="scope.row"
                                :http-request="handleUpload"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :before-upload="beforeUpload"
                                :on-exceed="onexceed"
                                :limit="1"
                                v-model="uploadForm.fileId"
                                class="inline_left"
                                v-if="buttonShowList.imys"
                        >
                            <template slot="trigger">
                                <template>
                                    <el-button type="text"
                                               >导入应收分税</el-button>
                                </template>
                            </template>
                            <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>
                        </el-upload>
                        <el-upload
                                :action="'/operation/container/taxUpload'"
                                :file-list="fileList"
                                :data="scope.row"
                                :http-request="handleUploadPay"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :before-upload="beforeUpload"
                                :on-exceed="onexceed"
                                :limit="1"
                                v-model="uploadForm.fileId"
                                class="inline_left"
                                v-if="buttonShowList.imyf"
                        >
                            <template slot="trigger">
                                <template>
                                    <el-button type="text"
                                    >导入应付分税</el-button>
                                </template>
                            </template>
                            <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>
                        </el-upload>
                        <el-button type="text" @click="getSonList(scope.row)"
                                   v-if="scope.row.week && scope.row.week.indexOf('传统')!==-1"
                        >查看子柜子
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'complianceState'">
                        <span>{{ parseDict(scope.row.complianceState, "compliance_state")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetCategory'">
                        <span>{{parseDict(scope.row.cabinetCategory, "cabinet_category")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookAgentId'">
                        <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id_chinese") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompanyId'">
                        <span>{{ parseDict(scope.row.shipCompanyId, "ship_company") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'weekStatus'">
                        <span v-if="scope.row.weekStatus == '0'">自拼</span>
                        <span v-if="scope.row.weekStatus == '1'">非自拼</span>
                        <span v-if="scope.row.weekStatus == '2'">传统物流</span>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.id)">{{ scope.row.week }}</a>
                        <!-- 换成a标签，支持复制 -->
                        <!-- <el-button type="text" @click="infomation(scope.row.id)">{{scope.row.week}}</el-button> -->
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="(isBatchType == 'UnloadingShip' ? '批量' : '') + '卸船'"
                :visible.sync="dialogAddFormVisible"
                :close-on-click-modal="false"
        >

            <el-form :model="UnloadingShipForm" ref="UnloadingShipForm" :rules="rules">
                <el-form-item
                        label="卸船时间"
                        :label-width="formLabelWidth"
                        prop="unloadShip"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="UnloadingShipForm.unloadShip"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveAddForm">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog
                :title="(isBatchType == 'PickUpCabinet' ? '批量' : '') + '提柜'"
                :visible.sync="dialogAddFormMentionArkVisible"
                :close-on-click-modal="false"
        >
            <el-form :model="PickUpCabinetForm" ref="PickUpCabinetForm" :rules="rules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item
                                label="提柜时间"
                                :label-width="formLabelWidth"
                                prop="holdCabinetTime"
                        >
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="PickUpCabinetForm.holdCabinetTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="9">-->
<!--                        <el-form-item label="清关查验预估时间(周)" :label-width="formLabelWidth" prop="checkWeek" v-if="this.isCheekWeek" >-->
<!--                            <el-input v-model="PickUpCabinetForm.checkWeek" type="number" :disabled="true"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddFormMentionArkVisible = false"
                >取 消
                </el-button
                >
                <el-button size="medium" type="primary" @click="handMentionArk">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="特别提醒"
                   :visible.sync="dialogTieReminderVisible"
                   :close-on-click-modal="false"
                   v-if="dialogTieReminderVisible">
            <el-form :model="tieForm" ref="tieForm" :rules="rules">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="tieForm.title"></el-input>
                </el-form-item>
                <el-form-item label="提醒事项" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="tieForm.remark" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="文件类型" :label-width="formLabelWidth" prop="fileType">
                    <f-select :dict="'os_order_filelist'" v-model="tieForm.fileType"
                              :isNeed="tieForm.fileType"></f-select>
                </el-form-item>
                <el-form-item label="提醒文件" :label-width="formLabelWidth" prop="fileList">
                    <f-upload v-model="tieForm.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTieReminderVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendTieReminderEmail">确 定</el-button>
            </div>
        </el-dialog>
        <son-cabinet :container-id="rowContainerId" :visible.sync="sonVisible" :table-list="sonTableList" @close="close"></son-cabinet>

    </div>
</template>

<script>
    import {getAction, postAction, putAction, deleteAction, httpAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import Vue from "vue";
    import { setUpRouteUtil } from '@/utils/util';
    import SonCabinet from "@/views/operation/cabinet/SonCabinet.vue";


    export default {
        name: "Potential",
        mixins: [QueryMixins],
        components:{SonCabinet},
        data() {
            return {
                //特别提醒订单列表
                tieReminderOrderListNos:null,
                //特别提醒标识
                dialogTieReminderVisible: false,
                //特别提醒表单
                tieForm: {fileList: []},
                fileList: [],
                tip: "",
                rules: {
                    holdCabinetTime: [
                        {required: true, message: "请选择提柜时间", trigger: "change"},
                    ],
                    unloadShip: [
                        {required: true, message: "请选择卸船时间", trigger: "change"},
                    ],
                },
                ids: [],
                row: {},
                form: {},
                dialogAddFormVisible: false,
                dialogAddFormMentionArkVisible: false,
                PickUpCabinetForm: {},
                UnloadingShipForm: {},
                isBatchType: '',    //批量操作类型  PickUpCabinet 提柜  UnloadingShip 卸船
                selectRow: {},
                uploadForm: {},
                dictCodes: ["compliance_state", "cabinet_category", "bookAgent_id_chinese", "ship_company"],
                formLabelWidth: "130px",
                //控制弹框显示
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 200,
                        // fixed: 'left'
                    },
                    {
                        prop: "week",
                        label: "周数",
                        sortable: "week",
                    },
                    {
                        prop: "bookAgentId",
                        label: "订舱代理",
                        sortable: "bookAgentId",
                        width: 140,
                    },
                    {
                        prop: "shipCompanyId",
                        label: "船公司",
                        sortable: "shipCompanyId",
                        width: 140,
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                        sortable: "eta",
                        width: 160,
                    },
                    {
                        prop: "size",
                        label: "柜型",
                        sortable: "size",
                        width: 140,
                    },
                    {
                        prop: "bookNo",
                        label: "订舱单号",
                        sortable: "bookNo",
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        sortable: "custom",
                        width: 160,
                    },
                    {
                        prop: "originalNo",
                        label: "封条",
                        sortable: "custom",
                        width: 100,
                    },
                    {
                        prop: "weekStatus",
                        label: "柜子用途",
                        // headerType: "string",
                        minWidth: 180,
                        sortable: "weekStatus",
                    },
                ],
                url: {
                    list: "/operation/container/WaitingToMentionArkList",
                    mentionArk: "/operation/container/mentionArk",
                    updateCabinetAudit: "/operation/container/mentionArk",
                    stripping: '/operation/container/stripping',
                    update: "/operation/container/updateMentionArk",
                    updateUnloadShip:"/operation/container/updateUnloadShip",
                    upload: "/operation/container/taxUpload",
                    checkWeek: "/operation/container/checkWeek",
                    tieReminder: "/file/sendEmail/tieReminder",  //特别提醒
                    queryContainerOrderNo:"operation/container/getOrderNos",
                    getSonList:'/operation/container/getSonContainerListByContainerId',
                    batchMentionArk: '/operation/container/batchMentionArk',    //批量提柜

                },
                rowContainerId:'',
                sonVisible:false,
                sonTableList:[],
                countryList: [],
                addressList: [],
                isCheekWeek:false,
                isUsePost:true
            };
        },
        methods: {
            getSonList(row){
                this.rowContainerId = row.id;
                postAction(this.url.getSonList,{id:row.id})
                    .then(res=>{
                        this.sonTableList = res.result;
                        this.sonVisible = true;
                    })
            },
            close(){
                this.sonVisible = false;
            },
            //发送特别提醒文件
            sendTieReminderEmail() {
                this.$refs.tieForm.validate(valid => {
                    if (valid) {
                        if (this.tieForm.fileList && this.tieForm.fileList.length && !this.tieForm.fileType) {
                            this.$message.warning("有文件请选择文件类型！");
                            return
                        }
                        this.tieForm.orderNos = this.tieReminderOrderListNos;
                        postAction(this.url.tieReminder, this.tieForm)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogTieReminderVisible = false;
                                }
                            })
                    }
                })


            },
            // 特别提醒按钮点击
            tieReminder() {
                this.tieForm = {};
                this.tieForm.fileList = [];
                let id = this.selectedRowKeys.join(',');
                getAction(this.url.queryContainerOrderNo, {containerId: id})
                    .then((res) => {
                        if (res.success) {
                            this.tieReminderOrderListNos = res.result.join(",")
                            console.log(this.tieReminderOrderListNos)
                        }
                    })
                this.dialogTieReminderVisible = true;
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            saveAddForm() {

                this.$refs["UnloadingShipForm"].validate((valid) => {
                    if (valid) {
                        console.log(this.UnloadingShipForm);
                        this.UnloadingShipForm.id = this.selectRow.id;
                        this.UnloadingShipForm.complianceTime = this.selectRow.complianceTime;
                        let url = this.url.updateUnloadShip;
                        let params = {...this.UnloadingShipForm};
                        if(this.isBatchType == 'UnloadingShip') {
                            params = {unloadShip: this.UnloadingShipForm.unloadShip, containerIds: this.selectedRowKeys}
                        } else {
                            params.containerIds = [params.id];
                        }
                        delete params.id;
                        this.$store.commit("setFullscreenLoading", true);
                        putAction(url, params).then((res) => {
                            this.$store.commit("setFullscreenLoading", false);
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.handleSearch();
                                this.dialogAddFormVisible = false;
                            }
                            this.isBatchType = '';
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                            this.isBatchType = '';
                        });
                     }
                })
            },
            handUnloadShip(row) {
                if(row == null) {
                    this.isBatchType = 'UnloadingShip';    //批量操作类型  PickUpCabinet 提柜  UnloadingShip 卸船
                    this.dialogAddFormVisible = true;
                    this.UnloadingShipForm = {};
                } else {
                    this.isBatchType = '';
                    this.selectRow = JSON.parse(JSON.stringify(row));
                    this.dialogAddFormVisible = true;
                }
            },
            handMentionArkForm(row) {
                if(row == null) {
                    let isError = false;
                    try {
                        this.selectionRows.forEach((item, index) => {
                            if (item.circulationState !== '4') {
                                isError = true;
                                throw Error(); //满足条件，跳出循环
                            }
                        });
                    } catch (e) {
                    }
                    if(isError) {
                        this.$message.warning('已出港才可以进行提柜操作！');
                        return;
                    }
                    this.isBatchType = 'PickUpCabinet';    //批量操作类型  PickUpCabinet 提柜  UnloadingShip 卸船
                    this.dialogAddFormMentionArkVisible = true;
                    this.PickUpCabinetForm = {};
                } else {
                    this.isBatchType = '';
                    this.selectRow = JSON.parse(JSON.stringify(row));
                    //获取 该柜子曾经是否查验过 查验的周数
                    getAction(this.url.checkWeek+'?id='+row.id).then((res) => {
                        if (res.success && res.result) {
                            this.isCheekWeek = true;
                            this.$set(this.PickUpCabinetForm, 'checkWeek', res.result.checkWeek)
                        }else{
                            this.isCheekWeek = false;
                        }
                    });
                    this.dialogAddFormMentionArkVisible = true;
                }
            },
            handStrippingForm(row) {
                let form = JSON.parse(JSON.stringify(row));
                form.circulationState = "7";
                console.log(form)


                putAction(this.url.stripping, form).then((res) => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.dialogAddFormMentionArkVisible = false;
                        this.handleSearch();
                    }
                });
            },
            handMentionArk() {
                this.$refs["PickUpCabinetForm"].validate((valid) => {
                    if (valid) {
                        this.selectRow.holdCabinetTime = this.PickUpCabinetForm.holdCabinetTime;
                        let form = JSON.parse(JSON.stringify(this.selectRow));
                        // form.actionType = 'mentionArk'
                        form.circulationState = "7";
                        form.sendEmailType = "9";
                        console.log(form);
                        let url = this.url.updateCabinetAudit;
                        let params = {...form};
                        let method = 'put';
                        if(this.isBatchType == 'PickUpCabinet') {
                            url = this.url.batchMentionArk;
                            params = {holdCabinetTime: this.PickUpCabinetForm.holdCabinetTime, containerIds: this.selectedRowKeys, circulationState: '7', }
                            this.$store.commit("setFullscreenLoading", true);
                            method = 'post'
                        }

                        this.$store.commit("setFullscreenLoading", true);
                        httpAction(url, method, params).then((res) => {
                            this.$store.commit("setFullscreenLoading", false);
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.dialogAddFormMentionArkVisible = false;
                                this.handleSearch();
                            }
                            this.isBatchType = '';
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                            this.isBatchType = '';
                        });
                    }
                })
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                // this.$router.push("/operation/cabinet/Edit?id=" + id);
                // console.log(id);
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },
            handlePreview(file) {
                console.log("文件的url", file.url);

                if (!file.url) {
                    this.$message.error("下载失败");
                    return;
                }

                const type = file.url.substring(file.url.lastIndexOf(".") + 1);
                console.log("type", type);
                if (
                    type === "jpg" ||
                    type === "jpeg" ||
                    type === "img" ||
                    type === "gif" ||
                    type === "png" ||
                    type === "pneg"
                ) {
                    console.log("是图片预览", file.url);
                    this.imgUrl = file.url;
                    this.visible = true;
                }
                // 判断文件类型
                else if (
                    type === "doc" ||
                    type === "docx" ||
                    type === "xlsx" ||
                    type === "xls" ||
                    type === "ppt" ||
                    type === "pptx"
                ) {
                    console.log("不是图片下载", file.url);
                    // 在当前浏览器直接下载
                    document.location.href = file.url;
                } else {
                    // 图片在浏览器打开 新的页面展示
                    window.open(file.url, "hello");
                }
            },
            handleRemove(file) {
                console.log(file);
                if (file.id) {
                    const index = this.fileList.indexOf(file);
                    const {id} = this.fileList[index];
                    deleteAction("/sys/oss/file/delete", {id})
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                            }
                        })
                        .catch((err) => {
                            this.$emit("delete", index);
                        });
                    //如果传递参数为合同，则删除合同
                }
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            onexceed() {
                this.$message.warning("最多上传1个文件哦,亲");
            },
            handleUpload(data) {
                const formData = new FormData();
                // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
                formData.append("file", data.file);
                formData.append("dir", 'operate');
                formData.append("containerId", data.data.id);
                formData.append("type","0");

                postAction(this.url.upload, formData).then((res) => {
                    if (res.success) {
                        this.fileList.push(res.result);
                        this.$message.success(res.message);
                    }
                });
            },
            handleUploadPay(data) {
                const formData = new FormData();
                // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
                formData.append("file", data.file);
                formData.append("dir", 'operate');
                formData.append("containerId", data.data.id);
                formData.append("type","1");
                postAction(this.url.upload, formData).then((res) => {
                    if (res.success) {
                        this.fileList.push(res.result);
                        this.$message.success(res.message);
                    }
                });
            },
            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.pageType = "1";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            datedifference(sDate1, sDate2) {    //sDate1和sDate2是2006-12-18格式
                var dateSpan,
                    tempDate,
                    iDays;
                sDate1 = Date.parse(sDate1);
                sDate2 = Date.parse(sDate2);
                dateSpan = sDate2 - sDate1;
                dateSpan = Math.abs(dateSpan);
                iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
                if (iDays > 7) {
                    return true
                } else {
                    return false
                }

            }
        },
        created() {
            this.queryForm.pageType = "1";
        },
      computed:{
        buttonShowList() {
          return {
            'tx':this.$btnIsShow('docpage/AllOrderModule','0','特别提醒'),
            'pltg':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','0','批量提柜'),
            'plxc':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','0','批量卸船'),
            'tg':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','1','提柜'),
            'cgz':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','1','拆柜中'),
            'xc':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','1','卸船'),
            'imys':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','1','导入应收分税'),
            'imyf':this.$btnIsShow('operation/cabinet/WaitingToMentionArk','1','导入应付分税'),

          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
