<template>
  <follow :wait="true"/>
</template>


<script>
import Follow from './Follow.vue';
export default {
  components:{
    Follow
  }

}
</script>

<style>

</style>