import { render, staticRenderFns } from "./AllKdOrder.vue?vue&type=template&id=a97a8de8&scoped=true&"
import script from "./AllKdOrder.vue?vue&type=script&lang=js&"
export * from "./AllKdOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a97a8de8",
  null
  
)

export default component.exports