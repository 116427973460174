<template>
  <!-- 优惠促销 -->
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="客户名称">
              <el-input v-model.trim="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="业务员">
              <f-select v-model="queryForm.salesmanIds" :is-need="queryForm.salesmanIds" :dict="'sys_user_id'"
                placeholder="请选择" filterable clearable :multiple="true">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="账单状态">
              <f-select v-model="queryForm.billStatus" :is-need="queryForm.billStatus" :dict="'check_status'"
                placeholder="请选择" filterable clearable>
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="促销编号">
              <el-input v-model.trim="queryForm.discountNumber" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="备注">
              <el-input v-model.trim="queryForm.remark" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="币种">
              <f-select v-model="queryForm.curId" :is-need="queryForm.curId" :dict="'bd_cur'" placeholder="请选择"
                filterable clearable>
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo" type="textarea" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="客服">
              <f-select v-model="queryForm.docId" :is-need="queryForm.docId" :dict="'sys_user_id'" placeholder="请选择"
                filterable clearable>
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="销账状态">
              <f-select v-model="queryForm.recStatus" :is-need="queryForm.recStatus" :dict="'settle_status'"
                placeholder="请选择" filterable clearable>
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="优惠类型">
              <f-select v-model="queryForm.typeId" :is-need="queryForm.typeId" :dict="'discount_type'" placeholder="请选择"
                filterable clearable>
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="录入页面">
              <el-select v-model="queryForm.isSettle" placeholder="请选择">
                <el-option
                    label="应收账单"
                    value="0">
                </el-option>
                <el-option
                    label="销售结算"
                    value="1">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="检查状态">
              <el-select v-model="queryForm.checkLock" placeholder="请选择" clearable>
                <el-option
                    label="未检查"
                    value="0">
                </el-option>
                <el-option
                    label="已检测"
                    value="1">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="特批类型">
              <f-select v-model="queryForm.specialType" :is-need="queryForm.specialType" :dict="'discount_special_type'" placeholder="请选择"
                        filterable clearable>
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <div lass="box-card last_card">
      <div class="f-p-b-15">
        <el-button v-if="buttonShowList.add" type="primary" size="medium" icon="el-icon-plus"
          @click="actionBtn(null, '4')">新增
        </el-button>
        <el-button v-if="buttonShowList.delB" type="primary" size="medium"
          :disabled="!selectedRowKeys.length" @click="actionBtn(null, '5')">批量删除
        </el-button>
        <el-button v-if="buttonShowList.mbxz" type="primary" size="medium" @click="downloadModel(url.discountExcel, '优惠促销导入模板')">模板下载
        </el-button>
        <el-upload v-if="buttonShowList.im" action="/" :show-file-list="false" :http-request="uploadSectionFile" accept=".xls,.xlsx"
          style="margin:0 10px;display:inline">
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <div class="total_box">
          <div>合计优惠金额：</div>
          <div v-if="totalPrice.length == 0">/</div>
          <div v-for="(item, index) in totalPrice" :key="index">{{item.totalPrice + ' ' + item.curName + '， '}}</div>
        </div>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" @selection-change="handleSelectionChange" @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }" tableHeight="73vh">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="actionBtn(scope.row, '1')"
              v-if="buttonShowList.edit">编辑</el-button>
            <el-button type="text" @click="actionBtn(scope.row, '2')"
              v-if="buttonShowList.del">删除</el-button>
            <el-button type="text" @click="actionBtn(scope.row, '3')"
              v-if="buttonShowList.log">日志</el-button>
          </template>
          <template v-else-if="scope.column.property === 'specialType'">
            <span>{{
                parseDict(scope.row.specialType, "discount_special_type")
              }}</span>
          </template>
          <template v-else-if="scope.column.property === 'recStatus'">
            <span>{{
              parseDict(scope.row.recStatus, "settle_status")
              }}</span>
          </template>
          <template v-else-if="scope.column.property === 'billStatus'">
            <span>{{
              parseDict(scope.row.billStatus, "check_status")
              }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
              style="color:#57a3f3;">{{scope.row.customer}}</a>
          </template>
          <template v-else-if="scope.column.property === 'isSettle'">
           {{scope.row.isSettle=='0'?'应收账单':scope.row.isSettle=='1'?'销售结算':''}}
          </template>
          <template v-else-if="scope.column.property === 'isProfit'">
                       {{scope.row.isProfit=='0'?'否':scope.row.isProfit=='1'?'是':''}}</template>
                           <template v-else-if="scope.column.property === 'checkLock'">
                       {{scope.row.checkLock=='0'?'否':scope.row.checkLock=='1'?'是':''}}</template>
          </template>
      </f-table>
    </div>

    <el-row class="f-text-right f-p-v-8">
      <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    <edit-discount-promotion-dialog :visible.sync="visible" :type="type" :row="typeForm"
      @ok="handleSearch"></edit-discount-promotion-dialog>
  </div>
</template>

<script>
  import QueryMixins from "@/mixins/QueryMixins.js";
  import { getAction, postAction, deleteAction } from "@/api/manage";
  import EditDiscountPromotionDialog from "./modules/EditDiscountPromotionDialog";
  export default {
    mixins: [QueryMixins],
    components: {
      EditDiscountPromotionDialog,
    },
    data() {
      return {
        dictCodes: ["check_status", "settle_status","discount_special_type"],
        columns: [
          {
            type: "selection",
          },
          {
            prop: "action",
            label: "操作",
            width: 150,
          },
          {
            prop: "discountNo",
            label: "促销单号",
          },
          {
            prop: "orderNo",
            label: "订单号",
          },
          {
            prop: "customer",
            label: "客户名称",
          },
          {
            prop: "salesman",
            label: "业务员",
          },
          {
            prop: "doc",
            label: "客服",
          },
          {
            prop: "containerNo",
            label: "柜号",
          },
          {
            prop: "shipmentCountry",
            label: "国家",
          },
          {
            prop: "channelName",
            label: "渠道名称",
          },
          {
            prop: "realCmb",
            label: "实际体积",
          },
          {
            prop: "countFeeNum",
            label: "计费数量",
          },
          {
            prop: "specialType",
            label: "特批类型",
          },
          {
            prop: "typeName",
            label: "优惠类型",
          },
          {
            prop: "feeName",
            label: "费用名称",
          },
            {
            prop: "isSettle",
            label: "录入页面",
          },
            {
            prop: "checkLock",
            label: "检查状态",
          },
          {
            prop: "price",
            label: "价格",
          },
          {
            prop: "quantity",
            label: "数量",
          },
          {
            prop: "total",
            label: "合计",
          },
          {
            prop: "curName",
            label: "币种",
          },
          {
            prop: "discountNumber",
            label: "促销编号",
          },
             {
            prop: "isProfit",
            label: "是否影响利润",
          },
          {
            prop: "remark",
            label: "备注",
          },
          {
            prop: "billStatus",
            label: "账单状态",
          },
          {
            prop: "recStatus",
            label: "销账状态",
          },
          {
            prop: "createBy",
            label: "录入人",
          },
          {
            prop: "createTime",
            label: "录入时间",
          },
          {
            prop: "updateBy",
            label: "修改人",
          },
          {
            prop: "approvalNumber",
            label: "审批编号",
          },
        ],
        url: {
          list: "/jeecg-cloud-shipping-biz/ttOrderDiscounts/page", //查询列表的接口
          deleteType: "/jeecg-cloud-shipping-biz/ttOrderDiscounts/deleteBatch", //删除
          listCount: '/jeecg-cloud-shipping-biz/ttOrderDiscounts/listCount', //合计
          discountExcel: '/jeecg-cloud-file-biz/file/download/model/discountExcel',//模板下载
          importDiscount: '/jeecg-cloud-file-biz/file/read/excel/importDiscount', //导入
        },
        visible: false,
        typeForm: {},
        type: '',
        totalPrice: [],
        // queryForm:{
        //   isSettle:'0'
        // }
      };
    },
    methods: {
      // 获取合计数据
      getTotalInit(params) {
        postAction(this.url.listCount, params).then(res => {
          this.totalPrice = res.result || [];
        })
      },

      handleQuery() {
        this.tableData = [];
        this.loading = true
        let form = { ...this.queryForm, ...this.filterForm, ...this.initData }
        // for (let item in form) {
        //     if (typeof form[item] === 'string') {
        //         form[item] = form[item].trim()
        //         if (form[item] === '') {
        //             form[item] = null
        //         }
        //     }
        // }
        //清空字符串的前后空格
        this.trimParamsStr(form);
        const { prop, order } = this.isorter
        // 传入的参数
        const params = {
          ...form,
          column: prop,
          order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
        }
        //判断 是否要查询startTime

        if (!params.column) {
          if (this.queryForm.column === 'startTime') {
            this.$set(params, 'column', 'startTime')
          } else if (this.enabledCreatetime) {
            this.$set(params, 'column', 'createTime')
          }
          //提货中查询
          if (this.queryForm.column === 'distributionTime') {
            this.$set(params, 'column', 'distributionTime')
          }
          //排序
          //提货中查询
          if (this.queryForm.column === 'sort') {
            this.$set(params, 'column', 'sort')
          }
        }
        if (this.ipagination) {
          params.pageNo = this.ipagination.currentPage
          params.pageSize = this.ipagination.pageSize
        }
        if(this.queryForm.salesmanIds && this.queryForm.salesmanIds.length) {
          params.salesmanId = this.queryForm.salesmanIds.join(',');
        }
        delete params.salesmanIds;
        // let method = "GET";
        // if (this.isUsePost) {
        //     method = "POST";
        // }
        this.getTotalInit(params);
        if (!this.isUsePost) {
          getAction(this.url.list, params).then((res) => {
            // consoloe.log(res)
            if (res.success) {
              this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
              if (res.result) {
                const { records, total } = res.result
                this.tableData = records || res.result;
                this.ipagination && (this.ipagination.total = total || res.result.length)
              } else {

                this.initTableData();
              }

            }
            this.loading = false;
            this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
          }).catch(err => {
            this.loading = false;
            // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
          })
        } else {
          postAction(this.url.list, params).then((res) => {
            // consoloe.log(res)
            if (res.success) {
              this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
              if (res.result) {
                const { records, total } = res.result
                this.tableData = records || res.result;

                this.ipagination && (this.ipagination.total = total || res.result.length)

              } else {

                this.initTableData();
              }

            }
            this.loading = false;
            this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
          }).catch(err => {
            this.loading = false;
            this.initTableData();
            // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
          })
        }

      },
      uploadSectionFile(param) {
        //file就是当前添加的文件
        var fileObj = param.file;
        //FormData 对象
        var form = new FormData();
        //文件对象，key是后台接受的参数名称
        form.append("file", fileObj);
        //执行上传方法
        console.log("执行上传方法");
        postAction(this.url.importDiscount, form)
          .then((res) => {
            //这里做上传之后的操作
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
            } else {
              this.handleQuery();
            }
          })
      },
      //  type 1-编辑  2-删除 3-日志 4-新增优惠类型 5-批量删除
      actionBtn(row, type) {
        if (type === "1") {
          this.type = '';
          this.visible = true;
          this.typeForm = { ...row };
        } else if (type === "2") {
          this.$confirm(`确定删除`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          })
            .then(() => {
              postAction(this.url.deleteType, [row.id]).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleQuery();
              });
            })
            .catch(() => { });
        } else if (type === "3") {
          this.visible = true;
          this.type = 'log';
          this.typeForm = { ...row };
        } else if (type === "4") {
          this.type = '';
          this.typeForm = {};
          this.visible = true;
        } else if (type === "5") {
          this.$confirm(`确定删除`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          })
            .then(() => {
              postAction(this.url.deleteType, this.selectedRowKeys).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleQuery();
              });
            })
            .catch(() => { });
        }
      },
       handleReset() {
            this.queryForm = {
              isSettle:'0'
            };
            this.filterForm = {};
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
    },
    created(){
      this.queryForm={
        isSettle:'0'
      }
      // this.queryForm.isSettle='0'
    },
    computed:{
      buttonShowList() {
        return {
          'add':this.$btnIsShow('product/DiscountPromotion','0','新增'),
          'delB':this.$btnIsShow('product/DiscountPromotion','0','批量删除'),
          'mbxz':this.$btnIsShow('product/DiscountPromotion','0','模板下载'),
          'im':this.$btnIsShow('product/DiscountPromotion','0','导入'),
          'edit':this.$btnIsShow('product/DiscountPromotion','1','编辑'),
          'del':this.$btnIsShow('product/DiscountPromotion','1','删除'),
          'log':this.$btnIsShow('product/DiscountPromotion','1','日志'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .total_box {
    line-height: 22px;
    display: flex;
    align-items: center;
    color: red;
  }
</style>
