<template>
  <div>

    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="报关单号">
              <el-input v-model="queryForm.declarationNo" type="textarea"></el-input>
            </el-form-item>
          </el-col>

          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px" style="width: 500px">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card">
      <div class="f-p-b-15">
        <div>
          <el-button type="primary" size="medium" icon="el-icon-plus" @click="exportExcel('导出报关单号')" v-if="buttonShowList.export">导出</el-button>

        </div>
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
      >

      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {getAction, postAction} from "@/api/manage";

export default {
  name: "ShareRecord",
  mixins: [QueryMixins],
  components: {
  },

  data() {
    return {
      formLabelWidth: "120px",
      queryForm: {},
      isUsePost: true,
      url: {
        list: "/shipping/hySettlePay/getAllDeclarationNoList",
        exportXlsUrl: '/shipping/hySettlePay/exportAllDeclarationNoList',
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "containerNo",
          label: "柜号",
          width: 120,
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 120,
        },
        {
          prop: "declarationNo",
          label: "报关单号",
          width: 120,
        },

        {
          prop: "feeName",
          label: "费用名称",
          width: 100,
        },
        {
          prop: "total",
          label: "价格",
          width: 100,
        },
        {
          prop: "moneyType",
          label: "币种",
          width: 80,
        },
        {
          prop: "operator",
          label: "排柜操作",
          width: 150,
        },
        {
          prop: "docOperator",
          label: "文件操作",
          width: 150,
        },
        {
          prop: "endOperator",
          label: "派送操作",
          width: 150,
        },
        {
          prop: "createBy",
          label: "录入人",
          width: 150,
        }
      ]
    };
  },

  created() {

  },
  methods: {

  },
  computed:{
    buttonShowList() {
      return {
        'export':this.$btnIsShow('hyFinanceManage/modules/pay/declarationNoModule', '0', '导出'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
