<template>
    <div class="main-full-content">
                <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="周数">
                                <el-input v-model="queryForm.week"  @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" style="display: none;">
                            <el-form-item label="no">
                                <el-input v-model="queryForm.no"  @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label-width="30px">
                                <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="81vh"
                :isRecordTableScroll="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button
                            v-if="buttonShowList.caCheck && (Number(scope.row.circulationState)<=2 || Number(scope.row.circulationState)===8)"
                             type="text" @click="handleEdit(scope.row)">取消审核</el-button>
                </template>
                <template v-else-if="scope.column.property === 'circulationState'">
                    <span>{{parseDict(scope.row.circulationState,'circulation_state')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'cabinetCategory'">
                    <span>{{parseDict(scope.row.cabinetCategory,'cabinet_category')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'bookAgentId'">
                    <span>{{parseDict(scope.row.bookAgentId,'bookAgent_id')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'week'">
                    <a style="color: #00a0e9" @click="infomation(scope.row.id)">{{scope.row.week}}</a>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
    import {putAction} from '@/api/manage'
    import QueryMixins from "../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: 'SensitiveProducts',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                dictCodes: ['circulation_state', 'cabinet_category', 'bookAgent_id'],
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 200,
                    },
                    {
                        prop: 'week',
                        label: '周数',
                        sortable: 'custom',
                        // width: 140
                    },
                    {
                        prop: 'cabinetCategory',
                        label: '专线or拆柜',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'bookAgentId',
                        label: '订舱代理',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'size',
                        label: '柜型',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'bookNo',
                        label: '订舱号',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'containerNo',
                        label: '柜号',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: 'originalNo',
                        label: '封条',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'loadTime',
                        label: '装柜时间',
                        sortable: 'custom',
                        // width: 160
                    }
                ],
                url: {
                    list: '/operation/containerApi/getAuditalbnormalList',
                    updateCabinetAudit: '/operation/containerApi/updateCabinetAudit'
                    // exportXlsUrl: '/customer/product/exportXls',
                    // tuihui:'/customer/product/qxSense/',
                    // tuihuiBatch:'/customer/product/qxSenseBatch',
                }
            }
        },
        methods: {
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push('/operation/cabinet/Edit?id=' + id)
            },
            showRate(val) {
                if (val.indexOf('%') !== -1) {
                    return val;
                } else {
                    return val + "%";
                }
            },
            handleReset() {
                this.queryForm = {}
                this.queryForm.sense = '1'
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleEdit(row) {
                let form = JSON.parse(JSON.stringify(row));
                form.complianceState = '1'
                this.$confirm(`确定取消审核?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.updateCabinetAudit, form).then(res => {
                            this.$message.success("修改成功");
                            //关闭弹窗
                            this.handleSearch();
                    })
                }).catch(() => {
                    this.handleSearch();
                })
            }
        },
        created() {
            this.queryForm.sense = '0'
        },
      computed:{
        buttonShowList() {
          return {
            'caCheck':this.$btnIsShow('conformance/AuditaNobnormal','1','取消审核'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
