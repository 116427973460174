<template>
  <div>
    <el-dialog
        :title="'账龄详情：' + settlement"
        :visible="visible"
        width="75%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="订单号">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    type="textarea"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客户">
                <el-input
                    type="textarea"
                    class="textarea_table"
                    v-model="queryForm.settlement"
                    disabled
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="业务类型">
                <f-select
                    :dict="'container_type'"
                    :is-need="queryForm.containerType"
                    class="textarea_table"
                    v-model="queryForm.containerType"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="币种">
                <f-select
                    :dict="'bd_cur'"
                    :is-need="queryForm.realMoneyType"
                    class="textarea_table"
                    v-model="queryForm.realMoneyType"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账单时间" label-width="100px" v-if="this.windowsType =='0'">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.billTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.billTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleClick"
                    :disabled="disabledFlag"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
                <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-card class="box-card">
        <div>
          <el-button  type="primary" size="small"  @click="daochu">导出</el-button>
        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
        >
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
    </el-dialog>


  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';
import {getAction} from "../../api/manage";


export default {
  name: "YingshouDt",
  mixins: [QueryMixins],
  components: {
  },
  props:{
    payType:{
      type:String,
      default: "0"
    },
    settlement:{
      type:String
    },
    queryForm:{
      type:Object
    },
    settleId:{
      type:String
    },
    cusId:{
      type:String
    },
    countType:{
      type:String
    },
    visible:{
      type:Boolean
    },
    windowsType: {
      type:String,
      default:"0"
    }
  },
  data() {
    return {
      disabledFlag:false,
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      proVisible: false,
      url: {
        list: "/order/yingshouzd/detail/pageList",
        daochu: "/file/jasper/downloadRecDetailExport"

      },
      issalesman: "0",
      payColumns: [
        {
          type: "index",
          label:'序号',
          width: 33,
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "containerType",
          label: "业务类型",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "settlement",
          label: "结算对象",
        },
        {
          prop: "settleCreateBy",
          label: "费用录入人",
        },
        {
          prop: "realMoneyType",
          label: "币种",
        },
        {
          prop: "realPay",
          label: "合计金额",
        },
        {
          prop: "billTime",
          label: "账单时间",
          minWidth: 120
        },
        {
          prop: "billDays",
          label: "账单预期天数",
        },
        {
          prop: "pol",
          label: "起运港",
        },
        {
          prop: "podEnd",
          label: "目的港",
        },
        {
          prop: "shipmentCode",
          label: "FBA CODE",
        },
        {
          prop: "desc",
          label: "最新物流",
          minWidth:200,
          over:true
        },

      ],
      columns: [
        {
          type: "index",
          label:'序号',
          width: 33,
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "containerType",
          label: "业务类型",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "settlement",
          label: "结算对象",
        },
        {
          prop: "salesman",
          label: "销售",
        },
        {
          prop: "billman",
          label: "收款人",
        },
        {
          prop: "realMoneyType",
          label: "币种",
        },
        {
          prop: "realPay",
          label: "合计金额",
        },
        {
          prop: "billTime",
          label: "账单时间",
          minWidth: 120
        },
        {
          prop: "billDays",
          label: "账单预期天数",
        },
        {
          prop: "pol",
          label: "起运港",
        },
        {
          prop: "podEnd",
          label: "目的港",
        },
        {
          prop: "shipmentCode",
          label: "FBA CODE",
        },
        {
          prop: "desc",
          label: "最新物流",
          minWidth:200,
          over:true
        },

      ],
      disableMixinMounted: true,
    };
  },
  watch: {
    visible(val) {
      if(this.payType == '1') this.columns = this.payColumns

      if(this.queryForm.payType == '1') this.url.daochu  = "/file/jasper/downloadPayDetailExport"
      if(this.queryForm.payType =='1') this.url.list  = "/order/yingshouzd/pay/detail/pageList"
      if(val && this.settlement) {
        this.$set(this.queryForm,'settlement',this.settlement);
        this.$set(this.queryForm,'settleId',this.settleId);
        this.$set(this.queryForm,'cusId',this.cusId);
        if(this.countType) this.$set(this.queryForm,'countType',this.countType);
       this.handleQuery();
      } else {

      }
    }
  },
  created() {
      if(this.windowsType == "1") this.url.list  = "/order/yingshouzd/detail/totalPageList"
      if(this.windowsType == "1") this.$set(this.queryForm,'cusId',this.cusId);


  },
  methods: {
    handleClick() {
      this.disabledFlag =true
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else {
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }

      console.log("查询对象", params)

      getAction(this.url.list, params).then((res) => {
        if (res.success) {
          const {records, total} = res.result
          this.tableData = records || res.result;
          this.ipagination && (this.ipagination.total = total || res.result.length)
        }
      }).finally(() => {
        this.disabledFlag = false
        this.loading = false
      })
    },
    handleClose() {
      this.$emit("ok");

    },
    daochu(){
      this.downloadExcel(this.url.daochu, "应收账单账龄详情", this.queryForm);
    },
    handleReset() {
      this.queryForm = {};
      if(this.windowsType == "1") this.$set(this.queryForm,'cusId',this.cusId);
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.$set(this.queryForm,'settlement',this.settlement);
      this.$set(this.queryForm,'settleId',this.settleId);
      this.handleQuery();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
