<template>
    <el-card style="width: 100%;height: 100%; ">
        <div class="upload_box">
            <el-upload
                class="upload-demo"
                action=""
                accept=".jpg,.png"
                :http-request="handleUpload"
                :on-preview="handlePreview"
                :before-upload="beforeUpload"
                :on-remove="deletImg"
                :before-remove="beforeRemove"
                multiple
                :file-list="fileList"
                style="width: 50%;"
        >
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-tooltip content="只能上传图片文件" placement="top">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-tooltip>
                </el-col>
            </el-row>

         


        </el-upload>
        <el-button size="small" type="primary" @click="getPDAImgList" style="position: absolute;
    left: 120px;
    top: 10px;">PDA收货图片</el-button>
   <div class="treeData_box" v-if="treeData && treeData.length && treeData[0].createBy">
                <div v-for="(item,index) in treeData" :key="index" class="treeData_item">
                    <span style="margin-right: 8px;">上传人：{{item.createBy}}</span>
                    <span>上传时间：{{item.createTime}}</span>
                </div>
            </div>
        </div>
        
        <div class="content">
            <div class="cont" v-for="(item, index) in imgList" :key="index">
                <el-image :src="item" fit="fill" :preview-src-list="imgList">
                    <div slot="error" class="image-slot" style="font-size: 20px;margin-top: 300px;">
                        <i class="el-icon-picture-outline">暂无图片</i>
                    </div>
                </el-image>
            </div>
            <div class="img-page" v-show="currentImageObj.imgUrl !== ''">
                第{{currentImageObj.sort + 1}}张，共{{imageTotal}}张
            </div>
        </div>

        <el-dialog
      title="PDA收货图片"
      :visible="imgVisible"
      width="30%"
      append-to-body
      custom-class="c-custom-dialog"
      :before-close="()=>imgVisible=false"
    >
    <div style="margin-bottom:10px">开箱验货图片</div>
     <div class="take_img_box" v-if="takeImgList.openFileList && takeImgList.openFileList.length">
      <img :src="item.url" alt="" v-for="(item,index) in takeImgList.openFileList" :key="index">
     </div>
     <div v-else style="color: red;">暂无</div>
      <div style="margin: 10px 0;">收货场景图片</div>
      <div class="take_img_box" v-if="takeImgList.recFileList && takeImgList.recFileList.length">
      <img :src="item.url" alt="" v-for="(item,index) in takeImgList.recFileList" :key="index">
     </div>
     <div v-else style="color: red;">暂无</div>
      <span slot="footer">
        <el-button type="primary" size="medium" @click="()=>imgVisible=false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    </el-card>
</template>

<script>
    import draggable from 'vuedraggable'
    import {getAction, httpAction, deleteAction, postAction} from '@/api/manage'

    export default {
        name: 'ImageDetail',
        components: {
            draggable,
            getAction,
            httpAction,
        },
        data() {
            return {
                obj:{},
                orderNo:'',
                description: '入仓单',
                //数据集
                treeData: [
                    {
                        imgUrl: ''
                    }
                ],
                url: {
                    list: '/interface/warehouse/getWarehouseImage',
                    deleteImg: '/interface/warehouse/deleteByFileIds',
                    updateImageStatus: '/interface/warehouse/updateImageStatus',
                    uploadImage: '/interface/saodaniu/uploadImage',
                    queryOssFilesByNo:"/warehouse/pda/queryOssFilesByNo"
                },
                imgList: [],
                currentImageObj: {
                    sort: 0,
                    imgUrl: ''
                },
                currentId: 0,
                loading: false,
                fileList: [],
                files1: {},
                form:{},
                imgVisible:false,
                takeImgList:[]
            }
        },
        computed: {
            imageTotal() {
                return this.imgList.length;
            },
             buttonShowList() {
      return {
        sctp: this.$btnIsShow(
          "docpage/modules/volumeModule",
          "0",
          "删除上传材积图片"
        ),
      };
    },
        },
        methods: {
            //上传文件到oss
            handleUpload(data) {
                const formData = new FormData();
                if (!this.orderNo ){
                    this.orderNo=sessionStorage.getItem('volumeOrderNo')
                }
                formData.append("file", data.file);
                formData.append("orderNo",this.orderNo)
                formData.append("dir",'warehouse')
                console.log("订单号",this.orderNo)

                postAction(this.url.uploadImage, formData).then(res => {
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        let {id, size, ext, name, url} = res.result;
                        this.fileList.push({ossId: id, size, ext, name, url, id});
                        this.imgList.push(url)
                        this.form.url = url;
                        this.form.ossId = id;
                        this.form.size = size;
                        this.form.ext = ext;
                        this.form.name = name;
                        // this.form.fileName = name;
                        this.$message.success('上传成功');
                        // this.fileList.push(res.result);
                    }
                })
            },
            //文件上传之前校验文件合法
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15
                if (!isLt2M) {
                    this.$message.warning('文件大小不能超过15MB')
                }
                const isFile = file.name.indexOf('.') !== -1;
                if (!isFile) {
                    this.$message.warning('该文件名不合法')
                }
                return isLt2M && isFile;
            },
            warehouseImage(obj) {
                this.obj = obj;
                this.orderNo = obj.orderNo;
                this.imgList = []
                this.treeData = [];
                this.fileList = [];
                let that = this
                this.loading = true;
                if (obj.orderNo) {
                    getAction(this.url.list, {"id": obj.orderNo}).then((res) => {
                        console.log('warehouseImage',res)
                        if (res.code === 200) {
                            that.treeData = res.result
                            if (that.treeData.length > 0) {
                                this.getImgList(res.result);
                            }else{
                                this.fileList = []
                                this.imgList = []
                            }
                        }

                    })
                        .finally(() => {
                            this.loading = false;
                        })
                }

            },
            getImgList() {
                var count = 0;
                for (var i = 0; i < this.treeData.length; i++) {
                    this.imgList.push(this.treeData[i].fileDownloadUrl)
                    /*this.fileList=[
                        {
                            ossId:this.treeData[i].fileId,
                            name:this.treeData[i].name || this.treeData[i].fileName,
                            url:this.treeData[i].fileDownloadUrl
                        }]*/
                    this.files1={
                        ossId:this.treeData[i].fileId,
                        name:this.treeData[i].name || this.treeData[i].fileName,
                        url:this.treeData[i].fileDownloadUrl
                    }
                    this.fileList.push(this.files1)
                    count++
                }
                console.log('6565165156',this.fileList)
                // if (count > 0) {
                //     this.currentImageObj = this.imgList[0];
                // } else {
                //     this.currentImageObj = {
                //         sort: 0,
                //         imgUrl: ''
                //     }
                // }
            },

            prev() {
                let index = this.currentImageObj.sort;
                if (index <= 0) {
                    this.currentImageObj = this.imgList[this.imgList.length - 1];
                } else {
                    this.currentImageObj = this.imgList[index - 1];
                }
            },
            next() {
                let index = this.currentImageObj.sort;
                if (index >= this.imageTotal - 1) {
                    this.currentImageObj = this.imgList[0];
                } else {
                    this.currentImageObj = this.imgList[index + 1];
                }
            },
            deletImg(val) {
                console.log(val,'val');
                let that = this
                this.loading = true;
                deleteAction(this.url.deleteImg, {
                    fileIds: val.ossId
                })
                    .then((res) => {

                        this.warehouseImage(this.obj);
                        if (this.imgList.length === 0) {
                            getAction(this.url.updateImageStatus, {'id': val.ossId}).then(res => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                }
                            })

                        }
                    })
                    .catch((err) => {
                    })
                    .finally(() => {
                        this.loading = false;
                    });


            },
            handleRemove(val) {
                //删除oss的文件
                const index = this.fileList.indexOf(val);
                deleteAction(this.url.delOss,{id:this.form.ossId})
                    .then(res=>{
                        if (res.success){
                            this.fileList.splice(index,1);
                            //同时清空文件的url
                            this.form.url = '';
                            this.form.ossId = '';
                            this.$message.success('删除文件成功')
                        }else {
                            //清空filelist
                            this.fileList.splice(index,1);
                        }
                    }).catch(err=>{
                    this.fileList.splice(index,1);
                    this.$refs.upload.clearFiles();

                })
            },
            handlePreview(file) {
            },
            //  :limit="1"   :on-exceed="handleExceed"
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                if(!this.buttonShowList.sctp) {
                    this.$message.warning('您暂无删除权限，请先分配权限')
                    return false
                }
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            upload() {
                postAction(this.url.uploadImage,)
            },
            getPDAImgList() {
                if(!this.orderNo) {
                    this.$message.warning('请输入订单号')
                    return
                };     
               getAction(this.url.queryOssFilesByNo, {
                orderNo: this.orderNo
               }).then((res) => {
                    if(res.success){
                      this.takeImgList=res.result
                      this.imgVisible=true
                    }else{
                       this.takeImgList=[]
                        this.$message.warning('暂无数据');
                    }
                });

            },
        }
    }
</script>

<style scoped lang="scss">
    .content {
        height: 640px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        position: relative;
    }

    .upload_box {
        display: flex;
        align-items: flex-end;
    }
    .treeData_box {
        flex: 1;
        color: #606266;
    }

    .treeData_item {
       span {
        display: inline-block;
        line-height: 21px;
        margin-top: 4.89px;
       }
    }

    .cont {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: auto;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image-slot {
        margin-top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #c0c4cc;
        vertical-align: middle;
        text-align: center;
    }


    .z-icon {
        font-size: 26px;
        cursor: pointer;
    }

    .img-page {
        height: 40px;
    }

    .img-page {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .delete {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

</style>
