<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item>
              <f-select
                      placeholder="时间"
                      slot="label"
                      :dict="'profit_statement_time_type'"
                      v-model="queryForm.timeType"
                      :isNeed="queryForm.timeType"
                      @changet="() => {this.$set(this.queryForm, 'timeStr', '')}">

              </f-select>
              <el-date-picker
                      class="width_date_per"
                      v-model="queryForm.timeStr"
                      :type="queryForm.timeType"
                      placeholder="选择时间"
                      :value-format="queryForm.timeType === 'year' ? 'yyyy' : 'yyyy-MM'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="buttonShowList.saleman">
            <el-form-item label="业务员" label-width="60px">
              <f-select ref="salesmanIds" v-model="queryForm.salesmanIds" :isNeed="queryForm.salesmanIds" :dict="'salesmanId'" :isAll="true"
                :multiple="true" :collapseTags="true" @keyup.enter.native="handleSearch" @selectAll="handleSelectAll"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="拼柜类型">
              <f-select v-model="queryForm.containerType" :isNeed="queryForm.containerType" :dataList="containerTypeList">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportSalesmanProfit, '销售利润报表')"
          v-if="buttonShowList.dc">导出
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import QueryMixins from "@/mixins/QueryMixins.js";
  import {profitStatementUrl} from "@/utils/profitStatementUrl";

  export default {
    name: "SaleReport",
    mixins: [QueryMixins],
    components: {
    },
    props: {
      salesMouth: {
        type: String,
        default: ''
      },
      containerTypeList: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        queryForm : {
          timeType : '',
          timeStr : '',
          containerType : '0',
          queryType : 'salesman'
        },
        isUsePost: true,
        columns: [
          {
            type: "selection",
            width: 50,
          },
          {
            prop: "departmentName",
            label: "部门",
          },
          {
            prop: "salesmanName",
            label: "业务员",
            sortable: "custom",
          },
          {
            prop: "timeStr",
            label: "年月",
          },
          {
            prop: "quantityCmb",
            label: "货量CBM/KG",
            sortable: "custom",
          },
          {
            prop: "quantityRank",
            label: "货量排名",
            sortable: "custom",
          },
          {
            prop: "profit",
            label: "毛利汇总CNY",
            sortable: "custom",
          },
          {
            prop: "profitRank",
            label: "总毛利排名",
            sortable: "custom",
          },
          {
            prop: "profitPerCubicMeter",
            label: "每方毛利CNY",
            sortable: "custom",
          },
          {
            prop: "profitPerCubicMeterRank",
            label: "每方毛利排名",
            sortable: "custom",
          },
          {
            prop: "orderCount",
            label: "订单票数",
            sortable: "custom",
          },
        ],
        url: {
          list: profitStatementUrl.list,
          exportSalesmanProfit: profitStatementUrl.export
        },
      };
    },
    created() {
    },
    methods: {
      handleReset() {
        this.queryForm = { queryType : 'salesman', containerType: '0' };
        this.filterForm = {};
        this.ipagination.currentPage = 1
        this.handleQuery()
      },
    },
    computed:{
      buttonShowList() {
        return {
          'saleman':this.$btnIsShow('sale/SalesReport/SaleReport','0','业务员'),
          'dc':this.$btnIsShow('sale/SalesReport/SaleReport', '0', '导出'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
