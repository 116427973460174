import { render, staticRenderFns } from "./YingshouZDNew.vue?vue&type=template&id=9240871c&scoped=true&"
import script from "./YingshouZDNew.vue?vue&type=script&lang=js&"
export * from "./YingshouZDNew.vue?vue&type=script&lang=js&"
import style0 from "./YingshouZDNew.vue?vue&type=style&index=0&id=9240871c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9240871c",
  null
  
)

export default component.exports