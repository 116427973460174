import { render, staticRenderFns } from "./SalesFixOrder.vue?vue&type=template&id=bed67cec&scoped=true&"
import script from "./SalesFixOrder.vue?vue&type=script&lang=js&"
export * from "./SalesFixOrder.vue?vue&type=script&lang=js&"
import style0 from "./SalesFixOrder.vue?vue&type=style&index=0&id=bed67cec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed67cec",
  null
  
)

export default component.exports