<template>
    <div>
        <el-card class="box-card" v-if="order.containerType === '0'">
            <div class="flex_wrap_start">
                <div class="title_tab_text">
          <span style="margin-left: 20px"
          >派送信息【{{ $route.query.orderNo }}】</span
          >
                    <span style="margin-left: 20px"
                    >件数&nbsp;&nbsp;&nbsp;{{ totalShipCtn }}</span
                    >
                    <span style="margin-left: 20px"
                    >重量&nbsp;&nbsp;&nbsp;{{ totalShipKg }}</span
                    >
                    <span style="margin-left: 20px"
                    >体积&nbsp;&nbsp;&nbsp;{{ totalShipCmb }}</span
                    >
                </div>
                <el-form
                        :model="form"
                        ref="bdform"
                        :rules="rules"
                        style="float: left; margin-left: 30px"
                >
                    <el-radio-group v-model="form.shipmentType">
                        <el-radio :label="'0'" v-if="checkIsCt()">FBA</el-radio>
                        <el-radio :label="'1'">私人住宅</el-radio>
                        <el-radio :label="'2'" v-if="checkIsCt()">海外仓</el-radio>
                        <el-radio :label="'3'">商业地址</el-radio>
                        <!--            <el-radio :label="'4'">大森林海外仓</el-radio>-->
                    </el-radio-group>
                </el-form>
            </div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="f-p-b-15">
                        <el-button
                                type="primary"
                                size="medium"
                                @click="saveAddressInfo"
                                v-if="buttonShowList.bcdz"
                                :disabled="getIsSaveBtnDisabled()"
                        >保存派送地址
                        </el-button>
                        <!-- <el-button type="primary" size="medium" @click="initShiplist">
                        查询
                      </el-button> -->
                    </div>
                    <div v-if="form.shipmentType === '0'">
                        <el-form
                                :model="addressModel"
                                ref="addressModel"
                                :rules="addressRules"
                        >
                            <el-form-item prop="sysId0" key="fbaAddress">
                                <el-select
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="getFbaAddressDetail"
                                        v-model="addressModel.sysId0"
                                >
                                    <el-option
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item, index) in fbalist"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="fba地址：">
                                <span>{{ addressModel.addressDetail0 }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-if="form.shipmentType === '4'">
                        <el-form
                                :model="addressModel"
                                ref="addressModel"
                                :rules="addressRules"
                        >
                            <el-form-item prop="sysId4" key="fbaAddress4">
                                <el-select
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="getFbaAddressDetail4"
                                        v-model="addressModel.sysId4"
                                >
                                    <el-option
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item, index) in fbalist4"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="大森林海外仓地址：">
                                <span>{{ addressModel.addressDetail4 }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-if="form.shipmentType === '2'">
                        <el-form
                                :model="addressModel"
                                ref="addressModel"
                                :rules="addressRules"
                        >
                            <el-form-item prop="sysIdw" key="warehouse">
                                <el-select
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="getWareAddressDetail"
                                        v-model="addressModel.sysIdw"
                                >
                                    <el-option
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item, index) in warehouseList"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="海外仓地址：">
                                <span>{{ addressModel.addressDetail2 }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-if="form.shipmentType === '1' || form.shipmentType === '3'">
                        <el-form
                                :model="addressModel"
                                ref="addressModel"
                                :rules="addressRules"
                        >
                            <el-form-item
                                    prop="contact"
                                    label="联系人:"
                                    :label-width="'60px'"
                                    :rules="addressRules.contact"
                                    key="contact"
                            >
                                <el-input
                                        v-model="addressModel.contact"
                                        maxlength="50"
                                        show-word-limit
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                    prop="tel"
                                    label="电话:"
                                    :label-width="'60px'"
                                    key="tel"
                                    :rules="addressRules.tel"
                            >
                                <el-input
                                        v-model="addressModel.tel"
                                        maxlength="50"
                                        show-word-limit
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                    prop="country"
                                    label="国家:"
                                    :label-width="'60px'"
                                    key="country"
                            >
                                <el-select
                                        v-model="addressModel.country"
                                        :is-need="addressModel.country"
                                        filterable
                                        clearable
                                        @change="initStateList"
                                >
                                    <el-option
                                            v-for="(item, index) in countryList"
                                            :key="index"
                                            :label="item.itemText"
                                            :value="item.itemValue"
                                    ></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item
                                    prop="state"
                                    label="州:"
                                    :label-width="'60px'"
                                    key="state"
                                    :rules="addressRules.state"
                            >
                                <el-input
                                        v-model="addressModel.state"
                                        maxlength="20"
                                        show-word-limit
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                    prop="zipcode"
                                    label="邮编:"
                                    :label-width="'60px'"
                                    key="zipcode"
                                    :rules="addressRules.zipcode"
                            >
                                <el-input
                                        v-model="addressModel.zipcode"
                                        maxlength="50"
                                        show-word-limit
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                    prop="city"
                                    label="城市:"
                                    :label-width="'60px'"
                                    key="city"
                                    :rules="addressRules.city"
                            >
                                <el-input
                                        v-model="addressModel.city"
                                        maxlength="50"
                                        show-word-limit
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                    prop="address"
                                    label="地址:"
                                    :label-width="'60px'"
                                    key="address"
                                    :rules="addressRules.address"
                            >
                                <el-input
                                        v-model="addressModel.address"
                                        type="textarea"
                                        maxlength="200"
                                        show-worl-limit
                                ></el-input>
                            </el-form-item>
                            <!--                            <el-form-item prop="company" label="仓库类型:" :label-width="'80px'">-->
                            <!--                                <el-input v-model="addressModel.company" maxlength="60" show-word-limit></el-input>-->
                            <!--                            </el-form-item>-->
                            <el-col :span="12">
                                <el-form-item
                                        prop="isDischarge"
                                        label="派送尾板车:"
                                        :label-width="'85px'"
                                        key="isDischarge"
                                >
                                    <el-radio-group v-model="addressModel.isDischarge">
                                        <el-radio :label="'0'">是</el-radio>
                                        <el-radio :label="'1'">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item
                                        prop="isYuyue"
                                        label="是否预约:"
                                        :label-width="'80px'"
                                        key="isYuyue"
                                >
                                    <el-radio-group v-model="addressModel.isYuyue">
                                        <el-radio :label="'1'">是</el-radio>
                                        <el-radio :label="'0'">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </div>
                </el-col>
                <!--                唛头列表-->
                <el-col :span="18">
                    <div class="f-p-b-15">
                        <el-button
                                type="primary"
                                size="medium"
                                icon="el-icon-plus"
                                @click="addShip"
                                v-if="buttonShowList.add"
                                :disabled="getIsSaveBtnDisabled()"
                        >添加
                        </el-button>
                        <el-button
                                type="primary"
                                size="medium"
                                @click="saveShipBatch"
                                v-if="buttonShowList.bcjbxx"
                                :disabled="getIsSaveBtnDisabled()"
                        >保存派送信息
                        </el-button>
                        <el-upload
                                action=""
                                :http-request="importShips"
                                :show-file-list="false"
                                :before-upload="beforeUpload"
                                accept=".xls,.xlsx"
                                style="display: inline"
                                class="upload_margin"
                                :disabled="getIsSaveBtnDisabled()"
                        >
                            <el-button
                                    type="primary"
                                    size="medium"
                                    :disabled="getIsSaveBtnDisabled()"
                            >导入</el-button
                            >
                        </el-upload>
                        <el-button
                                type="primary"
                                size="medium"
                                @click="downloadModel(url.downloadShipModel, 'shipment导入模板')"
                        >
                            模板下载
                        </el-button>
                        <!-- <el-button type="primary" size="medium" @click="initShiplist">
                        查询
                      </el-button> -->
                    </div>
                    <el-form :model="form" ref="shipref" :rules="rules">
                        <el-table
                                :data="form.osOrderShipmentList"
                                style="width: 99%"
                                ref="shiptab"
                                :summary-method="getSummaries"
                                :header-cell-style="{ 'text-align': 'center' }"
                                show-summary
                                class="suTable c-th-has-bgcolor"
                                v-loading="loading"
                                @selection-change="handleSelectionChange"
                                @sort-change="handleTableChange"
                                :cell-style="{ padding: 2 + 'px' }"
                        >
                            <el-table-column type="selection" width="30"></el-table-column>
                            <el-table-column
                                    v-if="form.shipmentType === '0'"
                                    prop="shipmentId"
                                    label="Shipment ID"
                                    minWidth="140"
                                    key="fba"
                            >
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                    <template>
                                        <span class="is_require_form">Shipment ID</span>
                                    </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="
                      'osOrderShipmentList.' + scope.$index + '.shipmentId'
                    "
                                            :rules="rules.shipmentId"
                                    >
                                        <el-input
                                                v-model="scope.row.shipmentId"
                                                maxlength="50"
                                        ></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    v-if="form.shipmentType === '0'"
                                    prop="referenceId"
                                    label="Reference ID"
                                    minWidth="140"
                                    key="refe"
                            >
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                    <template>
                                        <span class="is_require_form">Reference ID</span>
                                    </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="
                      'osOrderShipmentList.' + scope.$index + '.referenceId'
                    "
                                            :rules="rules.referenceId"
                                    >
                                        <el-input
                                                v-model="scope.row.referenceId"
                                                maxlength="50"
                                        ></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <!--                          fba创建时间-->
                            <el-table-column
                                    v-if="form.shipmentType === '0'"
                                    prop="fbaCreateTime"
                                    label="FBA ID 创建时间"
                                    key="fbaCreateTime"
                                    minWidth="120"
                            >
                                <!-- 标头需要加必填标识 -->
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="
                      'osOrderShipmentList.' + scope.$index + '.fbaCreateTime'
                    "
                                    >
                                        <el-date-picker
                                                v-model="scope.row.fbaCreateTime"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    v-if="form.shipmentType != '0'"
                                    prop="mark"
                                    :label="form.shipmentType == '2' ? '入仓编号' : '唛头'"
                                    minWidth="120"
                                    key="mark"
                            >
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'osOrderShipmentList.' + scope.$index + '.mark'"
                                            :rules="[
                      { required: true, message: hint, trigger: 'blur' },
                    ]"
                                    >
                                        <el-input
                                                v-model="scope.row.mark"
                                                maxlength="150"
                                        ></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="pcs" label="PCS(欧日线填)">
                                <template slot="header" v-if="checkCountryIsEuroAndJapan()">
                                    <template>
                                        <span class="is_require_form">PCS(欧日线填)</span>
                                    </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'osOrderShipmentList.' + scope.$index + '.pcs'"
                                            :rules="
                      checkCountryIsEuroAndJapan() ? rules.pcs2 : rules.pcs
                    "
                                    >
                                        <el-input v-model="scope.row.pcs" type="number"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="ctn" label="件数(CTN)" minWidth="130">
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                    <template>
                                        <span class="is_require_form">件数(CTN)</span>
                                    </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'osOrderShipmentList.' + scope.$index + '.ctn'"
                                            :rules="rules.ctn"
                                    >
                                        <el-input v-model="scope.row.ctn" type="number"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="kg" label="重量(KG)" minWidth="120">
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                    <template>
                                        <span class="is_require_form">重量(KG)</span>
                                    </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'osOrderShipmentList.' + scope.$index + '.kg'"
                                            :rules="rules.kg"
                                    >
                                        <el-input v-model="scope.row.kg" type="number"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="cmb" label="体积(CBM)" minWidth="140">
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                    <template>
                                        <span class="is_require_form">体积(CBM)</span>
                                    </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'osOrderShipmentList.' + scope.$index + '.cmb'"
                                            :rules="rules.cmb"
                                    >
                                        <el-input v-model="scope.row.cmb" type="number"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                            <el-table-column prop="remark" label="派送备注" minWidth="200">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'osOrderShipmentList.' + scope.$index + '.remark'"
                                    >
                                        <el-input
                                                v-model="scope.row.remark"
                                                maxlength="1000"
                                                type="textarea"
                                        ></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" label="操作" minWidth="130">
                                <template slot-scope="scope">
                                    <!--                                  <el-button type="warning" icon="el-icon-star-off" circle @click="removeShip(scope.$index)"-->
                                    <!--                                             v-if="$btnIsShow('docpage/modules/Shipmen','1','删除')"></el-button>-->
                                    <el-button
                                            type="text"
                                            @click="removeShip(scope.$index)"
                                            v-if="buttonShowList.sc"
                                    >删除
                                    </el-button>
                                    <!--                                    <f-upload-->
                                    <!--                                      v-if="form.shipmentType == '0'"-->
                                    <!--                                      v-model="scope.row.filelist"-->
                                    <!--                                      :limit="1"-->
                                    <!--                                      buttonType="text"-->
                                    <!--                                      :isUseOss="true"-->
                                    <!--                                      buttonTitle="fba标签"-->
                                    <!--                                    ></f-upload>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="box-card" v-if="order.containerType === '1'">
            <div class="flex_wrap_start">
                <div class="title_tab_text">
          <span style="font-size: 20px"
          >派送信息【{{ $route.query.orderNo }}】</span
          >
                    <span style="margin-left: 20px"
                    >件数&nbsp;&nbsp;&nbsp;{{ totalShipCtn }}</span
                    >
                    <span style="margin-left: 20px"
                    >重量&nbsp;&nbsp;&nbsp;{{ totalShipKg }}</span
                    >
                    <span style="margin-left: 20px"
                    >体积&nbsp;&nbsp;&nbsp;{{ totalShipCmb }}</span
                    >
                </div>
                <div class="f-m-l-30">
                    <el-form
                            :model="form"
                            ref="bdform"
                            :rules="rules"
                            style="float: left; margin-right: 20px"
                    >
                        <el-radio-group v-model="form.shipmentType">
                            <el-radio :label="'0'" v-if="checkIsCt()">FBA</el-radio>
                            <el-radio :label="'1'">私人住宅</el-radio>
                            <el-radio :label="'2'" v-if="checkIsCt()">海外仓</el-radio>
                            <el-radio :label="'3'">商业地址</el-radio>
                            <!--              <el-radio :label="'4'">大森林海外仓</el-radio>-->
                        </el-radio-group>
                    </el-form>
                </div>
            </div>
            <!--            <p>-->
            <!--                &nbsp;&nbsp;&nbsp;重量&nbsp;&nbsp;&nbsp;{{-->
            <!--                totalShipKg-->
            <!--                }}&nbsp;&nbsp;&nbsp;体积&nbsp;&nbsp;&nbsp;{{ totalShipCmb }}-->
            <!--            </p>-->
            <!--            <el-form :model="form" ref="bdform" :rules="rules">-->
            <!--                <el-radio-group v-model="form.shipmentType">-->
            <!--                    <el-radio :label="'0'">FBA</el-radio>-->
            <!--                    <el-radio :label="'1'">私人住宅</el-radio>-->
            <!--                    <el-radio :label="'2'">海外仓</el-radio>-->
            <!--                    <el-radio :label="'3'">商业地址</el-radio>-->
            <!--                </el-radio-group>-->
            <!--            </el-form>-->
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="addShip"
                        v-if="buttonShowList.add"
                        :disabled="getIsSaveBtnDisabled()"
                >添加
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="saveShipBatch2"
                        :disabled="getIsSaveBtnDisabled()"
                        v-if="buttonShowList.bc"
                >保存
                </el-button>
                <el-upload
                        action=""
                        :http-request="importShips"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        accept=".xls,.xlsx"
                        style="display: inline"
                        class="upload_margin"
                        :disabled="getIsSaveBtnDisabled()"
                >
                    <el-button
                            type="primary"
                            size="medium"
                            :disabled="getIsSaveBtnDisabled()"
                    >导入shipment
                    </el-button>
                </el-upload>
                <el-button
                        type="primary"
                        size="medium"
                        @click="downloadModel(url.downloadShipModel, 'shipment导入模板')"
                >导入模板
                </el-button>
            </div>
            <!-- <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
                <span
                        class="stress f-m-l-12 f-cursor-pointer"
                        @click="handleClearSelection"
                >清空</span
                >
            </div> -->
            <el-form :model="form" ref="shipref" :rules="rules">
                <el-table
                        :data="form.osOrderShipmentList"
                        style="width: 99%"
                        ref="shiptab"
                        show-summary
                        :summary-method="getSummaries"
                        :header-cell-style="{ 'text-align': 'center' }"
                        class="suTable c-th-has-bgcolor"
                        v-loading="loading"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                        :cell-style="{ padding: 2 + 'px' }"
                >
                    <el-table-column type="selection" width="30"></el-table-column>
                    <el-table-column
                            v-if="form.shipmentType == '0'"
                            prop="shipmentId"
                            label="Shipment ID"
                            minWidth="140"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">Shipment ID</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.shipmentId'"
                                    :rules="rules.shipmentId"
                            >
                                <el-input
                                        v-model="scope.row.shipmentId"
                                        maxlength="50"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="form.shipmentType == '0'"
                            prop="referenceId"
                            label="Reference ID"
                            minWidth="140"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">Reference ID</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.referenceId'"
                                    :rules="rules.referenceId"
                            >
                                <el-input
                                        v-model="scope.row.referenceId"
                                        maxlength="50"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="form.shipmentType === '0'"
                            prop="fbaCreateTime"
                            label="FBA ID 创建时间"
                            key="fbaCreateTime"
                            minWidth="120"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.fbaCreateTime'"
                            >
                                <el-date-picker
                                        v-model="scope.row.fbaCreateTime"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="form.shipmentType != '0'"
                            prop="mark"
                            :label="form.shipmentType == '2' ? '入仓编号' : '唛头'"
                            minWidth="120"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.mark'"
                                    :rules="[{ required: true, message: hint, trigger: 'change' }]"
                            >
                                <el-input v-model="scope.row.mark" maxlength="150"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pcs" label="PCS(欧日线填)" minWidth="120">
                        <template slot="header" v-if="checkCountryIsEuroAndJapan()">
                            <template>
                                <span class="is_require_form">PCS(欧日线填)</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.pcs'"
                                    :rules="checkCountryIsEuroAndJapan() ? rules.pcs2 : rules.pcs"
                            >
                                <el-input v-model="scope.row.pcs" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ctn" label="件数(CTN)" minWidth="130">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">件数(CTN)</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.ctn'"
                                    :rules="rules.ctn"
                            >
                                <el-input v-model="scope.row.ctn" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="kg" label="重量(KG)" minWidth="120">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">重量(KG)</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.kg'"
                                    :rules="rules.kg"
                            >
                                <el-input v-model="scope.row.kg" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cmb" label="体积(CBM)" minWidth="140">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">体积(CBM)</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.cmb'"
                                    :rules="rules.cmb"
                            >
                                <el-input v-model="scope.row.cmb" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="地址信息" minWidth="180">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">地址信息</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.addressId'"
                                    :rules="rules.addressId"
                            >
                                <el-select
                                        key="fbaAdd"
                                        v-if="form.shipmentType == '0'"
                                        :loading="fbaloading"
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="
                    getFbaAddressDetail2(scope.row.addressId, scope.$index)
                  "
                                        v-model="scope.row.addressId"
                                >
                                    <el-option
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item, index) in fbalist"
                                            :key="index"
                                    ></el-option>
                                </el-select>

                                <el-select
                                        key="fbaAdd"
                                        v-if="form.shipmentType == '4'"
                                        :loading="fbaloading"
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="
                    getFbaAddressDetail44(scope.row.addressId, scope.$index)
                  "
                                        v-model="scope.row.addressId"
                                >
                                    <el-option
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item, index) in fbalist4"
                                            :key="index"
                                    ></el-option>
                                </el-select>

                                <el-select
                                        key="wareAdd"
                                        v-if="form.shipmentType == '2'"
                                        :loading="storeloading"
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="
                    getWareAddressDetail2(scope.row.addressId, scope.$index)
                  "
                                        v-model="scope.row.addressId"
                                >
                                    <el-option
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="(item, index) in warehouseList"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                                <el-select
                                        key="perAdd"
                                        v-if="form.shipmentType === '1'"
                                        :loading="addressloading"
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="
                    getPerAddressDetail2(scope.row.addressId, scope.$index)
                  "
                                        v-model="scope.row.addressId"
                                >
                                    <el-option
                                            v-for="(item, index) in addresslist1"
                                            :label="item.label"
                                            :value="item.value"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                                <el-select
                                        key="perAdd"
                                        v-if="form.shipmentType === '3'"
                                        :loading="addressloading"
                                        clearable
                                        filterable
                                        placeholder="请选择"
                                        @change="
                    getPerAddressDetail2(scope.row.addressId, scope.$index)
                  "
                                        v-model="scope.row.addressId"
                                >
                                    <el-option
                                            v-for="(item, index) in addresslist3"
                                            :label="item.label"
                                            :value="item.value"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="详细地址" minWidth="140">
                        <template slot-scope="scope">
                            <span>{{ scope.row.addressDetail }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="派送备注" minWidth="200">
                        <template slot-scope="scope">
                            <el-form-item
                                    class="el_form_item"
                                    :prop="'osOrderShipmentList.' + scope.$index + '.remark'"
                            >
                                <el-input
                                        v-model="scope.row.remark"
                                        maxlength="255"
                                        type="textarea"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="action" label="操作" minWidth="130">
                        <template slot-scope="scope">
                            <el-button type="text" @click="removeShip(scope.$index)"
                            >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
        </el-card>

        <el-card class="box-card">
            <div class="title_tab_text">
        <span style="margin-right: 20px"
        >发票及装箱单【{{ $route.query.orderNo }}】</span
        >
                <span style="margin-right: 20px">
          件数&nbsp;&nbsp;&nbsp;{{ totalGoodCtn }}</span
                >
                <span style="margin-right: 20px">
          重量&nbsp;&nbsp;&nbsp;{{ totalGoodKg }}</span
                >
                <span style="margin-right: 20px">
          体积&nbsp;&nbsp;&nbsp;{{ totalGoodCmb }}</span
                >
                <span style="margin-right: 20px" v-if="checkIsCt()">
          总价&nbsp;&nbsp;&nbsp;{{ totalGoodPrice }}</span
                >
                <span
                        v-if="order.hasTaxStr === '0' && checkIsCt()"
                        style="margin-right: 5px"
                >
          <span>票均：</span>
          <span v-if="Number(totalPrePrice) < 270" style="color: red">{{
            totalPrePrice
          }}</span>
          <span v-else>{{ totalPrePrice }}</span>
        </span>
                <span style="margin-right: 20px" v-if="checkIsCt()">
          发票状态：
          <span v-if="order.invoiceStatus === '1'" style="color: green"
          >(已确认)</span
          >
          <span v-else style="color: red">(待确认)</span></span
                >
                <span style="margin-right: 20px" v-if="checkIsCt()">
          发票审核：
          <span v-if="order.invoiceCheck === '1'" style="color: green"
          >(已审核)</span
          >
          <span v-else style="color: red">(未审核)</span></span
                >
                <span style="margin-right: 20px" v-if="checkIsCt()">
          是否包税：
          <span v-if="order.hasTaxStr === '1'" style="color: green"> 是</span>
          <span v-else style="color: red"> 否</span></span
                >
                <!--                修改包税运费 和 附加费 只有包税的 订单才用修改-->
                <el-form
                        :model="taxForm"
                        :rules="rules"
                        ref="taxForm"
                        label-width="80px"
                        v-if="order.hasTaxStr === '1'"
                        :validate-on-rule-change="false"
                >
                    <el-form-item label="附加费" prop="fjf" :rules="rules.fjf">
                        <el-input type="number" v-model="taxForm.fjf"></el-input>
                    </el-form-item>
                </el-form>
                <el-button
                        type="primary"
                        size="small"
                        style="margin-left: 15px"
                        @click="saveTax('form')"
                        v-if="buttonShowList.bcbsfy && order.hasTaxStr === '1'"
                >保存包税费用
                </el-button>
            </div>
            <!--            <p>-->
            <!--                件数&nbsp;&nbsp;&nbsp;{{-->
            <!--                totalGoodCtn-->
            <!--                }}&nbsp;&nbsp;&nbsp;重量&nbsp;&nbsp;&nbsp;{{-->
            <!--                totalGoodKg-->
            <!--                }}&nbsp;&nbsp;&nbsp;体积&nbsp;&nbsp;&nbsp;{{-->
            <!--                totalGoodCmb-->
            <!--                }}&nbsp;&nbsp;&nbsp;总价&nbsp;&nbsp;&nbsp;{{ totalGoodPrice }}-->
            <!--                发票状态：<span v-if="order.invoiceStatus==='1'" style="color: green">(已确认)</span>-->
            <!--                <span v-else style="color: red">(待确认)</span>-->
            <!--                发票审核：<span v-if="order.invoiceCheck==='1'" style="color: green">(已审核)</span>-->
            <!--                <span v-else style="color: red">(未审核)</span>-->
            <!--            </p>-->
            <div class="f-p-b-15" style="display: flex">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="addGood"
                        v-if="buttonShowList.add1"
                        :disabled="getIsSaveProBtnDisabled()"
                        style="margin-right: 5px"
                >添加
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="saveGoodBatch"
                        v-if="buttonShowList.bc1"
                        :disabled="getIsSaveProBtnDisabled()"
                        style="margin-right: 5px"
                >保存
                </el-button>
                <!--                <el-button-->
                <!--                        type="primary"-->
                <!--                        size="medium"-->
                <!--                        @click="updateGoods301"-->
                <!--                        v-if="$btnIsShow('docpage/modules/Invoice','0','修改301编码')"-->
                <!--                        :disabled="getIsSaveProBtnDisabled()"-->
                <!--                        style="margin-right: 5px"-->
                <!--                >修改301编码</el-button>-->
                <!--        <el-button-->
                <!--          type="primary"-->
                <!--          size="medium"-->
                <!--          @click="recordBatch"-->
                <!--          v-if="-->
                <!--            $btnIsShow('docpage/modules/Invoice', '0', '批量备案') &&-->
                <!--            checkIsCt()-->
                <!--          "-->
                <!--          :disabled="getIsSaveProBtnDisabled()"-->
                <!--          style="margin-right: 5px"-->
                <!--          >批量备案-->
                <!--        </el-button>-->
                <el-button
                        type="primary"
                        size="medium"
                        @click="invoiceOkBatch"
                        v-if="buttonShowList.qrfp && order.invoiceStatus !== '1' && checkIsCt()"
                        style="margin-right: 5px"
                >确认发票
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="invoiceqxBatch"
                        v-if="buttonShowList.fpqrqx && order.invoiceStatus === '1' && checkIsCt()"
                        style="margin-right: 5px"
                >发票确认取消
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="lockInvoice"
                        v-if="buttonShowList.fpsh && order.invoiceCheck !== '1' && checkIsCt()"
                        style="margin-right: 5px"
                >发票审核
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="unlockInvoice"
                        v-if="buttonShowList.fpqxsh && order.invoiceCheck === '1' && checkIsCt()"
                        style="margin-right: 15px"
                >发票取消审核
                </el-button>
                <el-upload
                        action=""
                        :http-request="importGoods"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        accept=".xls,.xlsx"
                        style="display: inline; margin-right: 15px"
                        :disabled="getIsSaveProBtnDisabled()"
                >
                    <el-button
                            type="primary"
                            size="medium"
                            v-if="buttonShowList.drcp && checkIsCt()"
                            :disabled="getIsSaveProBtnDisabled()"
                    >导入产品
                    </el-button>
                </el-upload>
                <el-button
                        type="primary"
                        size="medium"
                        v-if="checkIsCt()"
                        @click="downloadModel(url.downloadModel, '产品导入模板')"
                >导入模板下载
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="downloadBaoGuan"
                        v-if="checkIsCt() && buttonShowList.xzbgzlqd"
                >
                    下载报关资料清单
                </el-button>

                <!-- <el-button
                      type="primary"
                      size="medium"
                      @click="initGoodlist"
                  >查询
                  </el-button> -->
                <el-dropdown @command="(cmd) => handleCommand(cmd)" v-if="checkIsCt()">
          <span class="el-dropdown-link">
            <el-button
                    type="text"
                    class="f-m-l-10"
                    v-if="buttonShowList.fpxz"
            >发票下载 <i class="el-icon-arrow-down"></i
            ></el-button>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="INVOICE">发票预览</el-dropdown-item>
                        <el-dropdown-item command="INVDOWN">发票下载</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown @command="(cmd) => handleCommand(cmd)" v-if="checkIsCt()">
          <span class="el-dropdown-link">
            <el-button type="text" class="f-m-l-10"
            >买单packing list<i class="el-icon-arrow-down"></i
            ></el-button>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="PACK">预览</el-dropdown-item>
                        <el-dropdown-item command="PACKDOWN">下载</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown @command="(cmd) => handleCommand(cmd)" v-if="checkIsCt()">
          <span class="el-dropdown-link">
            <el-button type="text" class="f-m-l-10" v-if=""
            >装箱单<i class="el-icon-arrow-down"></i
            ></el-button>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="PACKING">预览</el-dropdown-item>
                        <el-dropdown-item command="PACKDOWNING">下载</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown @command="(cmd) => handleCommand(cmd)" v-if="checkIsCt()">
          <span class="el-dropdown-link">
            <el-button
                    type="text"
                    class="f-m-l-10"
                    v-if="getIsSeeHscode() && getIsSeePretraiff()"
            >发票以及装箱单<i class="el-icon-arrow-down"></i
            ></el-button>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="PROLIST">预览</el-dropdown-item>
                        <el-dropdown-item command="PRODOWN">下载</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown @command="(cmd) => handleCommand(cmd)" v-if="checkIsCt()">
          <span class="el-dropdown-link">
            <el-button type="text" class="f-m-l-10"
            >产品制造商<i class="el-icon-arrow-down"></i
            ></el-button>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="MAKER">预览</el-dropdown-item>
                        <el-dropdown-item command="MAKERDOWN">下载</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown @command="(cmd) => handleCommand(cmd)" v-if="checkIsCt()">
          <span class="el-dropdown-link">
            <el-button type="text" class="f-m-l-10"
            >派送地址<i class="el-icon-arrow-down"></i
            ></el-button>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="DEL">预览</el-dropdown-item>
                        <el-dropdown-item command="DELDOWN">下载</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <GoodsDialog
                    :visible.sync="showGoodsVis"
                    :row="selectRow"
                    :order="order"
                    :invoiceCheck="getIsSaveProBtnDisabled()"
                    @ok="closeGoos"
            ></GoodsDialog>
            <el-form class="widthFull" :model="form" ref="goodref" :rules="rules">
                <el-table
                        ref="goodsTable"
                        key="goodsTable"
                        :header-cell-style="{ 'text-align': 'center' }"
                        :data="form.osOrderGoodsList"
                        style="width: 99%"
                        class="customTable c-th-has-bgcolor"
                        show-summary
                        :summary-method="getSummaries2"
                        v-loading="goodsloading"
                        @selection-change="handleSelectionChange2"
                        @sort-change="handleTableChange"
                        :cell-style="{ padding: 2 + 'px' }"
                        :cell-class-name="changeCellStyle"
                >
                    <el-table-column type="selection" width="30"></el-table-column>
                    <el-table-column
                            prop="tag"
                            label="是否备案"
                            minWidth="75"
                            v-if="checkIsCt()"
                    >
                        <template slot-scope="scope">
                            {{ getTag(scope.row.tag) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="action" label="操作" minWidth="80">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    @click="removeGood(scope.$index)"
                                    v-if="buttonShowList.del1"
                            >删除
                            </el-button>
                            <el-button
                                    type="text"
                                    @click="showGoods(scope.row)"
                                    v-if="buttonShowList.bj1 && scope.row.id && checkIsCt()"
                            >编辑
                            </el-button>
                            <el-button
                                    type="text"
                                    @click="record(scope)"
                                    v-if="buttonShowList.ba1 && scope.row.tag !== '1' && checkIsCt()"
                            >备案
                            </el-button>
                            <el-button
                                    type="text"
                                    v-if="buttonShowList.ba1 && scope.row.tag === '1' && checkIsCt()"
                                    @click="qxrecord(scope.row, scope.$index)"
                            >取消备案
                            </el-button>
                            <el-button
                                    type="text"
                                    @click="copyRow(scope.row)"
                                    v-if="buttonShowList.fz1"
                            >复制
                            </el-button>
                        </template>
                    </el-table-column>

                    <el-table-column prop="name" label="品名" minWidth="170">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">品名</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.productId'"
                                    :rules="rules.name"
                                    class="flex_form"
                                    style="width: 100%"
                            >
                                <f-select
                                        :id="'copyProductId' + scope.$index"
                                        :dataList="prolist"
                                        :dict-size="form.osOrderGoodsList.length"
                                        v-model="scope.row.productId"
                                        :is-need="scope.row.productId"
                                        @change="updatePro(scope.$index, scope.row)"
                                ></f-select>

                                <i
                                        class="el-icon-document-copy icon_copy"
                                        @click="copyFuc(scope.$index)"
                                ></i>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="country"
                            label="国家"
                            minWidth="100"
                            v-if="checkIsCt()"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">国家</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.country'"
                                    :rules="rules.country"
                                    style="width: 100%"
                            >
                                <el-select
                                        :loading="coloading"
                                        clearable
                                        filterable
                                        class="f-full-width"
                                        v-model="scope.row.country"
                                        placeholder="获取国家基础数据"
                                        @click.native="initCountryList"
                                        @change="
                    updateGoods33(
                      scope.row.hscode,
                      scope.$index,
                      scope.row.country
                    )
                  "
                                >
                                    <el-option
                                            v-for="(item, index) in countryList"
                                            :key="index"
                                            :label="item.itemText"
                                            :value="item.itemValue"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="material"
                            label="材质"
                            minWidth="90"
                            v-if="checkIsCt()"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">材质</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.material'"
                            >
                                <!--                                                                <el-input-->
                                <!--                                                                        v-model="scope.row.material"-->
                                <!--                                                                        maxlength="50"-->
                                <!--                                                                        show-word-limit-->
                                <!--                                                                        :disabled="isShow || order.invoiceCheck==='1'"-->
                                <!--                                                                ></el-input>-->
                                <span>{{ scope.row.material }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="purpose"
                            label="用途"
                            minWidth="90"
                            v-if="checkIsCt()"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.purpose'"
                            >
                                <!--                                                                <el-input-->
                                <!--                                                                        v-model="scope.row.purpose"-->
                                <!--                                                                        maxlength="50"-->
                                <!--                                                                        show-word-limit-->
                                <!--                                                                        :disabled="isShow || order.invoiceCheck==='1'"-->
                                <!--                                                                ></el-input>-->
                                <span>{{ scope.row.purpose }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="hscode"
                            label="税号"
                            minWidth="125"
                            v-if="getIsSeeHscode() && checkIsCt()"
                            key="hscode"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">税号</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.hscode'"
                                    :rules="
                  scope.row.country === '美国' ? rules.mgHsCode : rules.hscode
                "
                            >
                                <el-input
                                        type="textarea"
                                        v-model="scope.row.hscode"
                                        maxlength="50"
                                        @input="
                    updateGoods33(
                      scope.row.hscode,
                      scope.$index,
                      scope.row.country
                    )
                  "
                                ></el-input>
                            </el-form-item>
                            <!--                          <span>{{scope.row.hscode}}</span>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="addtionalCode"
                            label="301编码"
                            minWidth="85"
                            v-if="getIsSeeHscode() && checkIsCt()"
                            key="addtionalCode"
                    >
                        <!-- 标头需要加必填标识 -->
                        <!--                        <template slot="header">-->
                        <!--                            <template>-->
                        <!--                                <span class="is_require_form">税号</span>-->
                        <!--                            </template>-->
                        <!--                        </template>-->
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.addtionalCode'"
                            >
                                <!--                                <el-input  v-model="scope.row.addtionalCode" maxlength="10" show-word-limit></el-input>-->
                                <f-select
                                        class="textarea_table"
                                        v-model="scope.row.addtionalCode"
                                        :is-need="scope.row.addtionalCode"
                                        :dict="'hs_code_301'"
                                        @keyup.enter.native="handleSearch"
                                ></f-select>
                            </el-form-item>
                            <!--                          <span>{{scope.row.hscode}}</span>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="taxRate"
                            label="税率"
                            minWidth="80"
                            v-if="getIsSeeHscode() && checkIsCt()"
                            key="taxRate"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.taxRate'"
                                    :rules="
                  order.shipmentCountry === '美国' &&
                  scope.row.country === '美国'
                    ? []
                    : rules.taxRate
                "
                            >
                                <el-input v-model="scope.row.taxRate">
                                    <!--                                    <i-->
                                    <!--                                            v-if="scope.row.taxRate && scope.row.taxRate.indexOf('%') === -1"-->
                                    <!--                                            slot="suffix"-->
                                    <!--                                            style="font-style: normal; margin-right: 1px">%</i>-->
                                </el-input>
                                <!--                              <span>{{scope.row.taxRate}}</span>-->
                                <!--                                <i-->
                                <!--                                        v-if="scope.row.taxRate && scope.row.taxRate.indexOf('%') === -1"-->
                                <!--                                        slot="suffix"-->
                                <!--                                        style="font-style: normal; margin-right: 1px">%</i>-->
                            </el-form-item>
                        </template>
                    </el-table-column>


                    <el-table-column
                            prop="unitCost"
                            label="成本单价"
                            minWidth="80"
                            key="unitCost"
                            v-if="checkIsCt()"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.unitCost'"
                                    :rules="rules.un"
                            >
                                <el-input v-model="scope.row.unitCost" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="unitValue"
                            label="申报单价"
                            minWidth="80"
                            v-if="getIsSeeHscode() && getIsSeePretraiff() && checkIsCt()"
                            key="price"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">申报单价</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.unitValue'"
                                    :rules="rules.unitValue"
                            >
                                <el-input
                                        v-model="scope.row.unitValue"
                                        type="number"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="qty"
                            label="数量"
                            minWidth="50"
                            v-if="checkIsCt()"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">数量</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.qty'"
                                    :rules="rules.qty"
                            >
                                <el-input v-model="scope.row.qty" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="totalValue"
                            label="总价"
                            minWidth="80"
                            v-if="getIsSeeHscode() && getIsSeePretraiff() && checkIsCt()"
                            key="totalValue"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.totalValue'"
                            >
                                {{ getAllPrice(scope.row) }}
                                <el-input
                                        v-model="scope.row.totalValue"
                                        type="number"
                                        disabled
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column prop="ctn" label="箱数" minWidth="50">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">箱数</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.ctn'"
                                    :rules="rules.ctn"
                            >
                                <el-input v-model="scope.row.ctn" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="kg" label="毛重" minWidth="70">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">毛重</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.kg'"
                                    :rules="rules.kg"
                            >
                                <el-input v-model="scope.row.kg"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="nkg"
                            label="净重"
                            minWidth="70"
                            v-if="checkIsCt()"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header" v-if="checkCountryIsEuroAndJapan()">
                            <template>
                                <span class="is_require_form">净重</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.nkg'"
                                    :rules="checkCountryIsEuroAndJapan() ? rules.nkg2 : rules.nkg"
                            >
                                <el-input v-model="scope.row.nkg"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cmb" label="体积" minWidth="80">
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">体积</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.cmb'"
                                    :rules="rules.cmb"
                            >
                                <el-input v-model="scope.row.cmb"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="preTariff"
                            label="预算关税"
                            minWidth="80"
                            key="preTariff"
                            v-if="getIsSeeHscode() && getIsSeePretraiff() && checkIsCt()"
                    >
                        <template slot="header">
                            <span>预算关税</span>
                            <el-popover
                                    placement="top"
                                    trigger="hover"
                                    width="400"
                                    content=""
                            >
                                <p>
                                    计算美国预收关税 <br />
                                    税号 （税号匹配基础数据-美国海关编码 匹配的上的才有数据）<br />
                                    1、根据海关编码匹配 基础数据里的海关编码里的关税税率计算公式
                                    按照公式计算 <br />
                                    2、附加税率：.301-15 --> 7.5% 301-01,301-02,301-03,301-04 -->
                                    25% 301-67,301-68 --> 0<br />
                                    3、499 - Merchandise Processing Fee 产品货值 * 0.3464% <br />
                                    4、501 - Harbor Maintenance Fee 产品货值 * 0.1250% <br />
                                    <br />
                                    计算加拿大预收关税 <br />
                                    关税+增值税 <br />
                                    关税=货值*税率 <br />
                                    增值税=（关税+货值）*0.05 <br />
                                    <!--                                    税单加起来的金额-->
                                </p>
                                <i class="el-icon-question" slot="reference"></i>
                            </el-popover>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item>
                                <!--                                <el-input disabled v-model="scope.row.preTariff"></el-input>-->
                                <span v-if="order.hasTaxStr === '0'" style="color: red">{{
                  scope.row.preTariff
                }}</span>
                                <span v-else="scope.row.preTariff">{{
                  scope.row.preTariff
                }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="invoiceTag"
                            label="包税价格"
                            minWidth="80"
                            v-if="checkIsCt()"
                    >
                        <template slot="header">
                            <span>包税价格</span>
                            <el-popover
                                    placement="top"
                                    trigger="hover"
                                    width="400"
                                    content=""
                            >
                                <p>
                                    计算包税价格 <br />
                                    附加费*计费数量/汇率（USD转CNY）<br />
                                    1.如果所有产品的预算关税之和大于计算出来的包税价格 显示红色
                                    <br />
                                    2.如果所有的产品的预算关税之和小于计算出来的包税价格 显示绿色
                                    <br />
                                </p>
                                <i class="el-icon-question" slot="reference"></i>
                            </el-popover>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item>
                                <!--                                <el-input-->
                                <!--                                        :class="getInputClass(scope)"-->
                                <!--                                        disabled-->
                                <!--                                        v-model="scope.row.invoiceTag"-->
                                <!--                                ></el-input>-->
                                <span :class="getInputClass(scope)">{{
                  scope.row.invoiceTag
                }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="sku"
                            label="sku"
                            minWidth="70"
                            v-if="checkIsCt()"
                    >
                        <template slot-scope="scope">
                            <el-form-item :prop="'osOrderGoodsList.' + scope.$index + '.sku'">
                                <el-input
                                        v-model="scope.row.sku"
                                        show-word-limit
                                        maxlength="50"
                                ></el-input>
                            </el-form-item>
                            <!--                            <span>{{ scope.row.sku }}</span>-->
                        </template>
                    </el-table-column>
                    <el-table-column prop="unit" label="包装类型" v-if="!checkIsCt()">
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.unit'"
                            >
                                <f-select
                                        v-model="scope.row.unit"
                                        :is-need="scope.row.unit"
                                        :dict="'packaging_type'"
                                ></f-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" :label="checkIsCt() ? '备注' : '货描'">
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.remark'"
                            >
                                <el-input
                                        v-if="!checkIsCt()"
                                        v-model="scope.row.remark"
                                        maxlength="200"
                                        type="textarea"
                                        show-word-limit
                                ></el-input>
                                <span v-else>{{ scope.row.remark }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mixed" label="混装编号" v-if="checkIsCt()">
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.mixed'"
                                    :rules="rules.mixed"
                            >
                                <f-select
                                        v-model="scope.row.mixed"
                                        :dict="'mixed'"
                                        :is-need="scope.row.mixed"
                                ></f-select>
                            </el-form-item>
                            <!--                          <span>{{scope.row.mixed}}</span>-->
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="invoiceCode"
                            label="发票编码"
                            minWidth="60"
                            v-if="checkIsCt()"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.invoiceCode }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="specialCode"
                            label="特殊编码"
                            minWidth="85"
                            key="specialCode"
                            v-if="checkIsCt()"
                    >
                        <!-- 标头需要加必填标识 -->
                        <!--                        <template slot="header">-->
                        <!--                            <template>-->
                        <!--                                <span class="is_require_form">税号</span>-->
                        <!--                            </template>-->
                        <!--                        </template>-->
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.specialCode'"
                            >
                                <el-input
                                        v-model="scope.row.specialCode"
                                        maxlength="20"
                                        show-word-limit
                                ></el-input>
                            </el-form-item>
                            <!--                          <span>{{scope.row.hscode}}</span>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="specialRate"
                            label="特殊税率"
                            minWidth="80"
                            key="specialRate"
                            v-if="checkIsCt()"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                    :prop="'osOrderGoodsList.' + scope.$index + '.specialRate'"
                                    :rules="rules.baifenshu"
                            >
                                <el-input v-model="scope.row.specialRate"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {
        getAction,
        getallcountries,
        getstateList,
        postAction,
        deleteAction,
        putAction,
    } from "@/api/manage";
    import {
        noZhongwenValidator,
        validateCtn,
        validateQty,
        validateUnitValue,
        validateUnitValue4,
    } from "@/utils/validator";
    import {
        baifenshuPattren,
        sanweixiaoshu,
        shiweishuPattern,
    } from "@/utils/pattern";
    import GoodsDialog from "./GoodsDialog";
    import { caculateSishewuru } from "@/utils/caculate";

    export default {
        name: "ShipmentAndInvoiceModule",
        mixins: [QueryMixins],
        components: { GoodsDialog },
        props: {
            isShow: {
                type: Boolean,
                default: false,
            },
            shipRes: {
                type: Object,
            },
            order: {
                type: Object,
            },
            goodsImportExcelUrl: {
                type: Object,
            },
        },
        data() {
            const validateCmb = (rule, value, callback) => {
                if (value) {
                    if (isNaN(value)) {
                        callback(new Error("必须为数字"));
                    } else {
                        if (value < 0) {
                            callback(new Error("不能为负数"));
                        } else {
                            let str = value.toString().split(".");
                            if (str[0].length > 10) {
                                callback(new Error("最多10位"));
                            } else if (str[1] && str[1].length > 3) {
                                callback(new Error("最多3位小数"));
                            } else if (str[2]) {
                                callback(new Error("小数不对"));
                            } else {
                                callback();
                            }
                        }
                    }
                } else {
                    callback();
                }
            };
            const validateEnglish = (rule, value, callback) => {
                // 值不为空时进行校验
                if (value != "") {
                    //  校验英文的正则
                    if (/[^\u4E00-\u9FA5]+$/.test(value) == false) {
                        callback(new Error("请输入英文"));
                    } else {
                        //校验通过
                        callback();
                    }
                }
            };
            return {
                taxForm: {},
                formLabelWidth: "120px",
                addressModel: {},
                fsee: false,
                showGoodsVis: false,
                selectRow: {},
                shipVis: false,
                totalShipCtn: "",
                totalShipKg: "",
                totalShipCmb: "",
                totalGoodCtn: "",
                totalGoodKg: "",
                totalGoodCmb: "",
                totalGoodPrice: "",
                totalPrePrice: "",
                visible: false,
                row: {},
                form: {
                    osOrderShipmentList: [],
                    osOrderGoodsList: [],
                    shipmentType: this.order.shipmentType,
                },
                url: {
                    list: "/interface/ttOrder/getShipmentList",
                    get301binamByHscode: "/interface/ttOrder/get301binamByHscode",
                    saveShipBatch: "/interface/ttOrder/updateShipmentBatch",
                    goodslist: "/interface/ttOrder/getGoodsList",
                    saveGoodBatch: "/interface/ttOrder/updateGoodsBatch",
                    updateGoods301Bianma: "/interface/ttOrder/updateGoods301Bianma",
                    recordGoods: "/interface/ttOrder/updateGoodsTag1",
                    invoiceOk: "/interface/ttOrder/updateInvoiceStatus1",
                    invoiceqx: "/interface/ttOrder/updateInvoiceStatus0",
                    qxrecordBatch: "/interface/ttOrder/updateGoodsTag0Batch",
                    qxrecord: "/interface/ttOrder/updateGoodsTag0",
                    recordBatch: "/interface/ttOrder/updateGoodsTag1Batch",

                    downloadModel: "/file/read/excel/goodsImportExcel",
                    // importGoods: "order/os/goods/oldImport",
                    //新的导入产品
                    importGoods: "/file/read/excel/importOsOrderGoodsData",
                    deleteShip: "/interface/ttOrder/deleteShipmentById",
                    deleteGood: "/interface/ttOrder/deleteGoodsById",
                    importShip: "/file/read/excel/importOsOrderShpimentData",
                    downloadShipModel: "/file/read/excel/shipmentExcel",
                    lockInvoice: "/interface/ttOrder/updateInvoiceCheck1",
                    unlockInvoice: "/interface/ttOrder/updateInvoiceCheck0",

                    saveTax: "/interface/ttOrder/saveTax",
                    updateAddressInfo: "/interface/ttOrder/updateAddressInfo",
                    downloadQingguanziliao: "/file/jasper/download/qingguan/ziliao",
                },
                userlist: [],
                fbaloading: false,
                storeloading: false,
                warehouseList: [],
                fbalist: [],
                fbalist4: [],
                addresslist1: [],
                addresslist3: [],
                addressloading: false,
                goodsloading: false,
                selectedRowKeys2: [],
                selectionRows2: [],
                //校验
                addressRules: {
                    sysId0: [{ required: true, message: "请选择地址", trigger: "change" }],
                    sysIdw: [{ required: true, message: "请选择地址", trigger: "change" }],
                    sysId4: [{ required: true, message: "请选择地址", trigger: "change" }],
                    contact: [
                        { required: true, message: "请输入联系人", trigger: "change" },
                        {
                            validator: noZhongwenValidator,
                            trigger: "change",
                        },
                    ],
                    tel: [
                        { required: true, message: "请输入电话", trigger: "change" },
                        {
                            validator: noZhongwenValidator,
                            trigger: "change",
                        },
                    ],
                    country: [{ required: true, message: "请选择国家", trigger: "change" }],
                    zipcode: [
                        { required: true, message: "请输入邮编", trigger: "change" },
                        {
                            validator: noZhongwenValidator,
                            trigger: "change",
                        },
                    ],
                    address: [
                        { required: true, message: "请输入地址", trigger: "change" },
                        // {
                        //   validator: noZhongwenValidator,
                        //   trigger: "change",
                        // },
                    ],
                    isYuyue: [
                        { required: true, message: "请选择是否预约", trigger: "change" },
                    ],
                    isDischarge: [
                        { required: true, message: "请选择是否尾板车", trigger: "change" },
                    ],
                    state: [
                        { required: false, message: "请输入英文", trigger: "change" },
                        { validator: noZhongwenValidator, trigger: "change" },
                    ],
                    city: [
                        { required: false, message: "请输入英文", trigger: "change" },
                        {
                            validator: noZhongwenValidator,
                            trigger: "change",
                        },
                    ],
                },
                rules: {
                    //校验百分数
                    baifenshu: [
                        {
                            pattern: baifenshuPattren,
                            message: "请输入百分数",
                            trigger: "change",
                        },
                    ],
                    fjf: [
                        { required: true, message: "请输入附加费", trigger: "change" },
                        {
                            validator: validateCmb,
                            trigger: "change",
                        },
                    ],
                    yf: [
                        { required: true, message: "请输入运费", trigger: "change" },
                        {
                            validator: validateCmb,
                            trigger: "change",
                        },
                    ],
                    shipmentId: [
                        { required: true, message: "请输入shipmentId", trigger: "change" },
                    ],
                    referenceId: [
                        { required: true, message: "请输入referenceId", trigger: "change" },
                    ],
                    mark: [{ required: true, message: "请输入唛头", trigger: "change" }],
                    mark2: [
                        { required: true, message: "请输入入仓编号", trigger: "change" },
                    ],
                    pcs: [{ validator: validateCtn, trigger: "change" }],
                    pcs2: [
                        { required: true, message: "请输入PCS", trigger: "change" },
                        { validator: validateCtn, trigger: "change" },
                    ],
                    ctn: [
                        { required: true, message: "请输入件数", trigger: "change" },
                        { validator: validateCtn, trigger: "change" },
                    ],
                    qty: [
                        { required: true, message: "请填写数量", trigger: "change" },
                        { validator: validateQty, trigger: "change" },
                    ],
                    unitValue: [
                        { required: true, message: "请填写单价", trigger: "change" },
                        { validator: validateUnitValue4, trigger: "change" },
                    ],
                    unitCost: [{ validator: validateUnitValue4, trigger: "change" }],
                    kg: [
                        { required: true, message: "请输入重量", trigger: "change" },
                        { validator: validateCmb, trigger: "change" },
                    ],
                    nkg: [{ validator: validateCmb, trigger: "change" }],
                    nkg2: [
                        { required: true, message: "请输入净重", trigger: "change" },
                        { validator: validateCmb, trigger: "change" },
                    ],
                    cmb: [
                        { required: true, message: "请输入体积", trigger: "change" },
                        { validator: validateCmb, trigger: "change" },
                    ],
                    name: [{ required: true, message: "请选择品名", trigger: "change" }],
                    country: [{ required: true, message: "请选择国家", trigger: "change" }],
                    hscode: [{ required: true, message: "请填写税号", trigger: "change" }],
                    mgHsCode: [
                        { required: true, message: "请填写税号", trigger: "change" },
                        {
                            pattern: shiweishuPattern,
                            message: "美国税号只能十位数字",
                            trigger: "change",
                        },
                    ],
                    mixed: [{ max: 1, message: "最多1位数", trigger: "change" }],
                    // material: [{validator: noZhongwenValidator, trigger: "change"}],
                    // purpose: [{validator: noZhongwenValidator, trigger: "change"}],
                    // taxRate: [
                    //     {
                    //         pattern: sanweixiaoshu,
                    //         message: "税率最多三位小数",
                    //         trigger: "change",
                    //     },
                    // ],
                    addressId: [
                        { required: true, message: "请选择地址", trigger: "change" },
                    ],
                },
                isouri: false,
                cploading: false,
                proMap: {},
                prolist: [],
                countrylist: [],
                coloading: false,
                /*保存导入的数据*/
                importGoodsListAll: [],
                /*存储对应类型对应集合的数据*/
                tableMap: {},
                fbamap0: {},
                fbamap1: {},
                fbamap2: {},
                fbamap3: {},
                fbamap4: {},
                oldAddressData: {},
                //是否可以编辑产品与发票信息
                isEditProduct: false,
                formData: {
                    pageIndex: 1,
                    pageSize: 100,
                },
                statelist: [],
                countryList: [],
                hint: "",
            };
        },
        methods: {
            updateGoods33(val, index, country) {
                if (val && country === "美国") {
                    getAction(this.url.get301binamByHscode, { hscode: val }).then((res) => {
                        let obj = res.result;
                        if (obj) {
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "addtionalCode",
                                obj["addtionalDutyText"]
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "taxRate",
                                obj["dutyRate"]
                            );
                        }
                    });
                }
            },
            updateGoods301() {
                let list = this.form.osOrderGoodsList;
                if (!list || list.length === 0) {
                    this.$message.warning("没有产品数据，无法保存");
                    return false;
                } else {
                    this.$confirm(
                        `确定修改301编码，此操作只会修改已存在的产品，新增的产品请保存之后再点击`,
                        "提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                            closeOnClickModal: false,
                        }
                    ).then(() => {
                        postAction(this.url.updateGoods301Bianma, list).then((res) => {
                            this.$message.success(res.message);
                            this.initGoodlist();
                        });
                    });
                }
            },
            copyRow(row) {
                let obj = JSON.parse(JSON.stringify(row));
                obj.id = "";
                obj.isMingan = "0";
                this.form.osOrderGoodsList.push(obj);
            },
            changeCellStyle(row, column, rowIndex, columnIndex) {
                if (row.row.isMingan === "1") {
                    return "table_red_bg2";
                }
            },
            downloadBaoGuan() {
                let flag = this.getIsSeeHscode() && this.getIsSeePretraiff();
                let canSee = "1";
                if (!flag) {
                    canSee = "0";
                }
                let orderNo = this.$route.query.orderNo;
                this.downloadExcel(
                    this.url.downloadQingguanziliao,
                    orderNo + "-报关资料",
                    { orderNo: orderNo, canSee: canSee }
                );
            },
            //判断国家是否是 日本欧洲
            checkCountryIsEuroAndJapan() {
                let list = ["日本", "英国", "德国", "意大利", "西班牙", "法国"];
                return (
                    this.addressModel.country &&
                    list.indexOf(this.addressModel.country) !== -1
                );
            },
            saveAddressInfo() {
                let orderNo = this.$route.query.orderNo;
                let shipmentType = this.form.shipmentType;
                let obj = { orderNo: orderNo, shipmentType: shipmentType };
                obj["sysId"] = this.addressModel.sysId;
                if ("0" === shipmentType) {
                    obj["addressId"] = this.addressModel.sysId0;
                } else if ("2" === shipmentType) {
                    obj["addressId"] = this.addressModel.sysIdw;
                }else if ("4" === shipmentType) {
                    obj["addressId"] = this.addressModel.sysId4;
                }
                obj["contact"] = this.addressModel.contact;
                obj["tel"] = this.addressModel.tel;
                obj["country"] = this.addressModel.country;
                obj["zipcode"] = this.addressModel.zipcode;
                obj["isDischarge"] = this.addressModel.isDischarge;
                obj["isYuyue"] = this.addressModel.isYuyue;
                obj["address"] = this.addressModel.address;
                obj["address2"] = this.addressModel.address2;
                obj["yuyueRemark"] = this.addressModel.yuyueRemark;
                obj["state"] = this.addressModel.state;
                obj["city"] = this.addressModel.city;
                obj["company"] = this.addressModel.company;
                //校验地址
                this.$refs["addressModel"].validate((valid) => {
                    if (valid) {
                        putAction(this.url.updateAddressInfo, obj).then((res) => {
                            this.$message.success(res.message);
                            // this.initShiplist();
                            this.$emit("init");
                        });
                    }
                });
            },
            initStateList(val) {
                this.statelist = [];
                this.citylist = [];
                if (val) {
                    getstateList({ cnName: val }).then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.records.length; i++) {
                                let obj = {};
                                obj.value = res.result.records[i].cname;
                                obj.label =
                                    res.result.records[i].code + "-" + res.result.records[i].cname;
                                this.statelist.push(obj);
                            }
                        }
                    });
                } else {
                    // this.form.city = '';
                    // this.form.state = '';
                    // this.form.bdState = '';
                    // this.form.bdCity = '';
                }
            },
            // 所有包税单的销售同事，包含美国包税单，加拿大包税单，不让这些同事看到包税发票的，产品单价，总价，海关编码和税率，预算关税这五项；
            getIsSeeHscode() {
                if (this.order.hasTaxStr === "0") {
                    // //console.log('不包税，可以看到');
                    return true;
                }
                // if (this.order.shipmentCountry !=='美国' && this.order.shipmentCountry !=='加拿大') {
                //   // //console.log(this.order.shipmentCountry,'不是美国和加拿大，可以看到');
                //   return true;
                // }
                let flag = this.buttonShowList.kbshg;
                ////console.log('是否可以查看美国加拿大包税单的海关编码',flag);
                if (flag) {
                    ////console.log('所有包税单的销售同事，包含美国包税单，加拿大包税单，不让这些同事看到包税发票的，产品单价，总价，海关编码和税率，预算关税这五项',true);
                    return true;
                } else {
                    ////console.log('所有包税单的销售同事，包含美国包税单，加拿大包税单，不让这些同事看到包税发票的，产品单价，总价，海关编码和税率，预算关税这五项',false);
                    return false;
                }
            },
            //所有客服同事，不能看到包税发票的产品单价，总价，预算关税这三项；
            getIsSeePretraiff() {
                if (this.order.hasTaxStr === "0") {
                    ////console.log('不包税，可以看到');
                    return true;
                }

                let flag = this.buttonShowList.kysgs;
                // ////console.log('是否可以查看应付页面',flag);
                if (flag) {
                    ////console.log('所有客服同事，不能看到包税发票的产品单价，总价，预算关税这三项',true);
                    return true;
                } else {
                    ////console.log('所有客服同事，不能看到包税发票的产品单价，总价，预算关税这三项',false);
                    return false;
                }
            },
            //是否可以修改产品
            getIsSaveProBtnDisabled() {
                if (this.order.shipmentClearedStr) {
                    return false;
                } else {
                    // return true;
                    return this.getIsSaveBtnDisabled();
                }
            },
            //是否可以修改shipment
            getIsSaveBtnDisabled() {
                //在判断 是否发票确认
                let flag = this.buttonShowList.yzgxgxx;
                if (flag) {
                    return false;
                }
                let flag2 = this.buttonShowList.xgcpqx;
                if (flag2) {
                    return false;
                }
                //解锁了 就允许修改
                if (this.order.canEditInfo === "1") {
                    return false;
                }
                let orderStatus = this.order.orderStatus;
                //合规审核
                if (Number(orderStatus) >= 8 || this.order.containerLoadStr) {
                    //已装柜订单 ，判断canEditInfo
                    if (this.order.canEditInfo === "1") {
                        return false;
                    }
                    return true;
                }
                if (this.order.invoiceStatus === "1" || this.order.invoiceCheck === "1") {
                    return true;
                }

                return false;
            },
            saveTax() {
                this.$refs["taxForm"].validate((valid) => {
                    if (valid) {
                        this.taxForm.orderNo = this.$route.query.orderNo;
                        postAction(this.url.saveTax, this.taxForm).then((res) => {
                            this.$message.success(res.message);
                            this.$emit("init");
                        });
                    }
                });
            },
            caculateGoodsAll() {
                let list = JSON.parse(JSON.stringify(this.form.osOrderGoodsList));
                //存储 已经计算过的 混装编码
                let mixList = [];
                this.totalGoodCtn = 0;
                this.totalGoodKg = 0;
                this.totalGoodCmb = 0;
                this.totalGoodPrice = 0;
                if (list && list.length > 0) {
                    for (var i = 0; i < list.length; i++) {
                        let goods = list[i];
                        let ctn = goods.ctn;
                        let cmb = goods.cmb;
                        let kg = goods.kg;
                        let totalValue = goods.totalValue;
                        let flag = true;
                        // console.log('混装编码集合', mixList);
                        if (!goods.mixed) {
                            flag = true;
                        } else {
                            if (mixList.indexOf(goods.mixed) !== -1) {
                                flag = false;
                            } else {
                                flag = true;
                                mixList.push(goods.mixed);
                            }
                        }
                        if (ctn) {
                            if (flag) {
                                this.totalGoodCtn += Number(ctn);
                            }
                        }
                        if (cmb) {
                            if (flag) {
                                this.totalGoodCmb += Number(cmb);
                            }
                        }
                        if (kg) {
                            if (flag) {
                                this.totalGoodKg += Number(kg);
                            }
                        }
                        if (totalValue) {
                            if (flag) {
                                this.totalGoodPrice += Number(totalValue);
                            }
                        }
                    }
                }
                this.totalGoodCtn = this.totalGoodCtn.toFixed(0);
                this.totalGoodKg = this.totalGoodKg.toFixed(3);
                this.totalGoodCmb = this.totalGoodCmb.toFixed(3);
                this.totalGoodPrice = this.totalGoodPrice.toFixed(3);
                this.totalPrePrice = this.caculateCmbtv(
                    this.totalGoodCmb,
                    this.totalGoodPrice
                );
            },
            saveShipBatch2() {
                this.$refs["shipref"].validate(async (valid) => {
                    if (valid) {
                        // if (this.totalShipCtn != this.totalGoodCtn) {
                        //     this.$message.warning("派送总件数与产品总件数不一致");
                        //     return false;
                        // }
                        // if (this.totalShipKg != this.totalGoodKg) {
                        //     this.$message.warning("派送总重量与产品总重量不一致");
                        //     return false;
                        // }
                        // if (this.totalShipCmb != this.totalGoodCmb) {
                        //     this.$message.warning("派送总体积与产品总体积不一致");
                        //     return false;
                        // }

                        this.form.osOrderShipmentList.forEach((sp) => {
                            sp.shipmentType = this.form.shipmentType;
                            sp.orderNo = this.$route.query.orderNo;
                        });

                        await putAction(
                            this.url.saveShipBatch + "?orderNo=" + this.$route.query.orderNo,
                            this.form.osOrderShipmentList
                        ).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.initShiplist();
                            }
                            this.$emit("init");
                        });
                    }
                });
            },
            lockInvoice() {
                let orderNo = this.$route.query.orderNo;
                this.$confirm(
                    `确定发票审核${orderNo},该操作将会使得订单产品不可在修改`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        closeOnClickModal: false,
                    }
                )
                    .then(() => {
                        postAction(this.url.lockInvoice + "?orderNo=" + orderNo).then(
                            (res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                } else {
                                    this.$message.warning(res.message);
                                }
                                // this.initGoodlist();
                                this.$emit("init");
                            }
                        );
                    })
                    .catch(() => {});
            },
            unlockInvoice() {
                let orderNo = this.$route.query.orderNo;
                this.$confirm(
                    `确定取消发票审核${orderNo},该操作将会使得订单产品可以被修改`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        closeOnClickModal: false,
                    }
                )
                    .then(() => {
                        postAction(
                            this.url.unlockInvoice + "?orderNo=" + this.$route.query.orderNo
                        ).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                            // this.initGoodlist();
                            this.$emit("init");
                        });
                    })
                    .catch(() => {});
            },

            //更新地址信息
            formatAddressByModel(obj) {
                //fba地址
                let ss = "";
                if (this.form.shipmentType === "0") {
                    ss += "Amazon.com,";
                    if (obj) {
                        if (obj["address"]) {
                            ss += obj["address"] + ",";
                        }
                        if (obj["address2"]) {
                            ss += obj["address2"] + ",";
                        }
                        if (obj["city"]) {
                            ss += obj["city"] + ",";
                        }
                        if (obj["state"]) {
                            ss += obj["state"] + ",";
                        }
                        ////console.log('没有邮编',obj['zip'])
                        if (obj["zip"]) {
                            ss += obj["zip"] + ",";
                        }
                        if (obj["twoCode"]) {
                            ss += obj["twoCode"] + ",";
                        }
                        if (obj["fbaCode"]) {
                            ss += "(" + obj["fbaCode"] + ")";
                        }
                    }
                } else if (this.form.shipmentType === "2") {
                    //海外仓地址
                    if (obj["company"]) {
                        ss += obj["company"] + ",";
                    }
                    if (obj["address"]) {
                        ss += obj["address"] + ",";
                    }
                    if (obj["zipcode"]) {
                        ss += obj["zipcode"] + ",";
                    }
                    if (obj["twoCode"]) {
                        ss += obj["twoCode"];
                    }
                    ss += "\nContact Person:";
                    if (obj["contact"]) {
                        ss += obj["contact"] + ",";
                    }
                    ss += "\nTel:";
                    if (obj["tel"]) {
                        ss += obj["tel"] + ",";
                    }
                } else if (this.form.shipmentType !== '4'){
                    //私人地址
                    ss += "Contact Person:";
                    if (obj["contact"]) {
                        ss += obj["contact"];
                    }
                    ss += "\nTel:";
                    if (obj["tel"]) {
                        ss += obj["tel"];
                    }
                    if (obj["company"]) {
                        ss += obj["company"] + ",";
                    }
                    if (obj["address"]) {
                        ss += obj["address"] + ",";
                    }
                    if (obj["address2"]) {
                        ss += obj["address2"] + ",";
                    }
                    if (obj["city"]) {
                        ss += obj["city"] + ",";
                    }
                    if (obj["state"]) {
                        ss += obj["state"];
                    }
                    if (obj["zipcode"]) {
                        ss += obj["zipcode"];
                    }
                    if (obj["twoCode"]) {
                        ss += obj["twoCode"];
                    }
                    ss += "\nLIFT GATE DELIVERY:";
                    if ("1" === obj["isDischarge"]) {
                        ss += "No";
                    } else {
                        ss += "Yes";
                    }
                    ss += "\nDELIVERY APPOINMENT:";
                    if ("1" === obj["isYuyue"]) {
                        ss += "Yes";
                    } else {
                        ss += "No";
                    }
                    if (obj["yuyueRemark"]) {
                        ss += "," + obj["yuyueRemark"];
                    }
                }else {
                    if (obj['address']){
                        ss += obj['address'] + '';
                    }
                    if (obj['city']) {
                        ss += obj['city'] + '';
                    }
                    if (obj['state']) {
                        ss += obj['state'] + '';
                    }
                    if (obj['twoCode']) {
                        ss += obj['twoCode'] + '';
                    }
                }
                return ss;
            },
            getFbaAddressDetail2(val, index) {
                if (this.fbamap0[val]) {
                    let obj = this.fbamap0[val];
                    let add = this.formatAddressByModel(obj);
                    this.$set(this.form.osOrderShipmentList[index], "addressDetail", add);
                    this.$set(this.form.osOrderShipmentList[index], "sysId", obj.id);
                    this.$set(this.form.osOrderShipmentList[index], "addressId", obj.id);
                }
            },
            getFbaAddressDetail44(val, index) {
                if (this.fbamap4[val]) {
                    let obj = this.fbamap4[val];
                    let add = this.formatAddressByModel(obj);
                    this.$set(this.form.osOrderShipmentList[index], "addressDetail", add);
                    this.$set(this.form.osOrderShipmentList[index], "sysId", obj.id);
                    this.$set(this.form.osOrderShipmentList[index], "addressId", obj.id);
                }
            },
            getFbaAddressDetail4(val) {
                if (this.fbamap4[val]) {
                    let obj = this.fbamap4[val];
                    ////console.log('选中的fba地址',obj)

                    this.$set(this.addressModel, "sysId", obj.id);
                    this.$set(this.addressModel, "addressId", obj.id);
                    this.$set(this.addressModel, "fbaCode", obj.fbaCode);
                    this.$set(this.addressModel, "zipcode", obj.zip);
                    this.$set(this.addressModel, "twoCode", obj.twoCode);
                    this.$set(this.addressModel, "address", obj.address);
                    this.$set(this.addressModel, "country", obj.country);
                    this.$set(this.addressModel, "state", obj.state);
                    this.$set(
                        this.addressModel,
                        "addressDetail4",
                        this.formatAddressByModel(obj)
                    );
                    this.$set(
                        this.addressModel,
                        "addressDetail",
                        this.formatAddressByModel(obj)
                    );
                }
            },
            getWareAddressDetail2(val, index) {
                if (this.fbamap2[val]) {
                    let obj = this.fbamap2[val];
                    let add = this.formatAddressByModel(obj);
                    this.$set(this.form.osOrderShipmentList[index], "addressDetail", add);
                    this.$set(this.form.osOrderShipmentList[index], "sysId", obj.id);
                    this.$set(this.form.osOrderShipmentList[index], "addressId", obj.id);
                }
            },
            getPerAddressDetail2(val, index) {
                if (this.form.shipmentType === "3") {
                    if (this.fbamap3[val]) {
                        let obj = this.fbamap3[val];
                        let add = this.formatAddressByModel(obj);
                        this.$set(
                            this.form.osOrderShipmentList[index],
                            "addressDetail1",
                            add
                        );
                        this.$set(this.form.osOrderShipmentList[index], "addressDetail", add);
                        this.$set(this.form.osOrderShipmentList[index], "sysId", obj.id);
                        this.$set(this.form.osOrderShipmentList[index], "addressId", obj.id);
                    }
                } else {
                    if (this.fbamap1[val]) {
                        console.log(
                            "变更的地址",
                            val,
                            index,
                            this.fbamap1[val],
                            this.fbamap3[val]
                        );
                        let obj = this.fbamap1[val];
                        let add = this.formatAddressByModel(obj);
                        this.$set(
                            this.form.osOrderShipmentList[index],
                            "addressDetail1",
                            add
                        );
                        this.$set(this.form.osOrderShipmentList[index], "addressDetail", add);
                        this.$set(this.form.osOrderShipmentList[index], "sysId", obj.id);
                        this.$set(this.form.osOrderShipmentList[index], "addressId", obj.id);
                    }
                }
            },
            getFbaAddressDetail(val) {
                if (this.fbamap0[val]) {
                    let obj = this.fbamap0[val];
                    ////console.log('选中的fba地址',obj)

                    this.$set(this.addressModel, "sysId", obj.id);
                    this.$set(this.addressModel, "addressId", obj.id);
                    this.$set(this.addressModel, "fbaCode", obj.fbaCode);
                    this.$set(this.addressModel, "zipcode", obj.zip);
                    this.$set(this.addressModel, "twoCode", obj.twoCode);
                    this.$set(this.addressModel, "address", obj.address);
                    this.$set(this.addressModel, "country", obj.country);
                    this.$set(this.addressModel, "state", obj.state);
                    this.$set(
                        this.addressModel,
                        "addressDetail0",
                        this.formatAddressByModel(obj)
                    );
                    this.$set(
                        this.addressModel,
                        "addressDetail",
                        this.formatAddressByModel(obj)
                    );
                }
            },
            getWareAddressDetail(val) {
                if (this.fbamap2[val]) {
                    let obj = this.fbamap2[val];
                    this.$set(this.addressModel, "sysId", obj.id);
                    this.$set(this.addressModel, "addressId", obj.id);
                    this.$set(this.addressModel, "company", obj.company);
                    this.$set(this.addressModel, "zipcode", obj.zipcode);
                    this.$set(this.addressModel, "twoCode", obj.twoCode);
                    this.$set(this.addressModel, "address", obj.address);
                    this.$set(this.addressModel, "country", obj.country);
                    this.$set(this.addressModel, "contact", obj.contact);
                    this.$set(
                        this.addressModel,
                        "addressDetail2",
                        this.formatAddressByModel(obj)
                    );
                    this.$set(
                        this.addressModel,
                        "addressDetail",
                        this.formatAddressByModel(obj)
                    );
                }
            },
            getPerAddressDetail(val, index) {
                if (this.form.shipmentType === "3") {
                    if (this.fbamap3[val]) {
                        let obj = this.fbamap3[val];
                        let add = obj.company + "," + obj.zipcode + "," + obj.address;
                        this.$set(this.form.addressModel, "addressDetail1", add);
                        this.$set(this.form.addressModel, "sysId", obj.id);
                        this.$set(this.form.addressModel, "addressId", obj.id);
                    }
                } else {
                    if (this.fbamap1[val]) {
                        let obj = this.fbamap1[val];
                        let add = obj.company + "," + obj.zipcode + "," + obj.address;
                        this.$set(this.form.addressModel, "addressDetail1", add);
                        this.$set(this.form.addressModel, "sysId", obj.id);
                        this.$set(this.form.addressModel, "addressId", obj.id);
                    }
                }
            },
            getInputClass(scope) {
                if (Number(scope.row.totalPreTariff) < Number(scope.row.invoiceTag)) {
                    return "inputGreen;";
                }
                if (Number(scope.row.totalPreTariff) > Number(scope.row.invoiceTag)) {
                    return "inputRed;";
                }
            },

            handleCommand(cmd) {
                let flag = this.getIsSeeHscode() && this.getIsSeePretraiff();
                let canSee = "1";
                if (!flag) {
                    canSee = "0";
                }
                let type = "1";
                if (cmd.indexOf("GBP") !== -1) {
                    type = "2";
                }
                if (cmd.indexOf("UK") !== -1) {
                    type = "3";
                }
                let params = {
                    orderNo: this.$route.query.orderNo,
                    type: type,
                    canSee: canSee,
                };
                if (cmd === "INVOICE") {
                    this.previewHtml("/file/jasper/downloadInvoice", {
                        orderNo: params.orderNo,
                        t: "html",
                        type: type,
                        canSee: canSee,
                    });
                } else if (cmd === "INVDOWN") {
                    this.downloadExcel("/file/jasper/downloadInvoice", "发票下载", params);
                } else if (cmd === "GBP") {
                    this.previewHtml("/file/jasper/downloadInvoice", {
                        orderNo: params.orderNo,
                        t: "html",
                        type: type,
                        canSee: canSee,
                    });
                } else if (cmd === "GBPDOWN") {
                    this.downloadExcel("/file/jasper/downloadInvoice", "发票下载", {
                        orderNo: params.orderNo,
                        t: "html",
                        type: type,
                        canSee: canSee,
                    });
                } else if (cmd === "UK") {
                    this.previewHtml("/file/jasper/downloadInvoice", {
                        orderNo: params.orderNo,
                        t: "html",
                        type: type,
                        canSee: canSee,
                    });
                } else if (cmd === "UKDOWN") {
                    this.downloadExcel("/file/jasper/downloadInvoice", "发票下载", {
                        orderNo: params.orderNo,
                        t: "html",
                        type: type,
                        canSee: canSee,
                    });
                } else if (cmd === "PACKING") {
                    this.previewHtml("/file/jasper/downloadPckinglist", {
                        orderNo: params.orderNo,
                        t: "html",
                        canSee: canSee,
                    });
                } else if (cmd === "PACKDOWNING") {
                    this.downloadExcel("/file/jasper/downloadPckinglist", "装箱单", {
                        orderNo: params.orderNo,
                        canSee: canSee,
                    });
                } else if (cmd === "PACK") {
                    this.previewHtml("/file/jasper/downloadPckinglist2", {
                        orderNo: params.orderNo,
                        t: "html",
                        canSee: canSee,
                    });
                } else if (cmd === "PACKDOWN") {
                    this.downloadExcel("/file/jasper/downloadPckinglist2", "packinglist", {
                        orderNo: params.orderNo,
                        canSee: canSee,
                    });
                } else if (cmd === "MAKER") {
                    this.previewHtml("/file/jasper/downloadGoodsmaker", {
                        orderNo: params.orderNo,
                        t: "html",
                        canSee: canSee,
                    });
                } else if (cmd === "MAKERDOWN") {
                    this.downloadExcel("/file/jasper/downloadGoodsmaker", "产品制造商", {
                        orderNo: params.orderNo,
                        canSee: canSee,
                    });
                } else if (cmd === "DEL") {
                    this.previewHtml("/file/jasper/downloadDelivery", {
                        orderNo: params.orderNo,
                        t: "html",
                        canSee: canSee,
                    });
                } else if (cmd === "DELDOWN") {
                    this.downloadExcel("/file/jasper/downloadDelivery", "派送地址", {
                        orderNo: params.orderNo,
                        canSee: canSee,
                    });
                } else if (cmd === "PROLIST") {
                    this.previewHtml("/file/jasper/downloadProList", {
                        orderNo: params.orderNo,
                        t: "html",
                        canSee: canSee,
                    });
                } else if (cmd === "PRODOWN") {
                    this.downloadExcel("/file/jasper/downloadProList", "装箱单", {
                        orderNo: params.orderNo,
                        canSee: canSee,
                    });
                }
            },
            showGoods(row) {
                this.selectRow = JSON.parse(JSON.stringify(row));
                this.showGoodsVis = true;
            },
            closeGoos() {
                this.showGoodsVis = false;
                this.initGoodlist();
            },
            handleClearSelection() {
                this.$refs["shiptab"].clearSelection();
            },
            handleClearSelection2() {
                this.$refs["goodtab"].clearSelection();
            },
            async importShips(data) {
                this.loading = true;
                this.fsee = false;
                ////console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                let type = this.form.shipmentType;
                await postAction(
                    this.url.importShip +
                    "?cusId=" +
                    this.order.cusId +
                    "&orderNo=" +
                    this.$route.query.orderNo +
                    "&shipmentType=" +
                    type +
                    "&shipmentAddress=" +
                    this.order.address +
                    "&addressId=" +
                    this.order.addressId,
                    formData
                )
                    .then((res) => {
                        ////console.log("产品返回数据", res);
                        if (res.success) {
                            this.$message.success(res.message);
                            //遍历替换%
                            // this.form.shipmentType = type;
                            this.initShiplist();
                            // this.initGoodlist();
                            // this.#$emit("ok");
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.initShiplist();
                    });
            },
            //导入产品数据
            async importGoods(data) {
                this.goodsloading = true;
                this.fsee = false;
                ////console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                await postAction(
                    this.url.importGoods +
                    "?cusId=" +
                    this.order.cusId +
                    "&orderNo=" +
                    this.$route.query.orderNo +
                    "&country=" +
                    this.order.shipmentCountry,
                    formData
                )
                    .then((res) => {
                        ////console.log("产品返回数据", res);
                        if (res.success) {
                            this.goodsloading = false;
                            this.$message.success(res.message);
                            //遍历替换%
                            // this.initGoodlist();
                            this.$emit("init");
                            // this.initGoodlist();
                        }
                    })
                    .catch((err) => {
                        this.goodsloading = false;
                    });
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            invoiceOkBatch() {
                let orderNo = this.$route.query.orderNo;
                this.$confirm(
                    `确定发票确认${orderNo},确认之后订单的包税信息不能再修改`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        closeOnClickModal: false,
                    }
                )
                    .then(() => {
                        if (this.totalShipCtn != this.totalGoodCtn) {
                            this.$message.warning("派送总件数与产品总件数不一致");
                            return false;
                        }
                        if (this.totalShipKg != this.totalGoodKg) {
                            this.$message.warning("派送总重量与产品总重量不一致");
                            return false;
                        }
                        if (this.totalShipCmb != this.totalGoodCmb) {
                            this.$message.warning("派送总体积与产品总体积不一致");
                            return false;
                        }
                        // 保存派送信息
                        let flag = true;
                        // this.$refs["shipref"].validate((valid)=>{
                        //   flag = valid;
                        // })
                        // this.$refs["goodref"].validate((valid)=>{
                        //   flag = valid;
                        // })
                        if (flag) {
                            putAction(
                                this.url.invoiceOk + "?orderNo=" + this.$route.query.orderNo,
                                {
                                    orderNo: this.$route.query.orderNo,
                                    osOrderGoodsList: this.form.osOrderGoodsList,
                                    osOrderShipmentList: this.form.osOrderShipmentList,
                                }
                            ).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    // this.initGoodlist();
                                    // this.initShiplist();
                                    this.$emit("init");
                                }
                            });
                        }
                    })
                    .catch(() => {});
            },
            recordBatch() {
                this.importGoodsListAll = [];
                this.$refs["goodref"].validate((valid) => {
                    if (valid) {
                        this.form.osOrderGoodsList.map((g) => {
                            g.orderNo = this.$route.query.orderNo;
                        });
                        //判断件重体是否一致
                        putAction(
                            this.url.recordBatch + "?cusId=" + this.order.cusId,
                            this.form.osOrderGoodsList
                        ).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.initGoodlist();
                            }
                        });
                    }
                });
            },
            invoiceqxBatch() {
                let orderNo = this.$route.query.orderNo;
                if (this.order.invoiceCheck === "1") {
                    this.$message.warning("发票已审核的订单需解除审核才能取消确认");
                    return false;
                }
                this.$confirm(
                    `确定取消发票确认${orderNo},确认之后订单的包税信息可以修改`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        closeOnClickModal: false,
                    }
                )
                    .then(() => {
                        putAction(
                            this.url.invoiceqx + "?orderNo=" + this.$route.query.orderNo
                        ).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                // this.initGoodlist();
                                this.$emit("init");
                            }
                        });
                    })
                    .catch(() => {});
            },
            //判断是否可以编辑产品信息
            getIsDisable() {
                let orderStatus = this.order.orderStatus;
                if (orderStatus && Number(orderStatus) >= 18) {
                    let flag = this.buttonShowList.yzgxgxx;
                    if (flag) {
                        return false;
                    }
                    let flag2 = this.buttonShowList.xgcpqx;
                    if (flag2) {
                        return false;
                    }
                    return true;
                } else if (
                    this.order.invoiceCheck === "1" ||
                    this.order.invoiceStatus === "1"
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            getIsDisableShip() {
                let orderStatus = this.order.orderStatus;
                if (orderStatus && Number(orderStatus) >= 18) {
                    let flag = this.buttonShowList.yzgxgxx;
                    if (flag) {
                        return false;
                    }
                    let flag2 = this.buttonShowList.xgcpqx;
                    if (flag2) {
                        return false;
                    }
                    return true;
                } else if (this.order.invoiceStatus === "1") {
                    return true;
                } else {
                    return false;
                }
            },
            getTag(item) {
                return item == "1" ? "已备案" : "未备案";
            },
            removeGood(index) {
                let id = this.form.osOrderGoodsList[index].id;
                if (!id) {
                    this.form.osOrderGoodsList.splice(index, 1);
                    return true;
                }
                this.$confirm(`确定删除`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        if (id && id.length > 1) {
                            //判断是否有权限删除
                            let flag = this.buttonShowList.del1;
                            if (!flag) {
                                this.$message.warning("您暂时没有权限删除派送地址哦");
                                return false;
                            }
                            let invoiceStatus = this.order.invoiceStatus;
                            if (invoiceStatus === "1") {
                                this.$message.warning("发票已确认的订单不允许删除产品信息");
                                return false;
                            }
                            let invoiceCheck = this.order.invoiceCheck;
                            if (invoiceCheck === "1") {
                                this.$message.warning("发票已审核的订单不允许删除产品信息");
                                return false;
                            }
                            deleteAction(this.url.deleteGood, { id: id })
                                .then((res) => {
                                    this.$message.success(res.message);
                                    // this.initGoodlist();
                                    this.$emit("init");
                                })
                                .catch((err) => {
                                    this.$message.error(res.message);
                                });
                        } else {
                            this.form.osOrderGoodsList.splice(index, 1);
                        }
                    })
                    .catch(() => {});
            },
            addGood() {
                ////console.log('产品列表数据',this.form.osOrderGoodsList)
                this.form.osOrderGoodsList.push({
                    name: "",
                    country: "",
                    hscode: "",
                    taxRate: "",
                    qty: "",
                    unitValue: "",
                    unitCost: "",
                    ctn: "",
                    kg: "",
                    cmb: "",
                    material: "",
                    purpose: "",
                    mixed: "",
                    remark: "",
                    orderNo: this.$route.query.orderNo,
                    unit: "",
                });
            },
            getAllPrice(item) {
                if (!isNaN(parseFloat(item.qty)) && !isNaN(parseFloat(item.unitValue))) {
                    // item.totalValue = (
                    //     parseFloat(item.qty).toFixed(0) * parseFloat(item.unitValue)
                    // ).toFixed(3);
                    if (item.country === "美国") {
                        item.totalValue = caculateSishewuru(item.qty, item.unitValue).toFixed(
                            0
                        );
                    } else {
                        item.totalValue = caculateSishewuru(item.qty, item.unitValue);
                    }
                } else {
                    item.totalValue = 0;
                }
            },
            initCountryList() {
                this.coloading = true;
                if (this.countrylist.length <= 0) {
                    this.countrylist = [];
                    getallcountries().then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                            this.coloading = false;
                        }
                    });
                } else {
                    this.coloading = false;
                }
            },
            parseProduct(list) {
                this.prolist = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label = list[i].cname + "/ " + list[i].ename;
                    this.prolist.push({ value, label });
                    list[i].name = value;
                }
                this.proMap = this.listToMap(list, "id");
                ////console.log('获取的产品数据',this.prolist,this.proMap)
            },
            loadmore() {
                this.formData.pageIndex++;
                this.getMorePortlist(this.formData);
            },
            getMorePortlist(v) {
                let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
                this.options = this.allOptions.filter((item, index, arr) => {
                    return index < num;
                });
            },

            updatePro(index, row) {
                let obj = this.proMap[row.productId];
                ////console.log("被选中的对象", obj);
                ////console.log("被选中的下标", index);
                if (this.form.osOrderGoodsList[index].changeFlag) {
                    if (obj) {
                        this.$set(
                            this.form.osOrderGoodsList[index],
                            "name",
                            obj["cname"] || ""
                        );
                        this.$set(
                            this.form.osOrderGoodsList[index],
                            "ename",
                            obj["ename"] || ""
                        );
                        if (this.checkIsCt()) {
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "country",
                                obj["country"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "hscode",
                                obj["hscode"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "taxRate",
                                obj["taxRate"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "tag",
                                obj["tag"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "material",
                                obj["material"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "purpose",
                                obj["purpose"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "maker",
                                obj["maker"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "makerAdd",
                                obj["makerAdd"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "model",
                                obj["model"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "size",
                                obj["size"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "brandType",
                                obj["brandType"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "exportBenefits",
                                obj["exportBenefits"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "brand",
                                obj["brand"] || ""
                            );
                        } else {
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "unit",
                                obj["unit"] || ""
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "remark",
                                obj["remark"] || ""
                            );
                        }
                    }
                    ////console.log("被选中的下标", this.form.osOrderGoodsList);
                } else {
                    this.form.osOrderGoodsList[index].changeFlag = true;
                }
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                ////console.log("选中的集合", this.selectionRows);
            },
            handleSelectionChange2(selection) {
                // 多选
                let arr = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows2 = selection;
                this.selectedRowKeys2 = [...arr];
                ////console.log("选中的集合", this.selectionRows2);
            },
            getSummaries(param) {
                const { columns, data } = param;
                // //console.log('表格数据',columns);
                // //console.log('表格数据',data);
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = "总计";
                        return;
                    }
                    const values = data.map((item) => Number(item[column.property]));
                    let map = {};

                    if (!values.every((value) => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            // //console.log('合计',value);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (column.property === "ctn") {
                            this.totalShipCtn = sums[index].toFixed(0);
                            sums[index] = "总箱数：" + sums[index].toFixed(0);
                        } else if (column.property === "kg") {
                            this.totalShipKg = sums[index].toFixed(3);
                            sums[index] = "总毛重：" + sums[index].toFixed(3);
                        } else if (column.property === "cmb") {
                            this.totalShipCmb = sums[index].toFixed(3);
                            sums[index] = "总体积：" + sums[index].toFixed(3);
                        } else if (column.property === "totalValue") {
                            sums[index] = "总额：" + sums[index].toFixed(3);
                        } else if (column.property === "qty") {
                            sums[index] = "总数：" + sums[index].toFixed(0);
                        } else {
                            sums[index] = "";
                        }
                    } else {
                        sums[index] = "";
                    }
                });
                const table = document.querySelector(".suTable");
                const footer = document.querySelector(
                    ".suTable .el-table__footer-wrapper"
                );
                if (table && footer) {
                    table.removeChild(footer); // 移除表格最下方的合计行
                }

                return sums;
            },
            getSummaries2(param) {
                const { columns, data } = param;
                ////console.log('产品params',param);
                ////console.log('表格数据',columns);
                ////console.log('表格数据',data);
                const sums = [];
                let mixlist = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = "总计";
                        return;
                    }
                    if (data) {
                        const values = data.map((item) => Number(item[column.property]));
                        let map = {};
                        let flag = true;
                        // console.log('混装编码集合', mixlist)
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                // //console.log('合计',value);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);

                            if (column.property == "ctn") {
                                this.totalGoodCtn = sums[index].toFixed(0);
                                sums[index] = "总箱数：" + sums[index].toFixed(0);
                            } else if (column.property == "kg") {
                                this.totalGoodKg = sums[index].toFixed(3);
                                sums[index] = "总毛重：" + sums[index].toFixed(3);
                            } else if (column.property == "cmb") {
                                this.totalGoodCmb = sums[index].toFixed(3);
                                sums[index] = "总体积：" + sums[index].toFixed(3);
                            } else if (column.property == "totalValue") {
                                this.totalGoodPrice = sums[index].toFixed(3);
                                sums[index] = "总额：" + sums[index].toFixed(3);
                            } else if (column.property == "qty") {
                                sums[index] = "总数：" + sums[index].toFixed(0);
                            } else {
                                sums[index] = "";
                            }
                        } else {
                            sums[index] = "";
                        }
                    }
                });
                //移除表格合计行
                const table = document.querySelector(".customTable");
                const footer = document.querySelector(
                    ".customTable .el-table__footer-wrapper"
                );
                if (table && footer) {
                    table.removeChild(footer); // 移除表格最下方的合计行
                }
                return sums;
            },
            saveGoodBatch() {
                this.$refs["goodref"].validate(async (valid) => {
                    if (valid) {
                        //全选了才判断
                        //发票已确认 就不能在修改
                        // if (this.totalShipCtn != this.totalGoodCtn) {
                        //     this.$message.warning("派送总件数与产品总件数不一致");
                        //     return false;
                        // }
                        // if (this.totalShipKg != this.totalGoodKg) {
                        //     this.$message.warning("派送总重量与产品总重量不一致");
                        //     return false;
                        // }
                        // if (this.totalShipCmb != this.totalGoodCmb) {
                        //     this.$message.warning("派送总体积与产品总体积不一致");
                        //     return false;
                        // }

                        await putAction(
                            this.url.saveGoodBatch + "?orderNo=" + this.$route.query.orderNo,
                            this.form.osOrderGoodsList
                        ).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                // this.initGoodlist();
                                this.$emit("init");
                            }
                        });
                    }
                });
            },
            async checkForm(formName) {
                ////console.log('要校验的表单', formName)
                var _self = this;
                var result = await new Promise(function (resolve, reject) {
                    if (_self.$refs[formName]) {
                        _self.$refs[formName].validate((valid) => {
                            if (valid) {
                                resolve();
                            } else {
                                // setTimeout(() => {
                                //   var isError = document.getElementsByClassName("is-error");
                                //   isError[0].querySelector("input").focus();
                                // }, 100);
                                reject();
                            }
                        });
                    } else {
                        resolve();
                    }
                });
                return result;
            },
            saveShipBatch() {
                let formArr = [];
                let resultArr = [];
                formArr.push("shipref");
                // formArr.push('addressModel')
                let _self = this;
                ////console.log('需要校验的form', formArr)
                formArr.forEach((item) => {
                    // //console.log('校验的结果',this.checkForm(item))
                    resultArr.push(this.checkForm(item));
                });
                Promise.all(resultArr).then(function () {
                    // if (this.totalShipCtn != this.totalGoodCtn) {
                    //     this.$message.warning("派送总件数与产品总件数不一致");
                    //     return false;
                    // }
                    // if (this.totalShipKg != this.totalGoodKg) {
                    //     this.$message.warning("派送总重量与产品总重量不一致");
                    //     return false;
                    // }
                    // if (this.totalShipCmb != this.totalGoodCmb) {
                    //     this.$message.warning("派送总体积与产品总体积不一致");
                    //     return false;
                    // }
                    if (
                        !_self.form.osOrderShipmentList ||
                        _self.form.osOrderShipmentList.length === 0
                    ) {
                        _self.$message.warning("请至少输入一行唛头数据");
                        return false;
                    }
                    let osOrderShipmentList = [];
                    _self.form.osOrderShipmentList.forEach((sp) => {
                        let item = sp;
                        let id = sp.id;
                        // if (this.form.shipmentType === "0") {
                        //     sp.addressId = this.fbamap0[sp.address]
                        //         ? this.fbamap0[sp.address]["id"]
                        //         : "";
                        // }
                        // if (this.form.shipmentType === "2") {
                        //     sp.addressId = this.fbamap2[sp.address]
                        //         ? this.fbamap2[sp.address]["id"]
                        //         : "";
                        // }
                        // if (this.form.shipmentType === "1") {
                        //     sp.addressId = this.fbamap1[sp.address]
                        //         ? this.fbamap1[sp.address]["id"]
                        //         : "";
                        // }
                        // if (this.form.shipmentType === "3") {
                        //     sp.addressId = this.fbamap3[sp.address]
                        //         ? this.fbamap3[sp.address]["id"]
                        //         : "";
                        // }
                        // this.$set(sp,'contact',this.addressMode.contact || '');
                        // this.$set(sp,'tel',this.addressMode.tel || '');
                        // this.$set(sp,'address',this.addressMode.address || '');
                        // this.$set(sp,'zipcode',this.addressMode.zipcode || '');
                        // this.$set(sp,'fbaCode',this.addressMode.fbaCode || '');
                        // this.$set(sp,'state',this.addressMode.state || '');
                        // this.$set(sp,'city',this.addressMode.city || '');
                        // this.$set(sp,'country',this.addressMode.country || '');
                        // this.$set(sp,'twoCode',this.addressMode.twoCode || '');
                        // this.$set(sp,'addressId',this.addressMode.addressId || '');
                        // this.$set(sp,'company',this.addressMode.company || '');
                        ////console.log('要提交的addressmodel',_self.addressModel)
                        for (var name in _self.addressModel) {
                            if (
                                name != "mark" &&
                                name != "id" &&
                                name != "shipmentId" &&
                                name != "referenceId" &&
                                name != "pcs" &&
                                name != "ctn" &&
                                name != "kg" &&
                                name != "cmb" &&
                                name != "remark" &&
                                name !== "filelist" &&
                                name != "fbaCreateTime"
                            ) {
                                // _self.$set(sp,name,_self.addressModel[name]);
                                item[name] = _self.addressModel[name];
                            }
                        }
                        if (_self.form.shipmentType === "0") {
                            item["sysId"] = _self.addressModel.sysId0;
                        } else if (_self.form.shipmentType === "2") {
                            item["sysId"] = _self.addressModel.sysIdw;
                        }else if (_self.form.shipmentType === "4") {
                            item["sysId"] = _self.addressModel.sysId4;
                        }
                        item.id = id;
                        item.shipmentType = _self.form.shipmentType;
                        item.orderNo = _self.$route.query.orderNo;
                        osOrderShipmentList.push(item);
                    });
                    _self.$set(_self.form, "osOrderShipmentList", osOrderShipmentList);
                    putAction(
                        _self.url.saveShipBatch + "?orderNo=" + _self.$route.query.orderNo,
                        _self.form.osOrderShipmentList
                    ).then((res) => {
                        if (res.success) {
                            _self.$message.success(res.message);
                            _self.initShiplist();
                        }
                        _self.$emit("init");
                    });
                });
                // this.$refs["shipref"].validate(async (valid) => {
                //     if (valid) {
                //
                //     }
                // });
            },
            qxrecord(row, index) {
                //校验 国家 税率 材质 税号不能为空
                // if(row.country.length <1){
                //   this.$message.warning('国家不能为空');
                //   return false;
                // }
                // if(row.hscode.length <1){
                //   this.$message.warning('税号不能为空');
                //   return false;
                // }
                // if(row.material.length <1){
                //   this.$message.warning('材质不能为空');
                //   return false;
                // }
                // if(row.taxRate.length <1){
                //   this.$message.warning('税率不能为空');
                //   return false;
                // }
                // if (!baifenshuPattren.test(row.taxRate)){
                //   this.$message.warning('税率必须为百分数，最多三位小数，小于1');
                //   return false;
                // }
                if (row.id.length < 1) {
                    this.$message.warning("该产品未保存");
                    return false;
                }
                row.orderNo = this.$route.query.orderNo;
                putAction(this.url.qxrecord + "?cusId=" + this.order.cusId, row).then(
                    (res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            // this.initGoodlist();
                            this.form.osOrderGoodsList[index].tag = "0";
                        }
                    }
                );
            },
            record(scope) {
                ////console.log("要备案的产品", scope.row);
                //校验 国家 税率 材质 税号不能为空
                if (!scope.row.country || scope.row.country.length < 1) {
                    this.$message.warning("国家不能为空");
                    return false;
                }
                if (!scope.row.hscode || scope.row.hscode.length < 1) {
                    this.$message.warning("税号不能为空");
                    return false;
                }
                if (!scope.row.material || scope.row.material.length < 1) {
                    this.$message.warning("材质不能为空");
                    return false;
                }
                if (!scope.row.taxRate || scope.row.taxRate.length < 1) {
                    this.$message.warning("税率不能为空");
                    return false;
                }
                if (
                    (this.order.shipmentCountry !== "美国" ||
                        scope.row.country !== "美国") &&
                    !sanweixiaoshu.test(scope.row.taxRate) &&
                    scope.row.taxRate &&
                    scope.row.taxRate.indexOf("%") === -1
                ) {
                    this.$message.warning("税率最多三位小数");
                    return false;
                }
                if (!scope.row.id || scope.row.id.length < 1) {
                    this.$message.warning("该产品未保存");
                    return false;
                }
                //赋值单号
                scope.row.orderNo = this.$route.query.orderNo;
                putAction(
                    this.url.recordGoods + "?cusId=" + this.order.cusId,
                    scope.row
                ).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.initGoodlist();

                        /*如果之前有导入数据，就合并*/
                        // this.form.osOrderGoodsList[scope.$index].tag = "1";
                    }
                });
            },
            addShip() {
                let address = "";
                let addressId = "";
                let sysId = "";
                if (this.form.osOrderShipmentList.length > 0) {
                    address = this.form.osOrderShipmentList[0].address;
                    addressId = this.form.osOrderShipmentList[0].addressId;
                    sysId = this.form.osOrderShipmentList[0].sysId;
                }
                this.form.osOrderShipmentList.push({
                    shipmentId: "",
                    referenceId: "",
                    mark: "",
                    pcs: "",
                    ctn: "",
                    kg: "",
                    cmb: "",
                    address: address,
                    addressId: addressId,
                    sysId: sysId,
                    remark: "",
                    orderNo: this.$route.query.orderNo,
                    filelist: [],
                });
            },
            removeShip(index) {
                let id = this.form.osOrderShipmentList[index].id;
                if (!id) {
                    this.form.osOrderShipmentList.splice(index, 1);
                    return true;
                }
                ////console.log("要删除的对象", this.form.osOrderShipmentList[index]);
                this.$confirm(`确定删除`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        //判断是否有权限删除
                        let flag = this.buttonShowList.sc;
                        if (!flag) {
                            this.$message.warning("您暂时没有权限删除派送地址哦");
                            return false;
                        }
                        let invoiceStatus = this.order.invoiceStatus;
                        if (invoiceStatus === "1") {
                            this.$message.warning("发票已确认的订单不允许删除派送地址信息");
                            return false;
                        }

                        if (id && id.length > 1) {
                            deleteAction(this.url.deleteShip, { id: id })
                                .then((res) => {
                                    this.$message.success(res.message);
                                    this.initShiplist();
                                })
                                .catch((err) => {
                                    this.$message.error(res.message);
                                });
                        } else {
                            this.form.osOrderShipmentList.splice(index, 1);
                        }
                    })
                    .catch(() => {});
            },
            freshAddress1(list) {
                this.addresslist1 = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label = list[i].address;
                    // if (!this.form.shipmentCountry || this.form.shipmentCountry.length ===0) {
                    //     this.fbalist.push(obj);
                    // }
                    // if(this.form.shipmentCountry === list[i].country){
                    this.addresslist1.push({ value, label });
                    // }
                }
                // if ("0" == isBussiness) {
                this.fbamap1 = this.listToMap(list, "id");
                // console.log("私人地址集合",this.addresslist1,this.fbamap1)
                // //console.log("this.fbamap1", this.fbamap1);
                // } else {
                //   this.fbamap3 = this.listToMap(res.result.records, "id");
                //   //console.log("this.fbamap3", this.fbamap3);
                // }
            },
            freshAddress3(list) {
                this.addresslist3 = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label = list[i].address;
                    // if (!this.form.shipmentCountry || this.form.shipmentCountry.length ===0) {
                    //     this.fbalist.push(obj);
                    // }
                    // if(this.form.shipmentCountry === list[i].country){
                    this.addresslist3.push({ value, label });
                    // }
                }
                // if ("0" == isBussiness) {
                //   this.fbamap1 = this.listToMap(res.result.records, "id");
                //   //console.log("this.fbamap1", this.fbamap1);
                // } else {
                this.fbamap3 = this.listToMap(list, "id");
                // console.log("商业地址集合",this.addresslist3,this.fbamap3)
                // //console.log("this.fbamap3", this.fbamap3);
                // }
            },
            freshFbawareList(list) {
                this.warehouseList = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label =
                        list[i].company + "-" + list[i].country + "-" + list[i].zipcode;
                    let obj = { value, label };
                    // if (!this.form.shipmentCountry || this.form.shipmentCountry.length ===0) {
                    //     this.fbalist.push(obj);
                    // }
                    // if (this.form.shipmentCountry === res.result[i].country){
                    this.warehouseList.push(obj);
                    // }
                }
                this.fbamap2 = this.listToMap(list, "id");
            },

            freshFbalist(list) {
                this.fbalist = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label = list[i].country + "[" + list[i].fbaCode + "]";
                    let obj = { value, label };
                    // if (!this.form.shipmentCountry || this.form.shipmentCountry.length ===0) {
                    //     this.fbalist.push(obj);
                    // }
                    // if (res.result[i].country == this.form.shipmentCountry) {
                    this.fbalist.push(obj);
                    // }
                }
                this.fbamap0 = this.listToMap(list, "id");
            },
            freshFbalist4(list) {
                this.fbalist4 = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label = list[i].company + "[" + list[i].fbaCode + "]";
                    let obj = { value, label };
                    // if (!this.form.shipmentCountry || this.form.shipmentCountry.length ===0) {
                    //     this.fbalist.push(obj);
                    // }
                    // if (res.result[i].country == this.form.shipmentCountry) {
                    this.fbalist4.push(obj);
                    // }
                }
                this.fbamap4 = this.listToMap(list, "id");
            },
            handleQuery() {debugger
                this.loading = true;
                let form = { ...this.queryForm, ...this.filterForm };
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const { prop, order } = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order && order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                //console.log("查询对象", params);
                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            this.freshFbalist(res.result.fbalist0);
                            this.freshFbalist4(res.result.fbalist4);
                            this.freshAddress1(res.result.fbalist1);
                            this.freshAddress3(res.result.fbalist3);
                            this.freshFbawareList(res.result.fbalist2);
                            this.countryList = res.result.countryList;

                            let order = res.result.osOrder;
                            if (order) {
                                this.$set(this.taxForm, "createTime", order.createTime);
                                this.$set(this.taxForm, "fjf", order.fjf);
                                this.$set(this.form, "shipmentType", order.shipmentType);
                                this.$set(this.addressModel, "fbaCode", order.shipmentCode);
                                this.$set(this.addressModel, "zipcode", order.shipmentZip);
                                this.$set(this.addressModel, "state", order.shipmentState);
                                this.$set(this.addressModel, "city", order.shipmentCity);
                                this.$set(this.addressModel, "contact", order.shipmentContact);
                                this.$set(this.addressModel, "tel", order.shipmentTel);
                                this.$set(this.addressModel, "address", order.shipmentAddress);
                                this.$set(this.addressModel, "address2", order.shipmentAddress2);
                                this.$set(this.addressModel, "country", order.shipmentCountry);
                                this.$set(this.addressModel, "yuyueRemark", order.yuyueRemark);
                                this.$set(this.addressModel, "isDischarge", order.isDischarge);
                                this.$set(this.addressModel, "isYuyue", order.isYuyue);
                                this.$set(this.addressModel, "company", order.shipmentCompany);
                                if (order.shipmentType === "0") {
                                    this.$set(this.addressModel, "sysId0", order.sysId);
                                    this.$set(this.addressModel, "sysIdw", "");
                                    this.$set(this.addressModel, "sysId4", "");
                                    this.$set(
                                        this.addressModel,
                                        "addressDetail0",
                                        order.addressDetail
                                    );
                                } else if (order.shipmentType === "2") {
                                    this.$set(this.addressModel, "sysIdw", order.sysId);
                                    this.$set(this.addressModel, "sysId0", "");
                                    this.$set(this.addressModel, "sysId4", "");
                                    this.$set(
                                        this.addressModel,
                                        "addressDetail2",
                                        order.addressDetail
                                    );
                                } else if (order.shipmentType === "4") {
                                    this.$set(this.addressModel, "sysId4", order.sysId);
                                    this.$set(this.addressModel, "sysId0", "");
                                    this.$set(this.addressModel, "sysId2", "");
                                    this.$set(
                                        this.addressModel,
                                        "addressDetail4",
                                        order.addressDetail
                                    );
                                }else {
                                    this.$set(this.addressModel, "sysId", order.sysId);
                                    this.$set(
                                        this.addressModel,
                                        "addressDetail1",
                                        order.addressDetail
                                    );
                                }
                            }
                            this.oldAddressData = res.result.osOrderShipmentList;
                            this.form.osOrderShipmentList = res.result.osOrderShipmentList;
                            res.result.osOrderShipmentList &&
                            res.result.osOrderShipmentList.forEach((item, index) => {
                                item.filelist ? "" : (item.filelist = []);
                            });
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleQuery2() {
                this.goodsloading = true;
                let form = { ...this.queryForm, ...this.filterForm };
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const { prop, order } = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order && order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                //console.log("查询对象", params);

                getAction(this.url.goodslist, params)
                    .then((res) => {
                        if (res.success) {
                            // this.form.osOrderGoodsList = res.result.list;
                            this.parseProduct(
                                JSON.parse(JSON.stringify(res.result.productList))
                            );
                            // this.$set(this.form,'osOrderGoodsList',JSON.parse(JSON.stringify(res.result.list)));
                            let osOrderGoodsList = JSON.parse(JSON.stringify(res.result.list));
                            this.$set(this.form, "osOrderGoodsList", osOrderGoodsList);
                            // select初始的change不触发
                            this.form.osOrderGoodsList.forEach((item, index) => {
                                item.changeFlag = false;
                            });
                            // this.caculateGoodsAll();
                            //console.log("页面加载完成的产品列表数据", this.form.osOrderGoodsList);
                        }
                    })
                    .finally(() => {
                        this.goodsloading = false;
                        this.cploading = false;
                        this.fsee = true;
                    });
            },
            handleDel() {},

            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.orderNo = this.$route.query.orderNo;
                this.ipagination.currentPage = 1;
                this.handleQuery();
                this.handleQuery2();
            },
            initShiplist() {
                this.queryForm.orderNo = this.$route.query.orderNo;

                this.handleQuery();
            },

            initGoodlist() {
                this.queryForm.orderNo = this.$route.query.orderNo;
                this.handleQuery2();
            },
            copyFuc(index) {
                //获取input对象
                var input = document.getElementById("copyProductId" + index);
                //console.log(input,'input')
                //选中input标签
                input.select();
                //执行复制
                document.execCommand("copy");
                this.$message.success("复制成功!");
            },
            checkIsCt() {
                if (this.order.orderType === "1") {
                    return false;
                } else {
                    return true;
                }
            },
            download(url, fileName) {
                const x = new XMLHttpRequest();
                x.open("GET", url, true);
                x.responseType = "blob";
                x.onload = function () {
                    const url = window.URL.createObjectURL(x.response);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = fileName;
                    a.click();
                };
                x.send();
            },
            downGoodsImportExcel() {
                if (this.goodsImportExcelUrl && this.goodsImportExcelUrl.ossFile) {
                    const suffixArr = this.goodsImportExcelUrl.ossFile.url.split(".");
                    let suffix = suffixArr[suffixArr.length - 1];
                    this.download(
                        this.goodsImportExcelUrl.ossFile.url,
                        `${this.goodsImportExcelUrl.ossFile.name}.${suffix}`
                    );
                } else {
                    this.$message.warning("暂无文件可下载");
                }
            },
        },
        //vue页面初始化完毕 加载的方法
        created() {
            this.disableMixinMounted = true;
        },
        //这个是vue页面所有dom元素加载完毕加载的方法
        mounted() {
            // console.log(this.$refs.shipref.validate,'this.$refs.shiprefs8888');
        },
        watch: {
            shipRes(val) {
                if (val) {
                    console.log("传过来的数据", val);
                    this.totalShipKg = this.shipRes.totalShipmentKg;
                    this.totalShipCtn = this.shipRes.totalShipmentCtn;
                    this.totalShipCmb = this.shipRes.totalShipmentCmb;
                    this.$set(this.form, "osOrderShipmentList", val["osOrderShipmentList"]);
                    this.freshFbalist(this.shipRes.fbalist0);
                    this.freshAddress1(this.shipRes.fbalist1);
                    this.freshAddress3(this.shipRes.fbalist3);
                    this.freshFbalist4(this.shipRes.fbalist4);
                    this.freshFbawareList(this.shipRes.fbalist2);
                    this.countryList = this.shipRes.countryList;

                    let order = this.shipRes.osOrder;
                    if (order) {
                        this.$set(this.taxForm, "fjf", order.fjf);
                        this.$set(this.form, "shipmentType", order.shipmentType);
                        this.$set(this.addressModel, "fbaCode", order.shipmentCode);
                        //如果是包含中文 把包含中文的部分去掉
                        this.$set(this.addressModel, "zipcode", order.shipmentZip);
                        //如果是包含中文 把包含中文的部分去掉
                        this.$set(this.addressModel, "state", order.shipmentState);

                        this.$set(this.addressModel, "city", order.shipmentCity);
                        this.$set(this.addressModel, "contact", order.shipmentContact);
                        this.$set(this.addressModel, "tel", order.shipmentTel);
                        this.$set(this.addressModel, "address", order.shipmentAddress);
                        this.$set(this.addressModel, "address2", order.shipmentAddress2);
                        this.$set(this.addressModel, "country", order.shipmentCountry);
                        this.$set(this.addressModel, "yuyueRemark", order.yuyueRemark);
                        this.$set(this.addressModel, "isDischarge", order.isDischarge);
                        this.$set(this.addressModel, "isYuyue", order.isYuyue);
                        this.$set(this.addressModel, "company", order.shipmentCompany);
                        if (order.shipmentType === "0") {
                            this.$set(this.addressModel, "sysId0", order.sysId);
                            this.$set(this.addressModel, "sysIdw", "");
                            this.$set(this.addressModel, "sysId4", "");
                            this.$set(this.addressModel, "addressDetail0", order.addressDetail);
                        } else if (order.shipmentType === "2") {
                            this.$set(this.addressModel, "sysIdw", order.sysId);
                            this.$set(this.addressModel, "sysId0", "");
                            this.$set(this.addressModel, "sysId4", "");
                            this.$set(this.addressModel, "addressDetail2", order.addressDetail);
                        } else if (order.shipmentType === "4") {
                            this.$set(this.addressModel, "sysId4", order.sysId);
                            this.$set(this.addressModel, "sysId0", "");
                            this.$set(this.addressModel, "sysIdw", "");
                            this.$set(this.addressModel, "addressDetail2", order.addressDetail);
                        } else {
                            this.$set(this.addressModel, "sysId", order.sysId);
                            this.$set(this.addressModel, "addressDetail1", order.addressDetail);
                        }
                    }
                    this.oldAddressData = this.shipRes.osOrderShipmentList;
                    // this.form.osOrderShipmentList = res.result.list;

                    let list = this.form.osOrderShipmentList;
                    list &&
                    list.forEach((item, index) => {
                        item.filelist ? "" : (item.filelist = []);
                    });

                    this.parseProduct(JSON.parse(JSON.stringify(this.shipRes.proIdList)));

                    this.form.osOrderGoodsList = this.shipRes.osOrderGoodsList;
                    this.totalGoodCtn = this.shipRes.totalGoodsCtn;
                    this.totalGoodKg = this.shipRes.totalGoodsKg;
                    this.totalGoodCmb = this.shipRes.totalGoodsCmb;
                    this.totalGoodPrice = this.shipRes.totalGoodsValue;
                    this.totalPrePrice = this.caculateCmbtv(
                        this.totalGoodCmb,
                        this.totalGoodPrice
                    );
                }
            },
            "form.shipmentType": {
                immediate: true,
                handler(val) {
                    if (this.form.shipmentType && this.form.shipmentType !== "2") {
                        this.hint = "请输入唛头";
                    } else {
                        this.hint = "请输入入仓编号";
                    }
                    this.$nextTick(() => {
                        this.$refs.shipref && this.$refs.shipref.validate();
                    });
                },
            },
        },
      computed : {
        buttonShowList() {
          return {
            'bcdz':this.$btnIsShow('docpage/modules/Shipmen', '0', '保存地址'),
            'add':this.$btnIsShow('docpage/modules/Shipmen', '0', '添加'),
            'bcjbxx':this.$btnIsShow('docpage/modules/Shipmen', '0', '保存基本信息'),
            'sc':this.$btnIsShow('docpage/modules/Shipmen', '1', '删除'),
            'bc':this.$btnIsShow('docpage/modules/Shipmen', '0', '保存'),
            'bcbsfy':this.$btnIsShow('docpage/modules/Invoice', '0', '保存包税费用'),
            'add1':this.$btnIsShow('docpage/modules/Invoice', '0', '添加'),
            'bc1':this.$btnIsShow('docpage/modules/Invoice', '0', '保存'),
            'qrfp':this.$btnIsShow('docpage/modules/Invoice', '0', '确认发票'),
            'fpqrqx':this.$btnIsShow('docpage/modules/Invoice', '0', '发票确认取消'),
            'fpsh':this.$btnIsShow('docpage/modules/Invoice', '0', '发票审核'),
            'fpqxsh':this.$btnIsShow('docpage/modules/Invoice', '0', '发票取消审核'),
            'drcp':this.$btnIsShow('docpage/modules/Invoice', '0', '导入产品'),
            'xzbgzlqd':this.$btnIsShow('docpage/modules/Invoice', '0', '下载报关资料清单'),
            'fpxz':this.$btnIsShow('docpage/modules/Invoice', '0', '发票下载'),
            'del1':this.$btnIsShow('docpage/modules/Invoice', '1', '删除'),

            'bj1':this.$btnIsShow('docpage/modules/Invoice', '1', '编辑'),
            'ba1':this.$btnIsShow('docpage/modules/Invoice', '1', '备案'),
            'fz1':this.$btnIsShow('docpage/modules/Invoice', '1', '复制'),
            'kbshg':this.$btnIsShow("docpage/modules/Invoice", "0", "是否可以查看美国加拿大包税单的海关编码"),
            'kysgs':this.$btnIsShow("docpage/modules/Invoice", "0", "是否可以查看包税单的预算关税"),
            'yzgxgxx':this.$btnIsShow("isystem/RoleManage", "0", "已装柜可修改信息"),
            'xgcpqx':this.$btnIsShow("isystem/RoleManage", "0", "已装柜有清关信息修改产品权限"),
          }
        }
      }
    };
</script>
<style lang="scss">
    .customTable {
        .el-button--text {
            padding: 0px;
        }
    }
</style>
<style lang="scss">
    .widthFull {
        .el-form-item__content {
            width: 100%;
        }
    }

    .upload_margin {
        margin: 0 10px;
    }

    .flex_form {
        .el-form-item__content {
            display: flex;
            align-items: center;
        }
    }
</style>
