<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium" :inline="true">
            <el-card class="box-card">
                <el-form-item label="订单号">
                    <el-input v-model="queryForm.orderNo" type="textarea"></el-input>
                </el-form-item>

                <el-form-item label="业务员">
                    <el-select filterable v-model="queryForm.salesmanIds" multiple @change="changeSelect" collapse-tags
                        style="margin-left: 0px; width: 200px" placeholder="请选择"   clearable filterable>
                        <el-checkbox v-model="checked" @change="selectAll">全选</el-checkbox>
                        <el-option v-for="item in this.queryDictCodeListMap.salesmanId" :key="item.value" :label="item.text"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="客户">
                    <el-input v-model="queryForm.customer"></el-input>
                </el-form-item>

              <el-form-item label="订单类型2" >
                <f-select
                    :dict="'order_type_two'"
                    v-model="queryForm.orderTypeTwo"
                    :is-need="queryForm.orderTypeTwo"
                ></f-select>
              </el-form-item>

                <el-form-item label="业务类型">
                    <f-select v-model="queryForm.containerType" :isNeed="queryForm.containerType" :clearable="false"
                        :dataList="containerTypeList">
                    </f-select>
                </el-form-item>

              <el-form-item label="费用有改动">
                <f-select v-model="queryForm.salesProfitUpdate" :isNeed="queryForm.salesProfitUpdate"
                          :dataList="salesProfitUpdate">
                </f-select>
              </el-form-item>
                <el-form-item label-width="30px">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                    <el-button type="primary" icon="el-icon-search" @click="handleByOrderNo">查询未进入绩效的订单</el-button>
                    <el-button plain @click="handleReset">重置</el-button>
                </el-form-item>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" icon="el-icon-plus" @click="noProblem" :disabled="selectionRows.length === 0"
                    v-if="buttonShowList['批量修改订单数据']">
                    批量修改订单数据
                </el-button>
                <el-button type="primary" size="medium" @click="handleExportXls('海运绩效修改订单导出')"
                    v-if="buttonShowList['导出']">导出</el-button>
              <el-button type="primary" icon="el-icon-plus" @click="updateStatus" :disabled="selectionRows.length === 0"
                         v-if="buttonShowList['批量置空订单审核状态']">
                批量置空订单审核状态
              </el-button>
            </div>
            <f-table v-loading="loading" border ref="GTable" size="medium" row-key="id" class="c-th-has-bgcolor"
                :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="66vh"
                @selection-change="handleSelectionChange" @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'orderNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoDocPage(scope.row.orderNo)"
                    >{{ scope.row.orderNo }}</a
                    >
                  </template>
                  <template v-else-if="scope.column.property === 'salesOrderTypeTwo'">
                    <f-select v-model="scope.row.salesOrderTypeTwo"
                              :is-need="scope.row.salesOrderTypeTwo"
                              placeholder="请选择" filterable clearable :data-list="queryDictCodeListMap.order_type_two">
                    </f-select>
<!--                    <span>{{ parseDict2(scope.row.salesOrderTypeTwo, "order_type_two") }}</span>-->
                  </template>
                  <template v-else-if="scope.column.property === 'salesCommission'">
                    <f-select v-model="scope.row.salesCommission"
                              :is-need="scope.row.salesCommission"
                              placeholder="请选择" filterable clearable :data-list="queryDictCodeListMap.commission">
                    </f-select>
                  </template>
                  <template v-else-if="scope.column.property === 'salesRealCmb'">
                    <el-input type="number" v-model="scope.row.salesRealCmb"></el-input>
                  </template>
                  <template v-else-if="scope.column.property === 'salesPaymentCycle'">
                    <el-input type="number" v-model="scope.row.salesPaymentCycle"></el-input>
                  </template>
                  <template v-else-if="scope.column.property === 'salesProfit'">
                    <el-input type="number" v-model="scope.row.salesProfit"></el-input>
                  </template>
                  <template v-else-if="scope.column.property === 'profitAfterVerification'">
                    <el-input type="number" v-model="scope.row.profitAfterVerification"></el-input>
                  </template>
                  <template v-else-if="scope.column.property === 'salesAuditReason'">
                    <f-select v-model="scope.row.salesAuditReason"
                              :is-need="scope.row.salesAuditReason"
                              placeholder="请选择" filterable clearable :data-list="queryDictCodeListMap.lose_money_reason">
                    </f-select>
                  </template>
                  <template v-else-if="scope.column.property === 'salesSubRecRate'">
                    <f-select v-model="scope.row.salesSubRecRate"
                              :is-need="scope.row.salesSubRecRate"
                              placeholder="请选择" filterable clearable :data-list="queryDictCodeListMap.sub_rec_rate">
                    </f-select>
                  </template>
                  <template v-else-if="scope.column.property === 'salesProfitUpdate'">
                    <span v-if="scope.row.salesProfitUpdate === '0'">否</span>
                    <span v-else-if="scope.row.salesProfitUpdate === '1'">是</span>
                  </template>
                </template>

            </f-table>
        </el-card>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>

</template>

<script>
import {putAction, deleteAction, postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import Tempcclate from "@/views/isystem/weightMark.vue";

export default {
    name: "SalesFixOrder",
    mixins: [QueryMixins],
    components: {
      Tempcclate
    },
    data() {
        return {
            isUsePost: true,
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "salesman",
                    label: "业务员",
                    width: 100,
                },
                {
                    prop: "customer",
                    label: "客户",
                },
                {
                    prop: "orderNo",
                    label: "订单号",
                },
              {
                prop: "salesOrderTypeTwo",
                label: "订单类型2",
                width: 100,
              },
              {
                prop: "salesRealCmb",
                label: "入仓体积/收费重",
              },
              {
                prop: "salesCommission",
                label: "提成性质",
                width: 100,
              },
              {
                prop: "salesPaymentCycle",
                label: "回款周期",
              },
                {
                    prop: "receivables",
                    label: "应收",
                },
                {
                    prop: "costBilling",
                    label: "成本账单",
                },
              {
                prop: "salesProfitUpdate",
                label: "费用是否改动",
                width: 100,
              },
                {
                    prop: "salesProfit",
                    label: "利润",
                    width: 100,
                },
              {
                prop: "profitRateStr",
                label: "利润率",
              },
              {
                prop: "normalProportionalCommissionStr",
                label: "正常提成比例",
                width: 100,
              },
              {
                prop: "freightCommissions",
                label: "海运利润提成",
                width: 100,
              },
              {
                prop: "profitAfterVerification",
                label: "核对后提成",
                width: 100,
              },
                {
                    prop: 'remark',
                    label: '备注',
                    minWidth: 150
                },
              {
                prop: "handleType",
                label: "亏本订单处理类型",
                width: 100,
              },
              {
                prop: "salesAuditReason",
                label: "亏本订单审核原因",
                width: 100,
              },
              {
                prop: "loseRemark",
                label: "待审核备注",
                width: 100,
              },
              {
                prop: "salesSubRecRate",
                label: "扣款比例",
                width: 100,
              },
            ],
            url: {
                list: "/order/sale/SalesFixOrder/getFixOrder",
                exportXlsUrl: "/order/sale/SalesFixOrder/exportFixOrder",
                batchUpdateOrderInfo: "/order/sale/SalesFixOrder/updateOrderSalesInfo",
                updateOrderPerformance: "/order/sale/SalesFixOrder/updateOrderPerformanceCheckStatus",
                searchByOrderNo: "/order/sale/SalesFixOrder/getNoSalesFixOrder",
            },
            containerTypeList: [
                {
                    label: "自拼",
                    value: "0",
                },
                {
                    label: "整柜",
                    value: "1",
                },
                {
                    label: "非拼",
                    value: "2",
                },
            ],
            salesProfitUpdate: [
              {
                label: "是",
                value: "1",
              },
              {
                label: "否",
                value: "0",
              },
            ],
            needQueryDictCodes: ["salesmanId", "order_type_two", "commission", "sub_rec_rate", "lose_money_reason"],
            checked:false
        };
    },
    computed: {
        buttonShowList() {
            return {
                '批量修改订单数据': this.$btnIsShow('dashboard/SalesFixOrder', '0', '批量修改订单数据'),
                '导出': this.$btnIsShow('dashboard/SalesFixOrder', '0', '导出'),
              '批量置空订单审核状态': this.$btnIsShow('dashboard/SalesFixOrder', '0', '批量置空订单审核状态'),
            }
        }
    },
    created() {
      this.queryForm.containerType = "0";
    },
    methods: {
        noProblem() {
            this.$confirm('确定批量修改选中订单的数据', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning",
                closeOnClickModal: false,
                buttonSize: "small",
            })
                .then(() => {
                    this.$store.commit("setFullscreenLoading", true);
                  postAction(this.url.batchUpdateOrderInfo, this.selectionRows).then(
                      (res) => {
                        this.$message.success(res.message);
                        this.handleQuery();
                      }
                  );
                })
                .catch(() => { });
        },
      updateStatus() {
        postAction(this.url.updateOrderPerformance, {
          orderNoList: this.selectionRows.map((s) => s.orderNo)
        }).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          }
        });
      },
      handleByOrderNo() {
        postAction(this.url.searchByOrderNo, {
          orderNo: this.queryForm.orderNo,
          salesProfitUpdate: this.queryForm.salesProfitUpdate
        }).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            const {records, total} = res.result
            this.tableData = records || res.result;
          }
        });
      },

      handleReset() {
        this.queryForm = {
          containerType: "0",
          salesmanIds: [],
        };
        this.handleQuery();
      },

        changeSelect(val) {
            if (val.length === this.dictMap.salesmanId.length) {
                this.checked = true;
            } else {
                this.checked = false;
            }
        },
        selectAll() {
            this.queryForm.salesmanIds = [];
            if (this.checked) {
                for (const key in this.queryDictCodeListMap.salesmanId) {
                    this.queryForm.salesmanIds.push(this.queryDictCodeListMap.salesmanId[key].value);
                }
            } else {
                this.queryForm.salesmanIds = [];
            }
        },
    },

};
</script>
<style lang="scss" scoped>
.company_img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}
.el-checkbox {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
</style>