<template>
  <div>
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="客户名称">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.customer"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                  </el-form-item>
                </el-col>


              <el-col :span="6">
                <el-form-item label="下单时间" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.orderTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.orderTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="费用时间" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.settleTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.settleTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                  <el-form-item label="业务员">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                      :father-val="queryForm.salesmanId"
                    ></f-select>
                  </el-form-item>
                </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="收款人">
                  <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.billmanId"
                      :is-need="queryForm.billmanId"
                      :father-val="queryForm.billmanId"
                  ></f-select>
                </el-form-item>
              </el-col>

                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleClick"
                                :disabled="disabledFlag"
                        > 查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <div>
        <el-button  type="primary" size="small"  @click="exportRecordView">导出记录</el-button>
        <el-button  type="primary" size="small"  @click="daochuEasy">导出(新)</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            type="index"
            label="序号"
        ></el-table-column>
        <el-table-column
            prop="settlement"
            label="客户名称"
        >
          <template slot-scope="scope">
            <a
                style="color: #00a0e9"
                @click.prevent="openDertail(scope.row)"
            >{{ scope.row.settlement }}</a>
          </template>
        </el-table-column>
        <el-table-column
                prop="edu"
                label="信用额度"
        ></el-table-column>
        <el-table-column
            prop="salesman"
            label="销售"
        ></el-table-column>
        <el-table-column
            prop="billman"
            label="收款人"
        ></el-table-column>
        <el-table-column
            prop="freightway"
            label="付款类型"
        ></el-table-column>
        <el-table-column
                label="客户系统订单总应收款"
        >
          <el-table-column
                  prop="recTotal"
                  label="RMB"
          ></el-table-column>
        </el-table-column>
          <el-table-column label="合计">

            <el-table-column
                prop="usdjia"
                label="USD"
            ></el-table-column>
            <el-table-column
                prop="rmbjia"
                label="CNY"
            ></el-table-column>
            <el-table-column
                prop="gbpjia"
                label="GBP"
            ></el-table-column>
            <el-table-column
                prop="jpyjia"
                label="JPY"
            ></el-table-column>
            <el-table-column
                prop="cadjia"
                label="CAD"
            ></el-table-column>
            <el-table-column
                prop="hkdjia"
                label="HKD"
            ></el-table-column>
            <el-table-column
                prop="eurjia"
                label="EUR"
            ></el-table-column>
          </el-table-column>
      </el-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <yingshou-dt
      :visible.sync="visible"
      :windowsType = "windowsType"
      :settlement="settlement"
      :settle-id="settleId"
      :cusId="cusId"
      :settleId="settleId"
      :queryForm="queryForm"
      :countType="countType"
      @ok="handleOk"
    ></yingshou-dt>
    <el-dialog title="导出记录" :visible.sync="dialogTableVisible"  width="70%">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="5">
              <el-form-item label="文件名称">
                <el-input
                        v-model="queryRecordForm.fileName"
                        @keyup.enter.native="getExportRecordList"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="10">
              <el-form-item label="导出时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                          class="f-flex-1"
                          v-model="queryRecordForm.createTimeS"
                          type="datetime"
                          size="small"
                          placeholder="开始时间"
                          default-time="00:00:00"
                          value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                          class="f-flex-1"
                          v-model="queryRecordForm.createTimeE"
                          type="datetime"
                          size="small"
                          placeholder="结束时间"
                          default-time="23:59:59"
                          value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="5">
              <el-form-item label="文件导出状态" label-width="100px">
                  <f-select v-model="queryRecordForm.fileStatus"  :dict="'file_status'" :isNeed="queryRecordForm.fileStatus" ></f-select>
              </el-form-item>
            </el-col>




            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="getExportRecordList"
                        :disabled="disabledFlag"
                > 查询
                </el-button
                >
                <el-button plain @click="handleRecordReset">重置</el-button>
                <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <f-table
              v-loading="loading"
              ref="GTable2"
              border
              size="medium"
              row-key="id"
              class="c-th-has-bgcolor"
              :columns="columns"
              :data="exportRecordList"
              :cell-style="{ 'text-align': 'center' }"
              tableHeight="66vh"
              @selection-change="handleSelectionChange"
              @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'fileStatus'">
            <el-tag v-if="scope.row.fileStatus === '0'" type="danger">失败</el-tag>
            <el-tag v-if="scope.row.fileStatus === '1'" type="warning">进行中</el-tag>
            <el-tag v-if="scope.row.fileStatus === '2'" type="success">已完成</el-tag>
          </template>
          <template v-else-if="scope.column.property === 'fileUrl'">
            <a type="text" @click.prevent="downloadFile2(scope.row)" style="cursor: pointer;color:#57a3f3;">{{scope.row.fileUrl}}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
                v-bind="ipaginationRecord"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeRecordChange"
                @current-change="handleCurrentRecordChange"
        >
        </el-pagination>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';
import YingshouDt from "./YingshouDt";
import {getAction} from "../../api/manage";


export default {
  name: "YingshouZD",
  mixins: [QueryMixins],
  components: {
    YingshouDt
  },
  data() {
    return {
      ipaginationRecord: {
        currentPage: 1,
        pageSize: 50,
        pageSizes: [50, 100, 200, 300, 400, 500],
        total: 0
      },
      columns: [

        {
          type: "index",
          label:'序号',
          width: 33,
        },

        // {
        //   prop: "username",
        //   label: "客户名",
        // },
        {
          prop: "fileName",
          label: "文件名称",
        },
        {
          prop: "fileStatus",
          label: "文件导出状态",
        },
        {
          prop: "createBy",
          label: "导出人",

        },
        {
          prop: "createTime",
          label: "导出时间",
        },
        {
          prop: "fileUrl",
          label: "导出文件url",
        },
        {
          prop: "exportRemark",
          label: "导出备注",
        },
      ],
      exportRecordList:[],
      dialogTableVisible:false,
      windowsType:"1",
      disabledFlag:false,
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      proVisible: false,
      url: {
        list: "/order/yingshouzd/getCustomerTotalRecList",
        daochuView: "/file/jasper/downloadRecExport",
        daochuEasy: "/file/export/excel/download/exportCustomerTotalRec",
        getRecordList: "/order/yingshouzd/getCustomerExportRecordList",


      },
      issalesman: "0",
      settlement:'',
      cusId:'',
      queryRecordForm:{},
      countType:'',
        settleId:'',
    };
  },
  created() {

  },

  methods: {
    downloadFile2(file){
      window.open(file.fileUrl);
    },
    handleSizeRecordChange(val) {
      this.ipaginationRecord.currentPage = 1
      this.ipaginationRecord.pageSize = val
      this.getExportRecordList()
    },
    handleCurrentRecordChange(val) {
      this.ipaginationRecord.currentPage = val
      this.getExportRecordList()
    },
    getCurrentDate(date) {
      let currentDate = new Date();
      if(date) currentDate = new Date(date);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    handleClick() {
      this.disabledFlag =true
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else {
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }

      console.log("查询对象", params)

      getAction(this.url.list, params).then((res) => {
        if (res.success) {
          const {records, total} = res.result
          this.tableData = records || res.result;
          this.ipagination && (this.ipagination.total = total || res.result.length)
        }
      }).finally(() => {
        this.disabledFlag = false
        this.loading = false
      })
    },
    handleOk(){
      this.visible=false;
    },
    openDertail(row){
      this.settlement = row.settlement;
      this.cusId = row.cusId;
      this.settleId = row.settleId;
      this.countType = this.queryForm.countType;
      this.visible=true;
    },
    getExportRecordList(){
      if (this.ipaginationRecord) {
        this.queryRecordForm.pageNo = this.ipaginationRecord.currentPage
        this.queryRecordForm.pageSize = this.ipaginationRecord.pageSize
      }
      getAction(this.url.getRecordList,this.queryRecordForm ).then((res) => {
        if (res.success) {
          const {records, total} = res.result
          this.exportRecordList = records || res.result;
          this.ipaginationRecord && (this.ipaginationRecord.total = total || res.result.length)
        }
      }).finally(() => {
        this.disabledFlag = false
        this.loading = false
      })
    },
    exportRecordView(){
      this.dialogTableVisible = true
      this.getExportRecordList()

    },
    daochuEasy(){
      this.exportExcelPlus(this.url.daochuEasy, "客户总应收");
      console.log("");
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },
    handleRecordReset() {
      this.queryRecordForm = {};
      this.ipaginationRecord.currentPage = 1;
      if (this.$refs['GTable']){
        this.$refs['GTable2'].handleReset();
      }

      this.getExportRecordList();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
  /* 设置 label 的样式，允许换行 */
  .el-table .cell {
    white-space: pre-line;
  }
</style>
