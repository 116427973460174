<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row :gutter="24" class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="单号">
                                <el-input  v-model="queryForm.orderNo" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="仓库">
                          <f-select  v-model="queryForm.warehouse" :is-need="queryForm.warehouse" :dict="'delivery_point'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="扫码类型">
                          <el-select  v-model="queryForm.scanType" clearable filterable
                                     @keyup.enter.native="handleSearch">
                              <el-option value="1" label="扫码收货"></el-option>
                              <el-option value="2" label="扫码入库"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="入仓时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>



                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'扫码记录')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'orderNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoDocPage(scope.row.orderNo)"
                    >{{ scope.row.orderNo }}</a
                    >
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "ScanLog",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    // {
                    //     type: "selection",
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "createTime",
                        label: "入仓时间",
                    },
                  {
                    prop: "scanBy",
                    label: "扫码人",
                  },
                  {
                    prop: "warehouse",
                    label: "仓库",
                  },
                  {
                    prop: "realCtn",
                    label: "入仓件数",
                  },
                  {
                    prop: "realKg",
                    label: "入仓重量",
                  },
                  {
                    prop: "realCmb",
                    label: "入仓体积",
                  },
                  {
                    prop: "warehouseType",
                    label: "扫码类型",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/test/getScanLogIPage",

                    //导出excel的接口
                    exportExcelUrl: '/file/export/excel/warehouse/scanLogExcel',
                },


            };
        },
        methods: {

        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/ScanLog','0','导出扫码记录'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
