<template>
  <el-dialog v-if="type !== 'view'" :title="row.id ? '编辑新闻资讯' : '新增新闻资讯'" :visible="visible" custom-class="c-custom-dialog width_1200_dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="50px">
      <el-tabs class="forest_tab" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="中文配置" name="zh" v-loading="loading">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="标题" prop="title">
                <el-input v-model.trim="form.title" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="24">
              <el-form-item label="作者" prop="articleAuthor" class="width_50">
                <el-input v-model.trim="form.articleAuthor" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="24">
              <el-form-item label="来源" prop="articleSource"  class="width_50">
                <el-input v-model.trim="form.articleSource" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
                <el-col :span="24">
              <el-form-item label="关键字" prop="keyword" class="width_50">
                <el-input v-model.trim="form.keyword" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24" >
              <el-form-item label="发布时间" prop="title" class="width_50" label-width="80px">
               <el-date-picker
                        class="f-flex-1"
                        v-model="form.si"
                        type="datetime"
                        size="small"
                        placeholder="请选择时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%;"
                    >
                    </el-date-picker>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="封面" prop="coverFile">
                <f-upload v-model="form.coverFile" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 551*390</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="loading == false" label="内容" prop="text">
                <vue-ueditor-wrap class="ueditor_forest_box" v-model="form.text"
                  :config="editorConfig"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="类别" prop="articleTypeId">
                <el-select clearable filterable v-model="form.articleTypeId">
                  <el-option v-for="(item, index) in allArticleTypeList" :label="item.articleType" :value="item.id"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="SEO" name="en" v-loading="loading">
          <el-row>
          <el-col :span="24">
            <el-form-item prop="seoTitle" label="自定义标题" label-width="120px" style="width: 500px;">
              <el-input
                v-model="form.seoTitle"
                placeholder="请输入标题"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="seoKeyword" label="自定义关键词" label-width="120px" style="width: 500px;">
              <el-input
                v-model="form.seoKeyword"
                placeholder="请输入关键词"
                maxlength="255" show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="seoDescription" label="自定义描述" label-width="120px" style="width: 500px;">
              <el-input
                v-model="form.seoDescription"
                placeholder="请输入自定义描述"
                maxlength="255" show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item prop="selectedIdList" label="配置页面" label-width="120px" style="width: 500px;" v-if="row.id">
              <el-cascader
                v-model="form.selectedIdList"
                :options="pageList"
                filterable
                @change="changePage"
                clearable
                disabled
              ></el-cascader>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item prop="seoUrl" label="页面路径" label-width="120px" style="width: 500px;" v-if="row.id">
              <el-input v-model="form.seoUrl" disabled> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
          <!-- <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="标题" prop="titleEn">
                <el-input v-model.trim="form.titleEn" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
               <el-col :span="24">
              <el-form-item label="作者" prop="articleAuthorEn" class="width_50">
                <el-input v-model.trim="form.articleAuthorEn" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="24">
              <el-form-item label="来源" prop="articleSourceEn"  class="width_50">
                <el-input v-model.trim="form.articleSourceEn" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
                <el-col :span="24">
              <el-form-item label="关键字" prop="keyword" class="width_50">
                <el-input v-model.trim="form.keyword" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="24" >
              <el-form-item label="发布时间" prop="title" class="width_50" label-width="80px">
               <el-date-picker
                        class="f-flex-1"
                        v-model="form.si"
                        type="datetime"
                        size="small"
                        placeholder="请选择时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%;"
                    >
                    </el-date-picker>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="24">
              <el-form-item label="封面" prop="coverFileEn">
                <f-upload v-model="form.coverFileEn" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 551*390</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="loading == false" label="内容" prop="textEn">
                <vue-ueditor-wrap class="ueditor_forest_box" v-model="form.textEn"
                  :config="editorConfig"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-tab-pane>
        <!-- <el-card header="公共部分" class="f-m-t-20">
          <el-row class="inputs_row">
            <el-col :span="8">
              <el-form-item label="类别" prop="articleTypeId">
                <el-select clearable filterable v-model="form.articleTypeId">
                  <el-option v-for="(item, index) in allArticleTypeList" :label="item.articleType" :value="item.id"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card> -->
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
  <el-dialog v-else-if="type == 'view'" title="预览" :visible="visible" custom-class="c-custom-dialog width_1200_dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-row>
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文简介" name="zh">
          <el-col :span="24">
            <div v-html="form.text"></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="英文简介" name="en">
          <el-col :span="24">
            <div v-html="form.textEn"></div>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction, getAction } from '@/api/manage'
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: 'NewsInformationDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    VueUeditorWrap
  },
  data() {
    const titleValidator = (rule, value, callback) => {
      let val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && !(/^(?![\d\w]*$)(?=.*[\u4e00-\u9fa5]).+$/.test(val))) {
        callback(new Error('标题必须包含中文，不能是纯数字或者纯字母'));
    }
    callback();
  };
    return {
      loading: true,
      activeName: 'zh',
      form: {},
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          {validator:titleValidator,trigger:'change'}
        ],
        titleEn: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        coverFile: [
          { required: true, message: '请选择封面', trigger: 'change' },
        ],
        coverFileEn: [
          { required: true, message: '请选择封面', trigger: 'change' },
        ],
        text: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        textEn: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        articleTypeId: [
          { required: true, message: '请选择文章类别', trigger: 'change' },
        ],
        seoTitle: [{ required: true, message: "请输入标题", trigger: "blur" }],
        seoKeyword: [
          { required: true, message: "请输入关键词", trigger: "change" },
        ],
        seoDescription: [
          { required: true, message: "请输入描述", trigger: "change" },
        ],
        selectedIdList: [
          { required: true, message: "请选择配置页面", trigger: "change" },
        ],
      },
      checkKey: {
        zh: ['title', 'coverFile', 'text'],
        en: ['titleEn', 'coverFileEn', 'textEn']
      },
      url: {
        add: '/sys/managerFind/add',
        edit: '/sys/managerFind/edit',
        detail: '/sys/managerFind/queryCusById',
        allList: '/sys/managerType/allList',
      },
      editorConfig: {
        UEDITOR_HOME_URL: "/static/UEditor/",
        serverUrl: "/static/UEditor/jsp/config.json"
      },
      allArticleTypeList: [],
      allArticleTypeListMap: {},
      pageList: [
        {
          value: "/home",
          label: "主页",
        },
        {
          value: "/product",
          label: "产品中心",
          children: [
            // {
            //   value: "/product/all",
            //   label: "",
            // },
            {
              value: "/fbahy",
              label: "海运服务",
            },
            {
              value: "/Projects/fbaqgfw",
              label: "清关服务",
            },
            {
              value: "/fbaky",
              label: "快空服务",
            },
            {
              value: "/product/overseas",
              label: "海外仓服务",
            },
            {
              value: "/product/productDetail",
              label: "产品详情",
            },
          ],
        },
        {
          value: "/aboutUs",
          label: "关于我们",
          children: [
            {
              value: "/aboutUs/companyProfile",
              label: "公司简介",
            },
            {
              value: "/aboutUs/teamIntroduction",
              label: "团队介绍",
            },
            {
              value: "/aboutUs/globalForest",
              label: "全球森仓",
            },
            {
              value: "/aboutUs/talentRecruitment",
              label: "人才招聘",
            },
            {
              value: "/lxdsl",
              label: "联系我们",
            },
          ],
        },
        {
          value: "/",
          label: "新闻资讯",
          children: [
            {
              value: "/new",
              label: "新闻",
            },
            {
              value: "/newDetail",
              label: "新闻详情",
            },
          ],
        },
        {
          value: "/cooperation",
          label: "合伙流程",
        },
        {
          value: "/wjxz2",
          label: "下载中心",
        },
        {
          value: "/Projects/ckwlcywz",
          label: "常用网站",
        },
      ],
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.selectPushPort();
        this.handleClick()
        if(this.row.id) {
          this.getDetail(this.row.id)
        } else {
          this.loading = false;
        }
      }
    }
  },
  methods: {
    selectPushPort() {
      const params = {
        pushPort: '1',
      };
      getAction(this.url.allList, params).then((response) => {
        //给allArticleTypeList数组赋值
        this.allArticleTypeList = response.result;
        this.allArticleTypeListMap = this.listToMap(this.allArticleTypeList, 'id')
      });
    },
    getDetail(id) {
      this.$store.commit("setFullscreenLoading", true);
      getAction(this.url.detail, { id: id }).then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.form = res.result;
        this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0] ? '' : this.form.coverFile = [];
        this.form.coverFileEn && this.form.coverFileEn.length && this.form.coverFileEn[0] ? '' : this.form.coverFileEn = [];
        this.loading = false;
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
        this.loading = false;
      })
    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if (this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.coverFile;
      delete params.coverFileEn;
      params.coverUrl = (this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0].url) || '';
      params.coverId = (this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0].id) || '';
      params.coverUrlEn = (this.form.coverFileEn && this.form.coverFileEn.length && this.form.coverFileEn[0].url) || '';
      params.coverIdEn = (this.form.coverFileEn && this.form.coverFileEn.length && this.form.coverFileEn[0].id) || '';
      params.pushPort = '1';
      params.articleType = this.form.articleTypeId && this.allArticleTypeListMap[this.form.articleTypeId] && this.allArticleTypeListMap[this.form.articleTypeId].articleType;
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            // let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写SEO配置';
            // this.$message.warning(message);
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.loading = true;
      this.form = {};
      this.activeName = 'zh';
      this.$refs.form && this.$refs.form.resetFields()
    },
    handleClick() {
      if(this.activeName=='en'){
        this.rules.title[0].required=false
        this.rules.coverFile[0].required=false
        this.rules.text[0].required=false
         this.rules.seoTitle[0].required=true
        this.rules.seoKeyword[0].required=true
        this.rules.seoDescription[0].required=true
      }else {
 this.rules.title[0].required=true
        this.rules.coverFile[0].required=true
        this.rules.text[0].required=true
        this.rules.seoTitle[0].required=false
        this.rules.seoKeyword[0].required=false
        this.rules.seoDescription[0].required=false
      }
    },
    changePage(val) {
      if (!val) return;
      // let api = "http://192.168.2.42:8080"; //本地
       let api = "http://120.25.172.133:9801"  //测试环境
      if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
        // 线上环境
        api = "https://do.do2do.com";
      }
      if (val && val.length == 1) {
        this.form.seoUrl = `${api}${val[0]}`;
      } else if (val && val.length > 1) {
        this.form.seoUrl = `${api}${val[1]}`;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.width_50 {
  width: 50%;
}

</style>
