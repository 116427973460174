<template>

  <el-dialog :title="type == 'log' ? '日志' : (row.id ? '编辑优惠促销' : '新增优惠促销')" :visible="visible" width="560px"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type == 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
      class="c-th-has-bgcolor" :columns="columns" :data="tableData" :show-operation="true"
      :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
      @sort-change="handleTableChange">
    </f-table>
    <el-form v-else :model="addForm" :rules="rules" ref="addForm" label-width="70px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="orderNo" label="订单号">
            <el-input v-model="addForm.orderNo" placeholder="请输入订单号" type="textarea" :disabled="Boolean(row.id)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="specialType" label="特批类型">
            <f-select :is-need="addForm.specialType" v-model="addForm.specialType" placeholder="请选择" filterable clearable
                      :dict="'discount_special_type'" >
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="typeId" label="优惠类型">
            <f-select :is-need="addForm.typeId" v-model="addForm.typeId" placeholder="请选择" filterable clearable
              :dict="'discount_type'" @changet="(val) => {$set(addForm, 'typeName', val)}">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="feeId" label="费用名称">
            <f-select :is-need="addForm.feeId" v-model="addForm.feeId" placeholder="请选择" filterable clearable
              :dict="'bd_fee_type'" @changet="(val) => {$set(addForm, 'feeName', val)}">
            </f-select>
          </el-form-item>
        </el-col>
         <el-col :span="24">
          <el-form-item prop="isSettle" label="录入页面">
                <el-radio-group v-model="addForm.isSettle"  :disabled="(row.id  && addForm.isInitSettleInfo === '1')">
                <el-radio label="0">应收账单</el-radio>
                <el-radio label="1">销售结算</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="price" label="价格">
            <el-input v-model.trim="addForm.price" placeholder="请输入价格" :maxlength="12" show-word-limit
              @change="handleCalculateTotal"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="quantity" label="数量">
            <el-input v-model.trim="addForm.quantity" placeholder="请输入数量" :maxlength="27" show-word-limit
              @change="handleCalculateTotal"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="total" label="合计">
            <el-input v-model.trim="addForm.total" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="curId" label="币种">
            <f-select :is-need="addForm.curId" v-model="addForm.curId" placeholder="请选择" filterable clearable
              :dict="'bd_cur'" @changet="(val) => {$set(addForm, 'curName', val)}">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item prop="discountNumber" label="促销编号">
            <el-input v-model.trim="addForm.discountNumber" placeholder="请输入促销编号" :maxlength="50"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="approvalNumber" label="审批编号">
            <el-input v-model.trim="addForm.approvalNumber" placeholder="请输入审批编号" :maxlength="50"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="remark" label="备注">
            <el-input v-model.trim="addForm.remark" placeholder="请输入备注" type="textarea" :maxlength="250"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item prop="isProfit" label="是否影响利润" label-width="102">
               <el-radio-group v-model="addForm.isProfit"   :disabled="(row.id  && addForm.isInitSettleInfo === '1' )">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { httpAction } from '@/api/manage'
  import QueryMixins from "@/mixins/QueryMixins.js";
  import { fuzhengshuxiaoshuValidator, validateCmb  } from "@/utils/validator"


  export default {
    name: 'EditDiscountTypeDialog',
    mixins: [QueryMixins],

    props: {
      visible: {
        type: Boolean
      },
      row: {
        type: Object,
        default: () => { }
      },
      // 弹窗类型
      type: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        disableMixinMounted: true,
        columns: [
          {
            prop: "content",
            label: "详情",
          },
          {
            prop: "createTime",
            label: "创建时间",
          },
          {
            prop: "createBy",
            label: "创建人",
          },
        ],
        addForm: {},
        rules: {
          orderNo: [
            { required: true, message: "请输入订单号", trigger: "blur" },
          ],
          typeId: [
            { required: true, message: "请选择优惠类型", trigger: "change" },
          ],
          feeId: [
            { required: true, message: "请选择费用名称", trigger: "change" },
          ],
          price: [
            { required: true, message: "请输入价格", trigger: "blur" },
            { validator: fuzhengshuxiaoshuValidator, trigger: 'change' }
          ],
          quantity: [
            { required: true, message: "请输入数量", trigger: "blur" },
            { validator: validateCmb, trigger: 'change' }
          ],
          curId: [
            { required: true, message: "请选择币种", trigger: "change" },
          ],
           isProfit: [
            { required: true, message: "请选择是否影响利润", trigger: "change" },
          ],
           isSettle: [
            { required: true, message: "请选择录入页面", trigger: "change" },
          ],
        },
        url: {
          add: "/jeecg-cloud-shipping-biz/ttOrderDiscounts/saveDiscount",
          edit: "/jeecg-cloud-shipping-biz/ttOrderDiscounts/updateDiscount",
          list: '/jeecg-cloud-shipping-biz/ttOrderDiscountLogs/page'
        },
      }
    },
    watch: {
      visible(val) {
        if (val && this.row.id) {
          if (this.type == 'log') {
            this.initData = {
              typeId: this.row.id,
            }
            this.handleReset();
          } else {
            console.log( this.row,' this.row');
            const { id, orderNo, typeId,specialType,isInitSettleInfo, typeName, feeId, feeName, price, quantity, total, curId, curName, discountNumber, approvalNumber, remark, settleId,isSettle,isProfit } = this.row
            this.addForm = { id, orderNo,isInitSettleInfo, typeId,specialType, typeName, feeId, feeName, price, quantity, total, curId, curName, discountNumber, approvalNumber, remark, settleId,isSettle,isProfit }
          }
        } else {
          this.addForm = {}
        }
      },

    },
    created() {
    },
    methods: {
      handleCalculateTotal() {
        console.log((parseFloat(this.addForm.price) || 0) * (parseFloat(this.addForm.quantity) || 0), '(parseFloat(this.addForm.price) || 0) * (parseFloat(this.addForm.quantity) || 0)')
        this.$set(this.addForm, 'total', ((parseFloat(this.addForm.price) || 0) * (parseFloat(this.addForm.quantity) || 0)).toFixed(3))
      },
      handleSubmit() {

        let url = this.url.add
        if (this.row.id) {
          url = this.url.edit
        }
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            let params = { ...this.addForm };
            console.log("aaa")
            console.log(params)
            // delete params.orderNo;
            // params.orderNos = this.addForm.orderNo.split(/[\n,]/g);
            console.log(params, 'params');
            this.$store.commit("setFullscreenLoading", true);
            httpAction(url, 'POST', params).then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose()
              this.$emit('ok')
            }).catch(err => {
              this.$store.commit("setFullscreenLoading", false);
            })
          }
        })
      },
      handleClose() {
        this.$emit('update:visible', false)
        this.$refs.addForm && this.$refs.addForm.resetFields()
      },
    }
  }
</script>

<style lang="scss" scoped></style>
