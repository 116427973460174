<template>
  <div>
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="结算对象">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.settlement"
                        @keyup.enter.native="handleClick"
                    ></el-input>
                  </el-form-item>
                </el-col>


              <el-col :span="6">
                <el-form-item label="录入时间" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.deliveredStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.deliveredEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="开船时间" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.shipmentBoardS"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.shipmentBoardE"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="费用录入人">
                  <f-select
                          :dict="'sys_user_id'"
                          v-model="queryForm.settleCreateById"
                          :is-need="queryForm.settleCreateById"
                          :father-val="queryForm.settleCreateById"
                          @changet="selectCreateBy"
                          @clear="blurForBug"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item prop="type" label="订单类型">
                  <el-select v-model="queryForm.countType">
                    <el-option value="settle_create_time" label="费用录入时间"></el-option>
                    <el-option value="shipment_board_time" label="开船时间"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleClick"
                        >查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <div>
        <el-button  type="primary" size="small"  @click="daochu">导出</el-button>
      </div>
      <el-table
          :data="tableData"
          v-loading="loading"
          style="width: 100%">
        <el-table-column
            type="index"
            label="序号"
        ></el-table-column>
        <el-table-column
            prop="settlement"
            label="结算对象"
        >
          <template slot-scope="scope">
            <a
                style="color: #00a0e9"
                @click.prevent="openDertail(scope.row)"
            >{{ scope.row.settlement }}</a>
          </template>
        </el-table-column>
        <el-table-column
            prop="freightway"
            label="付款类型"
        ></el-table-column>
          <el-table-column label="合计">
            <el-table-column
                    prop="toCnyjia"
                    label="折合人民币合计"
            ></el-table-column>
          </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="zeroToThirty"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column width="150px"
                  prop="toCny30"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="thirtyToSixty"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny60"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="sixtyToNinety"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny90"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="ninetyToHunTwenty"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny120"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="hunTwentyToHunFifty"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny150"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="hunFiftyToHunHunEighty"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny180"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="hunEightyToTwoHunTen"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny210"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="twoHunTenToTwoHunForty"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny240"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="twoHunFortyToTwoHunSeventy"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny270"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span v-html="twoHunSeventyBefore"></span> <!-- 使用 v-html 渲染 HTML -->
          </template>
          <el-table-column
                  width="150px"
                  prop="toCny120jia"
                  label="折合人民币合计"
          ></el-table-column>
        </el-table-column>
      </el-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <yingshou-dt
      :visible.sync="visible"
      :settlement="settlement"
      :settleId="settleId"
      :queryForm="queryForm"
      :payType="payType"
      @ok="handleOk"
    ></yingshou-dt>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';
import YingshouDt from "@/views/docpage/YingshouDt"
import {getAction} from "../../../../api/manage";


export default {
  name: "YingshouZD",
  mixins: [QueryMixins],
  components: {
    YingshouDt
  },
  data() {
    return {
      payType:"1",
      zeroToThirty:null,
      thirtyToSixty:null,
      sixtyToNinety:null,
      ninetyToHunTwenty:null,
      hunTwentyToHunFifty:null,
      hunFiftyToHunHunEighty:null,
      hunEightyToTwoHunTen:null,
      twoHunTenToTwoHunForty:null,
      twoHunFortyToTwoHunSeventy:null,
      twoHunSeventyBefore:null,
      disableMixinMounted: true,
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      proVisible: false,
      url: {
        list: "/order/yingshouzd/pageListPay",
        daochu: "/file/jasper/downloadPayExport"

      },
      issalesman: "0",
      settlement:'',
      settleId:'',
    };
  },
  created() {
    this.calculateTime();
  },

  methods: {
    blurForBug(){
      this.queryForm.settleCreateBy = null
      this.queryForm.settleCreateById = null
    },
    calculateTime(val){
      const date = val ?new Date(val):new Date();
      const currentDate = date.setDate(date.getDate());
      const thirtyDate = date.setDate(date.getDate() - 30);
      const thirtyOneDate = date.setDate(date.getDate() - 1);
      const sixtyDate = date.setDate(date.getDate() - 29);
      const sixtyOneDate = date.setDate(date.getDate() - 1);
      const ninetyDate = date.setDate(date.getDate() - 29);
      const ninetyOneDate = date.setDate(date.getDate() - 1);
      const hunTwentyDate = date.setDate(date.getDate() - 29);
      const hunTwentyOneDate = date.setDate(date.getDate() - 1);
      const hunFiftyDate = date.setDate(date.getDate() - 29);
      const hunFiftyOneDate = date.setDate(date.getDate() - 1);
      const hunEightyDate = date.setDate(date.getDate() - 29);
      const hunEightyOneDate = date.setDate(date.getDate() - 1);
      const towHunTenDate = date.setDate(date.getDate() - 29);
      const towHunTenOneDate = date.setDate(date.getDate() - 1);
      const towHunFortyDate = date.setDate(date.getDate() - 29);
      const towHunFortyOneDate = date.setDate(date.getDate() - 1);
      const towHunSeventyDate = date.setDate(date.getDate() - 29);
      this.zeroToThirty = '0-30天<br>' + this.getCurrentDate(currentDate) + '~'  + this.getCurrentDate(thirtyDate)
      this.thirtyToSixty = '31-60天<br>' + this.getCurrentDate(thirtyOneDate) + '~'  + this.getCurrentDate(sixtyDate)
      this.sixtyToNinety = '61-90天<br>' + this.getCurrentDate(sixtyOneDate) + '~'  + this.getCurrentDate(ninetyDate)
      this.ninetyToHunTwenty = '91-120天<br>' + this.getCurrentDate(ninetyOneDate) + '~'  + this.getCurrentDate(hunTwentyDate)
      this.hunTwentyToHunFifty = '121-150天<br>' + this.getCurrentDate(hunTwentyOneDate) + '~'  + this.getCurrentDate(hunFiftyDate)
      this.hunFiftyToHunHunEighty = '151-180天<br>' + this.getCurrentDate(hunFiftyOneDate) + '~'  + this.getCurrentDate(hunEightyDate)
      this.hunEightyToTwoHunTen = '181-210天<br>' + this.getCurrentDate(hunEightyOneDate) + '~'  + this.getCurrentDate(towHunTenDate)
      this.twoHunTenToTwoHunForty = '211天-240天<br>' + this.getCurrentDate(towHunTenOneDate) + '~'  + this.getCurrentDate(towHunFortyDate)
      this.twoHunFortyToTwoHunSeventy = '241天-270天<br>' + this.getCurrentDate(towHunFortyOneDate) + '~'  + this.getCurrentDate(towHunSeventyDate)
      this.twoHunSeventyBefore = '270天+<br>' + this.getCurrentDate(towHunSeventyDate) + ' 之前'
    },
    getCurrentDate(date) {
      let currentDate = new Date();
      if(date) currentDate = new Date(date);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    handleClick() {
      this.disabledFlag =true
      this.queryForm.payType = '1'
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else {
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }

      params.settleType = "1"

      getAction(this.url.list, params).then((res) => {
        if (res.success) {
          const {records, total} = res.result
          this.tableData = records || res.result;
          this.ipagination && (this.ipagination.total = total || res.result.length)
        }
      }).finally(() => {
        this.disabledFlag = false
        this.loading = false
      })
    },
    selectCreateBy(e){
      this.queryForm.settleCreateBy = e
    },

    handleOk(){
      this.visible=false;
    },
    openDertail(row){
      this.settlement = row.settlement;
      this.settleId = row.settleId;
      this.visible=true;
    },
    daochu(){

      //清空字符串的前后空格
      this.trimParamsStr(this.queryForm);
      this.trimParamsStr(this.filterForm);
      this.trimParamsStr(this.initData);
      this.downloadExcel(this.url.daochu, "应付账单账龄", this.queryForm);
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
