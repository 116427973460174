<!--已完成-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div style="display:flex;">
              <el-card class="box-card">
                <el-row :gutter="24" class="inputs_row">
                  <el-col :md="4" :sm="24">
                    <el-form-item label="订单号">
                      <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"   type="textarea"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="周数">
                        <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"
                        ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="客户">
                        <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"
                        ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="航线名称">
                          <f-select :dict="'fba_solicitation'"
                                    v-model="queryForm.solicitation"
                                    :isNeed="queryForm.solicitation"
                          >
                          </f-select>
                      </el-form-item>
                  </el-col>
                  
                  <el-col :md="4" :sm="24">
                      <el-form-item label="国家">
                          <f-select :dict="'bd_country'"
                                    v-model="queryForm.shipmentCountry"
                                    :isNeed="queryForm.shipmentCountry"
                          >
                          </f-select>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="拼柜仓库">
                      <f-select :dict="'lclPlace'"
                                v-model="queryForm.lclPlace"
                                :isNeed="queryForm.lclPlace"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
                    <el-form-item label="渠道名称">
                      <f-select
                          v-model="queryForm.transportStatus"
                          :isNeed="queryForm.transportStatus"
                          :dict="'transportStatus'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
                      <el-form-item label="仓库代码">
                        <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"
                        ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
                      <el-form-item label="邮编">
                        <el-input v-model="queryForm.shipmentZip" @keyup.enter.native="handleSearch"
                        ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
                    <el-form-item label="报关方式">
                      <f-select
                          v-model="queryForm.declareType"
                          :isNeed="queryForm.declareType"
                          :dict="'os_declare_type'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
                    <el-form-item label="发票状态">
                      <f-select
                          v-model="queryForm.invoiceStatus"
                          :isNeed="queryForm.invoiceStatus"
                          :dict="'invoice_status'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">
                    <el-form-item label="是否包税">
                      <f-select
                          v-model="queryForm.hasTaxType"
                          :isNeed="queryForm.hasTaxType"
                          :dict="'is_default'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">
                    <el-form-item label="是否入仓">
                      <f-select
                          v-model="queryForm.isRucang"
                          :isNeed="queryForm.isRucang"
                          :dict="'is_default'">
                      </f-select>
                    </el-form-item>
                  </el-col>
<!--                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">-->
<!--                    <el-form-item label="是否录材积">-->
<!--                      <f-select-->
<!--          addContainerSettel                v-model="queryForm.isCmb"-->
<!--                          :isNeed="queryForm.isCmb"-->
<!--                          :dict="'is_default'">-->
<!--                      </f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">
                    <el-form-item label="客户等级">
                      <f-select
                          v-model="queryForm.grade"
                          :isNeed="queryForm.grade"
                          :dict="'customer_type'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">
                    <el-form-item label="客服">
                      <f-select
                          v-model="queryForm.docId"
                          :isNeed="queryForm.docId"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">
                    <el-form-item label="操作员">
                      <f-select
                          v-model="queryForm.operateId"
                          :isNeed="queryForm.operateId"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">
                    <el-form-item label="中文品名">
                      <el-input v-model="queryForm.productNameCn" @keyup.enter.native="handleSearch"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" class="no_margin" :md="4" :sm="24">
                    <el-form-item label="排柜提醒">
                      <el-input v-model="queryForm.pgRemark" @keyup.enter.native="handleSearch"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" class="no_margin" :md="4" :sm="24">
                    <el-form-item label="材积录入人">
                      <f-select
                          v-model="queryForm.createBy"
                          :isNeed="queryForm.createBy"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
<!--                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                    <el-form-item label="清关属性">-->
<!--                      <f-select-->
<!--                          :dict="'qg_prop'"-->
<!--                          v-model="queryForm.qgProp"-->
<!--                          :is-need="queryForm.qgProp"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
<!--                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                    <el-form-item label="海外仓中转服务">-->
<!--                      <f-select-->
<!--                          :dict="'haiwai_service'"-->
<!--                          v-model="queryForm.haiwaiService"-->
<!--                          :is-need="queryForm.haiwaiService"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="锁国外仓租柜租">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockCangzu"
                          :is-need="queryForm.lockCangzu"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="锁国外查验">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockChayan"
                          :is-need="queryForm.lockChayan"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="三锁服务">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.sanLock"
                          :is-need="queryForm.sanLock"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="地址类型">
                      <f-select
                          :dict="'address_type'"
                          v-model="queryForm.shipmentType"
                          :is-need="queryForm.shipmentType"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="航线名称">
                      <f-select
                          :dict="'fba_solicitation'"
                          v-model="queryForm.solicitation"
                          :is-need="queryForm.solicitation"
                      ></f-select>
                    </el-form-item>
                  </el-col>
 <!--                 <el-col v-if="showLoadingSearch" class="no_margin" :md="4" :sm="24">
                    <el-form-item label="fbaCode">
                      <el-input v-model="queryForm.fbaCode" @keyup.enter.native="handleSearch"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" class="no_margin" :md="4" :sm="24">
                    <el-form-item label="zip">
                      <el-input v-model="queryForm.zip" @keyup.enter.native="handleSearch"
                      ></el-input>
                    </el-form-item>
                  </el-col>-->
                   <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="订单产品定义"  prop="sensitiveTypeList">
                <f-select
                    v-model="queryForm.sensitiveTypeList"
                    :isNeed="queryForm.sensitiveTypeList"
                    :multiple="true"
                    :dict="'order_sensitveType'"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="库位"  prop="locationCodes" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.locationCodes"
                    :isNeed="queryForm.locationCodes"
                    :multiple="true"
                    :dict="'warehouse_location_type'"
                ></f-select>
              </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="区域"  prop="partitionName" :label-width="queryFormLabelWidth">
                <el-input v-model="queryForm.partitionName" @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
                  </el-col>
                  <el-col class="no_margin"  :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                        >查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button>
                    </el-form-item>
                </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card">
          <div class="f-p-b-10">
            <el-button
                    type="primary"
                    size="medium"
                    @click="handledel({orderNo: selectedRowOrderNos.join(',')})"
                    :disabled="this.selectedRowOrderNos.length ==0 || isOrderStatus"
            >批量踢柜
            </el-button>
          <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              @click="handleExportXls('排柜协同表')"
              v-if="buttonShowList.dcpgxt"
          >导出排柜协同</el-button>

            <el-button type="primary" size="medium" icon="el-icon-plus"
                       v-if="buttonShowList.dccy"
                       @click="exportExcelPlus(url.exportInspection,'查验报表')">导出查验</el-button>
            <el-button size="medium" type="primary" :disabled="selectedRowOrderNos.length === 0" @click="$intoDocPageBatch(selectedRowOrderNos)"
                  v-if="(buttonShowList.pldkdd && $route.path == '/docpage/AlreadyCabinet') ||
                  (buttonShowList.pldkdd && $route.path == '/operation/cabinet/AlreadyCabinet')">批量打开订单
            </el-button>
            <el-button size="medium" type="primary" :disabled="selectedRowOrderNos.length === 0" @click="WaitingCabinet" v-if="buttonShowList['一键转排']">一键转排
            </el-button>
          </div>
          <div class="c-alert f-m-b-10">
              已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
              <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
          </div>


            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="orderNo"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    :tableHeight="showLoadingSearch ? '47vh' : '61vh'"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button :disabled="scope.row.orderStatus >= 8" type="text" @click="handledel({orderNo: scope.row.orderNo})">踢柜</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                          <!--                        如果材积信息已有 也是已入仓-->
                        <a v-if="scope.row.isRucang === '1' || (scope.row.realCmb && Number(scope.row.realCmb)>0)"
                           style="color: red" 
                           @click.prevent="$intoDocPage(scope.row.orderNo)"
                        ><span :class="{'bg_class': scope.row.hasGuanwu == '1'}">{{ scope.row.orderNo }}</span>(已入仓)</a>
                        <a v-else
                           style="color: green"
                           @click.prevent="$intoDocPage(scope.row.orderNo)"
                         
                        ><span :class="{'bg_class': scope.row.hasGuanwu == '1'}">{{ scope.row.orderNo }}</span>(未入仓)</a>
                         <i class="el-icon-question" @click="lockRecord(scope.row.orderNo)" v-if="scope.row.hasGuanwu == '1'"></i>
                    </template>
                    <template v-else-if="scope.column.property === 'declareType'">
                        <span>{{ parseDict(scope.row.declareType, "os_declare_type") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'invoiceStatus'">
            <span>{{
              parseDict(scope.row.invoiceStatus, "invoice_status")
            }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
              parseDict(scope.row.transportStatus, "transportStatus")
            }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'username'">
                      <a type="text" @click.prevent="handleEdit(scope.row)" style="color:#57a3f3;"><span>{{scope.row.username}}</span><span v-if="scope.row.isClothing === '1'" style="color: red">（纺）</span></a>
                    </template>
                    <template v-else-if="scope.column.property === 'grade'">
                        <span>{{ parseDict(scope.row.grade, "customer_type") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'docId'">
                        <span>{{ parseDict(scope.row.docId, "sys_user_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                        <span>{{ parseDict(scope.row.operateId, "sys_user_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isVirtualCabinet'">
                        <span v-if="scope.row.isVirtualCabinet == '0'">自拼</span>
                        <span v-if="scope.row.isVirtualCabinet == '1'">非自拼</span>
                    </template>
                    <template v-else-if="scope.column.property === 'solicitation'">
                        <span v-if="scope.row.solicitation ==='1'"></span>
                        <span v-else>{{parseDict(scope.row.solicitation,'fba_solicitation')}}</span>
                    </template>
                    <!--                    拼柜仓库-->
                    <template v-else-if="scope.column.property === 'lclPlace'">
                        <span>{{parseDict(scope.row.lclPlace,'lclPlace')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isRucang'">
                        <span v-if="scope.row.isRucang==1">已入仓</span>
                        <span v-else>未入仓</span>
                    </template>
                    <!--                    是否包税-->
                    <template v-else-if="scope.column.property === 'hasTaxType'">
                        <span v-if="scope.row.hasTaxType ==='0'">未包税</span>
                        <span v-else>已包税</span>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" @click.prevent="infomation(scope.row.containerId)">
                            {{scope.row.week}}</a>
                    </template>
                    <!-- 实际小于预计标红，大于或者等于标绿 -->
                    <template v-else-if="scope.column.property === 'realCtn'">
                        <span :style="(scope.row.realCtn < scope.row.ctn) ? 'color: red' : 'color: green'">{{ scope.row.realCtn }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'realCmb'">
                        <span :style="(scope.row.realCmb < scope.row.cmb) ? 'color: red' : 'color: green'">{{ scope.row.realCmb }}</span>
                    </template>
                  <template v-else-if="scope.column.property === 'isWarning'">
                    <span v-if="scope.row.isWarning === '1'" style="color: red">是</span>
                    <span v-else >否</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <customs-record-dialog :visible="recordVisible" :orderNo='lockOrderNo'  @update:visible="
        (val) => {
          recordVisible = val;
        }
      "/>

<el-dialog
            title="转排"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
    >
      <el-form :model="changeContainerForm" ref="addCabinetFormTwo" :rules='rules'>
        <el-form-item
                label="件/重/体（合计）"
                label-width="140px"
        >
        {{total.ctn.toFixed(3)}}/{{ total.kg.toFixed(3) }}/{{ total.cmb.toFixed(3) }}
      </el-form-item>

        <el-form-item
                label="周数"
                label-width="140px"
                prop="containerId"
        >
          <el-select
                  v-model="changeContainerForm.containerId"
                  placeholder="请选择"
                  filterable
                  clearable
                  style="width: 50%"
                  @click.native="init"
          >
            <el-option
                    v-for="item in weekList"
                    :key="item.id"
                    :label="item.week"
                    :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false; changeContainerForm={}"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="handleChangeContainer"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
    </div>
</template>

<script>
    import {getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                showLoadingSearch: false,
                dictCodes: [
                    "os_declare_type",
                    "invoice_status",
                    "transportStatus",
                    "customer",
                    "cus_important",
                    "sys_user_id",
                    "customer_type",
                    "fba_solicitation",
                    "lclPlace",
                    "os_container"
                ],
                recordVisible:false,
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: "action",
                        label: "操作",
                        minWidth: 80,
                        fixed: 'left'
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                        fixed: 'left'
                    },
                    {
                        prop: "week",
                        label: "周数",
                        minWidth: 100,
                        fixed: 'left'
                    },
                    {
                        prop: "username",
                        label: "客户",
                        minWidth: 100,
                        fixed: 'left'
                    },
                  {
                    prop: "isWarning",
                    label: "是否问题件",
                    fixed: 'left'
                  },
                    {
                        prop: "solicitation",
                        label: "航线名称",
                        minWidth: 100,
                    },
                    {
                        prop: "shipmentCountry",
                        label: "国家",
                        minWidth: 100,
                    },
                    {
                        prop: "lclPlace",
                        label: "拼柜仓库",
                        minWidth: 80,
                    },
                    {
                        prop: "transportStatus",
                        label: "渠道名称",
                        minWidth: 100,
                    },
                    {
                        prop: "shipmentCode",
                        label: "仓库代码",
                        minWidth: 80,
                    },
                       {
          prop: "sensitiveType",
          label: "订单产品定义",
          minWidth: 80,
        },
                    {
                        prop: "shipmentZip",
                        label: "邮编",
                        minWidth: 80,
                    },
                    {
                        prop: "realCtn",
                        label: "实际入仓件数",
                        headerType: "rang",
                        minWidth: 60,
                        sortable: "realCtn",
                    },
                    {
                        prop: "ctn",
                        label: "预报件数",
                        headerType: "rang",
                        minWidth: 60,
                        sortable: "ctn",
                    },
                    {
                        prop: "kg",
                        label: "预报毛重",
                        headerType: "rang",
                        minWidth: 60,
                        sortable: "kg",
                    },
                    {
                        prop: "cmb",
                        label: "预报体积",
                        headerType: "rang",
                        minWidth: 60,
                        sortable: "cmb",
                    },
                    {
                        prop: "realCmb",
                        label: "实际体积",
                        headerType: "rang",
                        minWidth: 60,
                        sortable: "realCmb",
                    },
                     {
          prop: "allVolumeLocation",
          label: "货物所在仓",
        },
                    {
                        prop: "declareType",
                        label: "报关方式",
                        minWidth: 60,
                    },
                    {
                        prop: "invoiceStatus",
                        label: "发票状态",
                        minWidth: 50,
                    },
                    {
                        prop: "hasTaxType",
                        label: "是否包税",
                        minWidth: 50,
                    },
                    {
                        prop: "isRucang",
                        label: "是否入仓",
                        minWidth: 50,
                    },
                    {
                        prop: "cargoArrived",
                        label: "入仓时间",
                        minWidth: 100,
                        sortable: "custom",
                    },
                    {
                        prop: "location",
                        label: "库位",
                    },
                    {
                        prop: "partitionName",
                        label: "区域",
                    },
                    {
                        prop: "grade",
                        label: "客户等级",
                        minWidth: 50,
                    },
                    {
                        prop: "docId",
                        label: "客服",
                        minWidth: 80,
                    },
                    {
                        prop: "operateId",
                        label: "操作员",
                        minWidth: 80,
                    },
                    {
                        prop: "productNameCn",
                        label: "中文品名",
                        minWidth: 80,
                        overFlow: true,
                    },
                    // {
                    //     prop: "cargoArrived",
                    //     label: "入仓时间",
                    //     headerType: "datetime",
                    //     minWidth: 215,
                    //     sortable: "custom",
                    // },

                    {
                        prop: "pgRemark",
                        label: "排柜提醒",
                        minWidth: 100,
                    },
                    {
                        prop: "shipmentState",
                        label: "地址",
                        headerType: "rang",
                        minWidth: 100,
                        sortable: "custom",
                    },
                    {
                        prop: "withinOrderRemark",
                        label: "内部备注",
                        headerType: "rang",
                        minWidth: 100,
                        sortable: "custom",
                    },
                    {
                        prop: "storehouseRemark",
                        label: "入仓备注",
                        headerType: "rang",
                        minWidth: 100,
                        sortable: "custom",
                    },

                    {
                        prop: "createBy",
                        label: "材积录入人",
                        minWidth: 80,
                    },
                   /* {
                        prop: "fbaCode",
                        label: "fbaCode",
                        minWidth: 80,
                    },
                    {
                        prop: "zip",
                        label: "zip",
                        minWidth: 80,
                    },*/
                    // {
                    //     prop: "isVirtualCabinet",
                    //     label: "是否自拼",
                    //     headerType: "string",
                    //     minWidth: 180,
                    //     sortable: "isVirtualCabinet",
                    // },
                ],
                url: {
                    list: "/operation/container/alreadyCabinetList", //已排柜列表
                    orderUpdate: '/operation/container/orderUpdate', //踢柜 批量踢柜
                    exportInspection:"/file/export/excel/exportInspection",    //导出已查验
                    exportXlsUrl: "/file/export/excel/downloadPGXTExcel",// 导出排柜协同 暂时放在已排柜这里
                    queryCtnKgCmb:"/operation/container/queryCtnKgCmb",
                    changeContainer:"/operation/container/changeContainer",
                   // exportXlsUrl: "/file/export/excel/SalesExcel/downloadSaleDepartmentExportXls",
                   cabinetList: "/operation/container/cabinetList",
                },
                selectedRowOrderNos: [],
                selectedRowKeys: [],
                isOrderStatus: false, //批量踢柜是否禁用
                isUsePost:true,
                lockOrderNo:'',
                dialogFormVisible:false,
                changeContainerForm:{},
                weekList: [],
                total:{
                  ctn:0,
                  kg:0,
                  cmb:0
                },
                rules:{
                  containerId:[{
                   required: true, message: '请选择周数', trigger: 'change'
                  }]
                }
            };
        },
        methods: {
            handleEdit({id}) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?id=${id}`)
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            handleSelectionChange(selection) { // 多选
                this.isOrderStatus = false;
                let arr = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['orderNo'])
                    if(selection[i].orderStatus >= 8) {
                      this.isOrderStatus = true;
                    }
                }
                this.selectedRowOrderNos = [...arr];
                this.selectedRowKeys = [...arr];
                this.selectionRows = selection
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },
            handledel(row) {
                console.log(row, '踢柜')
                putAction(this.url.orderUpdate, row)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            lockRecord(orderNo) {
              this.lockOrderNo = orderNo;
              this.recordVisible = true
            },
            WaitingCabinet() {
              this.total.ctn = 0,
              this.total.kg = 0,
              this.total.cmb = 0;
              this.changeContainerForm ={};
              this.selectionRows.forEach(item => {
                this.total.ctn+=Number(item.ctn);
                this.total.kg+=Number(item.kg);
                this.total.cmb+=Number(item.cmb);
              });
              this.dialogFormVisible = true;
            },
    handleChangeContainer() {
      this.$refs['addCabinetFormTwo'].validate((valid) => {
        if (valid) {
          putAction(this.url.changeContainer, {
            ...this.changeContainerForm,
            orderNo:this.selectedRowOrderNos.join(',')
          })
              .then((res) => {
                if (res.success) {
                  this.dialogFormVisible = false;
                  this.$message.success(res.message);
                  this.handleQuery();
                }
       })
        }
      });
    
    },
    init() {
      //获取周期
      getAction(this.url.cabinetList)
              .then((res) => {
                console.log(res);
                if (res.success) {
                  this.weekList = res.result;
                  // that.weekList = that.weekList.filter(function(obj){
                  //   return obj.id != that.$route.query.id;
                  // });
                }
              })
    },

        },
        created() {
        },
      computed:{
        buttonShowList(){
          return {
            'dcpgxt':this.$btnIsShow('operation/cabinet/AlreadyCabinet','0','导出排柜协同'),
            'dccy':this.$btnIsShow('operation/cabinet/AlreadyCabinet','0','导出查验'),
            'pldkdd':this.$btnIsShow('docpage/AlreadyCabinet','0','批量打开订单'),
            '一键转排':this.$btnIsShow('docpage/AlreadyCabinet','0','一键转排'),


          }
        }
      }
    };
</script>

<style lang="scss" scoped>
.bg_class {
  background-color: yellow;
  color: #606266;
}
</style>
