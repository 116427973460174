<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item label="分库/分区名">
              <el-input v-model="queryForm.partitionName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="地址类型">
              <f-select :dict="'order_shipment_type'" v-model="queryForm.type" :is-need="queryForm.type"></f-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="地址编码">
              <el-input v-model="queryForm.addressName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="国家">
              <f-select clearable filterable v-model="queryForm.country" :isNeed="queryForm.country"
                :dict="'bd_country'" placeholder="请选择">
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="邮编">
              <el-input v-model="queryForm.zipcode" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="目的港">
              <f-select clearable filterable v-model="queryForm.portCode" :isNeed="queryForm.portCode"
                :dict="'hy_bd_port_code'" placeholder="请选择">
              </f-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :span="4">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-upload action="/" :show-file-list="false" :http-request="uploadSectionFile" accept=".xls,.xlsx"
          v-if="buttonShowList.导入仓库分区" style="margin: 0 10px; display: inline">
          <el-button size="medium" type="primary">导入仓库分区</el-button>
        </el-upload>
        <el-button type="primary" size="medium" @click="downloadModel(url.downloadAddressPartition, '仓库分区模版')"
          v-if="buttonShowList.模版下载">模版下载</el-button>
        <el-button type="primary" size="medium" @click="handleDel" v-if="buttonShowList.删除">删除</el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="66vh"
        @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">

          <template v-if="scope.column.property === 'type'">
            {{ parseDict(scope.row.type, 'order_shipment_type') }}
          </template>
          <template v-else-if="scope.column.property === 'portCode'">
            {{ parseDict(scope.row.portCode, 'hy_bd_port_code') }}
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>


  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { postAction } from "@/api/manage";

export default {
  name: "BascilDataManagement",
  mixins: [QueryMixins],
  components: {
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "partitionName",
          label: "仓库分区",
        },
        {
          prop: "type",
          label: "地址类型",
        },
        {
          prop: "addressName",
          label: "地址编码",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "state",
          label: "省州",
        },
        {
          prop: "city",
          label: "城市",
        },
        {
          prop: "address",
          label: "详细地址",
        },
        {
          prop: "zipcode",
          label: "邮编",
        },
        {
          prop: "portCode",
          label: "目的港",
        },
      ],
      url: {
        list: "/sys/address/partition/getAddressPartitionList",
        delete: "/sys/address/partition/batchDelete",
        importData: "/sys/address/partition/importData", //导入
        downloadAddressPartition:
          "/file/download/model/downloadAddressPartition", //模版下载
      },
      isUsePost: true,
      dictCodes: ['order_shipment_type', 'hy_bd_port_code']
    };
  },
  methods: {
    handleDel() {
      postAction(this.url.delete, this.selectedRowKeys
      ).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importData, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.handleQuery();
        }
      });
    },
  },
  computed: {
    buttonShowList() {
      return {
        导入仓库分区: this.$btnIsShow(
          "isystem/BaseData/WarehousePartition",
          "0",
          "导入仓库分区"
        ),
        模版下载: this.$btnIsShow("isystem/BaseData/WarehousePartition", "0", "模版下载"),
        删除: this.$btnIsShow("isystem/BaseData/WarehousePartition", "0", "删除"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.over-content {
  width: 80;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
