<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card ">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户名称">
<!--                            <f-select-->
<!--                                v-model="queryForm.cusIds"-->
<!--                                :is-need="queryForm.cusIds"-->
<!--                                :multiple="true"-->
<!--                                :dict="'follow_and_normal_cus_id'"-->
<!--                                @keyup.enter.native="handleSearch">-->
<!--                            </f-select>-->
                          <el-input v-model="queryForm.username" type="textarea" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanId" :isNeed="queryForm.salesmanId" :dict="'sys_user_id'"
                                      @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="source" label="客户来源">
                            <f-select clearable filterable class="f-full-width" v-model="queryForm.source" :isNeed="queryForm.source" :dict="'cus_source'"></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="地址">
                      <el-input v-model="queryForm.address" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="客户需求">
                      <f-select
                          v-model="queryForm.need"
                          :dict="'customer_need'"
                          :isNeed="queryForm.need"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="注册时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @keyup.enter.native="handleSearch">
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @keyup.enter.native="handleSearch">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="邮箱">
                        <el-input
                            type="textarea"
                            class="textarea_table"
                            v-model="queryForm.email"
                            @keyup.enter.native="handleSearch"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    v-if="buttonShowList.backB"
                    @click="setCustomerToBack(selectedRowKeys)"
                    :disabled="selectedRowKeys.length===0"
                >批量退回</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-download"
                    v-if="buttonShowList.dc"
                    @click="exportExcelPlus(url.exportExcelUrl,'六个月未下单客户')"
                >导出</el-button>
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="58vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'grade'">
                        <template v-if="scope.row.grade ==='0'">VIP客户</template>
                        <template v-if="scope.row.grade ==='1'">重点客户</template>
                        <template v-if="scope.row.grade ==='2'">一般客户</template>
                        <template v-if="scope.row.grade ==='3'">潜在客户</template>
                        <template v-if="scope.row.grade ==='4'">新客户</template>
                        <template v-else></template>
                    </template>
                    <template v-else-if="scope.column.property === 'source'">
                        <template v-if="scope.row.source ==='0'">在线咨询</template>
                        <template v-if="scope.row.source ==='1'">电话咨询</template>
                        <template v-if="scope.row.source ==='2'">营销QQ</template>
                        <template v-if="scope.row.source ==='33'">推广注册</template>
                        <template v-if="scope.row.source ==='3'">邮件咨询</template>
                        <template v-if="scope.row.source ==='4'">客户介绍</template>
                        <template v-if="scope.row.source ==='5'">展会资源</template>
                        <template v-if="scope.row.source ==='6'">独立开发</template>
                        <template v-if="scope.row.source ==='7'">注册会员</template>
                        <template v-if="scope.row.source ==='8'">fbashipping</template>
                        <template v-if="scope.row.source ==='9'">support</template>
                        <template v-if="scope.row.source ==='10'">forest+</template>
                        <template v-if="scope.row.source ==='11'">展会</template>
                        <template v-if="scope.row.source ==='12'">1688客服</template>
                        <template v-if="scope.row.source ==='13'">Amazon123</template>
                        <template v-if="scope.row.source ==='14'">Facebook</template>
                        <template v-if="scope.row.source ==='15'">Twitter</template>
                        <template v-if="scope.row.source ==='16'">Google搜索</template>
                        <template v-if="scope.row.source ==='17'">百度推广</template>
                        <template v-if="scope.row.source ==='18'">领英</template>
                        <template v-if="scope.row.source ==='19'">公司推荐</template>
                        <template v-if="scope.row.source ==='20'">FREIGHTOS</template>
                        <template v-if="scope.row.source ==='21'">Amazon 国内spn</template>
                        <template v-if="scope.row.source ==='25'">同事推荐</template>
                        <template v-else></template>
                    </template>
                    <template v-else-if="scope.column.property === 'commission'">
                        <template v-if="scope.row.commission ==='0'">自行开发</template>
                        <template v-if="scope.row.commission ==='1'">公司客户</template>
                        <template v-if="scope.row.commission ==='2'">团体客户</template>
                        <template v-if="scope.row.commission ==='3'">扶持区域</template>
                        <template v-if="scope.row.commission ==='4'">新开发同行</template>
                        <template v-if="scope.row.commission ==='5'">公司客户同行</template>
                        <template v-if="scope.row.commission ==='7'">特价客户</template>
                        <template v-else></template>
                    </template>

                    <template v-else-if="scope.column.property === 'regType'">
                        <template v-if="scope.row.regType ==='0'">企业</template>
                        <template v-if="scope.row.regType ==='1'">个人</template>

                        <template v-else></template>
                    </template>
                    <template v-else-if="scope.column.property === 'username'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.id)" style="color:#57a3f3;">{{scope.row.username}}</a>
                    </template>

                    <template v-else-if="scope.column.property === 'hasEmail'">
                        <template v-if="scope.row.sendEmailSixMonthNum >0"><span style="color: green">已发送</span></template>
                        <template v-else><span style="color: red">未发送</span></template>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"
    import AssignDialog from "@/views/customer/modules/AssignDialog";
    import {setUpRouteUtil} from '@/utils/util';


    export default {
        name: 'CusTransConRate',
        mixins: [QueryMixins],
        data() {
            return {
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'username',
                        label: '客户名',
                    },
                    {
                        prop: 'status',
                        label: '客户状态',
                    },
                    {
                        prop: 'hasEmail',
                        label: '是否发送邮件',
                    },
                    {
                        prop: 'name',
                        label: '联系人',
                    },
                    {
                        prop: 'email',
                        label: '邮箱',
                    },
                    {
                        prop: 'grade',
                        label: '客户等级',
                    },
                    {
                        prop: 'source',
                        label: '客户来源',
                    },
                    {
                        prop: 'commission',
                        label: '提成性质',
                    },
                    {
                        prop: 'follow',
                        label: '跟进人',
                    },
                    {
                        prop: 'doc',
                        label: '客服',
                    },
                    {
                        prop: 'billman',
                        label: '收款人',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'payType',
                        label: '付款方式',
                    },
                    {
                        prop: 'regType',
                        label: '注册性质',
                    },
                    {
                        prop: 'lastOrderTime',
                        label: '最后下单时间',
                    },
                    {
                        prop: 'createTime',
                        label: '注册时间',
                    },
                    {
                        prop: 'allotTime',
                        label: '分配时间',
                    },
                    {
                        prop: 'passTimeStart',
                        label: '审核时间',
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                    },

                ],
                url: {
                    list:'/customer/statisticalReports/queryCusOverSixMonthNoOrderList',
                    exportExcelUrl:'/file/export/excel/downloadCusOverSixMonthNoOrderExcel',
                    setCustomerToBack:'/customer/statisticalReports/setCustomerListToGonghai',
                },
                isUsePost:true,
            }
        },
        methods: {
            setCustomerToBack(list){
                let isArrears = false, ArrearsUserName = [];
                this.selectionRows.forEach(item => {
                    if(item.haveDebt == '1') {
                        isArrears = true;
                        ArrearsUserName.push(item.username);
                    }
                });
                if(isArrears) {
                    this.$message.warning( `【${ArrearsUserName.join(",")}】存在欠款的情况，无法退回公海`);
                    return;
                }
                this.$confirm(`确定将这些客户退回公海吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                }) .then(()=>{
                    postAction(this.url.setCustomerToBack,list)
                    .then(res=>{
                        this.$message.success(res.message);
                        this.handleQuery();
                    })
                })
            }
        },
        created(){
            this.initData.month=6;
        },
      computed : {
        buttonShowList() {
          return {
            'backB':this.$btnIsShow('customer/CusOverSixMonthNoOrder','0','批量退回'),
            'dc':this.$btnIsShow('customer/CusOverSixMonthNoOrder','0','导出'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
