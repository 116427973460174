<!--跟进中-->
<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="供应商中文名">
              <el-input v-model="queryForm.chinaseName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="供应商英文名">
              <el-input v-model="queryForm.englishName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5" hidden="hidden">
            <el-form-item label="合作类型">
              <el-input v-model="queryForm.cooperationType" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="供应商类型">
              <f-select
                v-model="queryForm.supplierType"
                :is-need="queryForm.supplierType"
                :data-list="queryDictCodeListMap['sup_type']"
                placeholder="请选择"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="添加人">
              <f-select
                clearable
                filterable
                class="f-full-width"
                :data-list="queryDictCodeListMap['sys_user']"
                v-model="queryForm.createBy"
                :is-need="queryForm.createBy"
                placeholder="请选择"
                style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          @click="handleBatDel"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.delB"
        >批量删除
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="underwayVerifyBatch"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.shB"
        >
          批量审核
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.bj"

            >编辑</el-button>
            <el-button type="text" @click="handleDele(scope.row)"
                       v-if="buttonShowList.sc"

            >删除</el-button>
            <el-button type="text" @click="handleShenhe(scope.row)"
                       v-if="buttonShowList.sh"

            >审核</el-button>
          </template>
          <template v-else-if="scope.column.property === 'settlementType'">
            <span>{{ parseDict2(scope.row.settlementType, "sup_settle") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'supplierType'">
            <span>{{ parseDict2(scope.row.supplierType, "sup_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'supplierNature'">
            <span>{{
              parseDict2(scope.row.supplierNature, "supplier_nature")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'salesmanId'">
            <span>{{parseDict2(scope.row.salesmanId,'all_user_id')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'chinaseName'">
            <a style="color: #00a0e9" @click.prevent="handleEdit(scope.row)">{{scope.row.chinaseName}}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog title="审核" :visible.sync="dialogFormVisibleShenhe">
      <el-form :model="form">
        <el-form-item label="拒绝原因" :label-width="formLabelWidth">
          <el-input v-model="form.remarks" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleShenhe = false">取 消</el-button>
        <el-button type="primary" @click="refuse">拒 绝</el-button>
        <el-button type="primary" @click="underwayVerify">通 过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, getAction, postAction } from "@/api/manage";
import QueryMixins from "../../mixins/QueryMixins";
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      needQueryDictCodes: ["sup_type", "supplier_nature", "sup_settle",'all_user_id','sys_user'],
      ids: [],
      userlist: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      dialogFormVisibleShenhe: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: 'left'
        },
        {
          prop: "action",
          label: "操作",
          width: 140,
          fixed: 'left'
        },
        {
          prop: "supplierNature",
          label: "性质",
          sortable: "custom",
          width: 140,
          fixed: 'left'
        },
        {
          prop: "chinaseName",
          label: "供应商中文名",
          sortable: "custom",
          width: 140,
          fixed: 'left'
        },
        {
          prop: "englishName",
          label: "供应商英文名",
          sortable: "custom",
          width: 140,
        },
        {
          prop: "settlementType",
          label: "结算方式",
          sortable: "custom",
          width: 130,
        },
        {
          prop: "supplierType",
          label: "供应商类型",
          sortable: "custom",
          width: 140,
        },
        {
          prop: "contact",
          label: "联系人",
          sortable: "custom",
          width: 120,
        },
        {
          prop: "tel",
          label: "联系电话",
          sortable: "custom",
          width: 140,
        },
        {
          prop: 'landline',
          label: '座机',
          sortable: 'custom',
          width: 140
        },
        {
          prop: "email",
          label: "邮箱",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "country",
          label: "国家",
          sortable: "custom",
          width: 100,
        },
        {
          prop: "registerAddress",
          label: "详细地址",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "advantageRoute",
          label: "优势航线",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "remarks",
          label: "备注",
          sortable: "custom",
        },
        {
          prop: 'salesmanId',
          label: '跟进人',
          sortable: 'custom',
          width: 140
        },
        {
          prop: 'recommendBy',
          label: '推荐人',
          sortable: 'custom',
          width: 140
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
          width: 140,
        },
        {
          prop: 'sort',
          label: '排序',
          sortable: 'custom',
          width: 140
        },
      ],
      url: {
        list: "/sys/supplier/list",
        edit: "/sys/supplier/edit",
        getById: "/sys/supplier/queryById",
        delete: "/sys/supplier/delete",
        deleteBatch: "/sys/supplier/deleteBatch",
        underwayVerify: "/sys/supplier/underwayVerify", //跟进中审核
        underwayVerifyBatch: "/sys/supplier/underwayVerifyBatch", //跟进中批量审核
        refuse: "/sys/supplierOperatorHis/add",
      },
    };
  },
  methods: {
    init() {
      this.queryForm.cooperationType = "1";
    },
    handleEdit(row) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/supplier/Edit');
      this.$router.push(`/supplier/Edit?id=${row.id}`);
    },
    //审核拒绝
    refuse() {
      //添加操作历史记录
      let remarks = this.form.remarks;

      if (!remarks) {
        this.$message.error("请输入原因");
      } else if (remarks.length > 255) {
        this.$message.error("长度不能超过255");
      } else {
        postAction(this.url.refuse, {
          supplierId: this.form.id,
          remarks: this.form.remarks,
          operatorType: "11",
        }).then((res) => {
          this.$message.success(res.message);
        });
        this.dialogFormVisibleShenhe = false;
        this.handleQuery();
      }
      //关闭弹窗
    },
    handleDele(row) {
      this.$confirm(`确定删除供应商：${row.chinaseName}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        });
    },
    //批量删除
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    //点击审核，显示弹窗
    handleShenhe(row) {
      getAction(this.url.getById, { id: row.id })
        .then((res) => {
          this.form = res.result;
        })
      this.dialogFormVisibleShenhe = true;
    },
    //跟进中审核通过
    underwayVerify() {
      postAction(this.url.underwayVerify, this.form)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            //关闭弹窗
            this.dialogFormVisibleShenhe = false;
            this.handleQuery();
          }
        })
    },
    //跟进中批量审核通过
    underwayVerifyBatch() {
      this.$confirm("确定批量审核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.underwayVerifyBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleReset() {
      this.queryForm = {};
      this.init();
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  created() {
    this.init();
    this.queryForm.initUser = 1;
  },
  computed:{
    buttonShowList() {
      return {
        'sh':this.$btnIsShow('supplier/FollowUp','1','审核'),
        'sc':this.$btnIsShow('supplier/FollowUp','1','删除'),
        'bj':this.$btnIsShow('supplier/FollowUp','1','编辑'),
        'shB':this.$btnIsShow('supplier/FollowUp','0','批量审核'),
        'delB':this.$btnIsShow('supplier/FollowUp','0','批量删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input_top .el-form-item {
  margin-bottom: 2px;
}
</style>
