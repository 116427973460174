<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="柜号">
                <el-input v-model="queryForm.containerNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="订单号">
                <el-input v-model="queryForm.orderNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="状态">
                <el-select v-model="queryForm.status"
                           filterable
                           clearable
                           @keyup.enter.native="handleSearch">
                  <el-option value="0" label="未上传"></el-option>
                  <el-option value="1" label="已上传"></el-option>
                  <el-option value="2" label="无需上传"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="6" :md="6" :lg="3">
            <el-form-item prop="operateId" label="排柜操作"  key="operateId">
              <f-select v-model="queryForm.operateId" :isNeed="queryForm.operateId" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="3">
            <el-form-item prop="docOperateId" label="文件操作"  key="docOperateId">
              <f-select :is-need="queryForm.docOperateId" v-model="queryForm.docOperateId" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
                      <el-form-item label="放行时间">
                        <div class="f-flex">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.shipmentReleasedTimeStart"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                          <span class="f-p-h-2">~</span>
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.shipmentReleasedTimeEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                    </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6" v-if="$checkIsBaoguan()">
              <el-form-item label="报关行">
                <f-select v-model="queryForm.declareAgentId"
                          :is-need="queryForm.declareAgentId"
                          :data-list="queryDictCodeListMap['declareAgent_id']"
                          @keyup.enter.native="handleSearch">
                </f-select>
              </el-form-item>
            </el-col>


            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                   <el-button
                                    size="medium"
                                    type="primary"
                                    @click="()=>{$set(this.queryForm,'filterFillMoreThanThreeDays','1')}"
                                    v-if ="!queryForm.filterFillMoreThanThreeDays || queryForm.filterFillMoreThanThreeDays == '0'"
                            >超3天未上传报关单
                            </el-button>
                            <el-button
                                    v-else
                                    size="medium"
                                    type="info"
                                    @click="()=>{$set(this.queryForm,'filterFillMoreThanThreeDays','0')}"
                            >超3天未上传报关单
                            </el-button>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>


        </el-card>
      </el-form>

        <!-- <el-card class="box-card last_card"> -->
          <div class="f-p-b-15">
               <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-download"
                        @click="exportExcel('上传报关单')"
                           v-if="buttonShowList.dc"
                >导出</el-button>
          </div>
 <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="55vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a v-if="$checkIsBaoguan()"
               style="color: #00a0e9"
               @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
            <span v-else>{{ scope.row.orderNo }}</span>
          </template>
          <template v-else-if="scope.column.property === 'containerNo'">
            <a v-if="$checkIsBaoguan()"
               style="color: #00a0e9"
               @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
            >{{ scope.row.containerNo }}</a>
            <span v-else>{{ scope.row.containerNo }}</span>
          </template>
          <template v-else-if="scope.column.property === 'upload'">
            <el-upload
                class="upload-demo"
                action=""
                :http-request="addUploadOssClirnt"
                :show-file-list="false"
                :data="scope.row"
                ref="add"
                :multiple="true"
                :before-upload="$beforeUpload"
                style="float: left;">
              <el-button type="text" size="small">上传</el-button>
            </el-upload>
          </template>
          <template v-else-if="scope.column.property === 'customsDeclareAgent'">
            <div v-for="(i,d) in scope.row.osOrderFileList" :key="d">
              <a
                  style="color: #00a0e9"
                  :href="i.url"
              >{{ i.name }}</a>
              <el-button type="text" style="margin-left: 10px" @click="handDel(i)">删除</el-button>
            </div>
          </template>
          <template v-else-if="scope.column.property === 'status'">
            <span :style="scope.row.status === '已上传' ? 'color:green' : ''">{{ scope.row.status }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
        <!-- </el-card> -->



    </el-card>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {deleteAction, httpAction} from "@/api/manage";
import OssClientMixins from "@/mixins/OssClientMixins";

export default {
  name: "TakeOrderWork",
  mixins: [QueryMixins, OssClientMixins],
  components: {},
  data() {
    return {
      isUsePost: true,
      needQueryDictCodes : ['declareAgent_id','sys_user_id'],
      columns: [
        {
            type: "selection",
        },
        // {
        //     prop: "action",
        //     label: "操作",
        // },
        {
          prop: "declareAgent",
          label: "报关行",
          minWidth: 130,
        },
        {
          prop: "pol",
          label: "起运港",
          minWidth: 150,
        },
        {
          prop: "containerNo",
          label: "柜号",
          minWidth: 110,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 80,
        },
        {
          prop: "blNumber",
          label: "份数",
          width: 44
        },
        {
          prop: "upload",
          label: "上传报关单",
          minWidth: 40
        },
        {
          prop: "customsDeclareAgent",
          label: "报关单",
          minWidth: 220,
        },
        {
          prop: "realCtn",
          label: "件数",
          width: 44
        },
        {
          prop: "pgRemark",
          label: "排柜备注",
          witdh: 50
        },
        {
          prop: "operator",
          label: "排柜操作",
          witdh: 50
        },
        {
          prop: "docOperator",
          label: "文件操作",
          witdh: 50
        },
        {
          prop: "shipmentReleasedTime",
          label: "报关已放行时间",
          witdh: 50
        },
        {
          prop: "etd",
          label: "etd",
          witdh: 50
        },
        {
          prop: "status",
          label: "状态",
          witdh: 50
        },

      ],
      url: {
        //查询列表的接口
        list: "/interface/ttOrder/queryDeclareAgentIPage",

        //导出excel的接口
        delById: "/interface/ttOrder/deleteFileById/",
        exportXlsUrl:"/interface/ttOrder/exportDeclareAgent"

      },
      form: {},

    };
  },
    computed:{
    buttonShowList(){
      return {
        'dc':this.$btnIsShow('declareAgent/UploadCustomsDeclarationForm', '0', '导出'),
      }
    }
  },
  methods: {
    handDel({id, name}) {
      this.$confirm(`删除${name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.delById + id).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch((err) => {
            if (err.messag) {
              this.$message.error(err.message);
            }
          });
    },
    async addUploadOssClirnt(param) {
      let orderNo = param.data.orderNo;
      console.log("要上传的单号", orderNo);
      let uuid = this.generateUUID(6);
      this.handleUploadOss(param, uuid,orderNo);

    },
    // 获取uuid
    generateUUID(length) {
      var d = new Date().getTime();
      let uuid = d.toString() + Math.random().toString().substr(3, length);
      return uuid;
    },

  },
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
