
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div style="display: flex">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-form-item label="统计月份">
              <el-date-picker
                v-model="queryForm.profitRecheckMonth"
                value-format="yyyy-MM"
                format="yyyy-MM"
                type="month"
                placeholder="选择月"
              >
              </el-date-picker>
            </el-form-item>
            <el-col :md="4" :sm="24">
              <el-form-item label="业务员">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.salesman"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="客户名">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.customer"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="国家">
                <f-select
                  v-model="queryForm.country"
                  :dict="'bd_country'"
                  :isNeed="queryForm.country"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="是否亏本">
                <el-select v-model="queryForm.isLoseMoney">
                  <el-option value="0" label="否"></el-option>
                  <el-option value="1" label="是"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              class="no_margin"
              v-if="buttonShowList.fhsj"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="7"
            >
              <el-form-item label="复核时间" label-width="80px">
                <div class="f-flex">
                  <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.recheckTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.recheckTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="复核人">
                <f-select
                  :dict="'sys_user_id'"
                  v-model="queryForm.recheckManId"
                  :is-need="queryForm.recheckManId"
                  :father-val="queryForm.recheckManId"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button
                size="medium"
                type="primary"
                @click="handleReset"
                icon="reload"
                style="margin-left: 8px"
                >重置
              </el-button>

              <el-button
                size="medium"
                type="primary"
                @click="
                  exportExcelPlus(
                    url.exportXlsUrl,
                    '订单利润已复核导出'
                  )
                "
                icon="reload"
                style="margin-left: 8px"
                >导出
              </el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">
      <div>
        <el-button
          type="primary"
          size="medium"
          @click="rollback"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.ht"
          >订单回退到待复核
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="65vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from "@/utils/util";
import { postAction } from "../../api/manage";

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      dictCodes: [
        "is_default",
        "transportStatus",
        "order_importer",
        "bd_port",
        "orderStatus",
        "pay_type",
        "pglx",
      ],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "profitRecheckMonth",
          label: "月份",
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "cmb",
          label: "计费重",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "profit",
          label: "利润",
        },
        {
          prop: "avgProfit",
          label: "计费重平均利润",
          sortable: "avgProfit",
        },
        {
          prop: "isLoseMoney",
          label: "是否亏本",
        },
        {
          prop: "recheckTime",
          label: "复核时间",
          minWidth: 100,
        },
        {
          prop: "recheckMan",
          label: "复核人",
          minWidth: 100,
        },
        {
          prop: "recheckResult",
          label: "复核结果",
          minWidth: 100,
        },
        {
          prop: "recheckRemark",
          label: "核对备注",
          minWidth: 100,
        },
      ],
      url: {
        list: "/order/kdOrder/countKdOrderProfit",
          exportXlsUrl:
          "/order/sale/SalesOrderDetails/exportKdOrderProfitCheck",
        backToWaitCheck: "/order/kdOrder/backToWaitCheck",
      },
      isUsePost: true,
      initData: {
        profitRecheck: "1",
      },
    };
  },
  methods: {
    intoDocpage(orderNo) {
      this.$intoDocPage(orderNo);
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether = "0";
      this.queryForm.profitRecheck = "1";
      if (this.$refs["GTable"]) {
        this.$refs["GTable"].handleReset();
      }
      this.handleQuery();
    },
    rollback() {
      postAction(this.url.backToWaitCheck, {
        orderNoList: this.selectionRows.map((item) => item.orderNo),
      }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        }
      });
    },
  },
  created() {
    this.queryForm.isWhether = "0";
    this.queryForm.profitRecheck = "1";
  },
  computed: {
    buttonShowList() {
      return {
        fhsj: this.$btnIsShow("kdorder/alreadyAudit", "0", "复核时间"),
        ht: this.$btnIsShow("kdorder/alreadyAudit", "0", "订单回退到待复核"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
