<template>
  <div>
    <el-dialog
        :title="'复核分税：' + week"
        :visible="visible"
        width="75%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose"
        append-to-body
    >
        <el-card class="box-card">
          <el-row class="inputs_row">
              <el-col :xs="24" :sm="12" :md="12" :lg="8">
                  <span>柜号：{{reviewTaxForm.containerNo}}</span>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="8">
                <span>国家：{{reviewTaxForm.country}}</span>
              </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>ETD：{{reviewTaxForm.etd}}</span>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>排柜操作：{{reviewTaxForm.operator}}</span>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>文件操作：{{reviewTaxForm.docOperator}}</span>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <span>派送操作：{{reviewTaxForm.endOperator}}</span>
            </el-col>

          </el-row>
        </el-card>
      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="reviewTaxForm.orderVOs"
            :cell-style="{ 'text-align': 'center' }"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'orderNo'">
<!--                  点击订单号 跳进销售结算页面-->
                  <a :style="getColorByRow(scope.row,'orderNo')" @click.prevent="$intoDocPageCost(scope.row.orderNo) && handleClose()">{{ scope.row.orderNo}}</a>
                </template>
                <template v-else-if="scope.column.property === 'hasTax'">
                  <span v-text="scope.row.hasTax" :style="getColorByRow(scope.row, 'hasTax')"></span>
                </template>
              <template v-else-if="scope.column.property === 'taxMoney'">
                <span v-text="scope.row.taxMoney" :style="getColorByRow(scope.row, 'taxMoney')"></span>
              </template>
              <template v-else-if="scope.column.property === 'importerType'">
                <span v-text="parseDict2(scope.row.importerType,'order_importer')"
                      :style="getColorByRow(scope.row, 'importerType')"></span>
              </template>
              <template v-else-if="scope.column.property === 'billman'">
                <span v-text="scope.row.billman" :style="getColorByRow(scope.row, 'billman')"></span>
              </template>
              <template v-else-if="scope.column.property === 'action'">
                <el-button
                    type="text"
                    v-if="scope.row.hasTax === '否' && scope.row.isGenerateGuanshui === '0'"
                    @click="handleSetNormal(scope.row.orderNo)">设为正常</el-button>
              </template>
            </template>
        </f-table>

      </el-card>
    </el-dialog>


  </div>
</template>

<script>
import {getAction, postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
export default {
  name: "ReviewTaxDialog",
  mixins: [QueryMixins],
  components: {
  },
  props:{
    containerId:{
      type:String
    },
    week:{
      type:String
    },
    visible:{
      type:Boolean
    },
    importerDictList : {
      type : Array,
      default : () => []
    }

  },
  data() {
    return {
      disableMixinMounted : true,
      reviewTaxForm:{orderVOs:[]},
      loading:false,
      url: {
        queryReviewTaxVOById:'operation/container/delivery/queryReviewTaxVOById',
        updateOrderNormalTax:'operation/container/delivery/updateOrderNormalTax',
      },
      columns: [

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "hasTax",
          label: "是否包税",
        },
        {
          prop: "taxMoney",
          label: "税金",
        },
        {
          prop: "importerType",
          label: "进口商类型",
        },
        {
          prop: "billman",
          label: "收款人",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],

    };
  },
  watch: {
    visible(val) {
      if(val && this.containerId) {
        this.initList();
      }
      if (this.importerDictList && this.importerDictList.length > 0 && Array.isArray(this.importerDictList)) {
        this.queryDictCodeListMap['order_importer'] = JSON.parse(JSON.stringify(this.importerDictList));
      }
    }
  },
  methods: {
    initList(){
      this.loading = true;
      getAction(this.url.queryReviewTaxVOById,{id:this.containerId})
          .then(res => {
            this.reviewTaxForm = res.result;
            this.loading = false;
          }).catch(() => {
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      })
    },
    handleSetNormal(no) {
      postAction(this.url.updateOrderNormalTax,{orderNo : no})
          .then(res => {
            this.$message.success(res.message);
            this.initList();
          })
    },
    // 判断是否变红
    getColorByRow({ hasTax, taxMoney, isGenerateGuanshui }, key) {


      if (hasTax === '否' && Number(taxMoney) === 0 && isGenerateGuanshui === '0') {
        return "color : red";
      }
      if (key === 'orderNo') {
        return "color : #00a0e9";
      }
      return "";
    },
    goToCost({orderNo}){
      this.$intoDocPageCost(orderNo);
      this.handleClose();
    },
    handleClose() {
      //重置对象
      this.reviewTaxForm = {orderVOs:[]};
      this.$emit("ok");
    },
  },
  computed:{
  }
};
</script>

<style lang="scss" scoped>
</style>
