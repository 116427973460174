<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo" type="textarea"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="visible = true"
                >订单分税</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-dialog
      title="发送美国税单"
      :visible="visible"
      width="50%"
      custom-class="c-custom-dialog"
      append-to-body
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <span>"确定发送税单邮件吗，请选择是否加税"</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSubmit('2')" v-if="selectedType !== '3'"
          >加税20%</el-button
        >
        <el-button @click="handleSubmit('1')" v-if="selectedType !== '3'"
          >加税10%</el-button
        >
        <el-button @click="handleSubmit('0')" v-if="selectedType !== '3'"
          >不加税</el-button
        >
        <el-button @click="handleSubmit('3')" v-if="selectedType === '3'"
          >确定</el-button
        >
        <el-button @click="handleClose" v-if="selectedType === '3'"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { postAction } from "@/api/manage";
export default {
  components: {},
  data() {
    return {
      queryForm: {},
      selectedType: "",
      visible: false,
    };
  },
  methods: {
    handleSubmit(isAdd10) {
      if (!this.queryForm.orderNo) {
        this.$message.warning("请输入订单号");
        return;
      }
      postAction("/operation/container/sendUsShuidanEmailBatchOrderNo", {
        orderNo: this.queryForm.orderNo,
        isAdd10,
      }).then((res) => {
        if (res.success) {
          if (res.result) {
            let str = "";
            res.result.forEach((i) => {
              str += i;
            });
            this.$confirm(str, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
            })
              .then(() => {
                this.handleClose();
              })
              .catch(() => {});
          }
        }
      });
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>

<style>
</style>