
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div style="display: flex">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="业务员">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.salesman"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="客户名">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.customer"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="国家">
                <f-select
                  v-model="queryForm.country"
                  :dict="'bd_country'"
                  :isNeed="queryForm.country"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="是否亏本">
                <el-select v-model="queryForm.isLoseMoney">
                  <el-option value="0" label="否"></el-option>
                  <el-option value="1" label="是"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item prop="type" label="收款状态">
                <el-select v-model="queryForm.recStatus" clearable>
                  <el-option value="0" label="无应收"></el-option>
                  <el-option value="1" label="未收款"></el-option>
                  <el-option value="2" label="部分收款"></el-option>
                  <el-option value="3" label="已收款"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
              <el-form-item label="订单跳入时间" label-width="120px">
                <div class="f-flex">
                  <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.jumpInTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.jumpInTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button
                size="medium"
                type="primary"
                @click="handleReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</el-button
              >
              <el-button
                size="medium"
                type="primary"
                @click="handleExportXls('待复核订单导出')"
                v-if="buttonShowList.dcdfh"
                icon="reload"
                style="margin-left: 8px"
                >待复核订单导出</el-button
              >
              <el-button
                size="medium"
                type="primary"
                @click="checkBatchDialog"
                :disabled="selectionRows.length === 0"
                v-if="buttonShowList.plfh"
                icon="reload"
                style="margin-left: 8px"
                >批量复核</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="65vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'recStatus'">
<!--            <span v-if="scope.row.recStatus === '3'">已收款</span>-->
<!--            <span v-else-if="scope.row.recStatus === '2'">部分收款</span>-->
<!--            <span v-else-if="scope.row.recStatus === '1'">未收款</span>-->
<!--            <span v-else>无应收</span>-->
          </template>
        </template>
        <!--              <template v-else-if="scope.column.property === 'settleStatus'">-->
        <!--                {{parseDict(scope.row.settleStatus,'settle_status')}}-->
        <!--              </template>-->
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog title="批量复核订单" :visible.sync="dialogFormVisibleBatchAudit">
      <el-form ref="form" :model="updateBatchForm" label-width="120px">
        <el-form-item label="核对是否通过">
          <el-radio-group v-model="updateBatchForm.profitRecheck">
            <el-radio :label="'1'">通过</el-radio>
            <el-radio :label="'0'">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="updateBatchForm.remark"
            type="textarea"
            :rows="3"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleBatchAudit = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="checkByBatch">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction, putAction } from "@/api/manage";
import { setUpRouteUtil } from "@/utils/util";

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      updateBatchForm: {},
      dictCodes: [
        "is_default",
        "transportStatus",
        "order_importer",
        "bd_port",
        "orderStatus",
        "pay_type",
        "pglx",
      ],
      ids: [],
      row: {},
      form: {},
      dialogFormVisibleBatchAudit: false,
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "cmb",
          label: "计费重",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "profit",
          label: "利润",
        },
        {
          prop: "avgProfit",
          label: "计费重平均利润",
          sortable: "avgProfit",
        },
        {
          prop: "recStatus",
          label: "收款状态",
        },
        {
          prop: "isLoseMoney",
          label: "是否亏本",
        },
        {
          prop: "orderJumpTime",
          label: "订单跳入时间",
        },
        {
          prop: "recheckRemark",
          label: "核对备注",
          minWidth: 100,
        },
      ],
      url: {
        list: "/order/kdOrder/countKdOrderProfit",
        exportXlsUrl: "/order/sale/SalesOrderDetails/exportKdOrderProfitCheck",
        checkOrderByBatch: "/order/kdOrder/checkKdOrderBatch",
      },
      isUsePost: true,
      initData: {
        profitRecheck: "0",
      },
    };
  },
  methods: {
    /* 批量核对弹框*/
    async checkBatchDialog() {
      let isContinue = true;
      if(this.selectionRows.some(item=>Number(item.avgProfit)>4)) {
        await this.$confirm("是否确认，存在订单利润超出4元/KG?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
        })
        .catch(() => {
          isContinue = false;
        });
      }
      if(!isContinue) {
        return
      }
      this.dialogFormVisibleBatchAudit = true;
      this.$set(this.updateBatchForm, "profitRecheck", "1");
    },
    /* 批量复核订单 */
    checkByBatch() {
      let orderNos = this.selectedRowOrderNos;
      if (!orderNos || orderNos.length === 0) {
        this.$message.warning("请勾选要复核的订单");
      }
      this.updateBatchForm.orderNoList = orderNos;
      postAction(this.url.checkOrderByBatch, this.updateBatchForm).then(
        (res) => {
          this.dialogFormVisibleBatchAudit = false;
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          }
        }
      );
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$intoDocPage(orderNo);
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether = "0";
      this.queryForm.profitRecheck = "0";
      if (this.$refs["GTable"]) {
        this.$refs["GTable"].handleReset();
      }
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.isWhether = "0";
    this.queryForm.profitRecheck = "0";
  },
  computed: {
    buttonShowList() {
      return {
        dcdfh: this.$btnIsShow("kdorder/waitAudit", "0", "待复核订单导出"),
        plfh: this.$btnIsShow("kdorder/waitAudit", "0", "批量复核"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
