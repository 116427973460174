import {postAction} from "@/api/manage";

export default {
    data() {
        return {
            usVisible : false,
            usSelectedIds:[],
            usSelectedNos :[],
            usSelectedType : '',
            usRow:{},
        }
    },
    methods : {
        realSendMeiguoShuidan(row,type) {
            this.usSelectedType = type;
            let ids = [];
            if (row && row.id) {
                ids.push(row.id);
                if (type === '3') {
                    this.usSelectedNos = [row.orderNo];
                    ids = [row.containerId];
                }else {
                    this.selectWeek = row.week;
                    ids = [row.id];
                }
            }else {
                ids = this.selectionRows.map(s => s.id);
            }
            this.usSelectedIds = ids;
            this.usRow = row;
            this.usVisible = true;
        },
        closeUsDialog(){
          this.usVisible = false;
        },
        //弹窗 选择数据类型
        okUs(res){
            if (res.success) {
                if (this.usSelectedType !== '3') {
                    if (this.usRow && this.usRow.id) {
                        this.selectWeek = this.usRow.week;
                    }
                    if (res.result && res.result.length === 0) {
                        this.$message.warning("没有需要发送税单的订单");
                    }else {
                        this.sendEmailResult = res.result;
                        this.resultTitle = "发送税单邮件结果：";
                        this.alertVisible = true;
                    }
                }
                //关闭弹窗
                this.closeUsDialog();
                this.handleQuery();
            }
        }

    }
}
